import React, { useEffect, useState , useCallback , useRef } from "react";
import { Container } from "react-bootstrap";
import * as catalogService from '../../services/catalog';
import ModalForm from "../../shared/ModalForm";
import { useDispatch } from "react-redux";
import * as alertActions from "../../actions/alert";

const CatalogForm = ({ catalogType , headerData,  parent , data , isVisible , hide , output}) => {

  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    key: '',
    name: '',
    parent : parent,
    catalog_type: catalogType.id,
    created_by: 1
  });

  const setFormStage = useCallback(() => {
    setFormState({
        key: '',
        name: '',
        parent ,
        catalog_type : catalogType.id 
      });
  },[catalogType.id , parent])

  useEffect(() => {
    setFormStage()
   }, [setFormStage]);

  const prevState = useRef(formState);

 
  useEffect(() => {
    if(data !== null){
      setFormState(data)
    }
  },[data]);


  const closeModal = useCallback((data) => {
    hide();
    setFormState(prevState.current);
    if(data !== ''){
      if(data.id){
         catalogService.update(data.id , data).then(([response, error]) => {
           if(response){
            dispatch(alertActions.success('Item Updated Successfully'));
              output();
           } else if(error){
            dispatch(alertActions.error(error.response));
         }
         });
      }else if(data){
        catalogService.add(data).then(([response, error]) => {
           if(response){
            dispatch(alertActions.success('Item Added Successfully'));
             output();
           } else if(error){
            dispatch(alertActions.error(error.response));
           }
         });
      }
    }
  }, [hide, output, dispatch]);



  const fields = [
    { label: 'Key', name: 'key', type: 'text', required: true, placeholder: 'Key', sm: 12, md: 12, lg: 12, xl: 12 },
    { label: 'Name', name: 'name', type: 'text', required: true, placeholder: 'Name', sm: 12, md: 12, lg: 12, xl: 12 }
  ];

  return (
    <Container fluid>
    {isVisible ? 
     <ModalForm  
     show={isVisible} 
     onHide={(e) => closeModal(e)} 
     data={formState} 
     fields={fields} 
     title={data !== null ? 'Edit ' + catalogType.title : 'Add ' + catalogType.title} 
     header={headerData}
    /> 
    : null }
  </Container>
  );
}

export default CatalogForm;