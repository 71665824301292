import React , { useEffect, useState , useCallback , useRef} from "react";
import { Container, Col, Form, Row } from 'react-bootstrap';
import FormBlock from '../../shared/FormBlock';
import * as FaqService from '../../services/faq';
import {  useDispatch } from "react-redux";
import * as alertActions from "../../actions/alert";

const FaqForm = ({data , hide , output}) => {
 
  const dispatch = useDispatch();
  const [faqData , setFaqData ] = useState({
    question: '',
    answer:''
  });
  const prevState = useRef(faqData);

  useEffect(() => {
    if(data){
      setFaqData(data);
    }else {
      setFaqData(prevState.current);
    }
  },[data])

  const handleResponse = useCallback(([response, error]) => {
    if(response){
      output();
      hide();
    } else if(error){
      dispatch(alertActions.error(error.response));
    }
  }, [hide, output , dispatch]);


  const saveForm = useCallback((data) => {
    hide();
    if(data !== ''){
      if(data.id){
        FaqService.updateFaq(data.id , data).then(handleResponse);
      }else if(data.question){
        FaqService.addFaq(data).then(handleResponse);
      }
    }
  }, [hide, handleResponse]);

  const handleChange = useCallback((e) => {
    setFaqData({
      ...faqData,
      [e.name]: e.value
    });
  }, [faqData]);

  const fileds = [
    { label: 'Question*', name: 'question', type: 'text', required: true, placeholder: 'Question', sm: 12, md: 12, lg: 12, xl: 12 },
    { label: 'Answer', name: 'answer', type: 'html', required: true, placeholder: 'Answer', sm: 12, md: 12, lg: 12, xl: 12 },
    { name: 'answer', type: 'button',  placeholder: 'Save',  sm: 12, md: 12, lg: 12, xl: 12 },
  ]
  return (

    <div className="bg-white">
    <Container fluid>
      <Row className="justify-content-md-center py-5 mx-0">
        <Col md={12}>
          <h4 className="mb-4">FAQ</h4>
          <Form onSubmit={() => saveForm(faqData)}>
            <FormBlock fields={fileds} size={12} getData={(e) => handleChange(e)} formData={faqData} />
          </Form>
        </Col>
      </Row>
    </Container>
  </div>
  );
}

export default FaqForm;