import React , { useEffect , useState} from 'react';
import { getStatus , history  } from '../../helpers';
import { Status } from '../../helpers/constants';
import AppTable from '../../shared/AppTable';
import TooltipHover from '../../shared/TooltipHover';

const ExpertListTable = ({ assetList }) => {

  const [assetExpertList , setAssetExpertList] = useState([]);

  useEffect(() => {
    if(assetList && assetList.length > 0){
      setAssetExpertList(assetList);
    }
  },[assetList])

  const subColumns = [
    {
      Header: "Action",
      accessor: "action",
      className: "action-l text-center",
      Cell: (cell) => (
        <div>
          {cell.row.original.status === Status.UNDER_REVIEW.value ?
            <TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/expertAssetView/'+cell.row.original.asset+'/'+cell.row.original.id+'/'+cell.row.original.status)} variant='link' iconClass='default' btnIcon='edit' message='Rating Asset' /> : 
            cell.row.original.status === Status.DRAFT.value ? '' :
            <TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/expertAssetView/'+cell.row.original.asset+'/'+cell.row.original.id+'/'+cell.row.original.status)} variant='link' iconClass='default' btnIcon='eye' message='Rating Preview' />}
        </div>
      )
    },
    {
      Header: "Expert Name",
      accessor: "specific_topic",
      sortType: "basic",
      Cell: (cell) => (<div>{cell.row.original.first_name + ' ' + cell.row.original.last_name}</div>)
    },
    {
      Header: "Raw Score",
      accessor: "raw_score",
      sortType: "basic"
    },
    {
      Header: "Status",
      accessor: "status",
      sortType: "basic",
      Cell: (cell) => (<div>{getStatus(cell.row.original.status)}</div>)
    },

  ];

  return (
    <>
      <AppTable columns={subColumns} data={assetExpertList ? assetExpertList : []} />
    </>
  )
}
export default ExpertListTable;