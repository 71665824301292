import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Container, Form, Row, Col, Card, Modal } from 'react-bootstrap';
import * as predefinedResponseService from '../../services/predefinedResponse';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';

const PredefinedResponseModal = ({ data, isVisible, title, hide, predefinedResponseData }) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    suggestion: '',
  });
  const prevState = useRef(formData);

  const closeModal = useCallback(() => {
    setFormData(prevState.current);
    hide();
  }, [hide]);

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData]);

  useEffect(() => {
    if(data && data.id !== 0){
      setFormData(data);
    }
  }, [data]);

  const saveCreateData = useCallback(() => {
    dispatch(loaderActions.loader(true));
    const form = new FormData();
    form.append('suggestion', formData.suggestion);

    if(data && data.id !== 0){
        predefinedResponseService.updatePredefinedResponse(data.id, form).then(([response, error]) => {
            if (response) {
              setFormData(prevState.current);
              setValidated(false);
              dispatch(alertActions.success('Suggestion Updated Successfully'));
              closeModal();
            } else if (error) {
              dispatch(alertActions.error(error.response));
            }
            dispatch(loaderActions.loader(false));
          })
    }else{
        predefinedResponseService.createPredefinedResponse(form).then(([response, error]) => {
            if (response) {
              setFormData(prevState.current);
              setValidated(false);
              dispatch(alertActions.success('Suggestion Added Successfully'));
              closeModal();
            } else if (error) {
              dispatch(alertActions.error(error.response));
            }
            dispatch(loaderActions.loader(false));
          })
    }
    
  }, [dispatch, formData.suggestion]);

  const saveData = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity()) {
      const obj = predefinedResponseData.find(d => (d.suggestion).toLowerCase() === (formData.suggestion).toLowerCase());
      if(obj){
        dispatch(alertActions.error('This suggestion already exists'));
      }else{
        setValidated(true);
        saveCreateData();
      }
      
    }
  }, [saveCreateData, dispatch]);

  return (
    <Container fluid>
      {isVisible ?
        <Modal show={isVisible} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Form noValidate validated={validated} onSubmit={saveData}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
                <Card className="border-0" style={{ maxHeight: 'calc(60vh - 210px)', overflow: 'auto' }} >
                    <Card.Body>
                        <Row>
                            <Col md={10} xs={9}>
                                <Form.Control type='text' className="rounded-0" required placeholder='Add a suggestion' name='suggestion' value={formData.suggestion} onChange={(e) => handleChange(e.target)} rows={1} />
                            </Col>
                            <Col md={2} xs={3}>
                                <Button variant="primary" className="w-100 rounded-0" type="submit" >Save</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                    
                </Card>
            </Modal.Body>
            </Form>
        </Modal>
        : null}
    </Container>
  )
}
export default PredefinedResponseModal;