import React, { useEffect, useState , useCallback } from "react";
import { Container, Row, Button, Col } from "react-bootstrap";
import DragDropCustom from "../../shared/DragDropCustom";
import * as criteriaService from '../../services/criteria';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import * as alertAction from '../../actions/alert';
import * as loaderAction from '../../actions/loader';

const RatingCriteria = () => {

  const dispatch = useDispatch();
  const [criteria, setCriteria] = useState([]);

  useEffect(() => {
    dispatch(loaderAction.loader(true));
    criteriaService.fetchAll().then(([response, error]) => {
      if(response){
        setCriteria(response.results);
      }else if(error) {
        dispatch(alertAction.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [dispatch]);

  const onDataChange = (list) => {
    setCriteria(list);
  };

  const saveData = useCallback(() => {
    dispatch(loaderAction.loader(true));
    criteriaService.saveCriteria(criteria).then(([response, error]) => {
      if(response){
        setCriteria(response.criteria_list);
        dispatch(alertAction.success('Rate Criteria Updated Successfully'));
      }else if(error){
        dispatch(alertAction.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  },[dispatch , criteria])

  return (
    <Container fluid>
      <Row className='py-2'>
        <Col md={8}>
          <h4> Default Rating Criteria </h4>
        </Col>
        <Col md={4} className="text-right">
        <Button className="btn btn-primary" variant="primary" onClick={saveData}> <FontAwesomeIcon className="text-white" icon="edit"  />Save </Button>
        </Col>
      </Row>
      {criteria.length > 0 ? 
      <DragDropCustom data={criteria} useInfo={true} onDataChange={onDataChange} infoHide={false} />
      : null }
    </Container>
  );
};

export default RatingCriteria;
