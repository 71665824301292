import React, { useState, useEffect } from "react";
import { Container, Row, Col, Alert, Modal, Button } from 'react-bootstrap';
import AppTable from "../../shared/AppTable";
import TooltipHover from "../../shared/TooltipHover";
import { Status } from "../../helpers/constants";
import { Storage } from "../../helpers/storage";
import { useDispatch, useSelector } from 'react-redux';
import * as loaderAction from "../../actions/loader";
import { dateOnlyFormat, history, UserRole } from "../../helpers";
import SearchField from '../../shared/SearchField';
import {fetchApplicationList} from '../../actions/application';

const Specialization = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('')
  useEffect(() => {
        dispatch(loaderAction.loader(true));
        dispatch(fetchApplicationList());
        dispatch(loaderAction.loader(false));
  }, [dispatch])
  const applications = useSelector((state) => state.application.appData.applications);
  const group = Storage.getSession().group;
  const [notifyType, setNotification] = useState('');

  useEffect(() => {
    if(applications && applications.length > 0){
      let val = 0;
      applications.forEach(a => {
        if(a.status === Status.APPROVED.value && (a.bio_status === 0 || a.bio_status === Status.DRAFT.value)){
          val += 1;
        }
      })
      if(val === 1){
        const obj = applications.find(e => e.status === Status.APPROVED.value && (e.bio_status === 0 || e.bio_status === Status.DRAFT.value));
        history.push('/bio/' + obj?.application_id);
      }else if(val > 1){
        setNotification('highlight');
      }
    }
  }, [applications])
  
  const columns = [
    {
      Header: "Action",
      accessor: "action",
      className: "action-l text-center",
      Cell: (row) => (
        <div>
          {(row.row.original.status === Status.DRAFT.value) || (row.row.original.status === Status.REVISE.value) ?
            <TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/applicationExpertEdit/' + row.row.original.application_id)} variant='link' iconClass='default' btnIcon='edit' message='Edit Application' />
            : row.row.original.status === Status.APPROVED.value && row.row.original.bio_status === 0 ? 
            <>
             
            <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='address-book' message='Apply Bio' handleEvent={() => history.push('/bio/' + row.row.original.application_id)} showGuide={notifyType === 'highlight'} pageMsg={'Apply Bio'} /> </>
            : row.row.original.bio_status === Status.DRAFT.value ?
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='user-edit' message='Apply Bio' handleEvent={() => history.push('/bio/' + row.row.original.application_id)} showGuide={notifyType === 'highlight'} pageMsg={'Apply Bio'} /> : null}
              {(row.row.original.status !== Status.DRAFT.value) && (row.row.original.status !== Status.REVISE.value) ?
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='eye' message='Application Preview' handleEvent={() => history.push('/applicationView/' + row.row.original.application_id)} /> : null }
              {row.row.original.bio_status !== 0 ? 
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='address-book' message='Bio Preview' handleEvent={() => history.push('/bioView/' + row.row.original.application_id)} /> 
            : null}
        </div>
      ),
    },
    {
      Header: "Specialization",
      accessor: "catalog_name",
      sortType: "basic",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (row) => (<div>{row.row.original.status === 1 ?
        <TooltipHover type='text' text='APPROVED' textClass='text-cener' message='You are all set to update your public bio now.' handleEvent={() => null} /> : row.row.original.status === 2 ?
          <TooltipHover type='text' text='UNDER REVIEW' textClass='text-cener' message='Your application is under review, we thank you for your patience.' handleEvent={() => null} /> : row.row.original.status === 3 ?
            <TooltipHover type='text' text='REJECTED' textClass='text-cener' message='Your application is rejected, so Please contact admin for any enquiry .' handleEvent={() => null} /> : row.row.original.status === 4 ?
              <TooltipHover type='text' text='DRAFT' textClass='text-cener' message='Your application is still not complete, please complete it and submit it for review .' handleEvent={() => null} /> : row.row.original.status === 5 ?
                <TooltipHover type='text' text='REVISE' textClass='text-cener' message='Admin has request to revise your application, please provide complete information and submit it again for review.' handleEvent={() => null} /> : null}
      </div>)
    },
    {
      Header: "Bio Status",
      accessor: "bio_status",
      Cell: (row) => (<div>{row.row.original.bio_status === 0 ?
        <TooltipHover type='text' text='PENDING' textClass='text-cener' message='Please stand by till your application is approved by Qrata Administration .' handleEvent={() => null} /> : row.row.original.bio_status === 1 ?
          <TooltipHover type='text' text='APPROVED' textClass='text-cener' message='You are all set to rate topics of your interest - check out the assets section.' handleEvent={() => null} /> : row.row.original.bio_status === 2 ?
            <TooltipHover type='text' text='UNDER REVIEW' textClass='text-cener' message='Your Bio is under review - once approved you will be all set! .' handleEvent={() => null} /> : row.row.original.bio_status === 3 ?
              <TooltipHover type='text' text='REJECTED' textClass='text-cener' message='Your bio is rejected , so  please contact admin any enquiry.' handleEvent={() => null} /> : row.row.original.bio_status === 4 ?
                <TooltipHover type='text' text='DRAFT' textClass='text-cener' message='Your Public Bio is still not complete, please complete it and submit it for review.' handleEvent={() => null} /> : null}
      </div>)
    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on) : 'N/A'}</div>)
    },

  ];
  const guideMsg = "Click here to create a specialization";

  return (
    <Container fluid>
      <Row className="no-gutters">
        <Col md={7} sm={6}  className="mb-md-0 mb-3"><h4>My Specializations</h4></Col>
        <SearchField md={4}  xs={10} action={setSearch} />

        <Col md={1} sm={6}  xs={2} className="text-right">
          <TooltipHover type='button' btnSize='md' variant='primary' message='Add Specialization' btnIcon='plus' iconClass='text-white' handleEvent={() => history.push('/applicationExpert')} showGuide={group[0] === UserRole.EXPERT.id && !applications} pageMsg={guideMsg}/>
        </Col>    
    
      </Row>
      <AppTable columns={columns} globalFilter={search}  data={applications ? applications : []} />
      
      </Container>
  );
}

export default Specialization;