import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'query/';

 export async function getCriticismList(search='', pageSize='', offset, ordering="", filter, memberType = '', status) {
    return await handleApi(axiosClient.get(`${BASE}1/${memberType === 1 ? 'list/?' : memberType === 2 ? 'global_query/?' : ''}status=${status}&query=4&search=${search}&limit=${pageSize}&qtype=member&offset=${offset}&ordering=${ordering}&${filter}`));
  }

  export async function deleteTicket(id, user = '') {
    if (user !== '') {
      return await handleApi(axiosClient.delete(`${BASE}${id}/`));
    }
    else {
      return await handleApi(axiosClient.delete(`${BASE}${id}/delete/`));
    }
  }