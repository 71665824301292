import React, { useEffect, useState, useCallback, useRef } from "react";
import { Container } from 'react-bootstrap';
import ModalForm from "../../shared/ModalForm";
import * as userService from '../../services/user';
import { useDispatch, useSelector } from "react-redux";
import * as alertActions from "../../actions/alert";
import * as loaderAction from '../../actions/loader';

const UserForm = ({ data, isVisible, hide, output }) => {

  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.loginData);

  const [groups, setGroups] = useState([]);
  const [trustscore , setTrustscore] = useState('');
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '' , 
    custom_message: '',
    reason_to_join: '',
  });

  const prevState = useRef(userData);

  useEffect(() => {
    const getGroup = () => {
      userService.getGroups().then(([response, error]) => {
        if (response) {
          setGroups(response.results);
        } else if(error) {
          dispatch(alertActions.error(error.response));
        }
      });
    }
    getGroup()
  }, [dispatch]);

  useEffect(() => {
    userService.fetchUserProfile(sessionData.id).then(([response, error]) => {
      if (response) {
        setUserData({
            ...userData,
            reason_to_join: response.user_info && response.profile.reason_to_join ? response.profile.reason_to_join : ""
          })
      }else if (error) {
        dispatch(alertActions.error(error.response));
      }
    })
  }, [])

  const getTrustScore = useCallback((id) => {
    if(id !== ''){
      userService.getTrustScore(id).then(([response , error]) => {
        if(response){
          setTrustscore(response.trustworthiness);
        }else if(error){
          dispatch(alertActions.error(error.response));

        }
      })
     }
  },[dispatch])

 

  useEffect(() => {
    if (data) {
      getTrustScore(data.id);
      setUserData({ ...data, groups: data.groups[0] , trustscore });
    } else {
      setUserData(prevState.current);
    }
  }, [data , getTrustScore , trustscore])

  const closeModal = useCallback((data) => {
    if (data) {
      dispatch(loaderAction.loader(true));
        if (data.id) {
          data.groups = [Number(data.groups)];
          userService.updateUser(data.id, data).then(([response , error]) => {
            if(response){
              dispatch(alertActions.success('User updated successfully.'));
              output();
              hide();
            }else if(error){
              dispatch(alertActions.error(error.response));
            }
          });
        } else {
          userService.inviteExpert(data).then(([response , error]) => {
            if(response){
              dispatch(alertActions.success('User added successfully.'));
              output();
              hide();
            }else if(error){
              dispatch(alertActions.error(error.response));
            }
          });
        }
        dispatch(loaderAction.loader(false));
    } else {
      hide();
    }
  }, [ hide , dispatch ,  output ]);



  const fields = [
    [{ label: 'First Name*', name: 'first_name', type: 'text', required: true, placeholder: 'First Name', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Last Name*', name: 'last_name', type: 'text', required: true, placeholder: 'Last Name', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Username*', name: 'username', type: 'text', readOnly: userData.id ? true : false, required: true, placeholder: 'Username', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Email Address*', name: 'email', type: 'email', readOnly: userData.email ? true : false, required: true, placeholder: 'Email Address', sm: 12, md: 6, lg: 6, xl: 6 }],
    { label: 'Group*', name: 'groups', type: 'select', required: true, placeholder: '---Select Group---', options: groups, sm: 12, md: 12, lg: 12, xl: 12 },
    { label: 'Custom Message', name: 'custom_message', type: 'textarea', sm: 12, md: 12, lg: 12, xl: 12},
    { label: 'Why I am involved with Qrata?', name: 'reason_to_join', type: 'textarea', sm: 12, md: 12, lg: 12, xl: 12},
  ]

  const editFields = [
    ...fields.slice(0,-2)
  ]

  return (
    <>
      <Container fluid>
        {isVisible ?
          <ModalForm
            show={isVisible}
            onHide={(e) => closeModal(e)}
            data={userData}
            fields={data ? editFields : fields}
            title={data ? 'Edit User' : 'Add User'}
            button={data ? 'Update' : 'Save'}
          />
          : null}
      </Container>
    </>
  );
}

export default UserForm;