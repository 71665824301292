import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccordionForm from '../../shared/AccordionForm';
import * as cataloServices from '../../services/catalog';
import * as assetService from '../../services/asset';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import { useDispatch, useSelector } from 'react-redux';
import { Status, UserRole } from '../../helpers/constants';
import { useParams } from 'react-router-dom';
import { useBoolean } from '../../hooks/basic';
import CommentModal from '../../shared/CommentModal';
import { history } from "../../helpers";
import { HasPermission } from '../../shared/HasPermission';
import ConfirmModal from '../../shared/ConfirmModal';
import CommentList from '../../shared/CommentList';

const RateFormView = () => {
 
  const dispatch = useDispatch();
  const { id, assetexpertId, statusExpert } = useParams();
  const [area, setArea] = useState([]);
  const [branch, setBranch] = useState([]);
  const loginData = useSelector((state) => state.auth.loginData);
  const [majorCatalog, setMajorCatalog] = useState([]);
  const [minorCatalog, setMinorCatalog] = useState([]);
  const [category, setCategory] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [update, setUpdate] = useState(false);
  const [editable, setEditable] = useState(false);
  const [comments, setComments] = useState([]);
  const [status, setStatus] = useState('');
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [modalText , setModalText] = useState('');
  const [identity , setIdentity] = useState('');
  const [detectHide , setDetectHide] = useState(true);

  const [formData, setFormData] = useState({
    specific_topic: '',
    url: '',
    notes: '',
    created_by: '',
    branch_opinion: '',
    opinion_percent: '',
    status: '',
    area: '',
    major: '',
    minor: '',
    category: '',
    sub_cat: '',
    topic: '',
    sub_topic: ''
  });

  const [comment, setComment] = useState({ notes: '' })
  const [overviewFormData, SetOverviewFormData] = useState({
    strengths: '',
    weakness: '',
    evaluation: ''
  });



  // GET AREA & BRANCH
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    assetService.getArea().then(([response, error]) => {
      if (response) setArea(response)
      if (error) dispatch(alertActions.error(error.response));
    })
    assetService.getBranch().then(([response, error]) => {
      if (response) setBranch(response)
      if (error) dispatch(alertActions.error(error.response));
    })
    dispatch(loaderAction.loader(false));

    return () => {
      setArea([]);
      setBranch([]);
    }
  }, [dispatch])

  // GET MAJOR CATALOG 
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    cataloServices.getMajorCatalog().then(([response, error]) => {
      if (response) {
        setMajorCatalog(JSON.parse(response));
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });

    return () => {
      setMajorCatalog([]);
    }
  }, [dispatch])


  // GET MINOR CATALOG BY MAJOR CATALOG ID  
  useEffect(() => {
    if (formData.major !== '' && formData.major !== undefined) {
      dispatch(loaderAction.loader(true));
      cataloServices.getMinorCatalog(formData.major).then(([response, error]) => {
        if (response) {
          setMinorCatalog(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
    return () => {
      setMinorCatalog([]);
    }
  }, [formData.major, dispatch]);

  // GET CATEGORY CATALOG BY MINOR CATALOG ID
  useEffect(() => {
    if (formData.minor !== '' && formData.minor !== undefined) {
      dispatch(loaderAction.loader(true));
      cataloServices.getCategoryCatalog(formData.minor).then(([response, error]) => {
        if (response) {
          setCategory(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }

    return () => {
      setCategory([]);
    }
  }, [formData.minor, dispatch]);


  // GET CRITERIA BY ASSET ID 
  const getCriteriaById = useCallback(() => {
    dispatch(loaderAction.loader(true));
    assetService.getCriteriaById(id).then(([response, error]) => {
      if (response) {
        if (response.asset_criteria.length > 0) {
          setCriteria(modifyData(response.asset_criteria));
        }
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })

    return () => {
      setCriteria([]);
    }
  }, [id, dispatch])

  const modifyData = (data) => {
    let arr = [];
    data.forEach((item) => {
      const obj = {
        criteria: item.criteria,
        id: item.id,
        info: item.info,
        name: item.name,
        notes: item.notes,
        weight: item.weight,
        rating: 1
      }
      arr.push(obj);
    })
    return arr;
  }

  const setAssetData = useCallback((response) => {
    setFormData({
      id: response.asset.id,
      specific_topic: response.asset.specific_topic,
      url: response.asset.url,
      notes: response.asset.notes,
      created_by: response.asset.created_by,
      branch_opinion: response.asset.branch_opinion,
      opinion_percent: response.asset.opinion_percent,
      status: response.asset.status,
      area: response.asset.area,
      major: response.specialization[2].catalog_id,
      minor: response.specialization[1].catalog_id,
      category: response.specialization[0].catalog_id,
      sub_cat: response.asset.sub_cat,
      topic: response.asset.topic,
      sub_topic: response.asset.sub_topic
    });
    setComment({
      notes: response.asset.notes
    })
  }, [])

  const getExpertAssetData = useCallback(() => {
    if(assetexpertId !== '' && +assetexpertId !== 0){
      assetService.assetExpertById(assetexpertId).then(([response , error]) => {
        if(response){
          SetOverviewFormData({
            strengths: response.strengths !== null && response.strengths !== '' ?  response.strengths : '',
            weakness: response.weakness !== null && response.weakness !== '' ?  response.weakness : '',
            evaluation: response.evaluation !== null && response.evaluation !== '' ?  response.evaluation : '',
          })
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
      })
    }
  },[assetexpertId , dispatch])

  // GET ASSET BY ID
  const getAssetById = useCallback(() => {
    dispatch(loaderAction.loader(true));
    assetService.getAssetById(id).then(([response, error]) => {
      if (response) {
        if(response && response.asset){
          setAssetData(response);
          getExpertAssetData();
          if (Number(statusExpert) === Status.UNDER_REVIEW.value) {
            setEditable(true);
            setDetectHide(false);
          } else if (Number(statusExpert) === Status.DRAFT.value) {
            setEditable(false);
            setDetectHide(false);
          }else {
            setEditable(true);
          }
        }else{
          if(loginData.permissions.includes('qrata.asset_approval')) history.push('/assets');
          if(loginData.permissions.includes('qrata.asset_rating')) history.push('/assetList');
          dispatch(alertActions.error('Asset is not found.'));
        }
        dispatch(loaderAction.loader(false));
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [id, dispatch, loginData.permissions , statusExpert, setAssetData , getExpertAssetData ])

  // GET ASSET DATA BY ID 
  useEffect(() => {
    if (id !== '') {
      getAssetById();
    }

    return () => {
      setEditable(false);
    }
  }, [id, getAssetById])

  const Form1 = [
    [{ label: 'Specific Topic', type: 'text', readOnly: true, name: 'specific_topic', sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'https://', type: 'url', readOnly: true, name: 'url', sm: 12, md: 6, lg: 6, xl: 6, }],
  ];

  const Form2 = [
    [{ label: 'Branch Opinion', type: 'multi-select', disabled: true, name: 'branch_opinion', placeholder: 'Select Branch', options: branch, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Opinion Percent ', readOnly: true, type: 'number', disabled: true, name: 'opinion_percent', minValue: 0, maxValue: 100, placeholder: 'Select Opinion Percent', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Major Classifications', type: 'multi-select', disabled: true, name: 'major', placeholder: 'Major Classifications', options: majorCatalog, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Minor Classifications', type: 'multi-select', disabled: true, name: 'minor', placeholder: 'Minor Classifications', options: minorCatalog, sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Category', type: 'multi-select', disabled: true, name: 'category', placeholder: 'Category ', options: category, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Sub-Category ', type: 'text', readOnly: true, name: 'sub_cat', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Topic ', type: 'text', readOnly: true, name: 'topic', sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Sub-Topic ', type: 'text', readOnly: true, name: 'sub_topic', placeholder: 'Sub-Topic', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Geographical Area ', type: 'multi-select', disabled: true, name: 'area', placeholder: 'Select Area', options: area, sm: 12, md: 6, lg: 6, xl: 6, },
    { placeholder: '', id: 'Save Classification', type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 6, lg: 6, xl: 6 }]
  ];

  const Form4 = [
    {
      disabled: true, type: 'textarea', name: 'notes', placeholder: 'Permanent Comments / Notes',
    }
  ]

  const ratingOverview = [
    { label: 'Strengths', disabled: loginData.group.includes(UserRole.EXPERT.id)  ? false : true, type: 'textarea', name: 'strengths', placeholder: 'Strengths' },
    { label: 'Weakness', disabled: loginData.group.includes(UserRole.EXPERT.id) ? false : true, type: 'textarea', name: 'weakness', placeholder: 'Weakness' },
    { label: 'Evaluation', disabled: loginData.group.includes(UserRole.EXPERT.id) ? false : true, type: 'textarea', name: 'evaluation', placeholder: 'Evaluation' }
  ]

  const onDataChange = (e) => {
    setCriteria(e);
  }


  const commentsCount  = comments && comments.length > 0 ? comments.length : 0;

// ACCORDION JSON DATA
  const data = [
    { name: 'Specific Topic Name', open: true, type: 'form', label: formData.specific_topic, fields: Form1, func: (e) => null, formData: formData },
    { name: 'Category', open: true, type: 'form', fields: Form2, func: (e) => null, formData: formData },
    { name: 'Rate Asset', label: 'When rating an Asset note that 1 is the lowest score and 10 is the best score ', open: true, type: 'custom',  isDragDisabled: true, rating: true, editable: editable, fields: criteria, func: (e) => onDataChange(e) },
    { name: 'Rating Overview', open: true, type: 'form', label: '', fields: ratingOverview, func: (e) => handleChange(e), formData: overviewFormData },
    { name: 'Permanent Comments / Notes', open: true, type: 'form', fields: Form4, func: (e) => null, formData: comment },
    { name: `Comments (${commentsCount})`, open: false, type: 'component', component: <CommentList comments={comments ? comments : []} /> }
  ]

  const handleChange = useCallback((e) => {
    SetOverviewFormData({
      ...overviewFormData,
      [e.name]: e.value
    })
  }, [overviewFormData])

  const createData = useCallback((data) => {
    let arr = [];
    data.forEach((item) => {
      const obj = {
        "criteria": item.criteria,
        "notes": item.notes,
        "rating": item.rating,
      }
      arr.push(obj);
    })
    const dumy = {
      asset_expert: +assetexpertId,
      strengths: overviewFormData.strengths,
      weakness: overviewFormData.weakness,
      evaluation: overviewFormData.evaluation,
      criteria_rating_list: arr
    }

    return dumy;
  }, [overviewFormData, assetexpertId])

  // CREATE CRITERIA RATING
  const createCriteria = useCallback(() => {
    const data = createData(criteria);
    assetService.createCriteriaRating(data).then(([response, error]) => {
      if (response && response.asset_criteria_rating) {
        dispatch(alertActions.success('Asset Rated Sucessfully.'))
        history.push('/assetList');
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
    })
  },[createData , criteria , dispatch])

  // GET RATED CRITERIA 
  const getCriteriaRating = useCallback(() => {
    if (assetexpertId !== '' && assetexpertId !== null) {
      assetService.getCriteriaRating(assetexpertId).then(([response, error]) => {
        if (response && response.asset_criteria_rating.length > 0) {
          setUpdate(true);
          setCriteria(response.asset_criteria_rating);
        } else if (response && response.asset_criteria_rating.length === 0) {
          getCriteriaById();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
    
  }, [assetexpertId, getCriteriaById, dispatch])

  useEffect(() => {
    if (id !== '' && +assetexpertId !== 0 && assetexpertId !== '') {
      getCriteriaRating();
    }

    return () => {
      setCriteria([]);
      setUpdate(false);
    }
  }, [id, assetexpertId, getCriteriaRating])

  // CREATE UPDATE ASSET DATA
  const updateData = useCallback((data) => {
    let arr = [];
    data.forEach((item) => {
      const obj = {
        "asset_expert": +assetexpertId,
        "criteria_rating_id": item.id,
        "criteria": item.criteria,
        "notes": item.notes,
        "rating": item.rating
      }
      arr.push(obj);
    })

    const dumy = {
      asset_expert: assetexpertId,
      strength: overviewFormData.strength,
      weakness: overviewFormData.weakness,
      evaluation: overviewFormData.evaluation,
      criteria_rating_list: arr
    }

    return dumy;
  }, [assetexpertId, overviewFormData]);

  // UPDATE RATING CRITERIA
  const updateCriteriaRating = useCallback(() => {
    const data = updateData(criteria);
    assetService.updateCriteriaRating(data).then(([response, error]) => {
      if (response && response.asset_criteria_rating.length > 0) {
        setCriteria(response.asset_criteria_rating);
        dispatch(alertActions.success('Asset Rated Updated Sucessfully.'))
        history.push('/assetList');
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
    })
  }, [dispatch, criteria, updateData])

  const getComment = useCallback(() => {
    if (id !== '') {
      assetService.getComment(id).then(([response, error]) => {
        if (response) {
          setComments(response.bio_comment);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }

  }, [id, dispatch])

  useEffect(() => {
    if (id !== '') {
      getComment();
    }
    return () => {
      setComments([]);
    }
  }, [id, getComment])



  const openModal = useCallback((value) => {
    if (value) setStatus(value);
    showModal();
  }, [showModal]);



  // VERIFY ASSET 
  const verifyAsset = useCallback(() => {
    if (status !== '') {
      const data = { id: +assetexpertId, status: status, asset_id: +id }
      assetService.verifyAsset(data).then(([response, error]) => {
        if (response) {
          setModalText('');
          setIdentity('');
          if (status === Status.DRAFT.value) {
            dispatch(alertActions.success('Asset Rating Revised Successfully.'))
            history.push('/assets');
          } else if (status === Status.APPROVED.value) {
            dispatch(alertActions.success('Asset Rating Approved Successfully.'))
            history.push('/assets');
          }
          setStatus('');
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }, [status, assetexpertId, id, dispatch])



  // CLOSE COMMENT MODAL
  const closeModal = useCallback((data) => {
    hideModal();
    if (data.comment) {
      if (id !== '') {
        const newData = new FormData();
        newData.append('asset_id', id);
        newData.append('ask_by', loginData.id);
        newData.append('comment', data.comment);
        if (data.attachment) {
          newData.append('attachment', data.attachment)
        };
        assetService.createComment(newData).then(([response, error]) => {
          if (response) {
            getComment();
            verifyAsset();
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
        })
      } else {
        dispatch(alertActions.error('Please save the asset first.'));
      }
    }
  }, [hideModal, id, dispatch, loginData, getComment, verifyAsset]);

  const closeConfirmModal = useCallback(() => {
    setStatus('');
    setModalText('');
    setIdentity('');
    hideConfirm();
  },[hideConfirm])

  const handleConform = useCallback(() => {
    hideConfirm();
    if(identity === 'approve'){
      verifyAsset()
    }
    
  },[hideConfirm , verifyAsset , identity])

  const openConfirmModal = useCallback((value) => {
    setStatus(value);
    setIdentity('approve')
    setModalText('Do you want to approve this asset rating ?');
    showConfirm();
  },[showConfirm])
  
  return (
    <Container fluid>
      <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={handleConform} title={modalText} />
      <CommentModal title='Comments' show={isVisible} onHide={(e) => closeModal(e)} />
      <Row>
        <Col md={{ span: 10, offset: 1 }} className="p-0">
          <h4 className="py-3 ">Assign Asset to be Rated</h4>
          <AccordionForm data={data} />
          {detectHide ? 
          <div className="float-right">
          <Button className="mb-2 " variant="outline-secondary" onClick={() => history.goBack()}>
          <FontAwesomeIcon className="mr-1" icon="times" size="sm" />
              CLOSE
          </Button>
          </div>
          :
          <Row className="mt-3">
            <Col md={12} className="text-md-right text-center ">
              <Button className="mr-2 mb-2 float-left" variant="primary" onClick={() => openModal(null)}>
                <FontAwesomeIcon className="text-white mr-1" icon="comment" size="sm" />
                    Ask
                </Button>      
                <><HasPermission permission='qrata.asset_approval'>
                  <Button className="mr-2 mb-2" variant="primary" onClick={() => openConfirmModal(1)}>
                    <FontAwesomeIcon className="text-white mr-1" icon="save" size="sm" />
                        APPROVE
                    </Button>
                  <Button className="mb-2 mr-2" variant="outline-secondary" onClick={() => openModal(4)}>
                    <FontAwesomeIcon className="mr-1" icon="exchange-alt" size="sm" />
                        REVISE
                    </Button>
                  <Button className="mb-2" variant="outline-secondary" onClick={() => history.goBack()}>
                    <FontAwesomeIcon className="mr-1" icon="times" size="sm" />
                        CLOSE
                    </Button>
                </HasPermission></>

              <HasPermission permission='qrata.expert_list'>
                {update ? <>
                  <Button className="mr-1 mb-2" variant="primary" type="submit" onClick={() => updateCriteriaRating()}>
                    <FontAwesomeIcon className="text-white mr-1" icon="save" size="sm" />
                     Update Rating
                  </Button>
                  <Button className="mb-2" variant="outline-secondary" onClick={() => history.goBack()}>
                    <FontAwesomeIcon className="mr-1" icon="times" size="sm" />
                        CLOSE
                    </Button>
                </> : <>
                    <Button className="mr-1 mb-2" variant="primary" type="submit" onClick={() => createCriteria()}>
                      <FontAwesomeIcon className="text-white mr-1" icon="save" size="sm" />
                    Save Rating
                    </Button>
                    <Button className="mb-2" variant="outline-secondary" onClick={() => history.goBack()}>
                    <FontAwesomeIcon className="mr-1" icon="times" size="sm" />
                        CLOSE
                    </Button>
                  </>}
              </HasPermission>

            </Col>
          </Row> }
        </Col>
      </Row>

    </Container>
  )
}
export default RateFormView;