import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'dashboard/';

export async function getExpertCount(user) {
  return await handleApi(axiosClient.get(`${BASE}expert/?user=${Number(user)}`));
}

export async function getAssetList(user , status , limit) {
  return await handleApi(axiosClient.get(`${BASE}asset_list/${user !== '' ?  '?user='+Number(user) : ''}${status !== '' ? '&status='+ Number(status) : ''}&limit=${+limit}`));
}

export async function getApplicationList(user , status , limit) {
  return await handleApi(axiosClient.get(`${BASE}app_list/?${user !== '' ?  'user='+Number(user) + '&' : ''}status=${+status}&limit=${+limit}`));
}

export async function getBios(user , status , limit) {
  return await handleApi(axiosClient.get(`${BASE}bio_list/?${user !== '' ?  'user='+Number(user)+'&' : ''}status=${+status}&limit=${+limit}`));
}

export async function getAppCounts(user , group) {
  return await handleApi(axiosClient.get(`${BASE}count/${user !== '' ?  '?user='+Number(user) : ''}${group !== '' ? '&group='+group : ''}`));
}

export async function getAdminCount(user) {
  return await handleApi(axiosClient.get(`${BASE}admin/${user !== '' ?  '?user='+Number(user) : ''}${'&group=3'}`));
}

export async function getAssetsLists(user , status , limit , expStatus) {

  return await handleApi(axiosClient.get(`${BASE}asset/?${user !== '' ?  'user='+Number(user)+ '&' : ''}${ status !== '' ? 'status='+status+'&' : ''}${expStatus !== '' ? 'exp_status='+expStatus+'&' : ''}limit=${+limit}`));
}

// getAssetAdminList
