import { USER } from '../helpers/actionTypes';

const initState = {
    isFetching: false,
    isDeleting: false,
    allUsers: [],
    user: {}
}

export function users(state, { payload, type, error }) {
    if (!state) {
        state = initState
    }
    switch (type) {
        case USER.FETCH_ALL.REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case USER.FETCH_ALL.SUCCESS:
            return {
                ...state,
                allUsers: payload.data.results,
                isFetching: false
            };
        case USER.FETCH_ALL.FAILURE:
            // showAlert('Invalid Credentials');
            return {
                ...state,
                isFetching: false,
                error: error
            };
        case USER.USER_GROUP.REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case USER.USER_GROUP.SUCCESS:
            return {
                ...state,
                allUsers: payload.data.results,
                isFetching: false
            };
        case USER.USER_GROUP.FAILURE:
            // showAlert('Invalid Credentials');
            return {
                ...state,
                isFetching: false,
                error: error
            };
        case USER.ADD.REQUEST:
            return {
                ...state,
                isFetching: true
            };
            
        case USER.ADD.SUCCESS:
            return {
                ...state,
                allUsers: payload.data.results,
                isFetching: false
            };
        case USER.ADD.FAILURE:
            return {
                ...state,
                isFetching: false,
                error: error
            };

            case USER.UPDATE.REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case USER.UPDATE.SUCCESS:
            return {
                ...state,
                allUsers: payload.data.results,
                isFetching: false
            };
        case USER.UPDATE.FAILURE:
            // showAlert('Invalid Credentials');
            return {
                ...state,
                isFetching: false,
                allUsers: [],
                error: error
            };
        case USER.DELETE.REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case USER.DELETE.SUCCESS:
            return {
                ...state,
                isDeleting: false,
            };
        case USER.DELETE.FAILURE:
            return {
                ...state,
                isDeleting: false,
                error: error
            };
        case USER.RESEND_INVITE.REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case USER.RESEND_INVITE.SUCCESS:
            return {
                ...state,
                isFetching: false,
                user : payload.data
            };
        case USER.RESEND_INVITE.FAILURE:
            return {
                ...state,
                isFetching: false,
                error: error
            };
        default:
            return state;
    }

}