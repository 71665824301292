import { handleApi, HEADERS } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'accomplishment/';

// GET ACCOMPLISHMENT BY ID
export async function getAccomplishmentById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_accomplishment/`));
}

// CREATE ACCOMPLISHMENT
export async function createAccomplishment(data) {
  return await handleApi(axiosClient.post(`${BASE}create_accomplishment/`, data , {headers: HEADERS.FILE()}));
}

// UPDATE ACCOMPLISHMENT
export async function updateAccomplishment(data) {
  return await handleApi(axiosClient.post(`${BASE}update_accomplishment/`, data , {headers: HEADERS.FILE()}));
}

// DELETE ACCOMPLISHMENT
export async function deleteAccomplishment(id) {
  return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}

// ACCOMPLISHMENT LIST BY USER ID
export async function getAccomplishment(user , status) {
  return await handleApi(axiosClient.get(`${BASE}?${user ? 'user='+user : ''}${user && status ? '&' : ''}${status ? 'status='+status : ''}`));
}

// VERIFY ACCOMPLISHMENT
export async function accomplishmentVerify(data) {
  return await handleApi(axiosClient.post(`${BASE}accomplishment_verify/`, data , {headers: HEADERS.FILE()}));
}

// GET ALL COMMENTS IN ACCOMPLISHMENT
export async function getAccomplishmentComment(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_comment/`));
}

// CREATE COMMENT IN ACCOMPLISHMENT
export async function createAccomplishmentComment(data) {
  return await handleApi(axiosClient.post(`${BASE}create_comment/`, data));
}

// GET COMPLETE ACCOMPLISHMENT INFORMATION OF A PARTICULAR USER
export async function getAccomplishmentInfo(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/list/`));
}