import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import ModalForm from '../../../shared/ModalForm';
import * as accomplishmentService from '../../../services/accomplishment';
import { useSelector, useDispatch } from 'react-redux';
import * as loaderAction from '../../../actions/loader';
import * as alertActions from '../../../actions/alert';


const AccomplishmentForm = ({ id, isVisible, hide, output }) => {

  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.loginData);
  const [comments, setComments] = useState([]);
  const [accData, setAccData] = useState({
    accomplishment_id: '',
    accomplishment_type: '',
    title: '',
    accomplishment_month_year: null,
    description: '',
    accomplishment_file: '',
    file_id: ''
  });

  const prevState = useRef(accData);

  // GET ALL COMMENTS 
  const getComment = useCallback(() => {
    if (id !== '') {
      accomplishmentService.getAccomplishmentComment(id).then(([response, error]) => {
        if (response) {
          setComments(response.comment);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id !== undefined && id !== '') {
      dispatch(loaderAction.loader(true));
      accomplishmentService.getAccomplishmentById(id).then(([response, error]) => {
        if (response) {
          let resData = {
            accomplishment_id: response[0].id,
            accomplishment_type: response[0].accomplishment_type,
            title: response[0].title,
            accomplishment_month_year: response[0].accomplishment_month_year,
            description: response[0].description,
            accomplishment_file: response[1] ? response[1].file_url : '',
            file_id: response[1] ? response[1].id : ''
          };
          if(response[0].accomplishment_type === 'License'){
            resData.license_type = response[0].license_type;
            resData.geographic_area = response[0].geographic_area;
            resData.license_id = response[0].license_id;
          }

          setAccData(resData);
          getComment();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    } else {
      setAccData(prevState.current);
      setComments([]);
    }
  }, [id, dispatch, getComment])

  // ADD NEW ACCOMPLSIHMENT
  const createAccomplishment = useCallback((data) => {
    if (data) {
      dispatch(loaderAction.loader(true));
      let newData = new FormData();
      newData.append("user_id", sessionData.id);
      newData.append("accomplishment_type", data.accomplishment_type);
      newData.append("title", data.title);
      newData.append("accomplishment_month_year", data.accomplishment_month_year);
      newData.append("description", data.description);
      if (data.accomplishment_file) {
        newData.append("accomplishment_file", data.accomplishment_file, data.accomplishment_file.name);
      }
      if(data.accomplishment_type === 'License'){
        newData.append("license_type", data.license_type);
        newData.append("geographic_area", data.geographic_area);
        newData.append("license_id", data.license_id);
      }
      accomplishmentService.createAccomplishment(newData).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Added Successfully.'));
          output();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch, output, sessionData]);

  // UPATE EXISTING ACCOMPLSIHEMNT
  const updateAccomplishment = useCallback((data) => {
    if (data) {
      dispatch(loaderAction.loader(true));
      let newData = new FormData();
      newData.append("user_id", sessionData.id);
      newData.append("accomplishment_id", data.accomplishment_id);
      newData.append("accomplishment_type", data.accomplishment_type);
      newData.append("title", data.title);
      newData.append("accomplishment_month_year", data.accomplishment_month_year);
      newData.append("description", data.description);
      if (data.accomplishment_file && data.accomplishment_file.name && data.accomplishment_file.type) {
        newData.append("accomplishment_file", data.accomplishment_file, data.accomplishment_file.name);
        newData.append("file_id", data.file_id);
      } 
      if(data.accomplishment_type === 'License'){
        newData.append("license_type", data.license_type);
        newData.append("geographic_area", data.geographic_area);
        newData.append("license_id", data.license_id);
      }
      accomplishmentService.updateAccomplishment(newData).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Update Successfully.'));
          output();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch, output, sessionData])


  // CLOSE POPUP MODAL & CALL CREATE AND UPDATE FUNC
  const closeModal = useCallback((data) => {
    hide();
    if (data.accomplishment_id && data !== false) {
      updateAccomplishment(data)
    } else if (data !== false) {
      createAccomplishment(data);
    }
  }, [hide, createAccomplishment, updateAccomplishment]);


  // FORM FIELDS
  const getFields = useCallback(() => {
    let fields = [
      [{ label: 'Type*', name: 'accomplishment_type', type: 'select', required: true, placeholder: '---Select---', options: [{ name: 'Award ' }, { name: ' License' }, { name: ' Publication ' }, { name: ' Other' }], sm: 12, md: 6, lg: 6, xl: 6 },
      { label: 'Title*', name: 'title', type: 'text', required: true, placeholder: 'Title', sm: 12, md: 6, lg: 6, xl: 6 }],
      [{ label: 'Year*', name: 'accomplishment_month_year', type: 'date', required: true, placeholder: 'MM/yyyy', sm: 12, md: 6, lg: 6, xl: 6 },
      { label: 'Description*', name: 'description', type: 'text', required: true, placeholder: 'Description', sm: 12, md: 6, lg: 6, xl: 6 }],
      [{ label: 'Attachment', required: false, name: 'accomplishment_file', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', type: 'file', placeholder: 'Select File', sm: 12, md: 12, lg: 12, xl: 12 }],
    ];

    const conditionalFields = [
      { label: 'Type of License*', name: 'license_type', type: 'text', required: true, placeholder: 'For eg: Real Estate Brokers License, CPA=Certified Public Accountant, Attorney etc', sm: 12, md: 12, lg: 12, xl: 12 },
      { label: 'State or Geographic Area of License*', name: 'geographic_area', type: 'text', required: true, placeholder: '', sm: 12, md: 6, lg: 6, xl: 6 },
      { label: 'License #', name: 'license_id', type: 'text', required: true, placeholder: '', sm: 12, md: 6, lg: 6, xl: 6 }
    ]

    if(accData.accomplishment_type === 'License'){
      fields.splice(1, 0, conditionalFields);
    }
    return fields;

  }, [accData])


  return (
    <Container fluid>
      {isVisible ?
        <ModalForm
          show={isVisible}
          onHide={(e) => closeModal(e)}
          data={accData}
          fields={getFields()}
          title={id ? 'Update an Accomplishment' : 'Add an Accomplishment'}
          comment={comments ? comments : []}
          button={id !== '' ? 'Update' : 'Save'}
          fetchUpdated={setAccData}
        />
        : null}
    </Container>
  )
}

export default AccomplishmentForm;