import React from 'react';
import { Form, Col, InputGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HasPermission } from '../HasPermission';
import TooltipHover from '../TooltipHover';
import { validURL } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputField = ({ label, permission, id, md, sm, lg, xs, xl, type, col, placeholder, name, opts, value, handleEvent, maxValue, info, showPassword, handleShowPassword }) => {

    const errorMessage = 'Please fill the ' + label;
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const data = { name, value };
        handleEvent(data);
    }

    const openLink = (link) => {
        window.open(link, '_blank').focus();
    }

   
    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}>
                        <div className='d-inline'>
                        {label ? <Form.Label  className={info?"":"d-block"}>{label}</Form.Label> : ''}
                        {info ? <TooltipHover type='icon' iconClass='ml-1' variant='link' icon='info-circle' message={info} handleEvent={() => null} />: ""}
                        </div>
                        <div className='d-flex'>
                            {
                            type === 'password' ?
                                <InputGroup className="mb-3">
                                    <Form.Control type={showPassword === true ? 'text' : 'password'} size={col} onChange={(e) => handleChange(e)}  autoComplete="off" name={name} value={value}  {...opts} maxLength={maxValue} placeholder={placeholder} />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-ctrlPassword">
                                            <FontAwesomeIcon icon={showPassword === true ? 'eye-slash' : 'eye'} size="lg" onClick={handleShowPassword}/>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup> 
                                :
                                <Form.Control type={type} size={col} onChange={(e) => handleChange(e)}  autoComplete="off" name={name} value={value}  {...opts} maxLength={maxValue} placeholder={placeholder} />
                            }
                        
                        {type === 'url' && validURL(value)  ? <TooltipHover type='button' btnSize='sm' btnClass="text-info float-right" variant='link' btnIcon='external-link-alt' iconSize='lg' message={'open link new tab'} handleEvent={() => openLink(value)} /> : null}</div>
                        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

InputField.propTypes = {
    value: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.any,
    label: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    maxlength: PropTypes.any,
    permission: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired
};

InputField.defaultProps = {
    id: '',
    value: '',
    name: '',
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: '',
    permission: '',
    type: 'text',
    label: '',
    maxlength: '',
    opts: [],
    showPassword: false
};
export default InputField;