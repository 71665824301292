import React from "react";
import { Button, Container, Col, Row } from 'react-bootstrap';
import AppTable from "../../shared/AppTable";
import TooltipHover from "../../shared/TooltipHover";

let list = [
  {
    id: 1,
    name: 'Leo Tolstoy',
    email: 'leo@gmail.com',
    date: '03/07/2020',
    status: 'PENDING'
  },
  {
    id: 2,
    name: 'Peter Bailey',
    email: 'pbailey@gmail.com',
    date: '19/06/2020',
    status: 'ACCEPTED'
  },
  {
    id: 3,
    name: 'Stella Stone',
    email: 'stella@gmail.com',
    date: '28/06/2020',
    status: 'PENDING'
  },
  {
    id: 4,
    name: 'Teresa Fidalgo',
    email: 'fidalgo@gmail.com',
    date: '30/06/2020',
    status: 'PENDING'
  },
  {
    id: 5,
    name: 'Stacy Wilson',
    email: 'stacy@gmail.com',
    date: '25/06/2020',
    status: 'PENDING'
  }
];


const MembershipInvitationList = () => {
  const columns = [
    {
      Header: "Action",
      accessor: "action",
      className: "action-l py-1",
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' iconClass='default' variant='link' btnIcon='edit' message='Edit' handleEvent={() => null} />
          <TooltipHover type='button' btnSize='sm' iconClass="text-danger" variant='link' btnIcon='trash' message='Delete' handleEvent={() => null} />
        </div>
      ),
    },
    {
      Header: "Sr.No",
      accessor: "id",
    },
    {
      Header: "Invited member",
      accessor: "name",
      sortType: "basic",
    },
    {
      Header: "Email",
      accessor: "email",
      sortType: "basic",
    },
    {
      Header: "Date of Invitation",
      accessor: "date",
      sortType: "basic",
    },
    {
      Header: "Status",
      accessor: "status",
      sortType: "basic",
    },

  ];

  return (
    <Container fluid>
      <Row className="py-3">
        <Col md={8}><h4>Membership Invitation</h4></Col>
        <Col md={4} className="text-right">
          <Button className="btn btn-info" variant="info"> Invite Member </Button>
        </Col>
      </Row>
      <AppTable columns={columns} data={list} />
    </Container>
  );
}

export default MembershipInvitationList;