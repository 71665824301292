import React, { useState, useCallback } from "react";
import { Button, Container, Row, Col, ButtonGroup, ToggleButton, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as userService from '../../services/user';
import * as promotionService from '../../services/promotion';
import { useBoolean } from "../../hooks/basic";
import UserForm from "./UserForm";
import {  UserRole, UserType, dateOnlyFormat, PromotionStatus } from "../../helpers";
import ConfirmModal from "../../shared/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import * as alertActions from "../../actions/alert";
import * as loaderAction from "../../actions/loader";
import TooltipHover from "../../shared/TooltipHover";
import AsyncAppTable from "../../shared/AsyncAppTable";
import SearchField from '../../shared/SearchField';
import { Storage } from "../../helpers";

const UserList = (props) => {


  const columns = [
    {
      Header: "Action",
      accessor: "action",
      sortingOff:true,
      className: 'py-1 text-nowrap',
      Cell: (cell) => (
        <div>
          {!cell.row.original.is_active && !cell.row.original.is_activated  ? <TooltipHover type='button' btnSize='sm'  variant='link' iconClass='default' btnIcon='paper-plane' message='Resend Invite' handleEvent={() => openReinviteConfirmModal(cell.row.original.id)} /> : '' }
          {!cell.row.original.is_active && cell.row.original.is_activated ? <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='default' btnIcon='user-plus' message='Activate Manager' handleEvent={() => openActiveModal(cell.row.original.id)}/> : ''}
          {cell.row.original.id === Storage.getSession().id ? '' :<>
          <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='edit' iconClass='default'  message='View the User Information' handleEvent={() => openModal(cell.row.original)} />
          { cell.row.original.is_active  && cell.row.original?.groups[0] === 4 && 
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='default' 
          btnIcon={
            cell.row.original?.promotion_offer[0]?.status?
            PromotionStatus.getPromotionStatus(cell.row.original?.promotion_offer[0]?.status)?.icon: 'arrow-up'
          } 
          message={cell.row.original?.promotion_offer[0]?.status?
            PromotionStatus.getPromotionStatus(cell.row.original?.promotion_offer[0]?.status)?.label: 'Promote to admin'} 
          handleEvent={
            () => {
              [undefined,3,5].includes(cell.row.original?.promotion_offer[0]?.status) && openPromotionConfirmModal(cell.row.original.id)}
            }/>}
          {cell.row.original.is_active ? <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='default' btnIcon='user-minus' message='Deactivate Manager' handleEvent={() => openInactiveModal(cell.row.original.id)}/> : '' }
          {!cell.row.original.is_active ?
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete User' handleEvent={() => openConfirmModal(cell.row.original.id)}/>
          : ''}
          </>  }
        </div>
      ),
    },
    {
      Header: "First Name",
      accessor: "first_name",
      sortType: "basic",
      className: 'text-capitalize',
      filtering:true,
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      sortType: "basic",
      className: 'text-capitalize',
      filtering:true,
    },
    {
      Header: "Username",
      accessor: "username",
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Email",
      accessor: "email",
      filtering:true,
    },
    {
      Header: "Group",
      accessor: "groups",
      Cell: (cell) => (<div>{displayGroups(cell.row.original.groups)}</div>)
    },
    {
      Header: "Created On",
      accessor: "date_joined",
      sortType: "basic",
      className: 'text-nowrap',
      Cell: (cell) => <div>{dateOnlyFormat(cell.row.original.date_joined)}</div>
    },
  ];
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.loginData);
  const [userType, setUserType] = useState(UserType.getType(UserType.ACTIVE.id));
  const [userRoleId, setUserRoleId] = useState(UserRole.ALL.id);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [userAll, setUserAll] = useState([]);
  const [userData, setUserData] = useState(null);
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [userId, setUserId] = useState();
  const [ModalTitle , setModalTitle ] = useState('');
  const [confirmType , setConfirmType] = useState('');
  const fetchIdRef = React.useRef(0);
  const [loading , setLoading ] = useState(false);
  const [pageCount, setPageCount] = React.useState(0)
  const [resultCount, setResultCount] = useState(0)
  const [search, setSearch] = useState('')
  const [callFetch, setCallFetch] = useState(false);


const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField="", filter }) => {
  const fetchId = ++fetchIdRef.current
  
  setLoading(true);
  setTimeout(() => {
    if (fetchId === fetchIdRef.current) {
      let offset = pageIndex * pageSize;
      dispatch(loaderAction.loader(true));     
      let isSuperAdmin = Storage.getSession().permissions.includes('qrata.super_admin_access') ? true : false;
      userService.fetchAll(userType.value, +userRoleId , pageSize, offset, sortedField, filter, search, isSuperAdmin).then(([response, error]) => {
        if(response){
          handleData(response, pageSize);
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
      setLoading(false);
      setCallFetch(false)
    }
  }, 100)
}, [dispatch , userType.value , userRoleId, search])

const handleEditorPromotion = useCallback(() => {
  hideConfirm()
  dispatch(loaderAction.loader(true));
  promotionService.sendEditorPromotionRequest(userId).then(([response, error]) => {
    if (response) {
      dispatch(alertActions.success('Offer sent Successfully'));
      setCallFetch(true)
      // fetchData({pageSize:defaultLimit ,pageIndex:0});
    } else if (error) {
      // console.log("error.respion", error.response)
      dispatch(alertActions.error(error.response));
    }
    dispatch(loaderAction.loader(false));
  });
}, [dispatch, userId, hideConfirm])

const handleData = (valueUsers, pageSize) => {
  // console.log(valueUsers)
  setUserAll(valueUsers.results)
  setResultCount(valueUsers.count) 
  setPageCount(Math.ceil(valueUsers.count / pageSize))
}

  const openModal = useCallback((data) => {
    setUserData(data);
    showModal();
  }, [showModal]);

  const displayGroups = (groups) => {
    let retVal = '';
    groups.forEach(element => {
      retVal = UserRole.getRole(element).title + ' ';
    });
    return retVal;
  }

  const closeModal = useCallback(() => {
    setUserData(null);
    setCallFetch(true);
    hideModal();
  },[hideModal])

  const handleDelete = () => {
    hideConfirm();
    if(loginData.id !== userId){
      dispatch(loaderAction.loader(true));
      userService.deleteById(userId).then(([response, error]) => {
        if (response === '') {
          dispatch(alertActions.success('User Deleted Successfully'));
          setCallFetch(true);
          // fetchData({pageSize:10, pageIndex: 0})
          const users = userAll.filter(obj => obj.id !== userId);
          setUserAll(users);
          setUserId('');
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }else {
      setUserId('');
      dispatch(alertActions.info('You don\'t have permission to delete yourself.'));
    }
  }

  const openConfirmModal = (id) => {
    setUserId(id);
    setConfirmType('delete');
    setModalTitle('Do you want delete this user?');
    showConfirm();
  };

  const closeConfirmModal = () => {
    setUserId();
    setConfirmType('');
    setModalTitle('');
    hideConfirm();
  };

  const changeUserType = (obj) => {
    setUserType(obj);
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setUserRoleId(value);
  }

  const openActiveModal = (id) =>{
    setUserId(id);
    setConfirmType('activate');
    setModalTitle('Do you want activate Manager?');
    showConfirm();
  };

  const handleActivateUser = useCallback(() => {
    hideConfirm();
    const data = { "id": userId };
    dispatch(loaderAction.loader(true));
    userService.activeStatus(data).then(([response, error]) => {
      if (response.success) {
        dispatch(alertActions.success('Manager Account Activated Successfully'));
        // fetchData({pageSize:10,pageIndex:0});
        setCallFetch(true)
        setUserId();
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
    setConfirmType('');
    setModalTitle('');
  },[hideConfirm, userId, dispatch]);

  const openInactiveModal = (id) =>{
    setUserId(id);
    setConfirmType('deactivate');
    setModalTitle('Do you want deactivate Manager?');
    showConfirm();
  };

  const handleDeactivateUser = useCallback(() => {
    hideConfirm();
    const data = { "id": userId };
    dispatch(loaderAction.loader(true));
    userService.inactiveStatus(data).then(([response, error]) => {
      if (response.success) {
        dispatch(alertActions.success('Manager Account Deactivated Successfully'));
        // fetchData({pageSize:10,pageIndex:0});
        setCallFetch(true);
        setUserId();
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
    setConfirmType('');
    setModalTitle('');
  },[hideConfirm, userId, dispatch])

  const resendInvite = useCallback(() => {
    hideConfirm();
    const data = { "id": userId };
    dispatch(loaderAction.loader(true));
    userService.resendInvite(data).then(([response, error]) => {
      if (response.success) {
        dispatch(alertActions.success('Reinvite Email Sent to User'));
        // fetchData({pageSize:defaultLimit , pageIndex:0});
        setCallFetch(true);
        setUserId();
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
    setConfirmType('');
    setModalTitle('');
  }, [hideConfirm, userId, dispatch])

  const openReinviteConfirmModal = useCallback((id) => {
    setUserId(id);
    setConfirmType('reinvite');
    setModalTitle('Do you want to re-invite this member?');
    showConfirm();
  }, [showConfirm]);

  const openPromotionConfirmModal = useCallback((id) => {
    setUserId(id);
    setConfirmType('promotion');
    setModalTitle('Your are going to send request to promote this user as Admin. Are you sure?');
    showConfirm();
  }, [showConfirm]);

  const acceptConfirmation = () => {
    if (confirmType === 'reinvite') {
      resendInvite();
    } else if (confirmType === 'delete') {
      handleDelete()
    } else if (confirmType === 'promotion') {
      handleEditorPromotion()
    } else if (confirmType === 'deactivate') {
      handleDeactivateUser()
    } else if (confirmType === 'activate') {
      handleActivateUser()
    }
  }
  

  return (

    <Container fluid className="px-0">
      {/* <UserForm data={userData} isVisible={isVisible} hide={closeModal} output={() => fetchData({pageSize:rowCount , pageIndex:0})} /> */}
      <UserForm data={userData} isVisible={isVisible} hide={closeModal} output={() => setCallFetch(true)} />
      <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={acceptConfirmation} title={ModalTitle} />
      <Row className="no-gutters">
        <Col md={3} className="mb-md-0 mb-3"><h4> List of Managers </h4></Col>
        <Col md={2}  className="mb-md-0 mb-2">
          <ButtonGroup toggle>
            {Object.values(UserType).map((type, idx) => {
              return type.id ? (
                <ToggleButton key={idx} type="radio" variant="light" name="userTypes"
                  value={type.id} checked={userType.id === type.id}
                  onChange={(e) => changeUserType(UserType.getType(type.id))}>
                  {type.title}
                </ToggleButton>
              ) : ''
            }
            )}
          </ButtonGroup>
        </Col>
        <Col md={2} className="mb-md-0 mb-2">
          <Form.Control as="select" size={'md'} value={userRoleId} onChange={(e) => handleChange(e)} >
            {Storage.getSession().permissions.includes('qrata.super_admin_access') ?
              Object.values(UserRole).map((item, i) => { return item.id === -1 || item.id === 2 || item.id === 3 || item.id === 4  ? <option value={item.id} key={i}>{item.title}</option> : null }
            ) :  
            Object.values(UserRole).map((item, i) => { return item.id === -1 || item.id === 3 || item.id === 4  ? <option value={item.id} key={i}>{item.title}</option> : null })
          }
          </Form.Control>
        </Col>
        <SearchField md={4} xs={10} action={setSearch}/>
        <Col md={1} xs={2} className="text-right">
          <TooltipHover type='button' btnSize='md' variant='primary' btnIcon='plus' iconClass='text-white' message='Add User' handleEvent={() => openModal(null)}  />
        </Col>
      </Row>
      <AsyncAppTable
        columns={columns}
        data={userAll}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        filtering={true} 
        callFetch ={callFetch}
        />
    </Container>
  );
};

export default UserList;
