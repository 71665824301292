import React, { useState, useEffect, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { monthYearFormat, getStatus, Storage } from '../../../helpers';
import AppTable from '../../../shared/AppTable';
import TooltipHover from '../../../shared/TooltipHover';
import * as jobServices from '../../../services/job';
import JobForm from './JobForm';
import { useBoolean } from '../../../hooks/basic';
import { useDispatch } from 'react-redux';
import * as alertActions from '../../../actions/alert';
import * as loaderAction from '../../../actions/loader';

import { dateOnlyFormat } from "../../../helpers";
import SearchField from '../../../shared/SearchField';

const Job = () => {

  const dispatch = useDispatch();
  const [job, setJob] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [editData, setEditData] = useState('');
  const [search, setSearch] = useState('')

  // GET ALL JOB LIST
  const getAllJobs = useCallback(() => {
    dispatch(loaderAction.loader(true));
    const userId = Storage.getSession().id;
    jobServices.getJob(userId, '').then(([response, error]) => {
      if (response) {
        setJob(response.results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch]);

  useEffect(() => {
    getAllJobs();
  }, [getAllJobs])

  // OPEN POPUP PANEL
  const openModal = useCallback((data) => {
    if (data !== '') setEditData(data);
    showModal();
  }, [showModal]);

  // CLOSE POPUP PANEL
  const closeModal = useCallback(() => {
    setEditData('');
    hideModal();
  }, [hideModal])

  // REMOVE JOB
  const removeJob = useCallback((id) => {
    if (id) {
      dispatch(loaderAction.loader(true));
      jobServices.deleteJob(id).then(([response, error]) => {
        const list = job.filter((item) => item.id !== id);
        setJob(list);
        dispatch(alertActions.success('Delete Successfully.'));
        if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [job, dispatch])

// TABLE COLUMNS
  const columns = [
    {
      Header: "Action",
      accessor: "action",
      filteringOff:true,
      className: 'text-center',
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='edit' iconClass='default' message='Edit job' handleEvent={() => openModal(cell.row.original.id)} />
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete job' handleEvent={() => removeJob(cell.row.original.id)} />
        </div>
      ),
    },
    {
      Header: "Company",
      accessor: "company",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Title",
      accessor: "job_title",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Experience Start",
      accessor: "experience_to",
      sortType: "basic",
      Cell: (cell) => (<div>{monthYearFormat(cell.row.original.experience_to)}</div>)

    },
    {
      Header: "Experience End",
      accessor: "experience_from",
      sortType: "basic",
      Cell: (cell) => (<div>{cell.row.original.experience_from !== null? monthYearFormat(cell.row.original.experience_from):"To Present"}</div>)
      
    },
    {
      Header: "Conflict",
      accessor: "is_conflict",
      sortType: "basic",
      filteringOff:true,
      Cell: (cell) => (<div>{cell.row.original.is_conflict ? 'YES' : 'NO'}</div>)
    },
    {
      Header: "Status",
      accessor: "status",
      filteringOff:true,
      Cell: (row) => (<div>{getStatus(row.row.original.status)}</div>)
    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on, "DD MMM YYYY HH:mm") : 'N/A'}</div>)
    },

  ];

  return (
    <Container fluid className="px-0">
      <JobForm id={editData} isVisible={isVisible} hide={closeModal} getAllJobs={() => getAllJobs()} />
      <Row className="no-gutters" >
        <Col md={7}  className="mb-md-0 mb-3" ><h4> Jobs </h4></Col>
        <SearchField md={4}  xs={10} action={setSearch} />
        <Col md={1}  xs={2} className="text-right">
        <TooltipHover type='button' btnSize='md' variant='primary' message='Add Job' btnIcon='plus' iconClass='text-white' handleEvent={() => openModal('')}  />
        </Col>
      </Row>
      <AppTable columns={columns} globalFilter={search} data={job} />
    </Container>
  )
}
export default Job;