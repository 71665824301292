import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";


const SettingComponent = () => {

  return (
    <>
      <div id="inner-wrapper">
        <Container fluid>
          <Row>
            <Col md={8}>
              <Card>
                <h4 className="pl-3 pt-2">Setting</h4>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SettingComponent