import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Stats from './Stats';
import FormBlock from '../../../shared/FormBlock';
import * as applicationServices from '../../../services/application';
import { useDispatch } from 'react-redux';
import * as alertActions from "../../../actions/alert";
import * as loaderAction from "../../../actions/loader";

const Fifth = (props) => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    reason: '',
    comments: '',
  })
  const [validated, setValidated] = useState(false);
  const [btn, setBtn] = useState(true);
  const form1 = [
    { label: 'Explain to us why are you an expert in the topic you are applying for?', name: 'reason', rows: 5 , type: 'textarea', required: true, placeholder: 'Type here.....' },
    { label: 'Additional Info', type: 'textarea', name: 'comments', placeholder: 'Type here.....' }
  ]

  useEffect(() => {
    if (props.applicationId) {
      dispatch(loaderAction.loader(true));
      applicationServices.getApplicationById(props.applicationId).then(([response, error]) => {
        if (response) {
          setFormData({
            reason: response.reason ? response.reason : '',
            comments: response.comments ? response.comments : ''
          })
          if (response.reason) {
            setBtn(false);
            props.verifyStatus(true);
          }
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [props.applicationId, props.verifyStatus, props , dispatch, btn])


  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

  const Submit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      saveOtherInfo();
    }
  }

  const saveOtherInfo = useCallback(() => {
    if (props.applicationId !== '' && formData.reason !== '') {
      const data = {
        user: props.user.id,
        reason: formData.reason,
        comments: formData.comments ? formData.comments : null
      }
      dispatch(loaderAction.loader(true));
      applicationServices.updateApplication(props.applicationId, data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Other Information saved successfully.'));
          props.verifyStatus(true);
          setBtn(false);
        } else {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [formData.reason, formData.comments,  props , dispatch])

  return (
    <Row className="justify-content-md-center pt-5 mx-0">
      <Col md={12}>
        <h4 className="mb-4">Other Information</h4>
        <Form noValidate validated={validated} onSubmit={Submit}>
          <FormBlock fields={form1} size={12} getData={(e) => handleChange(e)} formData={formData} />
        </Form>
        <Stats {...props} submitData={(e) => Submit(e)} start={btn} />
      </Col>
    </Row>

  );
}
export default Fifth;