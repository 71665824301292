import React , { useState, useCallback } from 'react';
import * as assetsDiscussionService from '../../services/assetsDiscussion';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import TooltipHover from '../../shared/TooltipHover';
import { useDispatch } from 'react-redux';
import AsyncAppTable from '../../shared/AsyncAppTable';
import { useBoolean } from '../../hooks/basic';
import AssetDiscussion from './AssetDiscussion';
import { defaultLimit } from '../../helpers';
import { Col, Container, Row } from 'react-bootstrap';
import SearchField from '../../shared/SearchField';

const AssetDiscussionListing = () => {

const dispatch = useDispatch();
const [assetsData , setAssetsData] = useState([]);
const [loading , setLoading ] = useState(false);
const [pageCount, setPageCount] = useState(0);
const [isVisible, showModal, hideModal] = useBoolean(false);
const [editData , setEditData] = useState('');
const [search , setSearch] = useState('');
const [resultCount, setResultCount] = useState(0);
const [callFetch, setCallFetch] = useState(false);

 const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField=""}) => {
  setLoading(true);
  setTimeout(() => {
      let offset = pageSize * pageIndex;
      dispatch(loaderAction.loader(true));
      assetsDiscussionService.assetsListing(pageSize, offset, search, sortedField).then(([response , error]) => {
        if(response){
          setAssetsData(response.results);
          setResultCount(response.count)
          setPageCount(Math.ceil(response.count / pageSize))
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
      setLoading(false);
      setCallFetch(false);
  }, 100)
}, [dispatch, search ])

const openModal = useCallback((data) => {
  if(data) setEditData(data);
  showModal();
}, [showModal]);


const closeModal = useCallback(() => {
  setEditData('');
  setCallFetch(true);
  // fetchData(defaultLimit ,0);
  hideModal();
},[hideModal])

const assetsDiscussionColumns = [
  // {
  //   Header: "Asset Id",
  //   accessor: "id",
  //   sortType: "basic",
  //   // filtering:true,
  // },
  {
    Header: "Comments Count",
    accessor: "discussions",
    sortType: "basic",
    // filtering:true,
  },
  {
    Header: "Specific Topic",
    accessor: "specific_topic",
    sortType: "basic",
    // filtering:true
  },
  {
    Header: "Action",
    accessor: "action",
    sortingOff: true,
    className: 'text-center',
    Cell: (cell) => (
      <div>
        <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default'  message='View Comments ' handleEvent={() => openModal(cell.row.original)} />
      </div>
    ),
  },
];

  return (
    <Container  fluid className="px-0">
        <Row>
            <Col md={8} sm={12} xs={12} className="mb-md-0 mb-3"><h4>Asset Discussion</h4></Col>
            <SearchField md={4} action={setSearch}/>
        </Row>
        {/* <AssetDiscussion data={editData} title='Asset Discussion' isVisible={isVisible} hide={closeModal} refreshData={() => fetchData(defaultLimit ,0)} /> */}
        <AssetDiscussion data={editData} title='Asset Discussion' isVisible={isVisible} hide={closeModal} />
        <AsyncAppTable
            columns={assetsDiscussionColumns}
            data={assetsData}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            resultCount={resultCount} 
            filtering={true} 
            callFetch = {callFetch}
            />
    </Container>
  )
}
export default AssetDiscussionListing;