import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertModal = ({show, onHide, title }) => {
    return (
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered = {true}
        backdrop="static"
        keyboard={false}
        
      >
        
        <Modal.Body className="text-center border border-warning rounded">
          
          <h4><FontAwesomeIcon size='lg' className='text-warning' icon='exclamation-triangle' /> &nbsp; {title}</h4>
          <br/>
          <Button variant="secondary" onClick={onHide}>OK</Button>
        </Modal.Body>
        
      </Modal>
    );
  }

  export default AlertModal;