import React, { useCallback, useEffect, useState } from 'react';
import FormBlock from '../../../shared/FormBlock';
import * as applicationService from '../../../services/application';
import { Card, Col, Row } from 'react-bootstrap';
import { monthYearFormat } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipHover from '../../../shared/TooltipHover';
import * as bioService from '../../../services/bio';
import { HasPermission } from '../../../shared/HasPermission';
import * as loaderAction from '../../../actions/loader';
import * as alertActions from '../../../actions/alert';
import { useDispatch } from 'react-redux';
import { Status } from '../../../helpers/constants';

const BioAccomplishment = ({ bioId, applicationId, accomplishment, getData, disable }) => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    accomplishment_id: '',
  })
  const [existingAcc, setExistingAcc] = useState([]);
  const [accStatus, setAccStatus] = useState(false);
  const [accId, setAccId] = useState('');

  // GET ALL ACCOMPLISHMENT
  const getAllAcc = useCallback(() => {
    if (applicationId !== '') {
      dispatch(loaderAction.loader(true));
      applicationService.getExistingAccomplishment(applicationId, true).then(([response, error]) => {
        if (response) {
          setExistingAcc(response);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [applicationId, dispatch])

  useEffect(() => {
    if (applicationId) {
      getAllAcc();
    }
  }, [getAllAcc, applicationId])

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

// DELETE ACCOMPLISHMENT
  const deleteAccomplishment = useCallback((id) => {
    if (id) {
      dispatch(loaderAction.loader(true));
      const data = { accomplishment_id: id };
      bioService.deleteAccomplishment(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Deleted Successfully.'));
          getData();
          getAllAcc();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(true));
      })
    }
  }, [getData, getAllAcc, dispatch])

// ADD ACCOMPLISHMENT IN BIO
  const addAccomplishment = useCallback(() => {
    if (formData.accomplishment_id !== '') {
      dispatch(loaderAction.loader(true));
      const data = { accomplishment_id: formData.accomplishment_id, bio_id: bioId, status: 2 }
      bioService.addExistingAccomplishment(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Added Successfully.'));
          setFormData({accomplishment_id: '',})
          getData();
          getAllAcc();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [formData.accomplishment_id, getData, getAllAcc, dispatch, bioId])

  // ACCOMPLISHMENT DROPDOWN JSON
  const form1 = [
    [{ label: 'Type', required: true, name: 'accomplishment_id', type: 'multi-select', group:true ,placeholder: 'Select Accomplishment', options: existingAcc.length > 0 ? existingAcc : [], sm: 12, md: 10, lg: 10, xl: 10 },
    { placeholder: 'Save', disabled: existingAcc.length > 0 ? false : true, handleEvent: () => addAccomplishment(), type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 2, lg: 2, xl: 2 }]];
  const toggleChange = (e) => {
    const { name, checked } = e.target;
    setAccId(name);
    setAccStatus(checked);
  }

  useEffect(() => {
    if (accId !== '') {
      const data = { bio_accomplishment_id: accId, status: accStatus ? 1 : 3 }
      bioService.verifyAccomplishment(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success(`Accomplishment ${accStatus ? 'Enable' : 'Disable'} Successfully.`));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }, [accId, accStatus, dispatch])

  return (
    <>
      <HasPermission permission='qrata.add_bioaccomplishments'>
        <FormBlock fields={form1} getData={(e) => handleChange(e)} formData={formData} />
      </HasPermission>
      {accomplishment.length > 0 ?
        <Card className="mt-1 border-0">
          {accomplishment.map((item, index) => (

            <Card.Body key={index} className="pb-0">
              <Row key={index}>
                <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item.accomplishment[0].accomplishment_type}>
                  {item.accomplishment[0].accomplishment_type}
                </Col>
                <Col md={3} sm={12} className="text-warning text-left font-weight-bold text-truncate overflow-hidden" title={item.accomplishment[0].title}>
                  {item.accomplishment[0].title}
                </Col>
                <Col md={2} sm={12} className='text-truncate overflow-hidden' title={item.accomplishment[0].description}>
                  {item.accomplishment[0].description}
                </Col>
                <Col md={3} sm={10} className="text-info font-weight-bold font-style-italic text-right">
                  {monthYearFormat(item.accomplishment[0].accomplishment_month_year)}
                  <a className=" text-danger text-decoration-none  ml-2" target="_blank" rel="noopener noreferrer" href={item.accomplishment[1] ? item.accomplishment[1].file_url : '#'}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>

                </Col>
                <Col md={1} sm={2}>
                  <HasPermission permission='qrata.delete_bioaccomplishments'>
                    <TooltipHover type='link' btnSize='sm' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Accomplishment' handleEvent={() => deleteAccomplishment(item.accomplishment_id)} />
                  </HasPermission>
                  <HasPermission permission='qrata.change_bioaccomplishments'>
                    {item.accomplishment_status === Status.APPROVED.value ? '' :
                      <><label className="switch">
                        <input type="checkbox" disabled={disable} name={item.accomplishment_id} onChange={(e) => toggleChange(e)} />
                        <span className="slider round"></span>
                      </label></>}
                  </HasPermission>
                </Col>
              </Row>

            </Card.Body>

          ))} </Card>
        : 'No data found.'}
    </>
  )
}
export default BioAccomplishment;