import { handleApi, HEADERS } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'users/';
 
// GET USER LIST BY ACTIVE/INACTIVE AND GROUP
export async function fetchAll(userType, userRole , limit , offset, ordering="", filter, search='', isSuperAdmin=false) {
  let url = '';
  if (userType && userRole === -1) {
    url = `${BASE}?is_active=true&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filter}&${isSuperAdmin?'groups__id=2,3,4':"groups__id=3,4"}`;
  } else if (userType && userRole !== -1) {
    url = `${BASE}?groups__id=${userRole}&is_active=true&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filter}`;
  } else if (!userType && userRole === -1) {
    url = `${BASE}?is_active=false&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filter}&${isSuperAdmin?'groups__id=2,3,4':"groups__id=3,4"}`;
  } else if (!userType && userRole !== -1) {
    url = `${BASE}?groups__id=${userRole}&is_active=false&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filter}`;
  }
  return await handleApi(axiosClient.get(url));
}

// ADD USER
export async function addUser(data) {
  return await handleApi(axiosClient.post(`${BASE}` , data));
}

// UPDATE USER
export async function updateUser(id , data) {
  return await handleApi(axiosClient.put(`${BASE}${id}/` , data));
}

// UPDATE USER
export async function updateUserPatch(id , data) {
  return await handleApi(axiosClient.patch(`${BASE}${id}/` , data));
}

// DELETE USER BY ID
export async function deleteById(id) {
  return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}

// inactive to active
export async function inactiveStatus(data) {
  return await handleApi(axiosClient.post(`${BASE}set_inactive/`, data));
}

export async function activeStatus(data) {
  return await handleApi(axiosClient.post(`${BASE}set_active/`, data));
}

// GET GROUPS LIST
export async function getGroups() {
  return await handleApi(axiosClient.get(`groups/`));
}

// INVITE EXPERT
export async function inviteExpert(data) {
  return await handleApi(axiosClient.post(`${BASE}create/` , data));
}

// RESEND INVITE
export async function resendInvite(data) {
  return await handleApi(axiosClient.post(`${BASE}resend_token/` , data));
}

// MAKE SUPER EXPERT
export async function handleSuperExpert(id) {
  return await handleApi(axiosClient.post(`${BASE}${id}/super-expert/`));
}


export async function fetchExperts(id) {
  return await handleApi(axiosClient.get(`${BASE}?groups__id=${id}`));
}

// RESET USER PASSWORD
export async function resetUserPassword(data) {
  return await handleApi(axiosClient.post(`${BASE}reset_user_password/` , data));
}

// GET USER INFO BY ID IN PROFILE
export async function fetchUserProfile(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/user_profile/`));
}

// UPDATE USER INFO IN PROFILE
export async function updateUserProfile(data) {
  return await handleApi(axiosClient.post(`${BASE}update/` , data ));
}

// UPDATE USER TRUST SCORE 
export async function userTrustScore(data) {
  return await handleApi(axiosClient.post(`${BASE}update_trustscore/` , data ));
}

// GET USER INFO BY ID IN PROFILE
export async function getTrustScore(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_trustscore/`));
}

// GET COUNTRY LIST
export async function getCountryList() {
  return await handleApi(axiosClient.get(`/country/`));
}

// GET STATE LIST
export async function getStateList(id) {
  return await handleApi(axiosClient.get(`/state/${id}`));
}

// GET CITY LIST
export async function getCityList(id) {
  return await handleApi(axiosClient.get(`/city/${id}`));
}

// UPLOAD PROFILE PIC 
export async function uploadProfilePic(data) {
  return await handleApi(axiosClient.post(`/profile_pic/upload/` , data ));
}

export async function updateProfilePic(id , data) {
  return await handleApi(axiosClient.post(`/profile_pic/${id}/` , data , {headers : HEADERS.FILE}));
}

export async function getLog(id) {
  return await handleApi(axiosClient.get(`/log/?user=${id}` ));
}

export async function updateLog(id , data) {
  return await handleApi(axiosClient.patch(`/log/${id}/` , data , {headers: HEADERS.NOAUTH()}));
}

export async function deleteLog(id) {
  return await handleApi(axiosClient.delete(`/log/${id}/` ));
}

export async function getCountries() {
  return await handleApi(axiosClient.get(`country/all/`));
}

export async function getStates(id) {
  return await handleApi(axiosClient.get(`country/${id}/states/`));
}

export async function getCities(id) {
  return await handleApi(axiosClient.get(`state/${id}/cities/`));
}