import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as alertActions from '../../../actions/alert';
import { useDispatch } from 'react-redux';

const AssetsNav = (props) => {
 
  const dispatch = useDispatch();

  // CHECK ONCLICK
  const showWarning = () => {
    
    if (props.currentStep == 1) {
      dispatch(alertActions.warning('Please save previous step first.'));
        
    }
  }


  return (
    <Container fluid>
      <ul id="qrata-step-form">
        <Row className="justify-content-md-center text-center no-gutters">
          <Col md={2} xs={12} className="  pt-3 pr-1 cursor-pointer" onClick={() => props.goToStep(1)}>
            1. Classification
       <li className={props.currentStep === 1 ? 'active' : null}></li>
          </Col>
          <Col md={2} xs={12} className=" pt-3 pr-1 cursor-pointer" onClick={isNaN(props.assetId) ?  () => showWarning() : props.assetId !== '' ?  () => props.goToStep(2) : () => showWarning()}>
            2. Rate Asset
      <li className={props.currentStep === 2 ? 'active' : null} ></li>
          </Col>
          <Col md={2} xs={12} className=" pt-3 pr-1 cursor-pointer" onClick={isNaN(props.assetId) ?  () => showWarning() : props.assetId !== '' &&  props.enableNextStep ?  () => props.goToStep(3) : () => showWarning()}>
            3. Comments
      <li className={props.currentStep === 3 ? 'active' : null}></li>
          </Col>
        </Row>
      </ul>
    </Container>
  )
}
export default AssetsNav;