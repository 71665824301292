import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { Storage } from '../../helpers/storage';
import PageNotFound from '../../shared/PageNotFound';

export const PrivateRoute = ({ component: Component,permission , ...rest  }) => {
    
    let couldShow = false;
    const location = useLocation();
    if(permission && Storage.getSession().permissions){
         couldShow = Storage.getSession().permissions.includes(permission);
          if(!couldShow)  return <PageNotFound />
    }else{couldShow = true;}

    const renderLogin = () => {
        return <Redirect to={`/login?next=${location.pathname}${location.search}`} />
    }
    return (
    <Route {...rest} render={props => (
        Storage.getSession() && Storage.getSession().permissions.includes('qrata.back_office_access') && couldShow ? <Component {...props} /> : 
        renderLogin()
    )} />
    )
};