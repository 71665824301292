import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import user from '../../assets/images/default.jpg';
import { Col, Container, Row, Image, Button, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as bioService from '../../services/bio';
import { useParams } from 'react-router-dom';
import AccordionForm from '../../shared/AccordionForm';
import BioJob from './bio/BioJob';
import BioAccomplishment from './bio/BioAccomplishment';
import BioEducation from './bio/BioEducation';
import { HasPermission } from '../../shared/HasPermission';
import { Status } from "../../helpers/constants";
import { history, Storage } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import * as loaderAction from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import CommentModal from '../../shared/CommentModal';
import { useBoolean } from '../../hooks/basic';
import CommentList from '../../shared/CommentList';
import TooltipHover from '../../shared/TooltipHover';

const ApplicationBio = () => {
   const dispatch = useDispatch();
   const [isVisible, showModal, hideModal] = useBoolean(false);
   const loginData = useSelector((state) => state.auth.loginData);
   const applicationId = useParams();
   const [bioId, setBioId] = useState('');
   const [bioText, setBioText] = useState('');
   const [bioTextShow, setBioTextShow] = useState(false);
   const [bioPic, setBioPic] = useState('');
   const [bioPicId, setBioPicId] = useState('');
   const [uploadFile, setUploadFile] = useState('');
   const [editName, setEditDisplay] = useState(false);
   const [bioDisplayName, setBioDisplayName] = useState('');
   const [accomplishment, setAccomplishment] = useState([]);
   const [job, setJob] = useState([]);
   const [education, setEducation] = useState([]);
   const [catalog, setCatalog] = useState('');
   const [disable, setDisable] = useState(false);
   const [commentList, setCommentList] = useState([]);
   const [revise, setRevise] = useState(false);
   const [detectSave , setDetectSave] = useState(true);
   const [loadData , setLoadData] = useState(false);


   // CREATE NEW BIO
   const createBio = useCallback(() => {
      if (applicationId) {
         dispatch(loaderAction.loader(true));
         const data = {
            "catalog": applicationId.applicationId,
            "status": 4
         }
         bioService.createBio(data).then(([response, error]) => {
            if (response) {
               dispatch(alertActions.success('New bio created successfully.'));
               setBioId(response.id);
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [applicationId, dispatch])


   // GET BIO ALL DATA
   const getBioData = useCallback(() => {
      if (bioId !== '') {
         dispatch(loaderAction.loader(true));
         bioService.getBioById(bioId).then(([response, error]) => {
            if (response) {
               setCatalog(response.specialization);
               setBioDisplayName(response.bio.display_name ? response.bio.display_name : '');
               setBioId(response.bio.id ? response.bio.id : '');
               setBioText(response.bio.bio_text ? response.bio.bio_text : '');
               setBioPicId(response.bio.bio_photo ? response.bio.bio_photo : '');
               setAccomplishment(response.accomplishment);
               setEducation(response.education);
               setJob(response.job);
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [bioId, dispatch]);

   // GET BIO BY APPLICATION ID 
   const getBioByApplicationId = useCallback(() => {
      if (applicationId.applicationId !== '') {
         dispatch(loaderAction.loader(true));
         bioService.getBio(applicationId.applicationId).then(([response, error]) => {
            if (response.results.length > 0) {
               if (response.results[0].status === Status.DRAFT.value && Storage.getSession().permissions.includes('qrata.add_bio')) {
                  setBioId(response.results[0].id);
                  getBioData();
               } else if (response.results[0].status === Status.UNDER_REVIEW.value && Storage.getSession().permissions.includes('qrata.approve_bio')) {
                  setBioId(response.results[0].id);
                  getBioData();
               } else if (response.results[0].status === Status.UNDER_REVIEW.value && Storage.getSession().permissions.includes('qrata.add_bio')) {
                  dispatch(alertActions.info('Your application in Under Review.'));
                  history.push('/specialization');
               } else if (response.results[0].status === Status.APPROVED.value) {
                  dispatch(alertActions.info('Your application already approved.'));
                  history.push('/dashboard');
               } else if (response.results[0].status === Status.REJECTED.value) {
                  dispatch(alertActions.info('Your application already rejected.'));
                  history.push('/dashboard');
               }
            } else if (response.results.length === 0 && Storage.getSession().permissions.includes('qrata.add_bio')) {
               createBio();
               getBioData();
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
            setLoadData(true)
         })
      }
   }, [applicationId.applicationId, createBio, dispatch, getBioData])

   useEffect(() => {
      if (applicationId.applicationId) {
         bioService.getInitialBioText(applicationId.applicationId).then(([response, error]) => {
            if (response.reason ) {
              if (bioText === "") {
               setBioText(response.reason)
              }
            }
         })
      }
   }, [applicationId.applicationId, bioText])

   useEffect(() => {
      if (applicationId.applicationId) {
         getBioByApplicationId();
      }
   }, [applicationId.applicationId, getBioByApplicationId])


   // SAVE DISPLAY NAME AND BIO TEXT
   const saveData = () => {
      if (bioDisplayName.trim() || bioText.trim()) {
         const data = {
            catalog: applicationId.applicationId,
            bio_text: bioText ? bioText : null,
            display_name: bioDisplayName ? bioDisplayName : null,
         }
         setDetectSave(true);
         dispatch(loaderAction.loader(true));
         bioService.updateBio(bioId, data).then(([response, error]) => {
            if (response) {
               if (response.display_name !== null && response.display_name !== '') setBioDisplayName(response.display_name);
               if (response.bio_text !== null && response.bio_text !== '') setBioText(response.bio_text);
               setEditDisplay(false);
               setBioTextShow(false);
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }

   // PIC PREVIEW
   const selectPhoto = useCallback((e) => {
      const fileFormat = ['jpg', 'png', 'jpeg'];
      let reader = new FileReader();
      const file = e.target.files[0];
      if (fileFormat.includes(file.type.split('/')[1])) {
         reader.onload = function (e) {
            setBioPic(reader.result);
            setUploadFile(file);
         }
         reader.readAsDataURL(file);
      } else {
         dispatch(alertActions.error('Please select valid file format .'));
      }
   }, [dispatch])

   // UPLOAD PROFILE IMAGE 
   const uploadPhoto = useCallback(() => {
      if (uploadFile !== '') {
         dispatch(loaderAction.loader(true));
         let data = new FormData();
         data.append('bio_id', bioId);
         data.append('bio_photo', uploadFile, uploadFile.name);
         bioService.uploadBioPhoto(data).then(([response, error]) => {
            if (response) {
               dispatch(alertActions.success('Profile photo updated.'));
               setUploadFile('');
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [bioId, dispatch, uploadFile])

   const handleBio = (e) => {
      setBioText(e);
   }

   const commentsCount  = commentList && commentList.length > 0 ? commentList.length : 0;
   // ACCORDION DATA
   const data = [
      { name: 'Accomplishments', open: true, type: 'component', component: <BioAccomplishment disable={disable} bioId={bioId} getData={() => getBioData()} accomplishment={accomplishment} applicationId={applicationId.applicationId} /> },
      { name: 'Education', open: true, type: 'component', component: <BioEducation disable={disable} bioId={bioId} getData={() => getBioData()} education={education} applicationId={applicationId.applicationId} /> },
      { name: 'Jobs', open: true, type: 'component', component: <BioJob disable={disable} bioId={bioId} getData={() => getBioData()} job={job} applicationId={applicationId.applicationId} /> },
      { name: `Comments (${commentsCount})`, open: false, type: 'component', component: <CommentList comments={commentList ? commentList : []} /> },
   ]

   // BIO EDITOR TOOLBAR
   var toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],
      [{ 'link': [] }]
   ];

   const modules = {
      toolbar: {
         container: toolbarOptions
      }
   }

   useEffect(() => {
      if (bioPicId !== '') {
         dispatch(loaderAction.loader(true));
         bioService.getBioPhotoById(bioPicId).then(([response, error]) => {
            if (response) {
               setBioPic(response.file_url ? response.file_url : '');
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [bioPicId, dispatch])

   // VERIFY BIO
   const verifyBio = useCallback((status) => {
      if (bioId && status) {
         dispatch(loaderAction.loader(true));
         const data = {
            "bio_id": bioId,
            "catalog": applicationId.applicationId,
            "status": status
         }
         bioService.verifyBio(data).then(([response, error]) => {
            if (response) {
               setDisable(true);
               if (status === 2) {
                  dispatch(alertActions.success('Bio Submitted Successfully.'));
                  history.push('/specialization');
               } else if (status === 1) {
                  dispatch(alertActions.success('Bio Approved Successfully.'));
                  history.push('/bioList');
               } else if (status === 3) {
                  dispatch(alertActions.success('Bio Rejected Successfully.'));
                  history.push('/bioList');
               } else if (status === 4) {
                  dispatch(alertActions.success('Bio Revised Successfully.'));
                  history.push('/bioList');
               }
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [applicationId.applicationId, bioId, dispatch])

   // GET ALL BIO COMMENTS
   const getBioComments = useCallback(() => {
      if (bioId !== '') {
         dispatch(loaderAction.loader(true));
         bioService.getCommentsBio(bioId).then(([response, error]) => {
            if (response) {
               setCommentList(response.bio_comment);
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [bioId, dispatch])

   useEffect(() => {
      if (bioId !== '') {
         getBioComments();
      }
   }, [getBioComments, bioId])

   // CLOSE MODAL COMMENTS AND SAVE COMMENT
   const closeModal = useCallback((data) => {
      hideModal();
      if (data.comment) {
         if (bioId !== '') {
            const newData = new FormData();
            newData.append('bio_id', bioId);
            newData.append('ask_by', loginData.id);
            newData.append('comment', data.comment);
            if (data.attachment) {
               newData.append('attachment', data.attachment)
            };
            bioService.createCommentBio(newData).then(([response, error]) => {
               if (response) {
                  dispatch(alertActions.success('Comment Added Successfully.'));
                  getBioComments();
                  if (revise) verifyBio(Status.DRAFT.value)
               } else if (error) {
                  dispatch(alertActions.error(error.response));
               }
            })
         }
      }
   }, [hideModal, loginData.id, bioId, revise, dispatch, getBioComments, verifyBio]);

   const openModal = useCallback((value) => {
      setRevise(value);
      showModal();
   }, [showModal]);

   // SUBMIT BIO AND CHECK IT'S SAVE OR NOT
   const saveBio = useCallback(() => {
      if(detectSave){
         verifyBio(Status.UNDER_REVIEW.value)
      }else {
         dispatch(alertActions.error('Please save the bio firstly then submit.'));
      }      
   },[detectSave  , verifyBio , dispatch])

   // EDIT BIO BUTTON 
   const editBio = useCallback(() => {
      setBioTextShow(true);
      setDetectSave(false)
   },[])

   return (
      <Container fluid>
         <CommentModal title='Comments' show={isVisible} onHide={(e) => closeModal(e)} />
         <Row className="bg-white">
            <Col md={4} className="p-0 bg-dark">
               <Card className="bg-dark p-3 border-0">
                  <Card.Body className="text-center">
                     <Row className="no-gutters">
                        <Col md={12} className="justify-content-center">
                           <span className="user-img m-auto"><Image src={bioPic !== '' ? bioPic : user} roundedCircle />
                              <HasPermission permission='qrata.add_bio'>
                                 {uploadFile === '' ? <><input type='file' accept='.jpg,.png,.jpeg' onChange={(e) => selectPhoto(e)} name='bioPicture' id='bioPicture' className='d-none' />
                                    <label className='btn-sm btn-primary rounded-pill upload-btn' htmlFor='bioPicture'><FontAwesomeIcon icon="camera" /></label></>
                                    : <Button variant='warning' onClick={() => uploadPhoto()} className='rounded-pill upload-btn' size='sm'><FontAwesomeIcon icon="upload" /> </Button>}
                              </HasPermission>
                           </span>
                        </Col>
                        <Col md={12} className="py-2">
                           <span className='d-inline-block'>
                              {editName ? <input type='text' className='display-name-input' value={bioDisplayName} onChange={(e) => setBioDisplayName(e.target.value)} name='displayName' placeholder='Name' /> :
                                 <span className="h3 text-white">{bioDisplayName ? bioDisplayName : 'Enter Name'} </span>}
                              <HasPermission permission='qrata.add_bio'>  <sup> {editName ? <FontAwesomeIcon onClick={() => saveData()} className='text-white' icon="check" /> : <FontAwesomeIcon onClick={() => setEditDisplay(true)} className='text-white' icon="pen" />}</sup>
                              </HasPermission>
                           </span>
                        </Col>
                     </Row>
                     <Card.Text className='text-warning'>
                        {catalog !== '' ? catalog : ''}
                     </Card.Text>
                  </Card.Body>
               </Card>
            </Col>
            <Col md={8} className=' bg-white p-3 positionm-relative'>
               {bioTextShow ?
                  <>
                     <Button size='sm' className="float-right mr-2 mt-2" onClick={() => saveData()} variant='primary'>Save bio</Button>
                     <ReactQuill
                        value={bioText}
                        onChange={(e) => handleBio(e)}
                        className=''
                        style={{ 'height': '250px' }}
                        modules={modules}
                     /> </> : <><HasPermission permission='qrata.add_bio'><FontAwesomeIcon onClick={() => editBio()} className='cursor-pointer float-right ml-2' icon='pen' /></HasPermission>
                     {bioText !== '' ? <div className="overflow-auto" style={{ 'height': '300px' }} dangerouslySetInnerHTML={{ __html: bioText }}>
                     </div> : <div className="overflow-auto" style={{ 'height': '300px' }} dangerouslySetInnerHTML={{ __html: bioText }}></div>}</>}
            </Col>
         </Row>
         <Row>
            <Col md={12} className='mt-5'>
               {loadData?<AccordionForm data={data} />:""}
            </Col>
            </Row>
            <Row className="mb-5">

               <Col md={4} className="text-left">
               <TooltipHover type='button' btnSize='md' btnClass="mr-2 d-block" variant='info' iconClass="ml-1" btnIcon={'comment'} label='Comment' message='This is for internal Team Qrata questions because things are unclear, for suggestions an upgrades, problems but is NOT a live chat and cannot be seen by Members using the Database from the front end UI' handleEvent={() => openModal()} placement='bottom'/> 
               </Col>
            <Col md={8} className='text-right mt-2'>
               {/* <Button className="mr-2 mb-2 float-left" variant="info" onClick={() => openModal(false)}>
                  <FontAwesomeIcon className="text-white mr-1" icon="comment" size="sm" />
                Comment
              </Button> */}
             

               <HasPermission permission='qrata.approve_bio'>
                  <Button disabled={disable} className=" btn  btn-primary mr-2" onClick={() => verifyBio(Status.APPROVED.value)}>
                     <FontAwesomeIcon className="text-white mr-1" icon="save" size="sm" />
                        APPROVE
                  </Button>
                  <Button disabled={disable} variant='outline-secondary' className=" mr-2" onClick={() => openModal(true)}>
                     <FontAwesomeIcon className="mr-1" icon="exchange-alt" size="sm" />
                        REVISE
                  </Button>
                  <Button variant="outline-secondary" onClick={() => history.push('/applications')}>
                     <FontAwesomeIcon className="mr-1" icon="times" size="sm" />
                        CLOSE
                  </Button>
               </HasPermission>
               <HasPermission permission='qrata.submit_bio'>
                  <Button className=" btn  btn-primary mr-2" onClick={() => saveBio()}>SUBMIT</Button>
                  <Button variant="outline-secondary" onClick={() => history.push('/specialization')}>CLOSE</Button>
               </HasPermission>
            </Col>
         </Row>
      </Container>
   )
}
export default ApplicationBio;