import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import * as alertActions from "../../actions/alert";
import * as doNotRankService from '../../services/doNotRank'
import { Container } from 'react-bootstrap';
import ModalForm from "../../shared/ModalForm";
import * as tagsService from '../../services/tags';
import * as loaderAction from '../../actions/loader'

const DoNotRankForm = ({ value, data, isVisible, hide, output }) => {

    const [tags, setTags] = useState()
    const [listType, setListType] = useState([])
    const dispatch = useDispatch()
    const [domainData, setDomainData] = useState({
        domain: '',
        reason: '',
        tags: '',
        list_type: ''
    })
    const prevState = useRef(domainData)

    useEffect(() => {
        if (data) {
            const x =data.tags?.map((tag)=>{
                return {'label':tag.tag, value: tag.id}
            }) 
            console.log('datause',data);
            setDomainData({...data,tags:x})
        } else {
            setDomainData(prevState.current);
        }
        doNotRankService.getListType().then(([response,error]) => {
            if (response) {
                setListType(response)
            }
            if (error) dispatch(alertActions.error(error.response))
        })
    }, [data])

    const handleResponse = useCallback(([response, error]) => {
        if (response) {
            output();
            hide();
            dispatch(alertActions.success(response.success))
        } else if (error) {
            dispatch(alertActions.error(error.response))
        }
    }, [hide, output, dispatch])

    const closeModal = useCallback((data) => {
        if (data.tags){
            const x = data.tags?.map((tag)=>{
                return {'tag':tag.label}
            })
            if (x && x.length>0){
                data.tags = x
            }
        }

        hide();
        if (data !== '') {
            if (data.id) {
                // console.log('data',data);
                // urlValidator(data.domain).then(
                //     (res) => {
                //         if (res===false){
                //             console.log('response update',res)
                            doNotRankService.updateDomain(data.id, data).then(handleResponse);
                //         }
                //     }
                // )
                // hide();
            } else if (data.domain){
                // urlValidator(data.domain).then(
                //     (res) => {
                //         if (res===false && domainData.domain!==''){
                            doNotRankService.addDomain(data).then(handleResponse)
            //                 hide();
            //             }else{
            //                 dispatch(alertActions.error('Domain is not valid'))
            //             }
            //         }
            //     )
            // }   
        }
            }
        }, [hide, handleResponse])

    // get tags list
    useEffect(() => {
        dispatch(loaderAction.loader(true));
        tagsService.fetchAll().then(([response, error]) => {
            if (response) {
                const t = response.results.map((t) => {
                    return {
                        value: t.id,
                        label: t.tag
                    }
                })
                setTags(t)                
            } else if (error) {
                dispatch(alertActions.error(error.response))
            }
        });
        dispatch(loaderAction.loader(false))
        return () => {
            setTags([]);
        };
    }, [dispatch,data]);

    const handleEve = (value) => {
        if(value!==null){
            
            if(value.length>0){

                if (value[value.length-1].__isNew__===true){
                    tagsService.addTag({tag:value[value.length-1].label})
                }
            }else {
                value=''
            }


        }else{
            value=''
        }
    }


    const fields = [
        [{ label: 'Domain Name ', name: 'domain', type: 'text', required: true, placeholder: 'Enter Domain', sm: 12, md: 12, lg: 12, xl: 12 },
        { label: 'Reason', name: 'reason', type: 'text', required: true, placeholder: "What's in your mind...", sm: 12, md: 12, lg: 12, xl: 12 }],
        { label: 'List Type', name: 'list_type', type: 'select',
            // options: listType,
            options: [{name:'BLACK', value: 1}, {name:'GREY', value:2}]
            , required: true, placeholder: "Select-Type", sm: 12, md: 12, lg: 12, xl: 12 },

        {
            label: "Tags",
            required: true,
            type: "multi-select",
            creatable: true,
            multi: true,
            name: "tags",
            placeholder: "--select--",
            options: tags,
            onCreate:handleEve,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
        },

    ]

    return (
        <>
            <Container fluid>
                {isVisible ?
                    <ModalForm
                        show={isVisible}
                        onHide={(e) => closeModal(e)}
                        data={domainData}
                        fields={fields}
                        title='DoNot Rank'
                        onCreate={(e)=>handleEve(e)}
                    />
                    : null}
            </Container>
        </>
    )
}

export default DoNotRankForm