import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TooltipHover from '../../../shared/TooltipHover';
import { monthYearFormat } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Status } from '../../../helpers/constants';

const BioJob = ({ job, disable }) => {

  return (
    <>
      {job.length > 0 ?
        <Card className="mt-1 border-0">
          {job.map((item, index) => (
            <Card.Body key={index} className="pb-0">
              <Row key={index}>
                <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item.job[0].company}>
                  {item.job[0].company}
                </Col>
                <Col md={3} sm={12} className="text-warning text-left font-weight-bold text-truncate overflow-hidden" title={item.job[0].job_title}>
                  {item.job[0].job_title}
                </Col>
                <Col md={2} sm={12}>
                  {item.job[0].is_conflict ? <>Conflict <TooltipHover type='icon' iconClass='ml-1' variant='link' icon='info-circle' message={item.job[0].conflict_reason} handleEvent={() => null} /> </> : 'No Conflict'}
                </Col>
                <Col md={3} sm={12} className="text-info font-weight-bold font-style-italic text-right">
                  {monthYearFormat(item.job[0].experience_to) + ' - ' + (item.job[0].experience_from !== null? monthYearFormat(item.job[0].experience_from):"To Present")}
                  <a className=" text-danger text-decoration-none ml-2" target="_blank" rel="noopener noreferrer" href={item.job[1] ? item.job[1].file_url : '#'}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>
                </Col>
                <Col md={1} sm={12}>
                    {item.job_status === Status.APPROVED.value ? '' :
                      <><label className="switch">
                        <input type="checkbox" name={item.job_id} onChange={(e) => null} disabled={disable} />
                        <span className="slider round"></span>
                      </label></>}
                </Col>
              </Row>
            </Card.Body>
          ))} </Card>
        : 'No data found.'}
    </>
  )
}
export default BioJob;