import React, { useRef, useState , useCallback } from "react";
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import TooltipHover from "../../shared/TooltipHover";
import * as topicRequestService from '../../services/topicRequest';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import AsyncAppTable from "../../shared/AsyncAppTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoolean } from "../../hooks/basic";
import ConfirmModal from "../../shared/ConfirmModal";
import TopicRequestForm from "./TopicRequestForm";
import TopicChangeParentForm from "./TopicChangeParentForm";
import {defaultLimit } from "../../helpers/utility"
import moment from "moment"
import { dateOnlyFormat, QueriesStatus } from '../../helpers';
import SearchField from '../../shared/SearchField';
import SubTable from "../../shared/SubTable";


const TopicRequestList = () => {

  const dispatch = useDispatch();
  const fetchIdRef = useRef(0);
  const [loading , setLoading ] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [listingData , setListingData] = useState([]);
  const [id , setId] = useState('');
  const [selectedAssets , setSelectedAssets] = useState([]);
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [isChangeFormVisible, showChangeForm, hideChangeForm] = useBoolean(false);
  
  const [editData , setEditData] = useState('');
  // const [editId , setEditId] = useState('');
  const [resultCount, setResultCount] = useState(0)
  const [search , setSearch] = useState('');
  const [status , setStatus] = useState(1);
  const [callFetch, setCallFetch] = useState(false);
  
  const Children = ({row}) => {
    const subColumns = [
      {
        Header: "Action",
        accessor: "action",
        sortingOff: true,
        className: 'text-center',
        Cell: (cell) => (
          <div>
            <TooltipHover type='button' btnSize='sm' handleEvent={() =>  setParent(cell.row.original.id)} variant='link' iconClass='default' btnIcon='child' message='Set Primary' /> 
            <TooltipHover type='button' btnSize='sm' handleEvent={() =>  unlink(cell.row.original.id)} variant='link' iconClass='primary' btnIcon='undo' message='Un-link' /> 
            <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default'  message='View Message' handleEvent={() => openModal(cell.row.original)} />
            <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Message' handleEvent={() => openConfirmModal(cell.row.original.id)}/>
          </div>
        ),
      },
      {
        Header: "Ticket Id",
        accessor: "ticket",
        sortType: "basic",
        filtering:true
      },

      {
        Header: "Name",
        accessor: "user__first_name",
        sortType: "basic",
        className: 'text-capitalize',
        filtering:true,
        Cell: (cell) => ( 
          <div>
            {cell.row.original.first_name || cell.row.original.last_name ?  cell.row.original.first_name+' '+cell.row.original.last_name  : 'Guest user'}
          </div>
        )
      },
      {
        Header: "Email ID",
        accessor: "user__email",
        sortType: "basic",
        className: 'text-capitalize',
        filtering:true,
        Cell: (cell) => (cell.row.original.email)
      },
      {
        Header: "Specific Topic",
        accessor: "specific_topic",
        sortType: "basic",
        filtering:true
      },
      {
        Header: "Created On",
        accessor: "created_on",
        sortType: "basic",
        Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
      },
      {
        Header: "File",
        className: 'text-center',
        sortingOff: true,
        Cell: (cell) => ( 
          <div>
            {cell.row.original.file ? <FontAwesomeIcon className='cursor-pointer' onClick={() => openlink(cell.row.original.file[0]?.file_url)} icon='download' /> : 'No file'}
          </div>
        )
      },

      
    ];  
    return <><SubTable columns={subColumns} data={row.original.children} /></>;
  }

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField="", filter }) => {
    const fetchId = ++fetchIdRef.current;
   
   setLoading(true);
   setTimeout(() => {
     if (fetchId === fetchIdRef.current) {
       const limit = pageSize;
       let offset = pageIndex * pageSize;
       dispatch(loaderAction.loader(true));
       topicRequestService.topicRequestListing( '' , limit , offset, sortedField, filter, search, status ).then(([response , error]) => {
         if(response){
          setListingData(response.results);
          setResultCount(response.count)
           setPageCount(Math.ceil(response.count / pageSize))
         }else if(error){
           dispatch(alertActions.error(error.response));
         }
         dispatch(loaderAction.loader(false));
       })
       setLoading(false);
       setCallFetch(false);
     }
   }, 100)
 }, [dispatch, search, status])

 const openlink = (link) => {
  window.open(link, '_blank');
}

const deleteData  = useCallback(() => {
  dispatch(loaderAction.loader(true));
  topicRequestService.deleteTicket(id).then(([response , error]) => {
    hideConfirm();
    // fetchData(defaultLimit ,0);
    setCallFetch(true);
    if(error){
      dispatch(alertActions.error(error.response));
    }
    dispatch(loaderAction.loader(false));
  })
},[dispatch , id , hideConfirm ])

const openConfirmModal = (id) => {
  setId(id);
  showConfirm();
};

const closeConfirmModal = () => {
  setId('');
  hideConfirm();
};

const acceptConfirmation = useCallback(() => {
 deleteData();
},[deleteData])

const openModal = useCallback((data) => {
  if(data) setEditData(data);
  showModal();
}, [showModal]);

const unlink = useCallback((topicId) => {
  dispatch(loaderAction.loader(true));

  topicRequestService.removeParent(topicId).then(([response , error]) => {
    if(response){
     dispatch(alertActions.success(response.message));
    //  fetchData({defaultLimit ,0});
    setCallFetch(true);
    }else if(error){
      dispatch(alertActions.error(error.response.data.detail));
    }
    dispatch(loaderAction.loader(false));
  });

},[dispatch]);

const setParent = useCallback((newParent) => {
      dispatch(loaderAction.loader(true));
       topicRequestService.setParent(newParent).then(([response , error]) => {
         if(response){
          dispatch(alertActions.success(response.message));
          // fetchData({defaultLimit ,0});
          setCallFetch(true);
         }else if(error){
           dispatch(alertActions.error(error.response.data.detail));
         }
         dispatch(loaderAction.loader(false));
       });
 },[dispatch])

const closeModal = useCallback(() => {
  setEditData('');
  // fetchData({defaultLimit ,0});
  setCallFetch(true)
  hideModal();
},[hideModal])

const changeParent =  useCallback(() => {
  showChangeForm();
},[showChangeForm])

const handleSelect  = (id, e) => {
  if (e.target.checked) {
    setSelectedAssets([...selectedAssets, id])
  } else {
    let assetIds = selectedAssets.filter(asset=> asset !== id)
    setSelectedAssets(assetIds)
  }
}

const renderRowSubComponent = useCallback(({ row  }) => (
  <>
    <Children
    row={row} />
  </>
), [])

 const columns = [
  {
    Header: "#",
    accessor: "id",
    id: 'expander', 
    sortingOff: true,
    Cell: ({ row, rows, toggleRowExpanded }) => (
      row.original.children.length>0?(
      <span {...row.getToggleRowExpandedProps} onClick={() => {
        const expandedRow = rows.find(row => row.isExpanded);

        if (expandedRow) {
          const isSubItemOfRow = Boolean(
            expandedRow && row.id.split(".")[0] === expandedRow.id
          );

          if (isSubItemOfRow) {
            const expandedSubItem = expandedRow.subRows.find(
              subRow => subRow.isExpanded
            );

            if (expandedSubItem) {
              const isClickedOnExpandedSubItem =
                expandedSubItem.id === row.id;
              if (!isClickedOnExpandedSubItem) {
                toggleRowExpanded(expandedSubItem.id, false);
              }
            }
          } else {
            toggleRowExpanded(expandedRow.id, false);
          }
        }
        row.toggleRowExpanded();
      }
    }>
        {row.isExpanded ? <span aria-label='img' role='img' > <FontAwesomeIcon className="text-mute  nav-icons" icon="caret-down" size="lg" /></span> :
        <span aria-label='img' role='img' ><FontAwesomeIcon className="text-mute  nav-icons" icon="caret-right" size="lg" /></span>}
      </span>):(<div className='text-center'>
          <Form.Check className='' defaultChecked={selectedAssets.includes(row.original.id)} onChange={(e) => handleSelect(row.original.id, e)} type="checkbox"/>
          </div>)
    )
  },
  {
    Header: "Action",
    accessor: "action",
    sortingOff: true,
    className: 'text-center',
    Cell: (cell) => (
      <div>
        {/* <TooltipHover type='button' btnSize='sm' handleEvent={() =>  setParent(cell.row.original.id)} variant='link' iconClass='default' btnIcon='edit' message='Change Parent' />  */}
        <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default'  message='View Message' handleEvent={() => openModal(cell.row.original)} />
        <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Message' handleEvent={() => openConfirmModal(cell.row.original.id)}/>
      </div>
    ),
  },
  {
    Header: "Ticket Id",
    accessor: "ticket",
    sortType: "basic",
    filtering:true
  },
  {
    Header: "Name",
    accessor: "user__first_name",
    sortType: "basic",
    className: 'text-capitalize',
    filtering:true,
    Cell: (cell) => ( 
      <div>
        {cell.row.original.first_name || cell.row.original.last_name ?  cell.row.original.first_name+' '+cell.row.original.last_name  : 'Guest user'}
      </div>
    )
  },
  {
    Header: "Email ID",
    accessor: "user__email",
    sortType: "basic",
    className: 'text-capitalize',
    filtering:true,
    Cell: (cell) => (cell.row.original.email)
  },
  {
    Header: "Specific Topic",
    accessor: "specific_topic",
    sortType: "basic",
    filtering:true
  },
  {
    Header: "Created On",
    accessor: "created_on",
    sortType: "basic",
    Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
  },
  {
    Header: "File",
    className: 'text-center',
    sortingOff: true,
    Cell: (cell) => ( 
      <div>
        {cell.row.original.file ? <FontAwesomeIcon className='cursor-pointer' onClick={() => openlink(cell.row.original.file[0]?.file_url)} icon='download' /> : 'No file'}
      </div>
    )
  },

];
const closeChangeParentModal = useCallback(() => {
  setSelectedAssets([])
  // fetchData({defaultLimit ,0});
  setCallFetch(true);
  hideChangeForm();
},[hideChangeForm])

  return (
    <>
    <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={acceptConfirmation} title={'Do you want delete this topic request ? '} />
    <TopicRequestForm data={editData} title='Topic Request Reply' isVisible={isVisible} hide={closeModal} refreshData={() => setCallFetch(true)} />
    <TopicChangeParentForm topicIds={selectedAssets} isVisible={isChangeFormVisible} hide={closeChangeParentModal}/>
   
   <Container fluid className="px-0">
      <Row>
          <Col md={4} sm={12} xs={12} className="mb-md-0 mb-3">
          <h4>Topic Requests</h4> 
          </Col>
          <Col md={1} sm={6} xs={6}>
          {selectedAssets.length>0?<TooltipHover handleEvent={changeParent} changeParent type='button' btnSize='md' variant='primary' btnIcon='child' iconClass='text-white' message='Link To' /> :""}
          </Col>
          <Col md={3}>
              <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
              {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
              )}
              </Form.Control>
          </Col>
          <SearchField md={4} action={setSearch}/>
      </Row>
            <AsyncAppTable
      columns={columns}
      data={listingData}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      resultCount={resultCount}
      filtering={true}
      subComponent={renderRowSubComponent} 
      callFetch={callFetch}
      />
    </Container>
    </>
  );
}

export default TopicRequestList;