import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import AccordionForm from '../../shared/AccordionForm';
import { useParams } from 'react-router-dom';
import * as applicationServices from '../../services/application';
import { monthYearFormat, getStatus } from '../../helpers';
import TooltipHover from '../../shared/TooltipHover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Status } from '../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import * as alertActions from "../../actions/alert";
import * as loaderAction from "../../actions/loader";
import { history } from '../../helpers';
import { useBoolean } from '../../hooks/basic';
import CommentModal from '../../shared/CommentModal';
import CommentList from '../../shared/CommentList';


const ApplicationReview = () => {

  const dispatch = useDispatch();
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const loginData =  useSelector((state) => state.auth.loginData);
  const { applicationId } = useParams();
  const [appData, setAppData] = useState([]);
  const [catalog, setCatalog] = useState('');
  const [commentList , setCommentList] = useState([]);
  const [revise , setRevise] = useState(false);


  useEffect(() => {
    if (applicationId) {
      dispatch(loaderAction.loader(true));
      applicationServices.getAppData(applicationId).then(([response, error]) => {
        if (response) {
          if (response.application.status === Status.UNDER_REVIEW.value) {
            setAppData(response);
          } else {
            dispatch(alertActions.error("Invalid application."));
            setTimeout(() => {
              history.push('/applications');
            }, 500)
          }
        } else if (error) {
          dispatch(alertActions.error(error.response));
          setTimeout(() => {
            history.push('/applications');
          }, 500)
        }
        dispatch(loaderAction.loader(false));
      })
    }

  }, [applicationId, dispatch])

  const getComment = useCallback(() => {
    if(applicationId !== ''){
    dispatch(loaderAction.loader(true));
    applicationServices.getComments(applicationId).then(([response , error]) => {
      if(response){
       setCommentList(response.app_comment);
      }else if(error){
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }
  },[applicationId , dispatch])

  useEffect(() => {
    if (applicationId !== '') {
      getComment();
   }
  },[applicationId , dispatch , getComment])

  const validation = useCallback(() => {
      if (appData.validate_accomplishment) {
        if (appData.validate_education) {
          if (appData.validate_job) {
            return true;
          } else {
            dispatch(alertActions.error('To approve this profile you need to verify all user qualifications like education, accomplishments and jobs from the qualifications tab in the left side bar of this dashboard.'));
            // dispatch(alertActions.error('To approve this profile you need to verify users qualifications like education, accomplishments and jobs from the qualifications tab in the left side bar of this dashboard.'));
          }
        } else {
          dispatch(alertActions.error('To approve this profile you need to verify all user qualifications like education, accomplishments and jobs from the qualifications tab in the left side bar of this dashboard.'));
        }
      } else {
        dispatch(alertActions.error('To approve this profile you need to verify all user qualifications like education, accomplishments and jobs from the qualifications tab in the left side bar of this dashboard.'));
      }
    return false;
  },[appData , dispatch])


  // APPLICATION VERIFY STATUS 
  const appVerify = useCallback((status) => {
    if (appData.application.id) {
      const data = {
        status: status,
        user: appData.application.user,
        application_id: appData.application.id
      }
      dispatch(loaderAction.loader(true));
      applicationServices.applicationVerify(data).then(([response, error]) => {
        if (response) {
          if (Status.APPROVED.value === status) {
            dispatch(alertActions.success('Application Approved Successfully.'));
          } else if (Status.DRAFT.value === status) {
            dispatch(alertActions.success('Application Revised Successfully.'));
          }
          history.push('/applications');
        } else if (error) {

          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [appData, dispatch]);

  const applicationApproved = useCallback((status) => {
    if(validation()){
      appVerify(status)
    }
  },[ validation ,appVerify])

  const closeModal = useCallback((data) => {
    hideModal();
    if (data.comment) {
      if (applicationId !== '') {
        const newData = new FormData();
        newData.append('application_id', applicationId);
        newData.append('ask_by', loginData.id);
        newData.append('comment', data.comment);
        if (data.attachment) {
            newData.append('attachment', data.attachment)
        };
        applicationServices.createComment(newData).then(([response, error]) => {
          if (response){
            dispatch(alertActions.success('Comment Added Successfully.'));
            getComment();
            if(revise) appVerify(Status.REVISE.value);
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
        })
      } else {
        dispatch(alertActions.error('Please save the specialization first.'));
      }
    }

  }, [hideModal, loginData, applicationId, revise ,  dispatch , getComment , appVerify]);

  const openModal = useCallback((value) => {
    setRevise(value);
    showModal();
  }, [showModal]);




  const getCatalog = (data) => {
    if (data) {
      setCatalog(data);
    }
  }

  // SPECIALIATION COMPONENT FOR SHOW DATA 
  const Specialization = ({ data, catalog }) => {

    const [specializationData, setSpecializationData] = useState([]);

    const setCatalog = useCallback(() => {
      if (specializationData) {
        let value = getFilterData(specializationData, 'CATEGORY').length > 0 ? getFilterData(specializationData, 'CATEGORY')[0].catalog_name : getFilterData(specializationData, 'MINOR').length > 0 ? getFilterData(specializationData, 'MINOR')[0].catalog_name : getFilterData(specializationData, 'MAJOR').length > 0 ? getFilterData(specializationData, 'MAJOR')[0].catalog_name : null;
        catalog(value);
      }
    }, [catalog, specializationData])


    useEffect(() => {
      if (data) {
        setSpecializationData(data.user_specialization);
      }
      setCatalog();
    }, [data, setCatalog])


    const getFilterData = (data, type) => {
      return data.filter(obj => obj.catalog_type === type);
    }

    return (
      <div>
        <p>{getFilterData(specializationData, 'MAJOR').length > 0 ? <><span className="text-info font-weight-bold">Major Catalog : </span>{getFilterData(specializationData, 'MAJOR')[0].catalog_name} </> : null}</p>
        <p>{getFilterData(specializationData, 'MINOR').length > 0 ? <><span className="text-info font-weight-bold">Minor Catalog : </span>{getFilterData(specializationData, 'MINOR')[0].catalog_name} </> : null}</p>
        <p>{getFilterData(specializationData, 'CATEGORY').length > 0 ? <><span className="text-info font-weight-bold">Category : </span>{getFilterData(specializationData, 'CATEGORY')[0].catalog_name} </> : null}</p>
      </div>
    )
  }

  // EDUCATION COMPONENT FOR SHOW DATA
  const Education = ({ education }) => {
    const [educationList, setEducationList] = useState([]);

    useEffect(() => {
      if (education) {
        setEducationList(education);
      }
    }, [education])

    return (
      <>
        {educationList.length > 0 ?
          <Card className="py-3">
            {educationList.map((item, index) => (

              <Card.Body key={index} className="py-1">
                <Row key={index}>
                  <Col md={3} sm={12} className="text-info font-weight-bold  text-truncate overflow-hidden" title={item[0].university}>
                  <label className="d-block text-left mb-0"><small>University</small></label>
                    <h6>{item[0].university}</h6>
                  </Col>
                  <Col md={3} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={item[0].course}>
                  <label className=" d-block text-left mb-0"><small>Course</small></label>
                   <h6 className='text-warning'> {item[0].course}</h6>
                  </Col>
                  <Col md={2}>
                  <label className="d-block text-left mb-0"><small>Status</small></label>
                  <Badge pill variant="primary">
                  {getStatus(item[0].status) ? getStatus(item[0].status) : null}
                  </Badge>
                  </Col>
                  <Col md={3} sm={6}>
                  <label className="d-block text-left mb-0"><small>Completion Date</small></label>
                  <h6>{monthYearFormat(item[0].degree_month_year)}</h6>
                  </Col>
                  <Col md={1} sm={6} className="text-info font-weight-bold font-style-italic text-right">
                  <label className="d-block text-left mb-0"><small>&nbsp;</small></label>
                    <a className=" text-danger text-decoration-none  ml-2" target="_blank" rel="noopener noreferrer" href={item[1].file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>
                  </Col>
                </Row>
                {educationList.length - 1 === index ? '' : <hr className="mt-1 mb-2" />}
              </Card.Body>

            ))} </Card>
          : 'No data found.'}
      </>
    )
  }

  // JOB COMPONENT FOR SHOW DATA
  const Jobs = ({ job }) => {
    const [jobList, setJobList] = useState([]);

    useEffect(() => {
      if (job) {
        setJobList(job);
      }
    }, [job])

    return (
      <>
        {jobList.length > 0 ?
          <Card className="py-3">
            {jobList.map((item, index) => (

              <Card.Body key={index} className="py-1">
                <Row key={index} className="no-gutters">
                  <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item[0].company}>
                  <label className="d-block text-left mb-0"><small>Company</small></label>
                    <h6>{item[0].company}</h6>
                  </Col>
                  <Col md={3} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={item[0].job_title}>
                  <label className="d-block text-left mb-0"><small>Title</small></label>
                   <h6 className='text-warning'> {item[0].job_title}</h6>
                  </Col>
                  {/* <Col md={2} sm={12}>
                  <label className="d-block text-left mb-0"><small>Conflict</small></label>
                    <h6>{item[0].is_conflict ? <>Yes <TooltipHover type='icon' iconClass='ml-1' variant='link' icon='info-circle' message={item[0].conflict_reason} handleEvent={() => null} /> </> : 'No'}</h6>
                  </Col> */}
                  <Col md={2}>
                  <label className="d-block text-left mb-0"><small>Status</small></label>
                  <Badge pill variant="primary">
                    {getStatus(item[0].status) ? getStatus(item[0].status) : null}
                  </Badge>
                  </Col>
                  <Col md={3} sm={6}>
                  <label className="d-block text-left mb-0"><small>Experience</small></label>
                  <h6>{monthYearFormat(item[0].experience_to) + ' - ' + (item[0].experience_from !== null? monthYearFormat(item[0].experience_from):"To Present")}</h6>
                  </Col>
                  <Col md={1} sm={6} className="text-info font-weight-bold font-style-italic text-right">
                  <label className="d-block text-left mb-0"><small>&nbsp;</small></label>
                  {item[0].is_conflict ? <><TooltipHover type='icon' iconClass='ml-0' variant='link' icon='info-circle' message={item[0].conflict_reason} handleEvent={() => null} /> </> : ''}
                  {item[1] ?   <a className=" text-danger text-decoration-none  ml-0" target="_blank" rel="noopener noreferrer" href={item[1].file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a> : '' }
                  </Col>
                </Row>
                {jobList.length - 1 === index ? '' : <hr className="mt-1 mb-2" />}
              </Card.Body>

            ))} </Card>
          : 'No data found.'}
      </>
    )
  }

  // ACCOMPLISHMENT COMPONENT FOR SHOW DATA
  const Accomplishment = ({ accomplishment }) => {

    const [accomplishmentList, setAccomplishmentList] = useState([]);

    useEffect(() => {
      if (accomplishment) {
        setAccomplishmentList(accomplishment);
      }
    }, [accomplishment])

    return (
      <>
        {accomplishmentList.length > 0 ?
          <Card className="py-3">
            {accomplishmentList.map((item, index) => (

              <Card.Body key={index} className="py-1">
                <Row key={index}>
                  <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item[0].accomplishment_type}>
                  <label className="d-block text-left mb-0"><small>Type</small></label>
                   <h6>{item[0].accomplishment_type}</h6>
                  </Col>
                  <Col md={3} sm={12} className="text-warning text-left font-weight-bold text-truncate overflow-hidden" title={item[0].title}>
                  <label className="d-block text-left mb-0"><small>Type</small></label>
                   <h6> {item[0].title} </h6>
                  </Col>
                  <Col md={2} sm={12}>
                  <label className="d-block text-left mb-0"><small>Status</small></label>
                  <Badge pill variant="primary">
                  {getStatus(item[0].status) ? getStatus(item[0].status) : null}
                  </Badge>
                  </Col>
                  <Col md={3} sm={6}>
                  <label className="d-block text-left mb-0"><small>Year</small></label>
                  <h6>{monthYearFormat(item[0].accomplishment_month_year)}</h6>
                  </Col>
                  <Col md={1} sm={6} className="text-info font-weight-bold font-style-italic text-right">
                  <label className="d-block text-left mb-0"><small>&nbsp;</small></label>
                    <a className=" text-danger text-decoration-none  ml-2" target="_blank" rel="noopener noreferrer" href={item[1]?.file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>
                  </Col>
                  <Col md={12} sm={12} className='text-truncate overflow-hidden' title={item[0].decrition}>
                  <label className="d-block text-left mb-0"><small>Description</small></label>
                    <h6>{item[0].description}</h6>
                  </Col>
                </Row>
                {accomplishmentList.length - 1 === index ? '' : <hr className="mt-1 mb-2" />}
              </Card.Body>

            ))} </Card>
          : 'No data found.'}
      </>
    )
  }

  // OTHER COMPONENT FOR SHOW DATA
  const OtherInformation = ({ data }) => {

    const [applicationData, setApplicationData] = useState([]);

    useEffect(() => {
      if (data) {
        setApplicationData(data);
      }
    }, [data])
    return (
      <div>
        <h6>Explain to us why are you an expert in the topic you are applying for ?</h6>
        <p>{applicationData.reason ? applicationData.reason : 'No Data'}</p>
        <h6>Additional Info</h6>
        <p>{applicationData.comments ? applicationData.comments : 'No Data'}</p>
      </div>
    )
  }

  const commentsCount  = commentList && commentList.length > 0 ? commentList.length : 0;
  const data = [
    { name: 'Specialization', type: 'component', open : true , label: catalog, component: <Specialization data={appData.user_specialization} catalog={(e) => getCatalog(e)} /> },
    { name: 'Accomplishments', type: 'component', open : true , component: <Accomplishment accomplishment={appData.accomplishment ? appData.accomplishment : []} /> },
    { name: 'Education', type: 'component', open : true , component: <Education education={appData.education ? appData.education : []} /> },
    { name: 'Jobs', type: 'component', open : true , component: <Jobs job={appData.job ? appData.job : []} /> },
    { name: 'Other Information', type: 'component', open : true , component: <OtherInformation data={appData.application ? appData.application : []} /> },
    { name: `Comments (${commentsCount})`, type: 'component', open: false ,  component: <CommentList comments={commentList ? commentList : []} /> },
  ]
  return (
    <Container fluid>
    <CommentModal title='Comments' show={isVisible} onHide={(e) => closeModal(e)}/>
      <Row className="py-3">
        <Col md={{ span: 10, offset: 1 }}>
          <h4>Application Review</h4>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <AccordionForm data={data} />
        </Col>
      </Row>
      <Row>
      <Col md={4} className="text-left">
               <TooltipHover type='button' btnSize='md' btnClass="mr-2 d-block" variant='info' iconClass="ml-1" btnIcon={'comment'} label='Comment' message='This is for internal Team Qrata questions because things are unclear, for suggestions an upgrades, problems but is NOT a live chat and cannot be seen by Members using the Database from the front end UI' handleEvent={() => openModal()} placement='bottom'/> 
               </Col>
        <Col md={8} className="text-md-right text-center mt-2 ">
        {/* <Button className="mr-2 mb-2 float-left" variant="info" onClick={() => openModal(false)}>
            <FontAwesomeIcon className="text-white mr-1" icon="comment" size="sm" />
                Comment
              </Button> */}
         
          <Button className="mr-2 mb-2" variant="primary" onClick={() => applicationApproved(Status.APPROVED.value)}>
            <FontAwesomeIcon className="text-white mr-1" icon="save" size="sm" />
                APPROVE
              </Button>
          <Button className="mb-2 mr-2" variant="outline-secondary" onClick={() => openModal(true)}>
            <FontAwesomeIcon className="mr-1" icon="exchange-alt" size="sm" />
                REVISE
              </Button>
          <Button className="mb-2" variant="outline-secondary" onClick={() => history.push('/applications')}>
          <FontAwesomeIcon className="mr-1" icon="times" size="sm" />
            CLOSE
            </Button>
        </Col>
      </Row>
    </Container>
  )
}
export default ApplicationReview;