import { Status, DateConsts } from './constants';
import { Storage } from './storage';
import  moment  from 'moment';
import { useLocation } from "react-router-dom";
import axios from 'axios'

// export const handleApi = (promise) => {
//   return promise
//     .then(result => [result.data, undefined]) 
//     .catch(error => Promise.resolve([undefined, error])
//     );
// }

export const handleApi = (promise) => {
  return promise
    .then(result => ([result.data, undefined])) 
    .catch(error => {
      if(!axios.isCancel(error)){
        console.log(' error');
      return Promise.resolve([undefined, error]) 
    } else {
      console.log(' cancel');
      return  Promise.resolve([undefined, undefined]) 
    } 
    }
    );

}

export const defaultLimit = 10;

export const HEADERS = {
  NOAUTH: () => {
    return {
        'Accept': 'application/json,text/plain',
        'Content-Type': 'application/json',
    }
  },
  BASIC: () => {
      return {
          'Accept': 'application/json,text/plain',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Storage.getToken()}`,
      }
  },
  FILE: () => {
      return {
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${Storage.getToken()}`,
      }
  }

}

export const convertDateYear = (date) => {
     return date.substr(5,2)+'/'+date.substr(0,4);
}

export const convertFullDate = (date) => {
    let d = new Date(date);
    return d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
}

export const getStatus = (status) => {
    return  Status.getStatus(status).name;
}

export const convertArrayToObject = (arrayData) => {
    let objData = '';
    arrayData.forEach((element, index) => {
      objData += element;
    });
    return JSON.parse(objData);
  } 

 export const capitalizeFirstLetter = (string) => {
     const str = string.toString();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
        
 export const dateFormat = (date , format) => {
    if(date) return moment(date).format(format);
    return '';
}

export const dateOnlyFormat = (date) => {
  if(date) return moment(date).format(DateConsts.DATE_ONLY_FORMAT);
    
  return '';
}

export const dateWithTimeFormat = (date) => {
  if(date) return moment(date).format(DateConsts.DATE_AND_TIME_FORMAT);
    
  return '';
}

export const monthYearFormat = (date) => {
  if(date) return moment(date).format(DateConsts.MONTH_YEAR_FORMAT);
    
  return '';
}
export const validURL= (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}