import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import InputField from './formComponent/InputField';
import TextAreaField from './formComponent/TextAreaField';
import CheckboxField from './formComponent/CheckboxField';
import SelectField from './formComponent/SelectField';
import RadiobuttonField from './formComponent/RadiobuttonField';
import NumberField from './formComponent/NumberField';
import FileField from './formComponent/FileField';
import ButtonForm from './formComponent/ButtonForm';
import MultiSelect from './formComponent/MultiSelect';
import DateField from './formComponent/DateField';
import HtmlField from './formComponent/HtmlField';
import SsnField from './formComponent/SsnField';

const FormBlock = ({ fields, getData, formData, onCreate }) => {

    const [content, setContent] = useState(formData);
    const updateValue = (e) => {
        setContent({
            ...content,
            [e.name]: e.value
        });
        getData(e);
    }

    useEffect(() => {
        setContent(formData);
    }, [formData]);


    // Render all fields data 
    const renderFields = (fields) => {
        return (fields.map((item, i) =>
            <Row key={`element-${i}-row`} >
                {item.length > 0 ? checkLayout(item, i) : renderFieldData(item, i)}
            </Row>
        ));
    }

    const checkLayout = (item) => {
        let columns = [];
        item.map((field, j) => columns.push(renderFieldData(field, j)));
        return columns;
    }

    const renderFieldData = (item, i) => {
        let input;

        const opts = {};
        if (item.readOnly) {
            opts.readOnly = item.readOnly;
        }
        if (item.required) {
            opts.required = item.required;
        }
        if (item.disabled) {
            opts.disabled = item.disabled;
        }

        let value = '';
        if (content && content[item.name]) {
            value = content[item.name];
        }

        const key = item.type + '-' + i;

        switch (item.type) {
            case 'text':
                input = (<InputField key={key} id={item.id ? item.id : item.name} info={item.info} type={item.type} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} label={item.label} col={item.col} className={item.className} permission={item.permission} maxlength={item.maxlength} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} name={item.name} value={value} opts={opts} />);
                break;
            case 'tel':
                input = (<InputField key={key} id={item.id ? item.id : item.name} type={item.type} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} label={item.label} col={item.col} permission={item.permission} minValue={item.minValue} maxValue={item.maxValue} className={item.className} maxlength={item.maxlength} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} name={item.name} value={value} opts={opts} />);
                break;
            case 'email':
                input = (<InputField key={key} id={item.id ? item.id : item.name} type={item.type} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} permission={item.permission} className={item.className} maxlength={item.maxlength} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} name={item.name} value={value} opts={opts} />);
                break;
            case 'url':
                input = (<InputField key={key} id={item.id ? item.id : item.name} type={item.type} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} permission={item.permission} className={item.className} maxlength={item.maxlength} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} name={item.name} value={value} opts={opts} />);
                break;
            case 'password':
                input = (<InputField key={key} id={item.id ? item.id : item.name} type={item.type} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} permission={item.permission} className={item.className} maxlength={item.maxlength} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} name={item.name} value={value} opts={opts} handleShowPassword={item.handleShowPassword} showPassword={item.showPassword}/>);
                break;
            case 'date':
                input = (<DateField key={key} id={item.id ? item.id : item.name} info={item.info} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} className={item.className} permission={item.permission} isClearable={item.isClearable} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} name={item.name} value={value} opts={opts} disabled={item.disabled} />);
                break;
            case 'file':
                input = (<FileField key={key} id={item.id ? item.id : item.name} type={item.type} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} className={item.className} permission={item.permission} fileFormat={item.fileFormat} col={item.col} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} name={item.name} value={value} opts={opts} />);
                break;
            case 'textarea':
                input = (<TextAreaField key={key} id={item.id ? item.id : item.name} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} className={item.className} permission={item.permission} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} rows={item.rows} cols={item.cols} name={item.name} value={value} opts={opts} />);
                break;
            case 'number':
                input = (<NumberField infoText={item.infoText} key={key} id={item.id ? item.id : item.name} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} className={item.className} permission={item.permission} handleEvent={(e) => updateValue(e)} opts={opts} placeholder={item.placeholder} minValue={item.minValue} maxValue={item.maxValue} name={item.name} value={value} />);
                break;
            case 'select':
                input = (<SelectField key={key} id={item.id ? item.id : item.name} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} className={item.className} permission={item.permission} handleEvent={(e) => updateValue(e)} opts={opts} placeholder={item.placeholder} name={item.name} value={value} options={item.options} />);
                break;
            case 'multi-select':
                input = (<MultiSelect key={key} id={item.id ? item.id : item.name} data={item.data} group={item.group} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} className={item.className} permission={item.permission} multi={item.multi} handleEvent={(e) => updateValue(e)} opts={opts} placeholder={item.placeholder} name={item.name} value={value} options={item.options} creatable={item.creatable} onCreate={onCreate} />);
                break;
            case 'checkbox':
                input = (<CheckboxField key={key} id={item.id ? item.id : item.name} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} className={item.className} permission={item.permission} handleEvent={(e) => updateValue(e)} name={item.name} checked={item.checked} value={value} opts={opts} />);
                break;
            case 'radio':
                let checkboxValid = false;
                if (value === item.checked) {
                    checkboxValid = true;
                }
                input = (<RadiobuttonField key={key} id={item.id ? item.id : item.name} label={item.label} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} className={item.className} permission={item.permission} handleEvent={(e) => updateValue(e)} name={item.name} checked={checkboxValid} value={item.value} opts={opts} labels={item.labels} validators={item.validators} />);
                break;
            case 'button':
                input = (<ButtonForm key={key} id={item.id ? item.id : item.name} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} col={item.col} opts={opts} handleEvent={item.handleEvent} permission={item.permission} placeholder={item.placeholder} variant={item.variant} className={item.className} icon={item.icon} />);
                break;
            case 'html':
                input = (<HtmlField key={key} id={item.id ? item.id : item.name} type={item.type} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} label={item.label} col={item.col} className={item.className} permission={item.permission} maxlength={item.maxlength} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} name={item.name} value={value} opts={opts} />);
                break;
            case 'ssn':
                input = (<SsnField key={key} id={item.id ? item.id : item.name} type={item.type} sm={item.sm} md={item.md} lg={item.lg} xs={item.xs} xl={item.xl} label={item.label} col={item.col} className={item.className} permission={item.permission} maxlength={item.maxlength} handleEvent={(e) => updateValue(e)} placeholder={item.placeholder} mask={item.mask} name={item.name} value={value} opts={opts} />);
                break;
            default:
                break;
        }

        return (
            <React.Fragment key={`${item.type}-element-${i}`}>
                {input}
            </React.Fragment>
        )
    }

    return renderFields(fields);
}
export default FormBlock;
