import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DragDropCustom from '../../../shared/DragDropCustom';
import AssetsStats from './AssetsStats';
import * as alertAction from '../../../actions/alert';
import * as loaderAction from '../../../actions/loader';
import * as criteriaService from '../../../services/criteria';
import * as assetService from '../../../services/asset';
import { useDispatch } from 'react-redux';

const AssetsForm2 = (props) => {


  const dispatch = useDispatch();
  const [updateData, setUpdateData] = useState(false);
  const [nextStepEnable, setNextStepEnable] = useState(false);
  const [criteria, setCriteria] = useState([]);

  const onDataChange = (list) => {
    setCriteria(list);
  };

  useEffect(() => {
    if(nextStepEnable){
      props.getNextStep(nextStepEnable);
    }
  },[nextStepEnable , props])

  const modifyData = useCallback((list) => {
    const data = [];
    list.forEach(item => {
      let dummy = { weight: item.weight, criteria: item.id, asset: props.assetId, notes: item.notes ? item.notes : '' }
      data.push(dummy);
    })
    return data;
  }, [props.assetId])

  const modifyDataUpdate = useCallback((list) => {
    const data = [];
    list.forEach(item => {
      let dummy = { asset_criteria_id: item.id, weight: item.weight, criteria: item.criteria, asset: props.assetId, notes: item.notes ? item.notes : '' }
      data.push(dummy);
    })
    return data;
  }, [props.assetId])


  const getCriteriaList = useCallback(() => {
    dispatch(loaderAction.loader(true));
    criteriaService.fetchAll().then(([response, error]) => {
      if (response) {
        setCriteria(response.results);
      } else if (error) {
        dispatch(alertAction.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [dispatch])

  // GET CRITERIA BY ASSET ID 
  const getCriteriaById = useCallback(() => {
    dispatch(loaderAction.loader(true));
    assetService.getCriteriaById(+props.assetId).then(([response, error]) => {
      if (response) {
        if (response.asset_criteria.length > 0) {
          setUpdateData(true);
          setNextStepEnable(true);
          setCriteria(response.asset_criteria);
        } else if (response.asset_criteria.length === 0) {
          getCriteriaList();
        }
      } else if (error) {
        dispatch(alertAction.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [props.assetId, getCriteriaList, dispatch])


  useEffect(() => {
    if (props.assetId !== '' && !isNaN(props.assetId)) {
      getCriteriaById();
    }
  }, [props.assetId, getCriteriaById])


  const createCriteria = useCallback(() => {
    if (props.assetId) {
      dispatch(loaderAction.loader(true));
      const data = modifyData(criteria);
      assetService.createCriteria(data).then(([response, error]) => {
        if (response) {
          setNextStepEnable(true);
          dispatch(alertAction.success('Rate asset save successfully.'));
          getCriteriaById();
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [props, criteria, modifyData, getCriteriaById, dispatch])


  // UPDATE CRITERIA 
  const updateCriteria = useCallback(() => {
    if (props.assetId) {
      dispatch(loaderAction.loader(true));
      const data = modifyDataUpdate(criteria);
      assetService.updateCriteria(data).then(([response, error]) => {
        if (response) {
          dispatch(alertAction.success('Rate Asset update successfully.'));
          getCriteriaById()
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [criteria, props, getCriteriaById, modifyDataUpdate, dispatch])

  return (
    <>
      <Row className="justify-content-md-center py-5 mx-0">
        <Col md={8}><h4 className="mb-4">Rate Assets</h4>
          <strong>Please rank these criteria according to importance in rating this topic and save it.</strong></Col>
        <Col md={4}>
          {updateData ? <Button onClick={() => updateCriteria()} className='float-right mr-2' variant='primary'>Update</Button> :
            <Button onClick={() => createCriteria()} className='float-right mr-2' variant='primary'>Save</Button>}
        </Col>
        <Col md={12}>
          <DragDropCustom data={criteria} onDataChange={onDataChange} />
          {updateData ? <Button onClick={() => updateCriteria()} className='float-right mr-2 align-btn' variant='primary'>Update</Button> :
            <Button onClick={() => createCriteria()} className='float-right mr-2 align-btn' variant='primary'>Save</Button>}
          <AssetsStats {...props} nextStepEnable={nextStepEnable} />
        </Col>
      </Row>
    </>
  )
}
export default AssetsForm2;