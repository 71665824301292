import React , { useCallback, useState} from 'react';
import { Button, Form, Col, Modal } from 'react-bootstrap';
import * as topicRequestService from '../../services/topicRequest';
import { useDispatch } from 'react-redux';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import AsyncSelect from '../../shared/select/AsyncSelect';
import { useBoolean } from "../../hooks/basic";

const TopicChangeParent = ({isVisible , hide, topicIds}) => {

    const dispatch = useDispatch();
    const [parentAsset, setParentAsset] = useState([]) 
  const [validated, setValidated] = useBoolean(false);

    // Create Feedback
    const changeParent = useCallback(() => {
            dispatch(loaderActions.loader(true));
            const data = new FormData();
            data.append('children' , JSON.stringify(topicIds));
            topicRequestService.changeParent(data, parentAsset).then(([response , error]) => {
                if(response){
                    dispatch(alertActions.success('Parent set successfully.')) 
                    hide()                 
                }else if(error){
                    dispatch(alertActions.error(error.response))
                }
                dispatch(loaderActions.loader(false));
            })
    },[dispatch, topicIds, parentAsset, hide])

    const saveData = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity()) {
        changeParent();
      }
    }

    // useEffect(()=> {
    // dispatch(loaderActions.loader(true));
    //    topicRequestService.topicRequestListing().then(([response , error]) => {
    //      if(response){
    //         setOptions(response.results);
    //      }else if(error){
    //        dispatch(alertActions.error(error.response));
    //      }
    //      dispatch(loaderActions.loader(false));
    //    })
    // }, [dispatch,])


  const handleChangeAsset = useCallback((data) => {
    setParentAsset(
    data.value
    )
  }, [])

  // FORM FIELDS
    return (
        <>
          {isVisible ?
            (<Modal show={isVisible} onHide={hide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Form noValidate validated={validated} onSubmit={saveData}>
            
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Link To
              </Modal.Title>
              <Col md={3}>
                
            </Col>
            </Modal.Header>
            <Modal.Body>
            <AsyncSelect  name='Parent' value={(parentAsset)} asyncMethod={(e) => topicRequestService.topicRequestTitle(e)} getData={(e) => handleChangeAsset(e)} />
            </Modal.Body>
            <Modal.Footer>
            <Button variant="light" onClick={hide}>Cancel</Button>
            <Button variant="primary" type="submit" >Submit</Button>
              </Modal.Footer>
              </Form>
            </Modal>)
            : null}
        </>
    )
}
export default TopicChangeParent;