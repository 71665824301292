import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import ModalForm from '../../../shared/ModalForm';
import * as jobServices from '../../../services/job';
import { useDispatch, useSelector } from 'react-redux';
import * as loaderAction from '../../../actions/loader';
import * as alertActions from '../../../actions/alert';

const JobForm = ({ id, isVisible, hide, getAllJobs }) => {

  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.loginData);
  const [comments , setComments] = useState([]);
  const [jobData, setJobData] = useState({
    job_id: '',
    company: '',
    job_title: '',
    experience_to: null,
    experience_from: null,
    is_conflict: false,
    conflict_reason: '',
    job_file: ''
  });

  const prevState = useRef(jobData);

  // GET COMMENTS
 const getComment = useCallback(()=> {
    if(id !== ''){
      jobServices.getJobComment(id).then(([response , error]) => {
        if(response){
          setComments(response.comment);
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
      })
    }
  },[id , dispatch])

  useEffect(() => {
    if (id !== '') {
      dispatch(loaderAction.loader(true));
      jobServices.getJobById(id).then(([response, error]) => {
        if (response) {
          setJobData({
            job_id: response[0].id,
            company: response[0].company,
            job_title: response[0].job_title,
            experience_to: response[0].experience_to,
            experience_from: response[0].experience_from,
            is_conflict: response[0].is_conflict,
            conflict_reason: response[0].conflict_reason,
            job_file: response[1] ? response[1].file_url : ''
          });
          getComment();
        } else if (error) {
          dispatch(alertActions.error(error.response));
          setComments([]);
        }
        dispatch(loaderAction.loader(false));
      })
    } else {
      setJobData(prevState.current);
    }
  }, [id, dispatch , getComment])

  // CREATE JOB
  const createJob = useCallback((data) => {
    if (data) {
      dispatch(loaderAction.loader(true));
      let newData = new FormData();
      newData.append("user_id", sessionData.id);
      newData.append('company', data.company);
      newData.append('job_title', data.job_title);
      newData.append('experience_to', data.experience_to);
      newData.append('experience_from', data.experience_from);
      newData.append('is_conflict', data.is_conflict);
      if(data.is_conflict){
        newData.append('conflict_reason', data.conflict_reason);
      }else {
        newData.append('conflict_reason', '');
      }
      
      if (data.job_file.name) {
        newData.append('job_file', data.job_file, data.job_file.name);
      }
      jobServices.createJob(newData).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Added Successfully.'));
          getAllJobs();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch, sessionData, getAllJobs])

  // UPDATE JOB
  const updateJob = useCallback((data) => {
    if (data) {
      dispatch(loaderAction.loader(true));
      let newData = new FormData();
      newData.append("user_id", sessionData.id);
      newData.append("job_id", data.job_id);
      newData.append('company', data.company);
      newData.append('job_title', data.job_title);
      newData.append('experience_to', data.experience_to);
      newData.append('experience_from', data.experience_from);
      newData.append('is_conflict', data.is_conflict);
      if(data.is_conflict){
        newData.append('conflict_reason', data.conflict_reason);
      }else {
        newData.append('conflict_reason', '');
      }
      if (data.job_file.name && data.job_file.type) {
        newData.append('job_file', data.job_file, data.job_file.name);
        newData.append("file_id", data.file_id);
      }
      jobServices.updateJob(newData).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Update Successfully.'));
          getAllJobs();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch, getAllJobs, sessionData])

  // CLOSE POPUP PANEL
  const closeModal = useCallback((data) => {
    hide();
    if (data.job_id !== '' && data !== false) {
      updateJob(data);
    } else if (data !== false) {
      createJob(data);
    }
  }, [hide, updateJob, createJob]);

  // FORM FIELDS
  const fields = [
    [{ label: 'Company*', name: 'company', type: 'text', required: true, placeholder: 'Company', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Title*', name: 'job_title', type: 'text', required: true, placeholder: 'Title', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Start*', name: 'experience_to', type: 'date', required: true, placeholder: 'MM/yyyy', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'End* ', name: 'experience_from', type: 'date', info:"Leave blank in case of current job", required: false, placeholder: 'MM/yyyy', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Attachment*', required: id !== '' ? false : true, name: 'job_file', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', type: 'file', placeholder: 'Select File', sm: 12, md: 12, lg: 12, xl: 12 },
    { label: 'Conflict', name: 'is_conflict', type: 'checkbox', checked: false, className: 'mt-0', value: false, placeholder: 'Conflict', sm: 12, md: 12, lg: 12, xl: 12 }]
  ];

  return (
    <Container fluid>
      {isVisible ?
        <ModalForm
          show={isVisible}
          onHide={(e) => closeModal(e)}
          data={jobData}
          fields={fields}
          title={id !== '' ? 'Update a Job' : 'Add a Job'}
          comment={comments ? comments : []}
          button={id !== '' ? 'Update' : 'Save'}
        />
        : null}
    </Container>
  )
}

export default JobForm;