import axiosClient from '../helpers/axiosClient';

import { ASSETS } from '../helpers/actionTypes'
import { createAction } from '../actions/actionBase';

const BASE = 'assets/';
export function fetchAssetsList(user , status, limit , offset, ordering="", filter, search='') {
    return createAction({
        type: ASSETS.GET_LIST_ASSETS,
        action: () => axiosClient.get(`${BASE}list/${status !== '' ? '?status='+status : ''}${user !== '' ? '&created_by='+user : ''}&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filter}`)
    });
}
