import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as loaderAction from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import * as userService from '../../services/user';
import user from '../../assets/images/default.jpg';

const BasicInfo = () => {

  // getting User Id from url params
  const { userId } = useParams();

  const [userData, setUserData] = useState({
    id: '',
    first_name: '',
    last_name: '',
    former_first_name: '',
    former_last_name: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
    wallet_address: '',
    social_security: '',
    nickname: '',
    image: ''
  });

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(loaderAction.loader(true));
    userService.fetchUserProfile(userId).then(([response, error]) => {
      if (response) {
        setUserData({
          id: response.user_info.id,
          former_first_name: response.profile ? response.profile.former_first_name : "",
          former_last_name: response.profile ? response.profile.former_last_name : "",
          first_name: response.user_info ? response.user_info.first_name : '',
          last_name: response.user_info ? response.user_info.last_name : '',
          email: response.user_info.email,
          phone: response.user_phone ? response.user_phone.phone : '',
          address1: response.profile ? response.profile.address1 : '',
          address2: response.profile ? response.profile.address1 : '',
          country: response.profile ? response.profile.Country : '',
          state: response.profile ? response.profile.State : '',
          city: response.profile ? response.profile.City : '',
          zipcode: response.profile ? response.profile.zipcode : '',
          wallet_address: response.profile ? response.profile.wallet_address : '',
          social_security: response.profile ? response.profile.social_security : '',
          nickname: response.profile ? response.profile.nickname : '',
          image: response.image ? response.image : ''
        });
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [dispatch, userId])

    useEffect(() => {
      fetchData();
    }, [fetchData])

  return (
    <>
      <Row className="bg-white">

        <Col md={4}>
          <Row className="no-gutters">
            <Col md={12} className="bg-dark">
              <Card className="bg-dark border-0 py-3">
                <Card.Body className="text-center">
                  <span className="user-img m-auto"><Image src={userData.image ? userData.image : user} roundedCircle />
                  </span>
                  <Card.Title className="text-white mt-2">{userData.first_name + ' ' + userData.last_name}</Card.Title>
                  <Card.Text>
                    {/* <Badge className="bg-yellow p-2" pill variant="primary" size="md">{sessionData.group}</Badge> */}
                  </Card.Text>
                  {/* <Card.Text className="text-white">Freelance editor and content writer . I help make your manuscript, essay, and outline shine!Freelance editor and content writer . I help make your manuscript, essay, and outline shine!</Card.Text> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col md={8}>
          <Card className="py-3">
            <Card.Body className="py-1">
              <Row>
                <Col md={6} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={userData.first_name}>
                  <label className="d-block text-left mb-0"><small>First Name</small></label>
                  <h6 className='text-warning'>{userData.first_name}</h6>
                </Col>
                <Col md={6} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={userData.last_name}>
                  <label className="d-block text-left mb-0"><small>Last Name</small></label>
                  <h6 className='text-warning'> {userData.last_name}</h6>
                </Col>
                <Col md={6} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={userData.former_first_name}>
                  <label className="d-block text-left mb-0"><small>Former First Name</small></label>
                  <h6 className='text-warning'>{userData.former_first_name}</h6>
                </Col>
                <Col md={6} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={userData.former_last_name}>
                  <label className="d-block text-left mb-0"><small>Former Last Name</small></label>
                  <h6 className='text-warning'> {userData.former_last_name}</h6>
                </Col>
                <hr className="mt-1 mb-2" />
                <Col md={6} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={userData.email}>
                  <label className="d-block text-left mb-0"><small>Email ID</small></label>
                  <h6 className='text-warning'>{userData.email}</h6>
                </Col>
                <Col md={6} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={userData.phone}>
                  <label className="d-block text-left mb-0"><small>Contact No</small></label>
                  <h6 className='text-warning'> {userData.phone}</h6>
                </Col>
                <hr className="mt-1 mb-2" />
                <Col md={6} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={userData.address1}>
                  <label className="d-block text-left mb-0"><small>Address 1#</small></label>
                  <h6 className='text-warning'>{userData.address1}</h6>
                </Col>
                <Col md={6} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={userData.address2}>
                  <label className="d-block text-left mb-0"><small>Address 2#</small></label>
                  <h6 className='text-warning'> {userData.address2}</h6>
                </Col>
                <hr className="mt-1 mb-2" />
                <Col md={6} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={userData.country}>
                  <label className="d-block text-left mb-0"><small>Country</small></label>
                  <h6 className='text-warning'>{userData.country}</h6>
                </Col>
                <Col md={6} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={userData.state}>
                  <label className="d-block text-left mb-0"><small>State / Province</small></label>
                  <h6 className='text-warning'> {userData.state}</h6>
                </Col>
                <hr className="mt-1 mb-2" />
                <Col md={6} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={userData.city}>
                  <label className="d-block text-left mb-0"><small>City</small></label>
                  <h6 className='text-warning'>{userData.city}</h6>
                </Col>
                <Col md={6} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={userData.zipcode}>
                  <label className="d-block text-left mb-0"><small>Zipcode</small></label>
                  <h6 className='text-warning'> {userData.zipcode}</h6>
                </Col>
                <hr className="mt-1 mb-2" />
                <Col md={6} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={userData.wallet_address}>
                  <label className="d-block text-left mb-0"><small>Wallet Address (#)</small></label>
                  <h6 className='text-warning'>{userData.wallet_address}</h6>
                </Col>
                <Col md={6} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={userData.social_security}>
                  <label className="d-block text-left mb-0"><small>Social Security #</small></label>
                  <h6 className='text-warning'> {userData.social_security}</h6>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </>
  )
}

export default BasicInfo;