import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccordionForm from '../../shared/AccordionForm';
import * as cataloServices from '../../services/catalog';
import * as assetService from '../../services/asset';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from '../../helpers/constants';
import { useParams } from 'react-router-dom';
import { useBoolean } from '../../hooks/basic';
import CommentModal from '../../shared/CommentModal';
import { history } from "../../helpers";
import { HasPermission } from '../../shared/HasPermission';
import ConfirmModal from '../../shared/ConfirmModal';
import ExpertListTable from './ExpertListTable';
import CommentList from '../../shared/CommentList';
import TooltipHover from '../../shared/TooltipHover';

const RatedFormTable = () => {

  const dispatch = useDispatch();
  const { id } = useParams();
  const [area, setArea] = useState([]);
  const [branch, setBranch] = useState([]);
  const loginData = useSelector((state) => state.auth.loginData);
  const [majorCatalog, setMajorCatalog] = useState([]);
  const [minorCatalog, setMinorCatalog] = useState([]);
  const [category, setCategory] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [comments, setComments] = useState([]);
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [modalText, setModalText] = useState('');
  const [assetList, setAssetList] = useState([]);
  const [identity, setIdentity] = useState('');
  const [publish, setPublish] = useState(false);
  const [hideButtons , setHideButtons] = useState(false);

  const [formData, setFormData] = useState({
    specific_topic: '',
    url: '',
    notes: '',
    created_by: '',
    branch_opinion: '',
    opinion_percent: '',
    status: '',
    area: '',
    major: '',
    minor: '',
    category: '',
    sub_cat: '',
    topic: '',
    sub_topic: ''
  });

  const [comment, setComment] = useState({ notes: '' })

  // GET AREA & BRANCH
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    assetService.getArea().then(([response, error]) => {
      if (response) setArea(response)
      if (error) dispatch(alertActions.error(error.response));
    })
    assetService.getBranch().then(([response, error]) => {
      if (response) setBranch(response)
      if (error) dispatch(alertActions.error(error.response));
    })
    dispatch(loaderAction.loader(false));

    return () => {
      setArea([]);
      setBranch([]);
    }
  }, [dispatch])

  // GET MAJOR CATALOG 
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    cataloServices.getMajorCatalog().then(([response, error]) => {
      if (response) {
        setMajorCatalog(JSON.parse(response));
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });

    return () => {
      setMajorCatalog([]);
    }
  }, [dispatch])

  // GET MINOR CATALOG BY MAJOR CATALOG ID  
  useEffect(() => {
    if (formData.major !== '' && formData.major !== undefined) {
      dispatch(loaderAction.loader(true));
      cataloServices.getMinorCatalog(formData.major).then(([response, error]) => {
        if (response) {
          setMinorCatalog(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
    return () => {
      setMinorCatalog([]);
    }
  }, [formData.major, dispatch]);

  // GET CATEGORY CATALOG BY MINOR CATALOG ID
  useEffect(() => {
    if (formData.minor !== '' && formData.minor !== undefined) {
      dispatch(loaderAction.loader(true));
      cataloServices.getCategoryCatalog(formData.minor).then(([response, error]) => {
        if (response) {
          setCategory(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
    return () => {
      setCategory([]);
    }
  }, [formData.minor, dispatch]);

  const setAssetData = useCallback((response) => {
    setFormData({
      id: response.asset.id,
      specific_topic: response.asset.specific_topic,
      url: response.asset.url,
      notes: response.asset.notes,
      created_by: response.asset.created_by,
      branch_opinion: response.asset.branch_opinion,
      opinion_percent: response.asset.opinion_percent,
      status: response.asset.status,
      area: response.asset.area,
      major: response.specialization[2].catalog_id,
      minor: response.specialization[1].catalog_id,
      category: response.specialization[0].catalog_id,
      sub_cat: response.asset.sub_cat,
      topic: response.asset.topic,
      sub_topic: response.asset.sub_topic
    });
    setComment({
      notes: response.asset.notes
    })
  }, [])


  // GET ASSET BY ID
  const getAssetById = useCallback(() => {
    dispatch(loaderAction.loader(true));
    assetService.getAssetById(id).then(([response, error]) => {
      if (response && response !== {}) {
        if(response && response.asset.status === 1){
          history.push('/assets');
        }else if (response && response.asset) {
          setAssetData(response);
          if(response.asset.status === 5) setHideButtons(true);
        } else {
          history.push('/assets');
        }
        dispatch(loaderAction.loader(false));
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [id, dispatch, setAssetData])

  // GET ASSET DATA BY ID 
  useEffect(() => {
    if (id !== '') {
      getAssetById();
    }

  }, [id, getAssetById])

  const Form1 = [
    [{ label: 'Specific Topic', type: 'text', readOnly: true, name: 'specific_topic', sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'https://', type: 'url', readOnly: true, name: 'url', sm: 12, md: 6, lg: 6, xl: 6, }],
  ];

  const Form2 = [
    [{ label: 'Branch Opinion', type: 'multi-select', disabled: true, name: 'branch_opinion', placeholder: 'Select Branch', options: branch, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Opinion Percent ', readOnly: true, type: 'number', disabled: true, name: 'opinion_percent', minValue: 0, maxValue: 100, placeholder: 'Select Opinion Percent', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Major Classifications', type: 'multi-select', disabled: true, name: 'major', placeholder: 'Major Classifications', options: majorCatalog, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Minor Classifications', type: 'multi-select', disabled: true, name: 'minor', placeholder: 'Minor Classifications', options: minorCatalog, sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Category', type: 'multi-select', disabled: true, name: 'category', placeholder: 'Category ', options: category, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Sub-Category ', type: 'text', readOnly: true, name: 'sub_cat', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Topic ', type: 'text', readOnly: true, name: 'topic', sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Sub-Topic ', type: 'text', readOnly: true, name: 'sub_topic', placeholder: 'Sub-Topic', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Geographical Area ', type: 'multi-select', disabled: true, name: 'area', placeholder: 'Select Area', options: area, sm: 12, md: 6, lg: 6, xl: 6, },
    { placeholder: '', id: 'Save Classification', type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 6, lg: 6, xl: 6 }]
  ];

  const Form4 = [
    {
      disabled: true, type: 'textarea', name: 'notes', placeholder: 'Permanent Comments / Notes',
    }
  ]

  const checkexpertStatus = useCallback((data) => {
    const list = data.filter((obj) => obj.status === Status.APPROVED.value)
    if (list && list.length >= 2) setPublish(true);
  }, [])

  // GET Expert Table list 
  useEffect(() => {
    if (id !== '') {
      dispatch(loaderAction.loader(true));
      assetService.getAssetExpertList(id).then(([response, error]) => {
        if (response) {
          setAssetList(response.asset_expert_list);
          checkexpertStatus(response.asset_expert_list);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
    return () => {
      setAssetList([]);
    }
  }, [id, dispatch, checkexpertStatus])


  const commentsCount  = comments && comments.length > 0 ? comments.length : 0;
  // ACCORDION JSON DATA
  const data = [
    { name: 'Specific Topic Name', open: true, type: 'form', label: formData.specific_topic, fields: Form1, func: (e) => null, formData: formData },
    { name: 'Category', open: true, type: 'form', fields: Form2, func: (e) => null, formData: formData },
    { name: 'Assigned Experts List', open: true, type: 'component', permission: 'qrata.asset_expert_tableview', component: <ExpertListTable assetList={assetList} /> },
    { name: 'Permanent Comments / Notes', open: true, type: 'form', fields: Form4, func: (e) => null, formData: comment },
    { name: `Comments (${commentsCount})`, open: false, type: 'component', component: <CommentList comments={comments ? comments : []} /> }
  ]

  const getComment = useCallback(() => {
    if (id !== '') {
      assetService.getComment(id).then(([response, error]) => {
        if (response) {
          setComments(response.bio_comment);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id !== '') {
      getComment();
    }
    return () => {
      setComments([]);
    }
  }, [id, getComment])

  const openModal = useCallback(() => {
    showModal();
  }, [showModal]);


  const publishAsset = useCallback(() => {
    if (id !== '') {
      assetService.publishAsset(id).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Asset Published Succesfully.'));
          setIdentity('');
          history.push('/assets')
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }, [id, dispatch])

  // CLOSE COMMENT MODAL
  const closeModal = useCallback((data) => {
    hideModal();
    if (data.comment) {
      if (id !== '') {
        const newData = new FormData();
        newData.append('asset_id', id);
        newData.append('ask_by', loginData.id);
        newData.append('comment', data.comment);
        if (data.attachment) {
          newData.append('attachment', data.attachment)
        };
        assetService.createComment(newData).then(([response, error]) => {
          if (response) {
            getComment();

          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
        })
      } else {
        dispatch(alertActions.error('Please save the asset first.'));
      }
    }
  }, [hideModal, id, dispatch, loginData, getComment]);

  const closeConfirmModal = useCallback(() => {
    setModalText('');
    setIdentity('');
    hideConfirm();
  }, [hideConfirm])

  const handleConform = useCallback(() => {
    hideConfirm();
    if (identity === 'publish') publishAsset();

  }, [hideConfirm, publishAsset, identity])

  const openConfirmModalPublish = useCallback(() => {
    setIdentity('publish')
    setModalText('Do you want to publish this asset ?')
    showConfirm();
  }, [showConfirm])


  return (
    <Container fluid>
      <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={handleConform} title={modalText} />
      <CommentModal title='Comments' show={isVisible} onHide={(e) => closeModal(e)} />
      <Row>
        <Col md={{ span: 10, offset: 1 }} className="p-0">
          <h4 className="py-3 ">Asset Details</h4>
          <AccordionForm data={data} />
          <Row className="mt-3">
            {hideButtons  ?
            <div className='w-100'>
            <Button className="mb-2 float-right" variant="outline-secondary" onClick={() => history.goBack()}>
            <FontAwesomeIcon className="mr-1" icon="times" size="sm" />
                  CLOSE
              </Button></div>:
              
  
       
      

            <Col md={12} className="text-md-right text-center ">
            <Row>
            <Col md={6} className="text-left">
            <TooltipHover type='button' btnSize='md' btnClass="mr-2 mb-2 " variant='info' iconClass="ml-1" btnIcon={'comment'} label='Comment' message='This is for internal Team Qrata questions because things are unclear, for suggestions an upgrades, problems but is NOT a live chat and cannot be seen by Members using the Database from the front end UI' handleEvent={() => openModal()} placement='top'/> 
            </Col>
            <Col md={6} className="text-right">
            <HasPermission permission='qrata.asset_publish'>
                {publish ? <Button className="mr-2 mb-2" variant="primary" onClick={() => openConfirmModalPublish()}>
                  <FontAwesomeIcon className="text-white mr-1" icon="save" size="sm" />
                        Publish Asset
                    </Button> : ''}
                <Button className="mb-2" variant="outline-secondary" onClick={() => history.goBack()}>
                  <FontAwesomeIcon className="mr-1" icon="times" size="sm" />
                        CLOSE
                    </Button>
              </HasPermission>
              </Col>            
              </Row>
              {/* <Button className="mr-2 mb-2 float-left" variant="info" onClick={() => openModal()}>
                <FontAwesomeIcon className="text-white mr-1" icon="comment" size="sm" />
                    Comment
                </Button> */}
              

             
            </Col>}
          </Row>
        </Col>
      </Row>

    </Container>
  )
}
export default RatedFormTable;