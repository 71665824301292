import React, { useState, useCallback, useRef, useEffect } from "react";
import { Container } from 'react-bootstrap';
import ModalForm from "../../shared/ModalForm";
import * as userService from '../../services/user';
import { useDispatch } from "react-redux";
import * as alertActions from "../../actions/alert";
import * as loaderAction from '../../actions/loader';

const TrustscoreForm = ({ isVisible, userData, hide, refreshList }) => {


  const dispatch = useDispatch();
  const [trustscore, setTrustscore] = useState('');
  const [expertData, setExpertData] = useState({
    id: '',
    get_full_name: '',
    username: '',
    email: '',
    trustscore: 100
  });
  const prevState = useRef(expertData);

  useEffect(() => {
    if (userData && userData.id) {
      setExpertData({ ...userData });
      setTrustscore(expertData.trustscore)
    } else {
      setExpertData(prevState.current);
    }
  }, [userData, trustscore, expertData.trustscore])

  const addTrustScore = useCallback((trustScore , id) => {
    hide();

    if(trustScore !== '' && trustScore !== undefined){
      dispatch(loaderAction.loader(true));
      const data = {"user": id, "trustscore": trustScore };
      userService.userTrustScore(data).then(([response , error]) => {
        if(response){
          refreshList();
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  },[dispatch , hide ,  refreshList])

  const closeModal = useCallback((formData) => {
      hide();
      if (formData && formData.id) {
        userService.updateUser(formData.id, formData).then(([response , error]) => {
          if(response){
            addTrustScore(formData.trustscore , response.id);
            if(formData.trustscore === '' && formData.trustscore === undefined) refreshList();
            dispatch(alertActions.success('User updated successfully.'));
          }else if(error){
            dispatch(alertActions.error(error.response));
          }
        });
      } else if(formData){
        userService.inviteExpert(formData).then(([response, error]) => {
          if (response) {
            addTrustScore(formData.trustscore , response.id);
            if(formData.trustscore === '' && formData.trustscore === undefined) refreshList();
            dispatch(alertActions.success('Invitation Email Sent to User'));
            setExpertData(prevState.current);
            // refreshList();
            // hide();
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
        });
      }
    
  }, [hide, refreshList, dispatch , addTrustScore]);

  const fileds = [
    [{ label: 'Full Name', name: 'get_full_name', type: 'text', disabled: true, required: true, placeholder: 'Full Name', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Username', name: 'username', type: 'text', disabled: true, required: true, placeholder: 'Username', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Email Address', name: 'email', type: 'email', disabled: true, required: true, placeholder: 'Email Address', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Trust Score*', permission: 'qrata.trustscore_access' , type: 'number', name: 'trustscore', minValue: 50, maxValue: 500, placeholder: 'Trust Score', sm: 12, md: 6, lg: 6, xl: 6, }]
  ]

  return (
    <Container fluid>

      {isVisible ?
        <ModalForm
          show={isVisible}
          onHide={(e) => closeModal(e)}
          data={expertData}
          fields={fileds}
          title="Edit Trust Score"
          button={userData ? 'Save' : 'Send Invite'}
        />
        : null}
    </Container>
  );
}

export default TrustscoreForm;