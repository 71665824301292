import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Container, Form, Row, Col, Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { dateWithTimeFormat, Storage, QueriesStatus } from '../../helpers';
import * as feedbackService from '../../services/feedback';
import ModalForm from '../../shared/ModalForm';
import { useBoolean } from '../../hooks/basic';
import FileField from '../../shared/formComponent/FileField'

const FeedbackForm = ({ data, isVisible, title, hide }) => {

  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [editData, setEditData] = useState([]);
  const [commentList , setCommentList] = useState([]);
  const [isModalVisible, showModal, hideModal] = useBoolean(false);

  const [formData, setFormData] = useState({
    comment: '',
    attachment: undefined
  });
  const prevState = useRef(formData);
  
  const emaildata = {email_id: ''}

  const closeModal = useCallback(() => {
    setEditData({});
    hide();
  }, [hide]);

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

  const getComments = useCallback((id) => {
      if(id){
          dispatch(loaderActions.loader(true));
          feedbackService.getFeedbackComment(id).then(([response , error]) => {
              if(response){
                  setCommentList(response.comment)
              }else if(error){
                  dispatch(alertActions.error(error.response));
              }
              dispatch(loaderActions.loader(false));
          })
      }
  },[dispatch])

  useEffect (() => {
    if(data){
        setEditData(data);
        getComments(data.id);
    }
  },[data , getComments])
  
  const createComment = useCallback(() => {
      if(formData.comment){
          const form = new FormData();
          form.append('ask_by', Storage.getSession().id);
          form.append('comment', formData.comment);
          form.append('feedback_id', editData.id);
          form.append('reply', 1);
          if (formData.attachment) {
            form.append('attachment', formData.attachment)
          };
          dispatch(loaderActions.loader(true));
        feedbackService.CreateFeedbackComment(form).then(([response , error]) => {
            if(response){
                setFormData(prevState.current);
                setValidated(false);
                getComments(editData.id);
                dispatch(alertActions.success('Comment Created Successfully.'));
            }else if(error){
                dispatch(alertActions.error(error.response));
            }
            dispatch(loaderActions.loader(false));
        })
      }
  },[dispatch , editData.id , formData.attachment , formData.comment , getComments])

  const updateStatus = (status) => {
    dispatch(loaderActions.loader(true));
    let statusData = {"status":status}
    feedbackService.updateFeedback(editData.id, statusData).then(([response , error]) => {
      if(response){
          dispatch(alertActions.success('Feedback Ticket Status Updated Successfully.'));
          setEditData({
            ...editData,
            status: status,
          })
      }else if(error){
          dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
  })
  }

  const saveData = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
        createComment();
    }
  }, [createComment])

  const saveModal = useCallback((dataMail) => {
    hideModal();
    
    if (dataMail.email_id && dataMail.email_id !== "") {
      dispatch(loaderActions.loader(true));
      feedbackService.forwardFeedback(editData.id, dataMail).then(([response , error]) => {
        if(response){
            dispatch(alertActions.success(`Feedback Forwarded to ${dataMail.email_id}.`));
        }else if(error){
            dispatch(alertActions.error(error.response));
        }
        dispatch(loaderActions.loader(false));
    })
    }
    
  }, [hideModal, dispatch, editData.id]);

  const openlink = (link) => {
    window.open(link, '_blank');
  }

  const newField = [
    [{ label: 'Email Address', name: 'email_id', type: 'email', required: true, placeholder: 'Email Addresss', sm: 12, md: 12, lg: 12, xl: 12 },] ];
  return (
    <Container fluid>
      <ModalForm
          show={isModalVisible}
          onHide={(e) => saveModal(e)}
          data={emaildata}
          fields={newField}
          title={"Foward Feedback"}
          modalClass={"w-md-75"}
          button={"Send"}
        />
      {isVisible ?
        <Modal show={isVisible} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Form noValidate validated={validated} onSubmit={saveData}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card className="border-0" >
                <Card.Body className='pb-0'>
                  <Row>
                    <Col md={8} className="mb-3">
                      <Card.Subtitle className="mb-2 ">
                        <span><small className="text-muted">Title </small></span>
                        <h4>{editData.title}</h4>
                      </Card.Subtitle>
                    </Col>
                    <Col md={2} className="mb-3">
                      <Card.Subtitle className="mb-2 ">
                        <Button variant="primary" className="w-100 rounded-0" onClick={showModal} >Forward</Button>
                      </Card.Subtitle>
                    </Col>
                    <Col md={2} className="mb-3">
                      <Card.Subtitle className="mb-2 ">
                      <Form.Control as="select" size={'md'} value={editData.status} onChange={(e) => updateStatus(e.target.value)} >
                            {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
                            )}
                        </Form.Control>
                      </Card.Subtitle>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Card.Text className="mb-2 ">
                        <span><small className="text-muted">Created By</small></span>
                        <span className='d-block'>{editData.full_name}</span>
                      </Card.Text>
                    </Col>

                    <Col md={4} className="mb-3">
                      <Card.Text className="mb-2 ">
                        <span><small className="text-muted">Attachment</small></span>
                        <span className='d-block ml-4'>{editData.attachments ? <> <a href={editData.attachments[0]} target="_blank" without rel="noopener noreferrer"><FontAwesomeIcon icon='download' /></a></> : ''} </span>
                      </Card.Text>
                    </Col>

                    <Col md={4} className="mb-3">
                      <Card.Text className="mb-2 ">
                        <span><small className="text-muted">Created On</small></span>
                        <span className='d-block'>{dateWithTimeFormat(editData.created_on)} </span>
                      </Card.Text>
                    </Col>
                    
                      <Col md={12}>
                        <Card.Text className="mb-2">
                          <span><small className="text-muted" >Description</small></span>
                          <span className='d-block'>{editData.description}</span>
                        </Card.Text>
                      </Col>
                  </Row>

                </Card.Body>
              </Card>
              <Card className="border-0" style={{ maxHeight: 'calc(60vh - 210px)', overflow: 'auto' }} >
                <Card.Body className='pt-0'>
                  <Row>
                    {commentList.length > 0 ? commentList.map((item, index) => (
                      <Col md={12} key={'comment'+index} className={editData.length - 1 === index ? 'py-2 position-relative ' : 'py-2 position-relative border border-top-0 border-right-0 border-left-0 border-bottom-primary'}>
                        <span className="guest-user font-weight-bold"><small className="font-weight-bold">{item.first_name + ' ' +item.last_name}</small></span>
                        <p className="mb-0" key={'reply' + index}>
                          {item.attachments.length > 0 ? 
                              <FontAwesomeIcon className='cursor-pointer btn-link' onClick={() => openlink(item.attachments[0].file_url)} icon='download' /> : ""
                              } 
                            {" " + item.comment}
                          <span className="position-absolute date-time text-light" ><small>{dateWithTimeFormat(item.created_on)}</small></span></p>
                      </Col>
                    )) : ''}
                  </Row>
                </Card.Body>
              </Card>

            </Modal.Body>
            <Modal.Footer>
              <Row className="w-100 no-gutters">
                <Col md={10} xs={10}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control type='textarea'  className="rounded-0" required placeholder='Reply type here...' name='comment' value={formData.comment} onChange={(e) => handleChange(e.target)} rows={1} />
                    <span className="attachment-icon text-right">
                  <FileField  key="attachment" value={formData.attachment} id="attachment" icon="paperclip" className="p-0" type="file"   fileFormat=".doc,.docx,.pdf,.jpg,.png,.jpeg" handleEvent={(e) => handleChange(e)} placeholder={""} name='attachment' />
                </span>
                  </Form.Group>
              
                </Col>
              
                <Col md={2} xs={3}>
                  <Button variant="primary" className="w-100 rounded-0" type="submit" ><FontAwesomeIcon size="lg" className="icon-green mr-2" icon="paper-plane" />Send</Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
        : null}
    </Container>
  )
}
export default FeedbackForm;