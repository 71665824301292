import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'donotRankAsset/';

export async function getGreyListAssets(id){
    return await handleApi(axiosClient.get(`${BASE}?donotrank__id=${id}`))
}
export async function getGreyListAssetsByType(id, status, limit , offset,  ordering="", search='', filters){
    status = status||2
    limit = limit || 10
    return await handleApi(axiosClient.get(`${BASE}?donotrank__id=${id}&status=${status}&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filters}`));
}

export async function updateGreyListStatus(id){
    return await handleApi(axiosClient.get(`${BASE}${id}/update_grey_status/`))
}

export async function rejectGreyListStatus(id){
    return await handleApi(axiosClient.get(`${BASE}${id}/reject_grey_status/`))
}

export async function acceptAllGreyPendingList(id){
    return await handleApi(axiosClient.get(`${BASE}${id}/accept_all_grey_pending_list/`))
}

