import React, { useState, useCallback, useEffect, createRef } from 'react';
import { debounce } from 'lodash';
import { Col, Form, Row } from 'react-bootstrap';
import FormBlock from '../../../shared/FormBlock';
import AssetsStats from './AssetsStats';
import * as assetService from '../../../services/asset';
import * as cataloServices from '../../../services/catalog';
import * as alertActions from '../../../actions/alert';
import * as loaderAction from '../../../actions/loader';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../helpers';
import axiosClient from '../../../helpers/axiosClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AssetsForm1 = (props) => {

  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.loginData);
  const [area, setArea] = useState([]);
  const [branch, setBranch] = useState([]);
  const [majorCatalog, setMajorCatalog] = useState([]);
  const [minorCatalog, setMinorCatalog] = useState([]);
  const [category, setCategory] = useState([]);
  const [validated, setValidated] = useState(false);
  const [disable, setDisable] = useState(true);
  const [assetId, setAssetId] = useState('');
  const [urlCheck, setUrlCheck] = useState(false)
  const aseetForm = createRef();
  const [formData, setFormData] = useState({
    specific_topic: '',
    url: '',
    notes: '',
    created_by: '',
    branch_opinion: 1,
    opinion_percent: 0,
    status: 1,
    area: '',
    major: '',
    minor: '',
    category: '',
    sub_cat: '',
    topic: '',
    sub_topic: ''
  });

  useEffect(() => {
    if (assetId !== '' && assetId !== undefined) {
      props.getId(assetId);
    }
  }, [assetId, props])

  // GET ASSET BY ID 
  const getAssetById = useCallback(() => {
    dispatch(loaderAction.loader(true));
    assetService.getAssetById(props.assetId).then(([response, error]) => {
      if (response) {
        if (response.asset.status === 1) {
          setAssetId(response.asset.id);
          props.getNotes(response.asset.notes);
          setFormData({
            id: response.asset.id,
            specific_topic: response.asset.specific_topic,
            url: response.asset.url,
            notes: response.asset.notes,
            created_by: response.asset.created_by,
            branch_opinion: response.asset.branch_opinion,
            opinion_percent: response.asset.opinion_percent,
            status: response.asset.status,
            area: response.asset.area,
            major: response.specialization[2].catalog_id,
            minor: response.specialization[1].catalog_id,
            category: response.specialization[0].catalog_id,
            sub_cat: response.asset.sub_cat,
            topic: response.asset.topic,
            sub_topic: response.asset.sub_topic
          })
        } else if (response.asset.status === 3) {
          history.push('/assets');
          dispatch(alertActions.info('Your asset is already approved.'));
        } else if (response.asset.status === 2) {
          history.push('/assets');
          dispatch(alertActions.info('Your asset in under reiew.'));
        }
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch, props])

  const getUrlData = useCallback(() => {
    assetService.getAssetById(props.assetId).then(([response,error]) => {
      if (response) {
        if (response.asset.status === 1){
          urlValidator(response.asset.url,1)
        }
      }
    })
  },[props.assetId])

  useEffect(() => {
    if (props.assetId){
      getUrlData()
    }
  },[getUrlData])

  // GET ASSET DATA BY ID 
  useEffect(() => {
    if (props.assetId) {
      getAssetById();
    }
  }, [props.assetId, getAssetById])


  // GET AREA & BRANCH
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    assetService.getArea().then(([response, error]) => {
      if (response) setArea(response)
      if (error) dispatch(alertActions.error(error.response));
    })
    assetService.getBranch().then(([response, error]) => {
      if (response) setBranch(response)
      if (error) dispatch(alertActions.error(error.response));
    })
    dispatch(loaderAction.loader(false));
  }, [dispatch])

  // GET MAJOR CATALOG 
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    cataloServices.getMajorCatalog(true).then(([response, error]) => {
      if (response) {
        setMajorCatalog(JSON.parse(response));
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });

    return () => {
      setMajorCatalog([]);
    }
  }, [dispatch])

  // GET MINOR CATALOG BY MAJOR CATALOG ID  
  useEffect(() => {
    if (formData.major !== '' && formData.major !== undefined) {
      dispatch(loaderAction.loader(true));
      cataloServices.getMinorCatalog(formData.major, true).then(([response, error]) => {
        if (response) {
          setMinorCatalog(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
    return () => {
      setMinorCatalog([]);
    }
  }, [formData.major, dispatch]);

  // GET CATEGORY CATALOG BY MINOR CATALOG ID
  useEffect(() => {
    if (formData.minor !== '' && formData.minor !== undefined) {
      dispatch(loaderAction.loader(true));
      cataloServices.getCategoryCatalog(formData.minor, true).then(([response, error]) => {
        if (response) {
          setCategory(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
  }, [formData.minor, dispatch]);

  const renderInfo = () => {         
    return( <Row>
        <Col md={12} className='text-center'>
          <h6 class="mb-3"> Amount Of  Opinion Scale</h6>
       </Col>
         <Col md={12} className='px-3 text-center m-auto'>
        <div class="scaler-outer-div ">
        <div class="scale-bar-wrapper">
           <span class="fact-scale"> Fact</span>
           <span class="fact-value">0% -5%</span>
           <span class="truth-scale">TRUTH</span>
           <span class="truth-value">6%-50%</span>
            <span class="belief-scale">BELIEF</span>
           <span class="belief-value">51%-90%</span>
           <span class="non-fact-lie-scale">Non-Fact LIE</span>
           <span class="non-fact-lie-value">91%-100%</span>
           </div>
       </div>
       </Col>
    </Row>)
  }

  // ASSET FORM JSON
  const form1 = [
    [{ label: 'Specific Topic*', type: 'text', required: true, placeholder: 'Specific Topic',   name: 'specific_topic', sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'https:// *', type: 'url', placeholder: 'https://', name: 'url', required: true,  sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Branch Opinion*', type: 'multi-select', name: 'branch_opinion', placeholder: 'Select Branch', options: branch, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Opinion Percent ',infoText:renderInfo() , type: 'number', placeholder: 'Opinion Percent', disabled: disable, name: 'opinion_percent', value: 0 , minValue: 0, maxValue: 100, sm: 12, md: 6, lg: 6, xl: 6,}],
    [{ label: 'Major Classifications*', type: 'multi-select', name: 'major', placeholder: 'Select Major Classifications', options: majorCatalog, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Minor Classifications*', type: 'multi-select', name: 'minor', placeholder: 'Select Minor Classifications', options: minorCatalog, sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Category*', type: 'multi-select', name: 'category', placeholder: 'Select Category', options: category, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Sub-Category ', type: 'text',  placeholder: 'Sub-Category', name: 'sub_cat', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Topic ', type: 'text', placeholder: 'Topic', name: 'topic', sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Sub-Topic ', type: 'text', placeholder: 'Sub-Topic', name: 'sub_topic', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Geographical Area* ', type: 'multi-select', name: 'area', placeholder: 'Select Area', options: area, sm: 12, md: 6, lg: 6, xl: 6, }]
  ];

  // FORMDATA SETDATA
  const setData = useCallback((formData) => {
    return {
      specific_topic: formData.specific_topic,
      url: formData.url,
      notes: formData.notes ? formData.notes : null,
      created_by: loginData.id,
      status: 1,
      branch_opinion: formData.branch_opinion,
      opinion_percent: formData.opinion_percent ? formData.opinion_percent : 0,
      area: formData.area,
      category: formData.category ? formData.category : formData.minor,
      sub_cat: formData.sub_cat ? formData.sub_cat : null,
      topic: formData.topic ? formData.topic : null,
      sub_topic: formData.sub_topic ? formData.sub_topic : null
    }
  }, [loginData])


  // Create Asset 
  const createAsset = useCallback(() => {
    if (formData.specific_topic && formData.url) {
      dispatch(loaderAction.loader(true));
      const data = setData(formData);
      assetService.createAssets(data).then(([response, error]) => {
        if (response) {
          setAssetId(response.id);
          dispatch(alertActions.success('Your asset created successfully.'));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    } else {
      dispatch(alertActions.error('Please fill all fields.'));
    }
  }, [formData, dispatch, setData])


  // Update Assets
  const updateAsset = useCallback(() => {
    if (props.assetId) {
      dispatch(loaderAction.loader(true));
      const data = setData(formData);
      assetService.updateAsset(assetId, data).then(([response, error]) => {
        if (response) {
          // console.log(response)
          dispatch(alertActions.success('Asset update successfully.'));
          getAssetById();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }

  }, [props.assetId, assetId, getAssetById, formData, setData, dispatch])


  useEffect(() => {
    if (formData.branch_opinion === 1) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formData.branch_opinion, form1, createAsset])

  const [cancelCheck, setCancelCheck] = useState(null); 

  const urlValidator = useCallback(debounce((url,status) => {
    const regex = new RegExp('(http(s)?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    // console.log(cancelCheck,'cancel')
    if (cancelCheck) {
      // console.log(cancelCheck,'insidecancel')
      cancelCheck.cancel('Overide API')
    }
    
    if (regex.test(url)) {
      const data = {'url': url}
      const cancelToken =axiosClient.CancelToken.source();
      setCancelCheck(cancelToken);
      // console.log(cancelToken,' -------');
      assetService.urlVerify(data,cancelToken).then(([response, error]) => {
        if (response) {
          // setUrlCheck(true)
          // console.log(response)
          // dispatch(alertActions.success('Url Verified Successfully.'));
          // setCancelCheck(null)
          if (response.success!==true){
            props.setIsGrey(1)
            if (status !== 1){
              dispatch(alertActions.warning(response.success))
            }
          }
          setUrlCheck(true)
        } else if (error.response.data.error === 'Url not found' || error.response.data.error === 'Cannot use None as a query value'){
          console.log(error.response);
          setUrlCheck(true)
          dispatch(alertActions.warning("As the URL didn't found please add asset at your own risk"))
          // if(!error.Cancel){
            //   console.log(error)
            //   dispatch(alertActions.error('Url not found'));
            // }
            // setCancelCheck(null)
          } else if (error.response.data.error) {
            setUrlCheck(false)
            dispatch(alertActions.info(error.response.data.error));
          
        }
      } )
    } else {
      dispatch(alertActions.error('Please enter valid url format'))
      return false
    } 

  },2000),[dispatch, cancelCheck]);

  const handleChange = useCallback((e) => {
    if(e.name==='major'){
      setFormData({
        ...formData,
        [e.name]: e.value,
        minor: '',
        category: ''
      });
    }
    else if(e.name==='minor'){
      setFormData({
        ...formData,
        minor: e.value,
        category: ''
      })
    }
    else if(e.name === 'url'){
      urlValidator(e.value)
      setFormData({
              ...formData,
                url: e.value,
            })
    }
    else{
      setFormData({
        ...formData,
        [e.name]: e.value,
      })
    }
    
  }, [formData,urlValidator]);

  


  // FORM VALIDATION FOR ASSET
  const formValidation = useCallback(() => {
    if (formData.specific_topic === '') return dispatch(alertActions.error('Please enter the specific topic'));
    if (formData.url === '') return dispatch(alertActions.error('Please enter the url.'));
    if (formData.major === '') return dispatch(alertActions.error('Please select the major classification.'));
    if (formData.minor === '') return dispatch(alertActions.error('Please select the minor classification.'));
    if (formData.category === '') return dispatch(alertActions.error('Please select the category classification.'));
    if (formData.area === '') return dispatch(alertActions.error('Please select the area.'));
    return true;
  }, [formData.major, formData.specific_topic, formData.url, formData.minor, formData.category, formData.area, dispatch])

  // CHECK VALIDTY ASSET
  const checkValidity = useCallback((e) => {

    if (aseetForm.current.reportValidity() === false && formValidation()) {
      dispatch(alertActions.error('Please fill required fields.'))
    // } else if (!urlCheck){
    //   dispatch(alertActions.error('Please enter Valid Url'))
    } 
    else if (aseetForm.current.reportValidity() && formValidation()) {
      setValidated(true);
      if (assetId !== '') {
        updateAsset();
      } else if (assetId === '' && loginData.permissions.includes('qrata.add_asset')) {
        createAsset();
      }
    }
  }
  , [aseetForm, assetId, updateAsset, createAsset, dispatch, formValidation, loginData.permissions,urlCheck])


  return (
    <>
      <Row className="justify-content-md-center py-5 mx-0">
        <Col md={12}>
          <h4 className="mb-4">Classification</h4>
          <Form ref={aseetForm} noValidate validated={validated}>
            <FormBlock fields={form1} size={12} getData={(e) => handleChange(e)} formData={formData} />
          </Form>
          <AssetsStats {...props} assetId={assetId} checkValidity={(e) => checkValidity(e)} />
        </Col>
      </Row>
    </>
  )
}
export default AssetsForm1;
