import axios from 'axios';
import { Storage } from '.';


// const baseURL = process.env.NODE_ENV === 'production' ? 'http://ec2-52-15-52-24.us-east-2.compute.amazonaws.com/api/v1/' :'http://localhost:8000/api/v1/';
// const baseURL = process.env.NODE_ENV === 'production' ? 'https://qratadb.net/api/v1/' :'http://localhost:8000/api/v1/';
const baseURL = process.env.REACT_APP_API_ROOT;

// const refreshToken = (values) => {
//     return axios.post(`${baseURL}/refresh`, values)
//         .then(res => {
//             return res;
//         })
//         .catch(() => {
//             Storage.removeAllCredentials();
//         });
// };

const createAxiosClient = () => {
    const instance = axios.create({
        baseURL
    });
    
    instance.CancelToken = axios.CancelToken;

    instance.interceptors.request.use(config => {
        config.headers['Content-Type'] = 'application/json';
        config.headers.Accept = 'application/json,text/plain';
        // config.headers['Cache-Control'] = 'no-cache';

        try {
            if(Storage.getToken()) {
                config.headers.Authorization = `Bearer ${Storage.getToken()}`;
            }
            
            return config;
        } catch (err) {
            // return original config if JSON.parse fails
            return config;
        }
    });

    // instance.interceptors.response.use(null, error => {
    //     const status = error.response ? error.response.status : null;
    //     if (status === 401) {
    //         return refreshToken({
    //             refresh: Storage.getRefreshToken(),
    //             access: Storage.getToken()
    //         })
    //             .then(response => {
    //                 const token = response.data.access;

    //                 Storage.setToken(token);
    //                 Storage.setRefreshToken(response.data.refresh);

    //                 error.config.headers.Authorization = `Bearer ${token}`;
    //                 return axios.request(error.config);
    //             })
    //             .catch(() => {
    //                 // Fail with original error
    //                 window.location.href = '';
    //                 return Promise.reject(error);
    //             });
    //     }
    //     return Promise.reject(error);

    // });

    return instance;
};

export default createAxiosClient();