import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, ListGroup } from 'react-bootstrap';
import InputField from '../../../shared/formComponent/InputField';
import * as applicationServices from '../../../services/application';
import { useDispatch } from 'react-redux';
import * as alertActions from "../../../actions/alert";
import * as loaderAction from "../../../actions/loader";

const SearchModal = ({show, closeModal, title, fetchData}) => {
    const [searchKey, setSearchKey] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selected, setSelected] = useState(null);
    const [isSearched, setIsSearched] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(selected){
            fetchData(selected);
            closeModal();
        }
    }, [selected])

    const handleSearch = () => {
        if(searchKey){
            dispatch(loaderAction.loader(true));
            applicationServices.getSearchSuggestions(searchKey).then(([response, error]) => {
                if (response) {
                    setSearchResults(JSON.parse(response));
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }
                setIsSearched(true);
                dispatch(loaderAction.loader(false));
            }) 
        }
    }

    const handleChange = (e) => {
        setSearchKey(e.value);
    }

    const handleClose = () => {
        setSearchKey('');
        closeModal();
    }

    return(
        <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={10} sm={10} lg={10} xl={10}>
                            <InputField value={searchKey} name='searchKey' md={12} sm={12} lg={12} xs={12} xl={12} placeholder='Search...' type='text' label='' handleEvent={handleChange}/>
                        </Col>
                        <Col md={2} sm={2} lg={2} xl={2}>
                            <Button onClick={handleSearch}>
                                <FontAwesomeIcon icon="search"/>
                            </Button>
                        </Col>
                    </Row>
                    {
                        isSearched ?
                            <ListGroup>
                                {searchResults?.length > 0 ? searchResults.map((e, i) => <ListGroup.Item key={`search-result-${i}`} onClick={() => setSelected(e)}>{`${e.major_catalog?.label}${e.minor_catalog?.label ? " > " + e.minor_catalog?.label  : ''}${e.category?.label ? " > " + e.category?.label : ''}`}</ListGroup.Item>)
                                : <ListGroup.Item>No results found</ListGroup.Item>}
                            </ListGroup>
                        : ''
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
    )
    
}

export default SearchModal;