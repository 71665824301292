import React , { useState ,  useCallback , useEffect} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import FormBlock from '../../../shared/FormBlock';
import AssetsStats from './AssetsStats';
import * as loaderAction from '../../../actions/loader';
import * as alertActions from '../../../actions/alert';
import * as assetService from '../../../services/asset';
import { useDispatch } from 'react-redux';
import { useBoolean } from '../../../hooks/basic';
import ConfirmModal from '../../../shared/ConfirmModal';
import { history } from "../../../helpers";

const AssetsForm3 = (props) => {

  const dispatch = useDispatch();
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [formData , setFormData] = useState({
    notes : ''
  });

  useEffect(() => {
    if(props.note !== ''){
      setFormData({notes : props.note });
    }
  },[props.note]);


   // Update Comment Assets
   const updateAsset = useCallback(() => {
    if(props.assetId && formData.notes !== '' && formData.notes !== null){
      dispatch(loaderAction.loader(true));
      assetService.patchAssetById(+props.assetId , formData).then(([response , error]) => {
        if(response){
          setFormData({notes : response.notes});
          dispatch(alertActions.success('Comment  save successfully.'));
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }else {
      dispatch(alertActions.error('Please Enter the comment.'));
    }
  },[props.assetId ,dispatch , formData])

  // UPDATE ASSET STATUS
  const saveAsset = useCallback((statusValue) => {
    if(props.assetId){
      assetService.updateAssetStatus(+props.assetId).then(([response , error]) => {
        if(response){
          history.push('/assets');
          dispatch(alertActions.success(response.success));
        }if(response.response_data){
          dispatch(alertActions.info(response.response_data));
          history.push('/assets');
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }else {
      dispatch(alertActions.error('Create the classification then save the asset.'));
    }
  },[props.assetId ,dispatch])


  const form2 = [
    {
      label: 'Permanent Comments / Notes', type: 'textarea', rows: 5 , name: 'notes', placeholder: 'Permanent Comments / Notes', md: 12 , sm : 12 , lg : 12
    }
  ]

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData]);

  // CLOSE MODAL
  const closeConfirmModal = () => {
    hideConfirm();
  };

  // CONFIRM MODAL
  const handleConform = () => {
    hideConfirm();
    saveAsset();
  }

  // OPEN CONFIRM MODAL
  const openConfirmModal = () => {
    showConfirm();
  };

  return (
    <>
     <Row className="justify-content-md-center py-5 mx-0">
     <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={handleConform} title='Do you want submit this asset ?' />
        <Col md={12}>
          <h4>Comment </h4>
          <Form>
          <FormBlock fields={form2} size={12} getData={(e) => handleChange(e)} formData={formData} />
          </Form>
          <AssetsStats {...props} assetId={props.assetId} updateAsset={(e) => updateAsset()} saveAsset={(e) => openConfirmModal(e)} isGrey={props.isGrey}/>
        </Col>
      </Row>
    </>
  )
}
export default AssetsForm3;