import React, { useState, useCallback, useEffect , useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../actions/auth';
import * as userService from '../../services/user';
import { Storage } from '../../helpers/storage';
import loginimg from '../../assets/images/login-img.svg';
import logo from '../../assets/images/qratalogo.png';
import { Container, Row, Col, Form, Button, Spinner, Jumbotron, InputGroup, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import AlertAndLoader from '../../shared/AlertAndLoader';
import * as alertActions from '../../actions/alert';
import { useQuery } from '../../helpers/utility';

function LoginPage({ history }) {

    const [inputs, setInputs] = useState({ username: '', password: '' });
    const [validated, setValidated] = useState(false);

    const { username, password } = inputs;
    const dispatch = useDispatch();
    const [captcha , setCaptcha ] = useState(false);

    const validateCaptcha = useRef();
    const loggingIn = useSelector(state => state.auth.isFetching)
    const authenticated = useSelector(state => state.auth.authenticated)
    const loginData = useSelector(state => state.auth.loginData)
    const errors = useSelector(state => state.auth.error);
    const query = useQuery()
    useEffect(() => {
        if(errors && errors.detail){
            Storage.setLoginState(false);
            setCaptcha(true);
        }else if(Storage.getLoginState() === false){
            setCaptcha(true);
        }
    },[errors])

    const getLog = useCallback(() => {
        if(Storage.getSession()){
            userService.getLog(Storage.getSession().id).then(([response , error ]) => {
                if(response){
                    Storage.setLog(response.results[response.count - 1].id);
                }else if(error){
                    dispatch(alertActions.error(error.response.data))
                }
            })
        }
    },[dispatch])

    useEffect(() => {
        if (authenticated && loginData) {
            Storage.setSession(loginData);
            getLog();
            if(Storage.getSession().permissions.includes('qrata.back_office_access')){
                Storage.removeLoginState();
                let next = query.get('next') || '/dashboard'
                history.push(next);
            }else {
                Storage.removeAll();
                dispatch(alertActions.error('Invalid Credentials.'));
            }
            
        }
    }, [authenticated, loginData, history , getLog , dispatch, query ]);

    // on textfield change
    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }, []);

   // on form submit
   const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if(inputs.username !== '' && inputs.password){
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        dispatch(authActions.login(inputs))
        /*if(captcha){
            if(form.checkValidity() && validateCaptcha.current.validated()){
                dispatch(authActions.login(inputs)) 
            }else {
                dispatch(alertActions.error('Please Enter the Valid Captcha Code.'))
            }
        }else {
            dispatch(authActions.login(inputs)) 
        }*/
        
    }else {
        setValidated(true);
    }
}, [inputs, dispatch]);

    return (
        <>
            <AlertAndLoader />
            <Jumbotron className="d-flex align-items-center min-vh-100 mb-0">
                <Container>
                    <Row>
                        <Col md={6} className="d-md-block d-lg-block d-none " >
                            <h1><span className="text-green">World's Most</span> Reliable Knowledge - <span className="text-green">Guaranteed</span></h1>
                            <img width="100%" height="100%" src={loginimg} alt="er" />
                        </Col>
                        <Col md={6} sm={12} className="bg-light align-self-center p-5 rounded shadow  bg-white" >
                            <Row className="text-center no-gutters my-4">
                                <Col md={12}>
                                    <Link to='/home'>
                                    <img className="mb-4" width="180px" src={logo} alt="Qrata-logo" />
                                    </Link>
                                </Col>
                                <Col md={12}>
                                    {/*  <img width="180px"  src={logo} alt="er" />  */}
                                    <h5 className="login-title">Login BO</h5>
                                </Col>
                            </Row>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                {errors.detail ? <Alert variant="danger">{errors.detail}</Alert> : null}
                                <InputGroup className="mb-1">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="username">
                                            <FontAwesomeIcon className="icon-green" icon="user" />
                                        </InputGroup.Text>

                                    </InputGroup.Prepend>
                                    <Form.Control type="text" autoComplete="username" placeholder="Enter username" name="username" required value={username} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                   
                                </InputGroup>
                                <small className="d-block pt-1 mb-3 text-dark">(Username) is case-sensitive.</small>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-ctrlPassword">
                                            <FontAwesomeIcon className="icon-green" icon="key" />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="password" autoComplete="current-password" placeholder="Password" name="password" required value={password} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </InputGroup>
                                {/*{captcha ? <Captcha ref={validateCaptcha} styleCaptcha={'login-captcha'} /> : ''} */}
                                <Row className=" my-4">
                                    <Col md={8} className="text-left">
                                        <span ><Link className="text-dark" to="/passwordForgot">Forgot Password ?</Link></span>
                                    </Col>
                                    <Col md={4} className="text-right">
                                        <Button className="btn-green" variant="primary" type="submit">
                                            {loggingIn && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                    Login
                                    </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </>
    );


}

export default LoginPage;