import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

const SuggestExpertForm = () => {

  return (
    <div id="inner-wrapper">
      <Container fluid>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Header>
                <h4 >Suggest another Expert</h4>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} controlId="Existing">
                      <Form.Label>Existing Expert Name</Form.Label>
                      <Form.Control type="text" placeholder="Existing Expert Name" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="https">
                      <Form.Label>Name of Applicant for Expert</Form.Label>
                      <Form.Control type="url" placeholder="Name of Applicant for Expert" />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="designations">
                      <Form.Label>Titles and professional designations </Form.Label>
                      <Form.Control type="text" placeholder="designations" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="minor">
                      <Form.Label>How long have you known them?</Form.Label>
                      <Form.Control type="text" placeholder="Answer..." />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="categoryID">
                      <Form.Label>How did you meet / where do you know them from?</Form.Label>
                      <Form.Control type="text" placeholder="Answer..." />
                    </Form.Group>

                    <Form.Group as={Col} controlId="subCategoryID">
                      <Form.Label>What topics or subjects should they be an expert in?</Form.Label>
                      <Form.Control type="text" placeholder="Answer..." />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="topicId">
                      <Form.Label>Why would they be an expert in these subjects?</Form.Label>
                      <Form.Control type="text" placeholder="Answer..." />
                    </Form.Group>

                    <Form.Group as={Col} controlId="subTopicId">
                      <Form.Label>Communicate via: email / phone / text</Form.Label>
                      <Form.Control type="text" placeholder="email / phone / text" />
                    </Form.Group>
                  </Form.Row>
                  <Button variant="info" type="submit">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SuggestExpertForm;
