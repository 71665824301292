import React, { useCallback } from 'react';
import CreatableSelect from 'react-select/creatable'; 
// import PropTypes from 'prop-types';
import * as tagService from '../../services/tags'

const CreateSelect = ({ multi,onCreate, handleEvent, data, value}) => {

    const handleEve = (createValue) => {
        if(createValue!==null){
            if (createValue.length>0 &&(createValue[createValue.length-1].__isNew__==true)){
                onCreate(createValue)
            }
        } else{
            onCreate('')
        }
        handleEvent(createValue)
    }
  
    return (
        <CreatableSelect
        isMulti = {multi}
        value = {value}
        onChange={(e) => handleEve(e)}
        options = {data}
        // onCreate = {(e) => handleEve(e)}
        // fields = {fields}
      />
    )
}

// CreatableSelect.propTypes = {
//     data: PropTypes.array,
//     multi: PropTypes.bool,
//     handleEvent: PropTypes.func,
//     value : PropTypes.value
// }

// CreatableSelect.PropTypes = {
//     data: [],
//     multi: false,
//     handleEvent: PropTypes.func,
//     value : ''
// }

export default CreateSelect;