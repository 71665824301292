import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = ({show, onHide, acceptConfirm, title }) => {
    return (
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        {/* <Modal.Header>
          <Modal.Title>
            Confirm you wish to delete ?
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body style={{textAlign: 'center'}}>
          <h4>{title}</h4>
          <br/>
          <Button variant="primary" onClick={acceptConfirm}>Yes</Button>
          &nbsp;&nbsp;
          <Button variant="secondary" onClick={onHide}>No</Button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button>Yes</Button>
          <Button onClick={props.onHide}>No</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

  export default ConfirmModal;