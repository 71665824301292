import React, { useState, useCallback, useRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import FormBlock from "../../shared/FormBlock";
import { REGEXES } from "../../helpers/constants";
import * as userService from '../../services/user';
import { useBoolean } from "../../hooks/basic";
import * as alertActions from "../../actions/alert";
import { useDispatch } from "react-redux";

const SecurityComponent = () => {

const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    password: '',
    new_password: '',
    re_password: ''
  });

  const prevState = useRef(formData);
  const { password, new_password, re_password } = formData;
  const [validated, setValidated, revokeValidated] = useBoolean(false);
  const [showPasswordObj, setShowPasswordObj] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }

  // VALIDATE FORM FUNC
  const validateForm = useCallback(() => {
    let retVal = true;
    let setMessage = '';
    const validRegex = REGEXES.PASSWORD.test(new_password);

    if (!validRegex) {
      setMessage = 'New Password does not match the required password pattern';
      retVal = false;
    } else if (password === new_password) {
      setMessage = 'New Password cannot be same as Old Password';
      retVal = false;
    } else if (new_password !== re_password) {
      setMessage = 'New Password & Confirm Password does not match';
      retVal = false;
    } 
    if(!retVal) {
      dispatch(alertActions.error(setMessage));
    }
    return retVal;
  },[password, new_password, re_password , dispatch]);

  // HANDLE SUBMIT AND RESET USER PASSWORD
  const handleSubmit = useCallback((event) => {
    setValidated();
    event.preventDefault();
    const form = event.currentTarget;
    if ((form.checkValidity() === true) && validateForm()) {
      userService.resetUserPassword(formData).then(([response, error]) => {
       if (response) {
        dispatch(alertActions.success('Profile Updated Successfully'));
        revokeValidated();
        setFormData(prevState.current);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      });
    } else {
      event.stopPropagation();
    }
  }, [validateForm, dispatch, formData, setValidated, revokeValidated]);

  // FORM FIELDS
    const fields = [
      { label: 'Old Password*', name: 'password', type: 'password', required: true, sm: 12, md: 12, lg: 12, xl: 12, showPassword:  showPasswordObj.password, handleShowPassword: () => handleShowPassword('password')},
      { label: 'New Password*', name: 'new_password', type: 'password', required: true, sm: 12, md: 12, lg: 12, xl: 12, showPassword:  showPasswordObj.new_password, handleShowPassword: () => handleShowPassword('new_password') },
      { label: 'Confirm Password*', name: 're_password', type: 'password', required: true, sm: 12, md: 12, lg: 12, xl: 12, showPassword:  showPasswordObj.re_password, handleShowPassword: () => handleShowPassword('re_password') }
    ];

  const handleShowPassword = (name) => {
    setShowPasswordObj({
      ...showPasswordObj,
      [name]: !showPasswordObj[name]
    })
  }

  return (
    <div id="inner-wrapper">
      <Container fluid>
        <Row>
          <Col md={8}>
            <Card>
              <h4 className="pl-3 pt-2">Password Change</h4>
              <Card.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <FormBlock fields={fields} getData={(e) => handleChange(e)} formData={formData} />
                  <Card.Text><i>** Password must be 6 to 14 charaters long. Must Include lowercase and uppercase alphabetic characters,
                       numbers and special characters.</i><br />
                  </Card.Text>
                  <Button className="btn-green" variant="primary" type="submit">
                    Submit
                    </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SecurityComponent