import React from 'react';
import { Form, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HasPermission } from '../HasPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const InputField = ({ label, id, infoText, permission, md, sm, lg, xs, xl, type, col, opts, placeholder, name, value, minValue, maxValue, handleEvent, validators }) => {


    const errorMessage = 'Please fill the ' + label;
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const data = { name, value };
        handleEvent(data);
    }

    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}>
                        {label ? <Form.Label >{label}
                            {infoText ?
                                <>
                                    <OverlayTrigger
                                        trigger="hover"
                                        placement='right'
                                        overlay={
                                            <Popover id="popover-basic" className="p-3">
                                                {infoText}
                                            </Popover>
                                        }
                                    >
                                        <FontAwesomeIcon icon="info-circle" />
                                    </OverlayTrigger>

                                </>
                                : ''
                            }
                        </Form.Label> : ''}
                        <Form.Control type={type} size={col} onChange={(e) => handleChange(e)} {...opts} name={name} value={value} placeholder={placeholder} min={minValue} max={maxValue} />
                        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                    </Form.Group>
                </HasPermission>
            </Col >
        </>
    )
}

InputField.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    minValue: PropTypes.any,
    max: PropTypes.any,
    permission: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired
};

InputField.defaultProps = {
    id: '',
    value: '',
    name: '',
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: '',
    permission: '',
    type: 'number',
    label: '',
    minValue: '',
    maxValue: '',
    opts: {}
};
export default InputField;