import React, { useCallback, useState, useEffect } from "react";
import {
   Container,
   Row,
   Col,
   Form,
   Button,
   Accordion,
   Card,
   FormControl,
   OverlayTrigger, Popover,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsyncAppTable from "../../shared/AsyncAppTable";
import { ReactTinyLink } from "react-tiny-link";
import AsyncSelect from "../../shared/select/AsyncSelect";
import * as searchService from "../../services/search";
import * as assetService from "../../services/asset";
import * as alertActions from "../../actions/alert";
import * as loaderActions from "../../actions/loader";
import { useDispatch } from "react-redux";
import moment from 'moment';
import { DateConsts, defaultLimit , AssetStatus, getStatus, Status } from "../../helpers";
import TooltipHover from "../../shared/TooltipHover";
import SubTable from "../../shared/SubTable";
import {dateOnlyFormat} from '../../helpers'

const SubComponent = ({row}) => {

   const dispatch = useDispatch();
   const [assetList , setAssetList] = useState([]);
   
   const getExpertAssetList = useCallback((id) => {
     dispatch(loaderActions.loader(true));
     assetService.getAssetExpertList(row.original.id).then(([response , error]) => {
       if(response){
         setAssetList(response.asset_expert_list)
       }else if(error){
         dispatch(alertActions.error(error.response))
       }
       dispatch(loaderActions.loader(false));
     })
   },[dispatch , row.original.id]) 
 
   useEffect(() => {
     
     if(row.original.id){
      getExpertAssetList()
     }
   }, [row.original.id , dispatch , getExpertAssetList])
 
     // SUB COMPONENT COLUMNS
 const subColumns = [
   {
     Header: "Expert Name",
     accessor: "specific_topic",
     sortType: "basic",
     Cell: (cell) => (<div>{cell.row.original.first_name + ' ' + cell.row.original.last_name}</div>)
   },
   {
     Header: "Raw Score",
     accessor: "raw_score",
     sortType: "basic",
     className:'text-center'
   },
   {
     Header: "Final Score",
     accessor: "final_score",
     sortType: "basic",
     className:'text-center'
   },
   {
     Header: "Status",
     accessor: "status",
     sortType: "basic",
     Cell: (cell) => (<div>{getStatus(cell.row.original.status)}</div>)
   },
   {
     Header: "Approved On",
     accessor: "approved_on",
     sortType: "basic",
     Cell: (cell) => (<div>{cell.row.original.approved_on !== null ? dateOnlyFormat(cell.row.original.approved_on) : 'N/A'}</div>)
   },
   {
     Header: "Action",
     accessor: "action",
     className: "action-l text-center",
     Cell: (cell) => (
       <div>
         
           {cell.row.original.status === Status.APPROVED.value ? 
           <FontAwesomeIcon icon='check' /> : ''}
       </div>
     )
   }
 ];
 
   return <><SubTable columns={subColumns} data={assetList} /></>;
 }

const columns = [
   {
      Header: "#",
      accessor: "id",
      id: 'expander', 
      sortingOff: true,
      Cell: ({ row, rows, toggleRowExpanded }) => (
        
        <span {...row.getToggleRowExpandedProps} onClick={() => {
          const expandedRow = rows.find(row => row.isExpanded);

          if (expandedRow) {
            const isSubItemOfRow = Boolean(
              expandedRow && row.id.split(".")[0] === expandedRow.id
            );

            if (isSubItemOfRow) {
              const expandedSubItem = expandedRow.subRows.find(
                subRow => subRow.isExpanded
              );

              if (expandedSubItem) {
                const isClickedOnExpandedSubItem =
                  expandedSubItem.id === row.id;
                if (!isClickedOnExpandedSubItem) {
                  toggleRowExpanded(expandedSubItem.id, false);
                }
              }
            } else {
              toggleRowExpanded(expandedRow.id, false);
            }
          }
          row.toggleRowExpanded();
        }
      }>
          {row.isExpanded ? <span aria-label='img' role='img' > <FontAwesomeIcon className="text-mute  nav-icons" icon="caret-down" size="lg" /></span> :
          <span aria-label='img' role='img' ><FontAwesomeIcon className="text-mute  nav-icons" icon="caret-right" size="lg" /></span>}
        </span>
      )
    },
   {
      Header: "Specific Topic",
      accessor: "specific_topic",
      sortType: "basic",
      filtering: true,
      Cell: (cell) =>
      <div>
         {cell.row.original.url ? 
         <><OverlayTrigger
            trigger="click"
            placement='top'
            overlay={
               <Popover id="popover-basic">
                  <ReactTinyLink
                     cardSize="small"
                     showGraphic={true}
                     maxLine={2}
                     minLine={1}
                     url={cell.row.original.url}
                  />
               </Popover>
            }
         >
            <div>{cell.row.original.specific_topic}</div>
         </OverlayTrigger> </>
         : <div>{cell.row.original.specific_topic}</div> }
      </div>

   },
   {
      Header: "Specialization",
      accessor: "specialization",
      sortType: "basic",
      filtering: false,
      Cell: (cell) => 
      <div>
        <TooltipHover type='text' text={cell.row.original.specialization} message={cell.row.original.specialization} textClass='text-ellipsis d-block' handleEvent={() => null} />
    </div>
   },
   {
      Header: "Created by",
      accessor: "created_by",
      sortType: "basic",
      filtering: false,
      className: 'text-truncate overflow-hidden',
   },
   {
      Header: "Challenges",
      accessor: "topic_challenge",
      sortType: "basic",
      filtering: true,
      className: 'text-truncate overflow-hidden text-center',
   },
   {
      Header: "Raw Score",
      accessor: "raw_score",
      sortType: "basic",
      filtering: true,
      className: 'text-truncate overflow-hidden text-center',
   },
   {
      Header: "Final Score",
      accessor: "final_score",
      sortType: "basic",
      filtering: true,
      className: 'text-truncate overflow-hidden text-center',
   },
  {
    Header: "Status",
    accessor: "status",
    sortType: "basic",
    className: 'text-truncate overflow-hidden text-center',
    Cell: (cell) => (<div>{AssetStatus.getAssetStatus(cell.row.original.status).label}</div>)
  },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      className:"text-nowrap",
      Cell: (row) => (<div>{row.row.original.created_on !== "" ? (dateOnlyFormat(row.row.original.approved_on)): null}</div>)
    }
];

const dates = [
   { value: 'date_eq', label: 'Equal' },
   { value: 'date_gt', label: 'Greater than ' },
   { value: 'date_lt', label: 'Less than' },
   { value: '><', label: 'Between' },
   { value: 't', label: 'Today' },
   { value: 'y', label: 'Yesterday' },
   { value: 'w', label: 'This week' },
   { value: 'lw', label: 'Last week' },
   { value: 'l2w', label: 'Last 2 weeks' },
   { value: 'm', label: 'This month' },
   { value: 'Y', label: 'This year' }
];

const percentage = [10 ,20,30,40,50,60,70,80,90,100];

const SearchPopup = () => {

   const dispatch = useDispatch();
   const [startDateShow, setStartDateShow] = useState(true);
   const [endDateShow, setEndDateShow] = useState(true)
   const [disabledFilter, setDisabledFilter] = useState(true);
   const [areaList, setAreaList] = useState([]);
   const [resultsList, setResultsList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [resultCount, setResultCount] = useState(0)
   const [pageCount, setPageCount] = useState(0);
   const [submit, setSubmit] = useState(false);
   const [toggle , setToggle] = useState(0);
   const [callFetch, setCallFetch] = useState(false);

   const [formData, setFormData] = useState({
      user: '',
      classification: '',
      ratingFilter: '',
      rating: '',
      specific_topic: '',
      url: '',
      opinionFilter: '',
      opinion: '',
      dateFilter: '',
      s_date: '',
      e_date: '',
      area: '',
      status: ''
   })


   useEffect(() => {
      assetService.getArea().then(([response, error]) => {
         if (response) {
            setAreaList(response);
         } else if (error) {
            dispatch(alertActions.error(error.response))
         }
      })
   }, [dispatch])



   const handleChange = useCallback((e) => {
      setFormData({
         ...formData,
         [e.name]: e.value
      })
      setSubmit(false);
   }, [formData])

   const handleChangeDate = useCallback((e) => {
      const value = new Date(e.value).toISOString();
      setFormData({
         ...formData,
         [e.name]: value
      })
      setSubmit(false);
   }, [formData])


   const selectDateFilter = useCallback((e) => {

      setSubmit(false);
      switch (e) {

         case e = '':
            setDisabledFilter(true);
            setStartDateShow(true);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: '',
               s_date: '',
               e_date: ''
            })
            break;

         case e = 'date_eq':
            setDisabledFilter(false);
            setStartDateShow(false);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e
            })
            break;

         case e = 'date_gt':
            setDisabledFilter(false);
            setStartDateShow(false);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e
            })
            break;

         case e = 'date_lt':
            setDisabledFilter(false);
            setStartDateShow(false);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e
            })
            break;

         case e = '><':
            setDisabledFilter(false);
            setStartDateShow(true);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e
            })
            break;

         case e = 't':
            setStartDateShow(false);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e,
               s_date: moment().format('YYYY-MM-DDT01:01'),
               e_date: moment().format('YYYY-MM-DDT23:59')
            })
            setDisabledFilter(true);
            break;

         case e = 'y':
            setStartDateShow(false);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e,
               s_date: moment().add(-1, 'days').format('YYYY-MM-DDT01:01'),
               e_date: moment().add(-1, 'days').format('YYYY-MM-DDT23:59')
            })
            setDisabledFilter(true);
            break;

         case e = 'w':
            setStartDateShow(true);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e,
               s_date: moment().startOf('week').format('YYYY-MM-DDT01:01'),
               e_date: moment().endOf('week').format('YYYY-MM-DDT23:59')
            })
            setDisabledFilter(true);
            break;

         case e = 'lw':
            setStartDateShow(true);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e,
               s_date: moment().startOf('week').subtract(1, 'week').format('YYYY-MM-DDT01:01'),
               e_date: moment().endOf('week').subtract(1, 'week').format('YYYY-MM-DDT23:59')
            })
            setDisabledFilter(true);
            break;

         case e = 'l2w':
            setStartDateShow(true);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e,
               s_date: moment().startOf('week').subtract(2, 'week').format('YYYY-MM-DDT01:01'),
               e_date: moment().endOf('week').subtract(1, 'week').format('YYYY-MM-DDT23:59')
            })
            setDisabledFilter(true);
            break;

         case e = 'm':
            setStartDateShow(true);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e,
               s_date: moment().startOf('month').format('YYYY-MM-DDT01:01'),
               e_date: moment().format('YYYY-MM-DDT23:59')
            })
            setDisabledFilter(true);
            break;

         case e = 'Y':
            setStartDateShow(true);
            setEndDateShow(true);
            setFormData({
               ...formData,
               dateFilter: e,
               s_date: moment().startOf('year').format('YYYY-MM-DDT01:01'),
               e_date: moment().format('YYYY-MM-DDT23:59'),
            })
            setDisabledFilter(true);
            break;

         default:
            console.log('');
      }
   }, [formData])


   const fetchData = useCallback(({ pageSize, pageIndex, sortedField = "", filter }) => {
      if(submit){
      dispatch(loaderActions.loader(true));
      const limit = pageSize;
      let offset = pageSize * pageIndex;
      setLoading(true);
      searchService.getAssets(formData.user, formData.classification, formData.ratingFilter,
         formData.rating, formData.specific_topic, formData.url, formData.area , formData.opinionFilter,
         formData.opinion, formData.dateFilter, formData.s_date, formData.e_date,  formData.status ,limit, offset).then(([response, error]) => {
            if (response && response.results) {
               setResultsList(response.results)
               setResultCount(response.count)
               setPageCount(Math.ceil(response.count / pageSize));
               setToggle(null);
            } else if (response.results.length === 0) {
               setResultsList([]);
               setResultCount(0)
               setPageCount(0);
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderActions.loader(false));
            setLoading(false);
            setCallFetch(false);
         });
      }
   }, [submit , dispatch, formData.user, formData.classification, formData.ratingFilter,
      formData.rating, formData.specific_topic, formData.url, formData.opinionFilter,
      formData.opinion, formData.dateFilter, formData.s_date, formData.e_date, formData.area , formData.status])

      const enableSubmit = () => {
         setSubmit(true);
         // fetchData({ defaultLimit });
         setCallFetch(true);
      }

      const renderRowSubComponent = useCallback(({ row  }) => (
         <>
           <SubComponent
           row={row} />
         </>
       ), [])

       const resetFilters = useCallback(() => {
         setFormData({
            user: '',
            classification: '',
            ratingFilter: '',
            rating: '',
            specific_topic: '',
            url: '',
            opinionFilter: '',
            opinion: '',
            dateFilter: '',
            s_date: '',
            e_date: '',
            area: '',
            status: ''
         });
         setDisabledFilter(true);
         setStartDateShow(true);
         setEndDateShow(true);
         setSubmit(false)
       },[])

   return (
      <>
         <Container fluid className="py-4 text-left">
            <Accordion defaultActiveKey={toggle !== null ? toggle.toString() : 'a'} onSelect={(e) => setToggle(e)}>
               <Card>
                  <Card.Header >
                     <Accordion.Toggle
                        as={Button}
                        variant="link"
                        className="text-secondary text-decoration-none"
                        eventKey={toggle !== null ? toggle.toString() : 'b'}
                        
                     >
                        <FontAwesomeIcon className="text-info collapse-icon mt-1" size="sm" icon={toggle !== null ? 'angle-down' : 'angle-right'} />
                        <FontAwesomeIcon
                           className="text-secondary"
                           icon="search"
                        />  Find an asset
                </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={toggle !== null ? toggle.toString() : 'c'} >
                     <Card.Body>
                        <Row className="no-gutters">
                           <Col md={3} className="mb-2 pr-2">
                              <Form.Group controlId="user">
                                 <Form.Label>Users</Form.Label>
                                 <AsyncSelect  name='user' value={formData.user} asyncMethod={(e) => searchService.getAllUser(e)} group={true} getData={(e) => handleChange(e)} />
                              </Form.Group>
                           </Col>

                           <Col md={6} className="mb-2 pr-2">
                              <Form.Group controlId="cat">
                                 <Form.Label>General Classification</Form.Label>
                                 <AsyncSelect  name='classification' value={formData.classification} asyncMethod={(e) => searchService.getAllClassification(e)} getData={(e) => handleChange(e)} />
                              </Form.Group>
                           </Col>
                           <Col md={2} className="mb-2 pr-2">
                              <Form.Group controlId="ratingSelect">
                                 <Form.Label>Rating</Form.Label>
                                 <Form.Control as="select" aria-label='ratingSelect' aria-describedby='ratingSelect' selected={formData.ratingFilter} value={formData.ratingFilter} name='ratingFilter' onChange={(e) => handleChange(e.target)}>
                                    <option value=''>Select</option>
                                    <option value='rating_lt'>Less than </option>
                                    <option value='rating_gt'>Greater than </option>
                                    <option value='rating_eq'>Equal</option>
                                 </Form.Control>
                              </Form.Group>
                           </Col>
                           <Col md={1} className="mb-2">
                              <Form.Group controlId="rating">
                                 <Form.Label>Score</Form.Label>
                                 <FormControl
                                    type="number"
                                    placeholder="10"
                                    aria-label="rating"
                                    aria-describedby="rating"
                                    min="10"
                                    max="500"
                                    maxLength="3"
                                    disabled={formData.ratingFilter === '' ? true : false}
                                    value={formData.rating}
                                    name='rating'
                                    onChange={(e) => handleChange(e.target)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col md={5} className="mb-2 pr-2">
                              <Form.Group controlId="Specific-Topic">
                                 <Form.Label>Specific Topic</Form.Label>
                                 <FormControl
                                    type="text"
                                    placeholder="Specific Topic"
                                    aria-label="Specific-Topic"
                                    aria-describedby="Specific-Topic"
                                    value={formData.specific_topic}
                                    name='specific_topic'
                                    onChange={(e) => handleChange(e.target)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col md={5} className="mb-2 pr-2">
                              <Form.Group controlId="https">
                                 <Form.Label>https://</Form.Label>
                                 <FormControl
                                    type="url"
                                    placeholder="https://"
                                    aria-label="https"
                                    aria-describedby="https"
                                    value={formData.url}
                                    name='url'
                                    onChange={(e) => handleChange(e.target)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col md={2} className="mb-2 ">
                              <Form.Group controlId="area">
                                 <Form.Label>Area </Form.Label>
                                 <Form.Control as="select" aria-label='area' aria-describedby='area' className="p-1" name='area' selected={formData.area} value={formData.area} onChange={(e) => handleChange(e.target)}>
                                    <option value=''>Select</option>
                                    {areaList.map((item, index) => (
                                       <option key={'area' + index} value={item.value}>{item.label}</option>
                                    ))}
                                 </Form.Control>
                              </Form.Group>
                           </Col>
                           <Col md={2} className="mb-2 pr-2">
                              <Form.Group controlId="opinion">
                                 <Form.Label>Opinion %</Form.Label>
                                 <Form.Control as="select" aria-label='opinion' aria-describedby='opinion' selected={formData.opinionFilter} value={formData.opinionFilter} name='opinionFilter' onChange={(e) => handleChange(e.target)} className="p-1">
                                    <option value=''>Select</option>
                                    <option value='op_eq'>Equal</option>
                                    <option value='op_lt'>Less than </option>
                                    <option value='op_gt'>Greater than </option>
                                    <option value='op_np=1'>Non Opinionated</option>
                                 </Form.Control>
                              </Form.Group>
                           </Col>
                           <Col md={1} className="mb-2 pr-2">
                              <Form.Group controlId="percentage">
                                 <Form.Label>.</Form.Label>
                                 <Form.Control as="select" aria-label='percentage' aria-describedby='percentage' disabled={formData.opinionFilter === '' ||  formData.opinionFilter === 'op_np=1' ? true : false} value={formData.opinion} selected={formData.opinion} name='opinion' onChange={(e) => handleChange(e.target)} className="p-1">
                                    <option value=''>Select</option>
                                    {percentage.map((item, index) => (
                                        <option key={'percentage'+index} value={item}>{item}%</option>
                                    ))}
                                 </Form.Control>
                              </Form.Group>
                           </Col>
                           <Col md={2} className="mb-2 pr-2">
                              <Form.Group controlId="dateAdded">
                                 <Form.Label>Date added </Form.Label>
                                 <Form.Control as="select" aria-label='dateAdded' aria-describedby='dateAdded' selected={formData.dateFilter} value={formData.dateFilter} onChange={(e) => selectDateFilter(e.target.value)}>
                                    <option value=''>Select</option>
                                    {dates.map((item, index) => (
                                       <option key={'dates' + index} value={item.value}>{item.label}</option>
                                    ))}
                                 </Form.Control>
                              </Form.Group>
                           </Col>
                           <Col md={startDateShow && endDateShow ? 5 : 3} className="mb-2 pr-2 d-flex">
                              {startDateShow ?
                                 <Form.Group controlId="startDate">
                                    <Form.Label className="p-2"></Form.Label>
                                    <Form.Group className="mb-0">
                                       <FormControl
                                          type="date"
                                          placeholder="Start Date"
                                          name='s_date'
                                          aria-label="startDate"
                                          aria-describedby="startDate"
                                          disabled={disabledFilter}
                                          onChange={(e) => handleChangeDate(e.target)}
                                          value={formData.s_date !== '' ? moment(formData.s_date).format(DateConsts.UI_DATE_FORMAT_CALENDER) : ''}
                                       />

                                    </Form.Group>
                                 </Form.Group>
                                 : ''}
                              {endDateShow ?
                                 <Form.Group className='ml-2' controlId="endDate">
                                    <Form.Label className="p-2"></Form.Label>
                                    <Form.Group className="mb-0">
                                       <FormControl
                                          type="date"
                                          placeholder="EndDate"
                                          name='e_date'
                                          aria-label="endDate"
                                          aria-describedby="endDate"
                                          disabled={disabledFilter}
                                          onChange={(e) => handleChangeDate(e.target)}
                                          value={formData.e_date !== '' ? moment(formData.e_date).format(DateConsts.UI_DATE_FORMAT_CALENDER) : ''}
                                       />

                                    </Form.Group>
                                 </Form.Group>
                                 : ''}
                           </Col>
                           <Col md={2} className="mb-2">
                              <Form.Group controlId="status">
                                 <Form.Label>Status </Form.Label>
                                 <Form.Control as="select" className="p-1" aria-label='status' aria-describedby='status' name='status' value={formData.status} selected={formData.status} onChange={(e) => handleChange(e.target)}>
                                    <option value=''>Select</option>
                                    {Object.values(AssetStatus).map((item, index) => item.value ? (
                                       <option key={'status' + index} value={item.value}>{item.label}</option>
                                    ): '')}
                                 </Form.Control>
                              </Form.Group>
                           </Col>
                        </Row>
                        <Row>
                           <Col md={12} className="text-right">
                           <Button className="btn-green" variant="secondary" onClick={() => resetFilters()}>
                                 Reset
                      </Button>{" "}
                              <Button className="btn-green" variant="primary" onClick={() => enableSubmit()}>
                                 Search
                      </Button>{" "}
                           </Col>
                        </Row>
                     </Card.Body>
                  </Accordion.Collapse>
               </Card>
            </Accordion>
            <Row className="my-3 no-gutters">

               <AsyncAppTable
                  columns={columns}
                  data={resultsList}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  resultCount={resultCount}
                  subComponent={renderRowSubComponent}
                  filtering={false} />

            </Row>
         </Container>
      </>
   )
};

export default SearchPopup;
