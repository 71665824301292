import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'assets/';

// GET ASSET LIST FOR SUPER ADMIN , ADMIN & EDITOR
export async function getAssets(user , status, limit , offset, ordering="", filter, search='') {
  return await handleApi(axiosClient.get(`${BASE}list/${status !== '' ? '?status='+status : ''}${user !== '' ? '&created_by='+user : ''}&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filter}`));
}

// GET EXPERT LIST
export async function getExpertAssets(user, status=1) {
  return await handleApi(axiosClient.get(`${BASE}expert_list/${user !== '' ? '?user='+user : ''}${status !== '' ? '&status='+status : ''}`));
}

// CREATE ASSET
export async function createAssets(data) {
  return await handleApi(axiosClient.post(`${BASE}create/` , data));
}

// verify asset url
export async function urlVerify(data, cancelToken) {
  return await handleApi(axiosClient.post(`${BASE}urlVerify/`, data , {cancelToken:cancelToken.token}));
}

// GET ASSET BY ID 
export async function getAssetById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_asset/`));
}

// GET UnSigned Expert List 
export async function getUnsignedExpert(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/unassigned_expert_list/`));
}

// Add expert to asset 
export async function addExpert(data) {
  return await handleApi(axiosClient.post(`${BASE}verify_unassigned_asset/` , data));
}

// Delete expert to asset 
export async function deleteExpert(data) {
  return await handleApi(axiosClient.post(`${BASE}delete_asset_expert/` , data));
}


// UPDATE ASSET [COMMENT]  BY ID 
export async function patchAssetById(id , data) {
  return await handleApi(axiosClient.patch(`${BASE}${id}/` , data));
}

// UPDATE ASSET
export async function updateAsset(id , data) {
  return await handleApi(axiosClient.post(`${BASE}${id}/update/` , data));
}

export async function partialUpdate(id , data) {
  return await handleApi(axiosClient.patch(`${BASE}${id}/` , data));
}

export async function reRateAsset(id , data) {
  return await handleApi(axiosClient.post(`${BASE}${id}/re-rate/` , data));
}

// CREATE ASSET CRITERIA
export async function createCriteria(data) {
  return await handleApi(axiosClient.post(`${BASE}create_criteria/` , data));
}

// UPDATE ASSET CRITERIA
export async function updateCriteria(data) {
  return await handleApi(axiosClient.post(`${BASE}update_criteria/` , data));
}

// GET ASSET CRITERIA BY ID
export async function getCriteriaById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_asset_criteria/`));
}

// UPDATE ASSET STATUS 
export async function updateAssetStatus(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/update_status/`));
}

// CRITERIA RATING API'S
export async function getCriteriaRating(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_criteria_rating/`));
}

export async function createCriteriaRating(data) {
  return await handleApi(axiosClient.post(`${BASE}create_criteria_rating/` , data));
}

export async function updateCriteriaRating(data) {
  return await handleApi(axiosClient.post(`${BASE}update_criteria_rating/` , data));
}

// GET ALL ASSET EXPERT
export async function getAssetExpertList(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/asset_expert/`));
}

// Publish ASSET
export async function publishAsset(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/publish_asset/`));
}

//Get Asset Expert By ID
export async function assetExpertById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/asset_expert_by_id/`));
}

// VERIFY ASSET
export async function verifyAsset(data) {
  return await handleApi(axiosClient.post(`${BASE}verify_asset_expert/` , data));
}

// GET AREA LIST
export async function getArea() {
  return await handleApi(axiosClient.get(`${BASE}area_enums/`));
}

// GET BRANCH LIST
export async function getBranch() {
  return await handleApi(axiosClient.get(`${BASE}branch_opinion_enums/`));
}

// CREATE COMMENT IN ASSET
export async function createComment(data) {
  return await handleApi(axiosClient.post(`${BASE}create_comment/` , data));
}

// GET COMMENT IN ASSET
export async function getComment(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_comment/`));
}

