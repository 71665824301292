import React, { useCallback, useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBoolean } from '../../../hooks/basic';
import { useDispatch } from 'react-redux';
import * as alertActions from "../../../actions/alert";
import * as applicationService from '../../../services/application';
import CommentModal from '../../../shared/CommentModal';
import AccordionForm from '../../../shared/AccordionForm';
import CommentList from '../../../shared/CommentList';
import TooltipHover from '../../../shared/TooltipHover';

const Stats = ({
  currentStep,
  isActive,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  saveApplication,
  start,
  validation,
  submitData,
  applicationId,
  user,
  type,
  listLength,
  getComments,
  commentList
}) => {
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (applicationId !== '') {
      getComments();
    }
  }, [applicationId, getComments])


  // CLOSE COMMENT MODAL
  const closeModal = useCallback((data) => {
    hideModal();
    if (data.comment) {
      if (applicationId !== '') {
        const newData = new FormData();
        newData.append('application_id', applicationId);
        newData.append('ask_by', user.id);
        newData.append('comment', data.comment);
        if (data.attachment) {
          newData.append('attachment', data.attachment)
        };
        applicationService.createComment(newData).then(([response, error]) => {
          if (response) { 
            dispatch(alertActions.success('Comment Added Successfully.'));
            getComments()
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
        })
      } else {
        dispatch(alertActions.error('Click Add to save the Specialization before proceeding.'));
      }
    }

  }, [hideModal, user, applicationId, dispatch ,getComments]);

// OPEN COMMENT MODAL
  const openModal = useCallback(() => {
    showModal();
  }, [showModal]);


  // SAVE SPECIALIZATION
  const save = () => {
    if (validation()) {
      saveApplication();
    } else {
      dispatch(alertActions.error('Please Select the Specialization.'));
    }
  }

  const commentsCount  = commentList && commentList.length > 0 ? commentList.length : 0;
  // ACCORDION DATA
  const data = [
    { name: `Comments (${commentsCount})`, open: false, type: 'component', component: <CommentList comments={commentList ? commentList : []} /> },
  ];

  return (
    <div>
      <CommentModal title='Comments' show={isVisible} onHide={(e) => closeModal(e)} />
      <hr />
      <Row>
        <Col md={8} className='text-left'>
          {applicationId ? 
          // <Button className="mr-2 mb-2 float-left" variant="info" onClick={() => openModal()}>
          //   <FontAwesomeIcon className="text-white mr-1" icon="comment" size="sm" />Comment</Button>
          <TooltipHover type='button' btnSize='md' btnClass="mr-2  " variant='info' iconClass="ml-1" btnIcon={'comment'} label='Comment' message='This is for internal Team Qrata questions because things are unclear, for suggestions an upgrades, problems but is NOT a live chat and cannot be seen by Members using the Database from the front end UI' handleEvent={() => openModal()} placement='bottom'/> 
            : ''}
          {currentStep === 1 ?
            <TooltipHover type='button' btnSize='md' variant='outline-secondary' label='Save as Draft' message='Save as Draft' handleEvent={save}
               placement='bottom' showGuide={ applicationId === '' && isActive} pageMsg={'Save your Progress to enable the Next Button'}/>
            // <Button id='save2' variant='outline-secondary' onClick={() => save()}>Save as draft</Button>
            : currentStep === 5 ?
            <TooltipHover type='button' btnSize='md' variant='outline-secondary' label='Save as Draft' message='Save as Draft' handleEvent={(e) => submitData(e)}
               placement='bottom'  showGuide={(applicationId || applicationId=='') && isActive} pageMsg={'Save your Progress to enable the Next Button'}/>
              // <Button id='save' variant='outline-secondary' onClick={(e) => submitData(e)}>Save as draft</Button>
              : null
          }
        </Col>
        <Col md={4} className='text-right'>
          {currentStep === totalSteps ?
            <Button id='submit' variant='primary mr-2' disabled={start} onClick={() => submitData()}>Submit</Button>
            : null}
          {currentStep > 1 &&
            <Button variant="light" onClick={previousStep}><FontAwesomeIcon icon='angle-left'></FontAwesomeIcon> Back</Button>
          }
          {(currentStep < totalSteps && listLength !== 0) || type === 'Accomplishment' ?
            // <Button id='next' variant="light" className=' mr-2 ml-2' disabled={start && listLength !== 0} onClick={nextStep}>Next <FontAwesomeIcon icon='angle-right'></FontAwesomeIcon></Button>
            (
              currentStep === 1 || currentStep === 5 ? 
              (start && listLength !== 0 ? 
              <TooltipHover type='button' btnSize='md' variant='light' label='Next' btnClass={'mr-2 ml-2'} isDisabled={true} handleEvent={nextStep} btnIcon='angle-right' message="You have to 'Save as Draft' to be able to continue the application process"
              placement='top' /> 
              : 
              <Button id='next-1' variant="light" className=' mr-2 ml-2' disabled={false} onClick={nextStep}>Next <FontAwesomeIcon icon='angle-right'></FontAwesomeIcon></Button>
              ) : 
              <Button id='next' variant="light" className=' mr-2 ml-2' disabled={start && listLength !== 0} onClick={nextStep}>Next <FontAwesomeIcon icon='angle-right'></FontAwesomeIcon></Button>
            )
            :
            null
          }

          {listLength === 0 && type !== 'Accomplishment' ?
            // <Button id='next' variant="light"  onClick={() => dispatch(alertActions.error('Please Select the '+ type + '.'))} className=' mr-2 ml-2 disabled'  >Next <FontAwesomeIcon icon='angle-right'></FontAwesomeIcon></Button>
            // <Button id='next' variant="light"  className=' mr-2 ml-2 disabled'  >Next <FontAwesomeIcon icon='angle-right'></FontAwesomeIcon></Button>
            (
              currentStep === 1 || currentStep === 5 ?
              <TooltipHover type='button' btnSize='md' variant='light' label='Next' btnClass=" mr-2 ml-2 disabled" btnIcon='angle-right' message="You have to 'Save as Draft' to be able to continue the application process"
              placement='top' /> :
              <Button id='next' variant="light"  className=' mr-2 ml-2 disabled'  >Next <FontAwesomeIcon icon='angle-right'></FontAwesomeIcon></Button>
            )
            :
            null
          }

        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          <AccordionForm data={data} />
        </Col>
      </Row>
    </div>
  )
}
export default Stats;