import React from "react";
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import LoginPage from "../login/LoginPage";
import { history } from "../../helpers/history";
import "./icons";
import PasswordForgot from "../login/PasswordForget";
import { PublicRoute } from "./PublicRoute";
import CreatePassword from "../login/CreatePassword";
import PageNotFound from "../../shared/PageNotFound";
import { PrivateRoute } from "./PrivateRoute";
import MainPage from "./MainPage";

function App() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>

        {/* Public Routes */}
        <PublicRoute component={LoginPage} restricted={true} path="/login" exact />
        <PublicRoute component={PasswordForgot} restricted={true} path="/passwordForgot" />
        <PublicRoute component={CreatePassword} restricted={true} path="/invite/:token" />
        <PublicRoute component={CreatePassword} restricted={true} path="/resetPassword/:token" />
        
        <PrivateRoute path="*" component={MainPage} />
        <PublicRoute path='*' component={PageNotFound} />
        
      </Switch>
    </ConnectedRouter>
  );
}

export default App;