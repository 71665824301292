import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { monthYearFormat } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Status } from '../../../helpers/constants';

const BioAccomplishment = ({ accomplishment,  disable }) => {
  return (
    <>
      {accomplishment.length > 0 ?
        <Card className="mt-1 border-0">
          {accomplishment.map((item, index) => (

            <Card.Body key={index} className="pb-0">
              <Row key={index}>
                <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item.accomplishment[0].accomplishment_type}>
                  {item.accomplishment[0].accomplishment_type}
                </Col>
                <Col md={3} sm={12} className="text-warning text-left font-weight-bold text-truncate overflow-hidden" title={item.accomplishment[0].title}>
                  {item.accomplishment[0].title}
                </Col>
                <Col md={2} sm={12} className='text-truncate overflow-hidden' title={item.accomplishment[0].description}>
                  {item.accomplishment[0].description}
                </Col>
                <Col md={3} sm={10} className="text-info font-weight-bold font-style-italic text-right">
                  {monthYearFormat(item.accomplishment[0].accomplishment_month_year)}
                  <a className=" text-danger text-decoration-none  ml-2" target="_blank" rel="noopener noreferrer" href={item.accomplishment[1] ? item.accomplishment[1].file_url : '#'}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>

                </Col>
                <Col md={1} sm={2}>
                    {item.accomplishment_status === Status.APPROVED.value ? '' :
                      <><label className="switch">
                        <input type="checkbox" disabled={disable} name={item.accomplishment_id} onChange={(e) => null} />
                        <span className="slider round"></span>
                      </label></>}
                </Col>
              </Row>

            </Card.Body>

          ))} </Card>
        : 'No data found.'}
    </>
  )
}
export default BioAccomplishment;