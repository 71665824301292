import React, { useState, useCallback } from 'react';
import loginimg from '../../assets/images/login-img.svg';
import logo from '../../assets/images/qratalogo.png';
import { Container, Row, Col, Form, Button, Jumbotron, InputGroup, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as authServices from '../../services/auth';
import {  Link } from 'react-router-dom';
import { REGEXES } from '../../helpers/constants';

function PasswordForgot({ history }) {

  const [inputs, setInputs] = useState({ email : '' });
  const [errorMessage, setErrorMessage] = useState();
  const [validRegex1, setValidRegex1] = useState();
  const [invalidRegex1, setInvalidRegex1] = useState();
  const [successMessage , setSuccessMessage ] = useState();
  const { email  } = inputs;


  const validateForm = useCallback(() => {
    let retVal = true;
    let setMessage = '';
    if (!email) {
      setMessage = 'Email Id required field.';
      setSuccessMessage('');
      retVal = false;
    } else if (invalidRegex1) {
      setMessage = 'Please enter the correct Email Id.';
      setSuccessMessage('');
      retVal = false;
    }
    setErrorMessage(setMessage);
    return retVal;
  } , [email , invalidRegex1]);

  // on textfield change
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
    setErrorMessage('');
    const validRegex = REGEXES.EMAIL.test(value);
    if (value && name === 'email') {
      setValidRegex1(validRegex);
      setInvalidRegex1(!validRegex);
    }
  }

  // on form submit
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
     if (validateForm()) {
      authServices.passwordForgot(inputs).then(([response, error]) => {
        if (response) {
          const msg = 'Thanks! Please check '+ email +' for a link to reset your password.'
          setSuccessMessage(msg);
          setInputs({email: ''});
        } else if (error) {
          setErrorMessage(error.response.data.error);
        }
     });
     }
  }, [inputs , validateForm , email]);

  return (
    <Jumbotron className="d-flex align-items-center min-vh-100 mb-0">
      <Container>
        <Row>
          <Col md={6} className="d-md-block d-lg-block d-none " >
            <h1><span className="text-green">World's Most</span> Reliable Knowledge - <span className="text-green">Guaranteed</span></h1>
            <img width="100%" height="100%" src={loginimg} alt="er" />
          </Col>
          <Col md={6} sm={12} className="bg-light align-self-center p-5 rounded shadow  bg-white" >
            <Row className="text-center no-gutters my-4">
              <Col md={12}>
                <img className="mb-4" width="180px" src={logo} alt="er" />
              </Col>
              <Col md={12}>
                {/*  <img width="180px"  src={logo} alt="er" />  */}
                <h5 className="login-title">Forgot Password</h5>
              </Col>

            </Row>
            <Form noValidate onSubmit={handleSubmit}>

              {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
              {successMessage ? <Alert variant="success">{successMessage}</Alert> : null}
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-ctrlPassword">
                    <FontAwesomeIcon className="icon-green" icon="key" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control type="email" placeholder="Enter your registered email" name="email" value={email} onChange={handleChange} required isValid={validRegex1} isInvalid={invalidRegex1} />
                <Form.Control.Feedback type="invalid">Please enter the correct Email-Id.</Form.Control.Feedback>
              </InputGroup>

              <Row className=" my-4">
                <Col md={8} className="text-left">
                 <span ><Link className="text-dark" to="/login"> <FontAwesomeIcon  icon="angle-left" /> Back</Link></span>         
                  </Col>
                <Col md={4} className="text-right">
                  <Button className="btn-green" variant="primary" type="submit">
                    {/* {loggingIn && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} */}
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}

export default PasswordForgot;