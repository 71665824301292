import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'response-suggestion/';
export async function predefinedResponseListing(pageSize, pageNumber, search='', filter, ordering='') {
    return await handleApi(axiosClient.get(`${BASE}?limit=${pageSize}&offset=${pageNumber}&ordering=${ordering}&search=${search}&${filter}`));
  }

export async function createPredefinedResponse(data) {
    return await handleApi(axiosClient.post(`${BASE}` , data));
  }

export async function updatePredefinedResponse(id, data) {
    return await handleApi(axiosClient.put(`${BASE}${id}/`, data));
  }
  
export async function deletePredefinedResponse(id) {
    return await handleApi(axiosClient.delete(`${BASE}${id}/`));
  }

