import React from 'react';
import { Row , Col } from 'react-bootstrap';

const PageNotFound = () => {
  return (
    <>
       <Row className="text-center p-5">
         <Col md="12">
            <h1 style={{'fontSize':'15vw'}}>404</h1>
            <p>Page does not exist! Unless you were looking for this error page, In which case you find it!</p>
         </Col>
       </Row>
       </>
  )
}
export default PageNotFound;