import React, { useEffect, useState, useCallback } from "react";
import {  Container, Row, Col } from 'react-bootstrap';
import AppTable from "../../shared/AppTable";
import * as buildoutService from '../../services/buildout';
import { useBoolean } from "../../hooks/basic";
import BuildoutForm from "./BuildoutForm";
import TooltipHover from "../../shared/TooltipHover";
import { useDispatch } from "react-redux";
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import { dateOnlyFormat } from '../../helpers';
import SearchField from '../../shared/SearchField';

const BuildoutList = () => {

  const dispatch = useDispatch();
  const [buildoutData, setBuildoutData] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [topicData, setTopicData] = useState({});
  const [search, setSearch] = useState('')

  const getBuildOutList = useCallback(() => {
    dispatch(loaderAction.loader(true));
    buildoutService.fetchAll().then(([response, error]) => {
      if (response) {
        setBuildoutData(response.results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [dispatch])

  useEffect(() => {
    getBuildOutList();
  }, [getBuildOutList])


  const columns = [
    {
      Header: "Action",
      accessor: "action",
      className: "text-center py-1",
      filteringOff:true,
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' iconClass='default' btnSize='sm' variant='link' btnIcon='edit' message='Edit Topic' handleEvent={() => openModal(cell.row.original)} />
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Topic' handleEvent={() => handleDelete(cell.row.original.id)} />
        </div>
      ),
    },
    {
      Header: "Topic Name",
      accessor: "topic",
      sortType: "basic",
    },
    {
      Header: "Status",
      accessor: "status",
      sortType: "basic",
      filteringOff:true,
      Cell: (cell) => (
        <div>
          {cell.row.original.status === 1 ? 'PENDING' : 'ASSOCIATED'}
        </div>
      ),
    },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
    },

  ];

  const openModal = useCallback((data) => {
    setTopicData(data);
    showModal();
  }, [showModal]);


  const handleDelete = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    buildoutService.deleteTopic(id).then(([response, error]) => {
      const list = buildoutData.filter(obj => obj.id !== id);
      setBuildoutData(list);
      if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [buildoutData, dispatch]);

  return (
    <Container fluid className="px-0">
      <BuildoutForm data={topicData} isVisible={isVisible} hide={hideModal} output={getBuildOutList} />
      <Row className="no-gutters" >
        <Col md={7} className="mb-md-0 mb-3">
          <h4> Topic Buildout List </h4>
        </Col>
        <SearchField md={4} xs={10} action={setSearch} />
        <Col md={1} xs={2} className="text-right">
        <TooltipHover type='button' btnSize='md' variant='primary' message='Add Topic' btnIcon='plus' iconClass='text-white' handleEvent={() => openModal(null)}  />
        </Col>
      </Row>
      <AppTable columns={columns} globalFilter={search} data={buildoutData} />
    </Container>
  );
}

export default BuildoutList;