import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Row, Col, Form, Card } from 'react-bootstrap';
import Stats from './Stats';
import FormBlock from '../../../shared/FormBlock';
import * as applicationService from '../../../services/application';
import { monthYearFormat } from '../../../helpers';
import TooltipHover from '../../../shared/TooltipHover';
import { useDispatch } from 'react-redux';
import * as alertActions from "../../../actions/alert";
import * as loaderAction from "../../../actions/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Third = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    job_title: '',
    experience_from: null,
    experience_to: null,
    company: '',
    is_conflict: false,
    conflict_reason: '',
    job_file: ''
  });
  const [formData2, setFormData2] = useState({
    job_id: ''
  })
  const prevState = useRef(formData);
  const [changeState, setChangeState] = useState(true);
  const [validated, setValidated] = useState(false);
  const [formData1, setFormData1] = useState({ new: true });
  const [existingJob, setExistingJob] = useState([]);
  const [jobsList, setJobsList] = useState('');
  const fieldPrevState = useState('');

  // GET JOB LIST 
  const getJobs = useCallback(() => {
    if (props.applicationId !== '') {
      dispatch(loaderAction.loader(true));
      applicationService.getJobById(props.applicationId).then(([response, error]) => {
        if (response && response.user_jobs) {
          setJobsList(response.user_jobs);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [props.applicationId, dispatch])

  
  useEffect(() => {
    if(jobsList){
          props.getStatus("Job", jobsList.length);
    }
  },[jobsList, props, props.getStatus]);

  // GET JOBS BY ID
  useEffect(() => {
    if (props.applicationId) {
      getJobs();
    }
  }, [props.applicationId, dispatch, getJobs])

 // GET ALL EXISTING JOB LISTING 
  const getAllExistingJob = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    applicationService.getExistingJob(id).then(([response, error]) => {
      if (response) {
        setExistingJob(response);
        setChangeState(false);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  },[dispatch])


  useEffect(() => {
    if (formData1.new === 'existing' && changeState) {
      getAllExistingJob(props.applicationId);
    }
  }, [formData1.new, props.applicationId, changeState, dispatch , getAllExistingJob])


  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

  const onChange = useCallback((e) => {
    setFormData2({
      ...formData2,
      [e.name]: e.value
    });
  }, [formData2])


  const Submit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      saveJob();
    }
  }

  const handleChange2 = useCallback((e) => {
    setFormData1({
      ...formData1,
      [e.name]: e.value
    });
  }, [formData1])


  // SAVE JOB 
  const saveJob = useCallback(() => {
    let data = new FormData();
    if (props.applicationId !== '') {
      data.append('application_id ', props.applicationId);
      data.append('user_id', props.user.id);
      data.append('job_title', formData.job_title);
      data.append('experience_from', formData.experience_from);
      data.append('experience_to', formData.experience_to);
      data.append('company', formData.company);
      data.append('is_conflict', formData.is_conflict);
      data.append('conflict_reason ', formData.conflict_reason);
      if (formData.job_file.name) {
        data.append('job_file ', formData.job_file, formData.job_file.name);
      } else {
        data.append('job_file ', '');
      }
      dispatch(loaderAction.loader(true));
      applicationService.createJob(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Add Job Successfully.'));
          setFormData(prevState.current);
          setValidated(false);
          getJobs();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [props.applicationId, props.user.id, dispatch, formData.job_file, formData.job_title,
  formData.company, formData.is_conflict, formData.conflict_reason,
  formData.experience_from, formData.experience_to, getJobs])

  // ADD EXISTING JOB IN APPLICATION
  const addExistingJobs = useCallback(() => {
    if (formData2.job_id !== '') {
      let data = new FormData();
      data.append('application_id', props.applicationId);
      data.append('job_id', formData2.job_id);
      dispatch(loaderAction.loader(true));
      applicationService.addExistingJob(data).then(([response, error]) => {
        if (response) {
          setFormData2({
            job_id : ''
          })
          dispatch(alertActions.success('Add Job Successfully.'));
          setChangeState(true);
          getJobs();
          
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    } else {
      dispatch(alertActions.error('Click Add to save the Job before proceeding.'));
    }
  }, [dispatch, formData2.job_id, getJobs, props.applicationId ])

  // RADIO BUTTONS 
  const form1 = [
    [{ label: 'Add new ', id: 'add-third', type: 'radio', checked: true, required: true, placeholder: 'Add New ', value: 'add', name: 'new', sm: 6, md: 2, lg: 2, xl: 2 },
    { label: 'Existing', id: 'existing-third', type: 'radio', checked: false, required: true, placeholder: 'Existing ', value: 'existing', name: 'new', sm: 6, md: 1, lg: 1, xl: 1 },
    { icon: '', type: 'button', variant: 'info', className: 'float-right', sm: 12, md: 9, lg: 9, xl: 9 }],
  ]

  // ADD JOB FIELDS
  const [form3, setForm3] = useState([
    [{ label: 'Company Name', name: 'company', type: 'text', required: true, placeholder: 'Company name', sm: 12, md: 4, lg: 4, xl: 4 },
    { label: 'Job Title', name: 'job_title', required: true, type: 'text', placeholder: 'Job Role', sm: 12, md: 4, lg: 4, xl: 4 },
    { label: 'Experience Start ', required: true, name: 'experience_to', type: 'date', placeholder: 'MM/yyyy', sm: 6, md: 2, lg: 2, xl: 2 },
    { label: 'Experience End ', name: 'experience_from', type: 'date', placeholder: 'MM/yyyy', info:"Leave blank in case of current job", sm: 6, md: 2, lg: 2, xl: 2 }],
    [{ label: 'Attachment', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', name: 'job_file', type: 'file', placeholder: 'Select File', sm: 12, md: 4, lg: 4, xl: 4 },
    { label: 'Potential Conflict', name: 'is_conflict', type: 'checkbox', checked: false, value: false, placeholder: 'Conflict', sm: 12, md: 6, lg: 6, xl: 6 },
    { placeholder: 'ADD', type: 'button', variant: 'primary', className: 'float-right', sm: 12, md: 2, lg: 2, xl: 2 }],
  ]);

  // EXISTING JOBS LISTING
  const form4 = [
    [{ label: 'Select Job', required: true, name: 'job_id', type: 'multi-select', group : true , placeholder: '---Select---', options: existingJob, value : formData2.job_id ? formData2.job_id : '' ,sm: 12, md: 10, lg: 10, xl: 10 },
    { placeholder: 'Save', inputRef: fieldPrevState, disabled: existingJob.length > 0 ? false : true, handleEvent: () => addExistingJobs(), type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 2, lg: 2, xl: 2 }]];

// USE USEFFECT TO ADD CONFLICT TEXT AREA 
  useEffect(() => {
    if (formData.is_conflict && form3.length === 2) {
      const fieldTitle = 'Please describe who and why you might have a conflict with rating some assets? (Assets is what we call each item cataloged in our Superior Database.)';
      const textarea = { label: fieldTitle, type: 'textarea', name: 'conflict_reason', placeholder: 'Type here.....' };
      const data = form3;
      data.push(textarea);
      setForm3(data);
    } else if (formData.is_conflict === false && form3.length === 3) {
      form3.splice(-1, 1);
      setForm3([...form3]);
    }
  }, [formData, form3])

  // REMOVE JOBS IN LISTING
  const removeJob = useCallback((id) => {
    if (id) {
      dispatch(loaderAction.loader(true));
      let data = new FormData();
      data.append('application_id', props.applicationId);
      data.append('job_id', id)
      applicationService.deleteJob(data).then(([response, error]) => {
        if (response) {
          const list = jobsList.filter((item) => item[0].id !== id);
          setJobsList(list);
          dispatch(alertActions.success('Delete Successfully.'));
          getAllExistingJob(props.applicationId);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch, props.applicationId, jobsList, getAllExistingJob])


  return (
    <Row className="justify-content-md-center pt-5 mx-0">
      <Col md={12}>
        <Row className='mb-4'>
          <Col md={1}>
            <h4 >Jobs</h4>
          </Col>
          <Col md={11}>
            <Form>
              <FormBlock fields={form1} size={12} getData={(e) => handleChange2(e)} formData={formData1} />
            </Form>
          </Col>
        </Row>
        {formData1.new === true || formData1.new === 'add' ?
          <Form noValidate validated={validated} onSubmit={Submit}>
            <FormBlock fields={form3} size={12} getData={(e) => handleChange(e)} formData={formData} />
          </Form> :
          <FormBlock fields={form4} size={12} getData={(e) => onChange(e)} formData={formData2} />

        }
        {jobsList.length > 0 ?
          <Card className="py-3">
            {jobsList.map((item, index) => (
              <Card.Body key={index} className="py-1">
                <Row key={index}>
                  <Col md={3} sm={12} className="text-info text-truncate overflow-hidden" title={item[0].company}>

                    <label className="d-block text-left mb-0"><small>Company</small></label>
                    <h6>   {item[0].company}</h6>
                  </Col>
                  <Col md={2} sm={12} className=" text-left  text-truncate overflow-hidden" title={item[0].job_title}>

                    <label className="d-block text-left mb-0"><small>Position</small></label>
                    <h6 className="text-warning">  {item[0].job_title}</h6>
                  </Col>
                  <Col md={2} sm={12}>

                    <label className="d-block text-left mb-0"><small>Experience</small></label>
                    <h6> {monthYearFormat(item[0].experience_to) + ' - ' + (item[0].experience_from !== null? monthYearFormat(item[0].experience_from):"To Present")}</h6>
                  </Col>
                  <Col md={2} sm={12}>
                    <label className="d-block text-left mb-0"><small>Potential Conflict </small></label>
                    <h6> {item[0].is_conflict ? <><TooltipHover type='icon' iconClass='ml-1' variant='link' icon='info-circle' message={item[0].conflict_reason} handleEvent={() => null} /> </> : 'No'}</h6>

                  </Col>
                  <Col md={2} sm={12} className="font-style-italic ">
                        <label className="d-block text-left mb-0"><small>Status</small></label>
                        <h6 className=" text-left"> {item[0].approved_on === null ? 'Not Approved' : 'Approved'}</h6>
                  </Col>
                  <Col md={1} sm={12} className="text-info d-flex align-items-center justify-content-end text-nowrap">

                    {item[1] ? <> <a className=" text-danger  ml-2 text-decoration-none" target="_blank" rel="noopener noreferrer" href={item[1].file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a></> : ''}
                      <TooltipHover type='button' btnSize='sm' iconClass="text-danger ml-2" variant='link' btnIcon='trash' message='Delete' handleEvent={() => removeJob(item[0].id)} />
                  </Col>
                </Row>
                { jobsList.length - 1 === index ? null : <><hr className="mt-1 mb-2" /></>}
              </Card.Body>

            ))} </Card>
          : null}
        <Stats {...props} type={"Job"} listLength={jobsList.length} />
      </Col>
    </Row>

  );
}
export default Third;