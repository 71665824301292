import React, { useState, useEffect, useCallback } from 'react';
import { Button, ButtonGroup, Col, Container, Form, Modal, Row, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { monthYearFormat } from '../../helpers';
import * as accomplishmentService from '../../services/accomplishment';
import * as educationService from '../../services/education';
import * as loaderAction from '../../actions/loader';
import * as alertAction from '../../actions/alert';
import * as jobService from '../../services/job';
import AppTable from '../../shared/AppTable';
import FormBlock from '../../shared/FormBlock';
import TooltipHover from '../../shared/TooltipHover';
import { Status } from '../../helpers/constants';
import CommentModal from '../../shared/CommentModal';
import { useBoolean } from '../../hooks/basic';
import { dateOnlyFormat } from "../../helpers";
import SearchField from '../../shared/SearchField';

const Qualifications = () => {

  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.loginData);
  const [type, setType] = useState(1);
  const [acc, setAcc] = useState([]);
  const [job, setJob] = useState([]);
  const [education, setEducation] = useState([]);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [formData, setFormData] = useState({});
  const [fields, setFields] = useState([]);
  const [identity, setIdentity] = useState('');
  const [status, setStatus] = useState(2);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  // const [commentList , setCommentList] = useState([]);
  const [search, setSearch] = useState('')

  const list = [
    { id: 1, label: 'Education' },
    { id: 2, label: 'Experience' },
    { id: 3, label: 'Accomplishment' },
  ]

  const educationFields = [
    [{ label: 'University', disabled: true, name: 'university', type: 'text', required: true, placeholder: 'University', sm: 12, md: 12, lg: 12, xl: 12 }],
    [{ label: 'Course', disabled: true, name: 'course', type: 'text', required: true, placeholder: 'Course', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Complete Year', isClearable: false, disabled: true, name: 'degree_month_year', type: 'date', required: true, placeholder: 'MM/yyyy', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Attachment', disabled: true, name: 'education_file', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', type: 'file', placeholder: 'Select File', sm: 12, md: 12, lg: 12, xl: 12 }],
  ];

  const accomplishmentFields = [
    [{ label: 'Type', name: 'accomplishment_type', disabled: true, type: 'select', required: true, placeholder: '---Select---', options: [{ name: 'Award ' }, { name: ' License' }, { name: ' Publication ' }, { name: ' Other' }], sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Title', name: 'title', type: 'text', disabled: true, required: true, placeholder: 'Title', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Year', name: 'accomplishment_month_year', isClearable: false, disabled: true, type: 'date', required: true, placeholder: 'MM/yyyy', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Description', name: 'description', disabled: true, type: 'text', required: true, placeholder: 'Description', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Attachment', name: 'accomplishment_file', disabled: true, fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', type: 'file', placeholder: 'Select File', sm: 12, md: 12, lg: 12, xl: 12 }],
  ];

  const jobFields = [
    [{ label: 'Company', name: 'company', type: 'text', disabled: true, required: true, placeholder: 'Company', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Title', name: 'job_title', type: 'text', disabled: true, required: true, placeholder: 'Title', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Experience Start', name: 'experience_to', isClearable: false, disabled: true, type: 'date', required: true, placeholder: 'MM/yyyy', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Experience End', name: 'experience_from', isClearable: false, disabled: true, type: 'date', required: true, placeholder: 'MM/yyyy', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Attachment', name: 'job_file', disabled: true, fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', type: 'file', placeholder: 'Select File', sm: 12, md: 12, lg: 12, xl: 12 },
    { label: 'Conflict', name: 'is_conflict', disabled: true, type: 'checkbox', checked: false, value: false, className: 'mt-0', placeholder: 'Conflict', sm: 12, md: 12, lg: 12, xl: 12 }],
  ];

  // GET ALL ACCOMPLISHMENT 
  const getAccomplishment = useCallback(() => {
    dispatch(loaderAction.loader(true));
    accomplishmentService.getAccomplishment('', status).then(([response, error]) => {
      if (response) {
        setAcc(response.results);
      } else if (error) {
        dispatch(alertAction.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch, status])


  // GET ALL EDUCATION
  const getEducation = useCallback(() => {
    dispatch(loaderAction.loader(true));
    educationService.getEducation('', status).then(([response, error]) => {
      if (response) {
        setEducation(response.results);
      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch, status])

  // GET ALL JOBS
  const getJobs = useCallback(() => {
    dispatch(loaderAction.loader(true));
    jobService.getJob('', status).then(([response, error]) => {
      if (response) {
        setJob(response.results);
      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch, status])


  useEffect(() => {
    if (+type === 1) {
      getEducation();
    } else if (+type === 2) {
      getJobs()
    } else if (+type === 3) {
      getAccomplishment();
    }
  }, [type, getAccomplishment, getEducation, getJobs])

  // ACCOMPLISHMENT VERIFY
  const accomplishmentVerify = useCallback((e) => {
    dispatch(loaderAction.loader(true));
    const data = new FormData();
    data.append('accomplishment_id', formData.accomplishment_id);
    data.append('status', e);
    accomplishmentService.accomplishmentVerify(data).then(([response, error]) => {
      if (response) {
        if (e === 1) {
          dispatch(alertAction.success('Verified Successfully.'));
        } else if (e === 4) {
          dispatch(alertAction.success('Revised Successfully.'));
        }
        setFields([]);
        setFormData({});
        getAccomplishment();
      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch, getAccomplishment, formData])

  // EDUCATION VERIFY
  const educationVerify = useCallback((e) => {
    dispatch(loaderAction.loader(true));
    const data = new FormData();
    data.append('education_id', formData.education_id);
    data.append('status', e);
    educationService.educationVerify(data).then(([response, error]) => {
      if (response) {
        if (e === 1) {
          dispatch(alertAction.success('Verified Successfully.'));
        } else if (e === 4) {
          dispatch(alertAction.success('Revised Successfully.'));
        }
        setFields([]);
        setFormData({});
        getEducation();
      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch, getEducation, formData])

  // JOB VERIFY
  const jobVerify = useCallback((e) => {
    dispatch(loaderAction.loader(true));
    const data = new FormData();
    data.append('job_id', formData.job_id);
    data.append('status', e);
    jobService.jobVerify(data).then(([response, error]) => {
      if (response) {
        if (e === 1) {
          dispatch(alertAction.success('Verified Successfully.'));
        } else if (e === 4) {
          dispatch(alertAction.success('Revised Successfully.'));
        }
        setFields([]);
        setFormData({});
        getJobs();
      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch, getJobs, formData])

  // ACCOMPLISHEMENT TABLE COLUMNS
  const columns1 = [
    {
      Header: "Action",
      accessor: "action",
      className: 'text-center',
      Cell: (row) => (
        <div>
          {row.row.original.status === Status.UNDER_REVIEW.value ?
            <TooltipHover type='button' btnSize='sm' handleEvent={() => openModal(row.row.original.id, 'ACCOMPLISHMENT')} variant='link' iconClass='default' btnIcon='eye' message='Verify Accomplishment' />
            : row.row.original.status === Status.APPROVED.value ?
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='check' message='No action' handleEvent={() => null} />
              : ''}
        </div>
      ),
    },
    {
      Header: "Created By",
      sortType: "basic",
      className: 'text-capitalize',
      Cell: (cell) => (<div>{cell.row.original.first_name + ' ' + cell.row.original.last_name}</div>)
    },
    {
      Header: "Type",
      accessor: "accomplishment_type",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Title",
      accessor: "title",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Description",
      accessor: "description",
      sortType: "basic"
    },
    {
      Header: "Year",
      accessor: "accomplishment_month_year",
      sortType: "basic",
      Cell: (cell) => (<div>{monthYearFormat(cell.row.original.accomplishment_month_year)}</div>)
    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on) : 'N/A'}</div>)
    },

  ];

  // EDUCATION TABLE COLUMNS
  const columns2 = [
        {
      Header: "Action",
      accessor: "action",
      filteringOff: true,
      className: 'text-center',
      Cell: (row) => (
        <div>
          {row.row.original.status === Status.UNDER_REVIEW.value ?
            <TooltipHover type='button' btnSize='sm' handleEvent={() => openModal(row.row.original.id, 'EDUCATION')} variant='link' iconClass='default' btnIcon='eye' message='Verify Education' />
            : row.row.original.status === Status.APPROVED.value ?
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='check' message='No action' handleEvent={() => null} />
              : ''}
        </div>
      ),
    },
    {
      Header: "Created By",
      sortType: "basic",
      accessor: 'first_name',
      className: 'text-capitalize',
      Cell: (cell) => (<div>{cell.row.original.first_name + ' ' + cell.row.original.last_name}</div>)
    },
    {
      Header: "University",
      accessor: "university",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Course",
      accessor: "course",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Completed",
      accessor: "degree_month_year",
      sortType: "basic",
      Cell: (cell) => (<div>{monthYearFormat(cell.row.original.degree_month_year)}</div>)

    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on) : 'N/A'}</div>)
    },

  ];

  // JOB TABLE COLUMNS
  const columns3 = [
    {
      Header: "Action",
      accessor: "action",
      className: 'text-center',
      Cell: (row) => (
        <div>
          {row.row.original.status === Status.UNDER_REVIEW.value ?
            <TooltipHover type='button' btnSize='sm' handleEvent={() => openModal(row.row.original.id, 'JOB')} variant='link' iconClass='default' btnIcon='eye' message='Verify Job' />
            : row.row.original.status === Status.APPROVED.value ?
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='check' message='No action' handleEvent={() => null} />
              : ''}

        </div>
      ),
    },
    {
      Header: "Created By",
      sortType: "basic",
      className: 'text-capitalize',
      Cell: (cell) => (<div>{cell.row.original.first_name + ' ' + cell.row.original.last_name}</div>)
    },
    {
      Header: "Company",
      accessor: "company",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Title",
      accessor: "job_title",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Experience Start",
      accessor: "experience_to",
      sortType: "basic",
      Cell: (cell) => (<div>{monthYearFormat(cell.row.original.experience_to)}</div>)

    },
    {
      Header: "Experience End",
      accessor: "experience_from",
      sortType: "basic",
      Cell: (cell) => (<div>{cell.row.original.experience_from !== null? monthYearFormat(cell.row.original.experience_from):"To Present"}</div>)

    },
    {
      Header: "Conflict",
      accessor: "is_conflict",
      sortType: "basic",
      Cell: (cell) => (<div>{cell.row.original.is_conflict ? 'YES' : 'NO'}</div>)
    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on) : 'N/A'}</div>)
    },

  ];

  // GET EDUCATION BY ID
  const getEducationById = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    educationService.getEducationById(id).then(([response, error]) => {
      if (response) {
        setFields(educationFields);
        setFormData({
          education_id: response[0].id,
          university: response[0].university,
          course: response[0].course,
          degree_month_year: response[0].degree_month_year,
          education_file: response[1] ? response[1].file_url : '',
          file_id: response[1] ? response[1].id : ''
        });

      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  }, [educationFields, dispatch])

  // GET JOB BY ID
  const getJobById = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    jobService.getJobById(id).then(([response, error]) => {
      if (response) {
        setFields(jobFields);
        setFormData({
          job_id: response[0].id,
          company: response[0].company,
          job_title: response[0].job_title,
          experience_to: response[0].experience_to,
          experience_from: response[0].experience_from,
          is_conflict: response[0].is_conflict,
          conflict_reason: response[0].conflict_reason,
          job_file: response[1] ? response[1].file_url : ''
        });

      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  }, [jobFields, dispatch])

  // GET ACCOMPLISHMENT BY ID
  const getAccomplishmentById = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    accomplishmentService.getAccomplishmentById(id).then(([response, error]) => {
      if (response) {
        setFields(accomplishmentFields);
        setFormData({
          accomplishment_id: response[0].id,
          accomplishment_type: response[0].accomplishment_type,
          title: response[0].title,
          accomplishment_month_year: response[0].accomplishment_month_year,
          description: response[0].description,
          accomplishment_file: response[1] ? response[1].file_url : '',
          file_id: response[1] ? response[1].id : ''
        });
      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  }, [accomplishmentFields, dispatch])

  // OPEN POP_UP MODAL
  const openModal = useCallback((id, type) => {
    if (type === 'EDUCATION') {
      setTitle('Education Verify');
      setIdentity('EDUCATION')
      getEducationById(id);
    } else if (type === 'JOB') {
      setTitle('Job Verify');
      setIdentity('JOB');
      getJobById(id);
    } else if (type === 'ACCOMPLISHMENT') {
      setTitle('Accomplishment Verify');
      setIdentity('ACCOMPLISHMENT');
      getAccomplishmentById(id);
    }
    setShow(true);
  }, [getAccomplishmentById, getJobById, getEducationById])

  // CLOSE MODAL FOR ACCOMPLISHMENT , EDUCATION & JOB 
  const closeModal = useCallback((type, action) => {
    if (type === 'ACCOMPLISHMENT' && action === 'REVISE') {
      accomplishmentVerify(4);
    } else if (type === 'EDUCATION' && action === 'REVISE') {
      educationVerify(4);
    } else if (type === 'JOB' && action === 'REVISE') {
      jobVerify(4);
    }
    setShow(false);
  }, [accomplishmentVerify, educationVerify, jobVerify])

  // VERIFY FUNC 
  const verifyStatus = useCallback((type) => {
    if (type === 'ACCOMPLISHMENT') {
      accomplishmentVerify(1);
    } else if (type === 'EDUCATION') {
      educationVerify(1);
    } else if (type === 'JOB') {
      jobVerify(1);
    }
    setShow(false);
  }, [accomplishmentVerify, educationVerify, jobVerify])

  // CREATE NEW FORMDATA FOR COMMENTS
  const createFormData = useCallback((id, userId, data) => {
    const newData = new FormData();
    newData.append('id', id);
    newData.append('ask_by', userId);
    newData.append('comment', data.comment);
    if (data.attachment) {
      newData.append('attachment', data.attachment)
    };
    return newData;
  }, [])

  // CREATE COMMENT IN ACCOMPLISHMENT
  const createAccomplishmentComment = useCallback((data) => {
    const newData = createFormData(formData.accomplishment_id, loginData.id, data);
    accomplishmentService.createAccomplishmentComment(newData).then(([response, error]) => {
      if (response) {
        closeModal(identity, 'REVISE');
      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
    })
  }, [formData.accomplishment_id, loginData.id, closeModal, createFormData, identity, dispatch])

  // CREATE COMMENT IN EDUCATION
  const createEducationComment = useCallback((data) => {
    const newData = createFormData(formData.education_id, loginData.id, data);
    educationService.createCommentEducation(newData).then(([response, error]) => {
      if (response) {
        closeModal(identity, 'REVISE');
      } else if (error) {
        dispatch(alertAction.error(error.response))
      }
    })
  }, [formData.education_id, loginData.id, closeModal, createFormData, identity, dispatch])

  // CREATE COMMENT IN JOB
  const createJobComment = useCallback((data) => {
    const newData = createFormData(formData.job_id, loginData.id, data);
    jobService.createJobComment(newData).then(([response, error]) => {
      if (response) {
        closeModal(identity, 'REVISE');
      } else if (error) {
        dispatch(alertAction.error(error.response));
      }
    })
  }, [formData.job_id, loginData.id, closeModal, createFormData, identity, dispatch])

  // CLOSE COMMENT MODAL
  const closeModalComment = useCallback((data) => {
    hideModal();
    if (data.comment) {
      if (identity === 'ACCOMPLISHMENT') {
        createAccomplishmentComment(data);
      } else if (identity === 'EDUCATION') {
        createEducationComment(data);
      } else if (identity === 'JOB') {
        createJobComment(data);
      }
    } else {
      setShow(true);
    }
  }, [hideModal, identity, createAccomplishmentComment, createEducationComment, createJobComment]);

  // OPEN COMMENT MODAL POP-UP
  const openModalComment = useCallback(() => {
    setShow(false);
    showModal();
  }, [showModal]);

  return (
    <Container fluid className="px-0">
      <CommentModal title='Comments' show={isVisible} onHide={(e) => closeModalComment(e)} />
      <Modal show={show} onHide={() => closeModal(identity, 'CLOSE')} size="md" backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormBlock fields={fields} formData={formData} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => verifyStatus(identity)}>Approve</Button>
          <Button variant="secondary" onClick={() => openModalComment()}>Revise</Button>
          {/* <Button variant="secondary" onClick={() => closeModal(identity, 'CLOSE')}>Close</Button> */}
        </Modal.Footer>
      </Modal>
      <Row>
        <Col md={3} sm={12} className="mb-md-0 mb-3"><h4>Qualifications List</h4></Col>
        <Col md={4} sm={12} className="mb-md-0 mb-2">
          <ButtonGroup toggle>
            {Object.values(list).map((item, idx) => {
              return item.id ? (
                <ToggleButton size={'md'} key={idx} type="radio" variant="light" name="catTypes"
                  value={item.id} checked={+type === item.id} onChange={(e) => setType(e.target.value)}>
                  {item.label}
                </ToggleButton>
              ) : ''
            }
            )}
          </ButtonGroup>
        </Col>
        
        <Col md={2} sm={12} className="text-right mb-md-0 mb-2">
          <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
            {Object.values(Status).map((item, i) => { return item.value === 1 || item.value === 2 ? <option value={item.value} key={i}>{item.label}</option> : null }
            )}
          </Form.Control>
        </Col>
        <SearchField md={3} action={setSearch} className="mb-md-0 mb-2" />
      </Row>
      {+type === 1 ? <AppTable columns={columns2} globalFilter={search} data={education ? education : []} /> :
        +type === 2 ? <AppTable columns={columns3} globalFilter={search} data={job ? job : []} /> : +type === 3 ? <AppTable globalFilter={search} columns={columns1} data={acc ? acc : []} /> : ''}
    </Container>
  )
}
export default Qualifications;