import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import * as promotionService from '../../services/promotion';
import {  UserRole, UserType } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import * as alertActions from "../../actions/alert";
import * as loaderAction from "../../actions/loader";
import TooltipHover from "../../shared/TooltipHover";
import AsyncAppTable from "../../shared/AsyncAppTable";

const PromotionRequestList = () => {

  const columns = [
    {
      Header: "Action",
      accessor: "action",
      sortingOff:true,
      className: 'text-center py-1 text-nowrap',
      Cell: (cell) => (
        status === 2 ? 
          <div>
           <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-success' btnIcon='check-circle' message='Approve' handleEvent={() => ConfirmApproved(cell.row.original.id, 4)}/>
           <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger ml-1' btnIcon='times' message='Reject' handleEvent={() => ConfirmApproved(cell.row.original.id, 5)}/>
        </div> : ''
      ),
    },
    {
      Header: "First Name",
      accessor: "first_name",
      id: "user__first_name",
      sortType: "basic",
      className: 'text-capitalize',
      filtering:true,
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      id: "user__last_name",
      sortType: "basic",
      className: 'text-capitalize',
      filtering:true,
    },
    {
      Header: "Username",
      accessor: "username",
      id: "user__username",
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Email",
      accessor: "email",
      id: "user__email",
      filtering:true,
    },
    {
      Header: "Group",
      accessor: "group",
     Cell: (cell) => (<div>{displayGroups(cell.row.original.group)}</div>)
    }
  ];
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.loginData);
  const [userType, setUserType] = useState(UserType.getType(UserType.ACTIVE.id));
  const [userRoleId, setUserRoleId] = useState(UserRole.ALL.id);
  const [userAll, setUserAll] = useState([]);
  const fetchIdRef = React.useRef(0);
  const [loading , setLoading ] = useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(2);
  const [callFetch, setCallFetch] = useState(false);

  const statusArr = [
    {id: 1, description: 'Offered'},
    {id: 2, description: 'Interested'}, 
    {id: 3, description: 'Not Interested'}, 
    {id: 4, description: 'Approved'}, 
    {id: 5, description: 'Rejected'} 
  ];

const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField="", filter }) => {
  const fetchId = ++fetchIdRef.current
  
  setLoading(true);
  setTimeout(() => {
    if (fetchId === fetchIdRef.current) {
      const limit = pageSize;
      let offset = pageIndex * pageSize;
      dispatch(loaderAction.loader(true));
      promotionService.fetchAllPromotionRequests(limit, offset, search, sortedField, filter, status).then(([response, error]) => {
        if(response){ 
          handleData(response, pageSize);
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
      setLoading(false);
      setCallFetch(false);
    }
  }, 100)
}, [dispatch , userType.value , userRoleId, search, status])


const handleData = (valueUsers, pageSize) => {
  setUserAll(valueUsers.results)
  setResultCount(valueUsers.count) 
  setPageCount(Math.ceil(valueUsers.count / pageSize))
}

  const displayGroups = (group) => {

    const retVal = Object.values(UserRole).map(element => {
      return element.id === group ? element.title : '';
    });
    return retVal;
  }

  const ConfirmApproved = (id, status) => {
    const data = {
      status
    };
    dispatch(loaderAction.loader(true));
      promotionService.updatePromotionStatus(id, data).then(([response, error]) => {
          if(response){
            dispatch(alertActions.success(status === 4 ? 'User Approved Successfully' : 'User Rejected Successfully'));
            // fetchData(10, 0);
            setCallFetch(true);
          }else if(error){
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false)); 
      })
      setLoading(false);
  }

  return (

    <Container fluid className="px-0">
      <Row className="no-gutters">
        <Col md={9} className="mb-md-0 mb-3"><h4> Promotion Requests </h4></Col>
        <Col md={3} className="mb-3">
        <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(Number(e.target.value))} >
          {statusArr.map((item, i) => { return item.id ? <option value={item.id} key={i}>{item.description}</option> : null })}
        </Form.Control>
        </Col>
      </Row>
      <AsyncAppTable
        columns={columns}
        data={userAll}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        filtering={true} 
        callFetch={callFetch}
        />
    </Container>
  );
};

export default PromotionRequestList;
