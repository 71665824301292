import React, { useCallback,useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import AlertModal from '../../../shared/AlertModal';
export const NavForm = (props) => {

  const [showWarning, setWarning] = useState(false);
  
  const [title, setTitle] = useState('');

    useEffect(()=> {
      const text = props.start? 'Please save the specialization first!': 
      !props.education ? 'Please save the education first!':
      !props.job ? 'Please save the job first!' :
      !props.verifyReason ? 'Please save other information' : 'Please save the data first!'
      setTitle(text);
    }, [props.start, props.education, props.job, props.verifyReason]);


  return (
    <Container fluid>
      <AlertModal show={showWarning} onHide={() => setWarning(false)} title={title}/>
      <ul id="qrata-step-form">
        <Row className="justify-content-md-center text-center no-gutters">
          <Col md={2} xs={12} className="  pt-3 pr-1 cursor-pointer" onClick={ props.start === true ? null : () => props.goToStep(1)}>
            1. Specialization
       <li className={props.currentStep === 1 ? 'active' : null}></li>
          </Col>
          <Col md={2} xs={12} className=" pt-3 pr-1 cursor-pointer" onClick={props.start === true ? () => setWarning(true) :  () => props.goToStep(2)}>
            2. Education
      <li className={props.currentStep === 2 ? 'active' : null} ></li>
          </Col>
          <Col md={2} xs={12} className=" pt-3 pr-1 cursor-pointer" onClick={props.start === true || props.education === false ? () => setWarning(true) :  () => props.goToStep(3)}>
            3. Jobs
      <li className={props.currentStep === 3 ? 'active' : null}></li>
          </Col>
          <Col md={2} xs={12} className=" pt-3 pr-1 cursor-pointer" onClick={props.start === true || props.job === false ? () => setWarning(true) :  () => props.goToStep(4)}>
            4. Accomplishments
      <li className={props.currentStep === 4 ? 'active' : null}></li>
          </Col>
          <Col md={2} xs={12} className=" pt-3 pr-1 cursor-pointer" onClick={props.start === true ? () => setWarning(true) :  () => props.goToStep(5)}>
            5. Other Information
      <li className={props.currentStep === 5 ? 'active' : null}></li>
          </Col>
          <Col md={2} xs={12} className=" pt-3 pr-1 cursor-pointer" onClick={props.verifyReason === true ?   () => props.goToStep(6) : () => setWarning(true)}>
            6. Final Submission
      <li className={props.currentStep === 6 ? 'active' : null}></li>
          </Col>
        </Row>
      </ul>
    </Container>
  )
};