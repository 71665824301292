import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'faq/';

// Fetch the list of all faqs
export async function fetchAll(status) {
  status = status ||1;
  return await handleApi(axiosClient.get(`${BASE}?status=${status}`));
}

// Add new FAQ
export async function addFaq(data) {
return await handleApi(axiosClient.post(`${BASE}`, data));
}

// Update Faq
export async function updateFaq(id , data) {
return await handleApi(axiosClient.put(`${BASE}${id}/`, data));
}

// Delete an Faq
export async function deleteFaq(id) {
return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}