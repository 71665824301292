import React, { useState, useCallback, useRef, useEffect } from "react";
import { Container } from 'react-bootstrap';
import ModalForm from "../../shared/ModalForm";
import * as userService from '../../services/user';
import { useDispatch, useSelector } from "react-redux";
import * as alertActions from "../../actions/alert";
import {  UserRole } from "../../helpers/constants";
import * as loaderAction from '../../actions/loader';

const InvitesForm = ({ isVisible, userData, hide, refreshList }) => {

  const sessionData = useSelector((state) => state.auth.loginData);

  const dispatch = useDispatch();
  const [trustscore, setTrustscore] = useState('');
  const [inviteData, setInviteData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    groups: [UserRole.EXPERT.id],
    trustscore: 100,
    custom_message: '',
    reason_to_join: ''
  });
  const prevState = useRef(inviteData);

  const getTrustScore = useCallback((id) => {
    if (id !== '') {
      userService.getTrustScore(id).then(([response, error]) => {
        if (response) {
          setTrustscore(response.trustworthiness);
        } else if (error) {
          dispatch(alertActions.error(error.response));

        }
      })
    }
  }, [dispatch])

  useEffect(() => {
    userService.fetchUserProfile(sessionData.id).then(([response, error]) => {
      if (response) {
          setInviteData({
            ...inviteData,
            reason_to_join: response.profile && response.profile.reason_to_join ? response.profile.reason_to_join : ""
          })
      }else if (error) {
        dispatch(alertActions.error(error.response));
      }
    })
  }, [])


  useEffect(() => {
    if (userData && userData.id) {
      getTrustScore(userData.id);
      setInviteData({ ...userData, trustscore });
    } else {
      setInviteData(prevState.current);
    }
  }, [userData, getTrustScore, trustscore])

  const addTrustScore = useCallback((trustScore , id) => {
    hide();

    if(trustScore !== '' && trustScore !== undefined){
      dispatch(loaderAction.loader(true));
      const data = {"user": id, "trustscore": trustScore };
      userService.userTrustScore(data).then(([response , error]) => {
        if(response){
          refreshList();
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  },[dispatch , hide ,  refreshList])

  const closeModal = useCallback((formData) => {
      hide();
      if (formData && formData.id) {
        userService.updateUser(formData.id, formData).then(([response , error]) => {
          if(response){
            addTrustScore(formData.trustscore , response.id);
            if(formData.trustscore === '' && formData.trustscore === undefined) refreshList();
            dispatch(alertActions.success('User updated successfully.'));
          }else if(error){
            dispatch(alertActions.error(error.response));
          }
        });
      } else if(formData){
        userService.inviteExpert(formData).then(([response, error]) => {
          if (response) {
            addTrustScore(formData.trustscore , response.id);
            if(formData.trustscore === '' && formData.trustscore === undefined) refreshList();
            dispatch(alertActions.success('Invitation Email Sent to User'));
            setInviteData(prevState.current);
            // refreshList();
            // hide();
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
        });
      }
    
  }, [hide, refreshList, dispatch , addTrustScore]);

  const fields = [
    [{ label: 'First Name*', name: 'first_name', type: 'text', required: true, placeholder: 'First Name', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Last Name*', name: 'last_name', type: 'text', required: true, placeholder: 'Last Name', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Username*', name: 'username', type: 'text', disabled: userData && userData.username ? true : false, required: true, placeholder: 'Username', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Email Address*', name: 'email', type: 'email', disabled: userData && userData.email ? true : false, required: true, placeholder: 'Email Address', sm: 12, md: 6, lg: 6, xl: 6 }],
    { label: 'Trust Score*', permission: 'qrata.trustscore_access' , type: 'number', name: 'trustscore', minValue: 50, maxValue: 500, placeholder: 'Trust Score', sm: 12, md: 12, lg: 12, xl: 12, },
    { label: 'Custom Message', name: 'custom_message', type: 'textarea', sm: 12, md: 12, lg: 12, xl: 12},
    { label: 'Why I am involved with Qrata?', name: 'reason_to_join', type: 'textarea', sm: 12, md: 12, lg: 12, xl: 12},
  ]

  const editFields = [
    ...fields.slice(0,-2)
  ]

  return (
    <Container fluid>

      {isVisible ?
        <ModalForm
          show={isVisible}
          onHide={(e) => closeModal(e)}
          data={inviteData}
          fields={userData && userData.id ? editFields : fields}
          title={userData && userData.id ? 'Edit Expert' :'Invite New Expert'}
          button={userData ? 'Save' : 'Send Invite'}
        />
        : null}
    </Container>
  );
}

export default InvitesForm;