import React from 'react';
import ReactQuill from 'react-quill';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HasPermission } from '../HasPermission';


const HtmlField = ({ label, permission, id, md, sm, lg, xs, xl,placeholder, name, opts, value, handleEvent, }) => {

    const errorMessage = 'Please fill the ' + label;

   
    const handleChange = (e) => {
        value = String(e)
        const data = { name, value};
        handleEvent(data);
    }

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        [{ 'link': [] }]
     ];
  
     const modules = {
        toolbar: {
           container: toolbarOptions
        }
     }

    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}>
                        {label ? <Form.Label >{label}</Form.Label> : ''}
                        <div style = {{ border: '1px solid lightgrey'}}>
                            <ReactQuill
                            name={name} 
                            value={value}
                            placeholder={placeholder}
                            onChange={(e) => handleChange(e)}
                            className=''
                            {...opts}
                            style={{ 'height': '250px' }}
                            modules={modules}
                            />
                        </div>
                     <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

HtmlField.propTypes = {
    value: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.any,
    label: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    maxlength: PropTypes.any,
    permission: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired,
    content: PropTypes.string,

};

HtmlField.defaultProps = {
    id: '',
    value: '',
    name: '',
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: '',
    permission: '',
    type: 'text',
    label: '',
    maxlength: '',
    opts: [],
    content:'',
};
export default HtmlField;