import React , {  useState  , useCallback} from "react";
import { ButtonGroup, Col, Container, Row, ToggleButton,OverlayTrigger, Popover } from 'react-bootstrap';
import TooltipHover from "../../shared/TooltipHover";
import * as memberService from '../../services/member';
import * as loaderAction from '../../actions/loader';
import { useDispatch } from "react-redux";
import MemberForm from "./MemberForm";
import { useBoolean } from "../../hooks/basic";
import * as alertActions from '../../actions/alert';
import ConfirmModal from "../../shared/ConfirmModal";
import { Storage, UserRole, MemberType, dateOnlyFormat } from "../../helpers";
import AsyncAppTable from "../../shared/AsyncAppTable";
import SearchField from '../../shared/SearchField';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MemberList = () => {

  const dispatch = useDispatch();
  const [ members , setMembers ] = useState([]);
  const [memberData , setMemberData] = useState({});
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [id , setId] = useState('');
  const [pageCount, setPageCount] = React.useState(0)
  const fetchIdRef = React.useRef(0);
  const [loading , setLoading ] = useState(false);
  const [userType, setUserType] = useState(MemberType.getType(MemberType.ACTIVE.id));
  const [resultCount, setResultCount] = useState(0)
  const [search, setSearch] = useState('')
  const [callFetch, setCallFetch] = useState(false);

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField="", filter }) => {
    const fetchId = ++fetchIdRef.current
    
    setLoading(true);
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        let offset = pageIndex * pageSize;
        dispatch(loaderAction.loader(true));
        memberService.memberListing(userType.value, pageSize, offset, userType.membership_plan, sortedField, filter, search).then(([response , error]) => {
          if(response){
            setMembers(response.results);
            setPageCount(Math.ceil(response.count / pageSize))
            setResultCount(response.count)

          }else if(error){
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
        setLoading(false);
        setCallFetch(false)
      }
    }, 100)
  }, [dispatch , userType.value, userType.membership_plan, search])

  const renderEmails = (emails) => (
    <div>{emails.map(el => (
      <div className="">
        {el.email}
        <> <TooltipHover type='icon' iconClass={el.verified ? 'text-primary' :'text-danger' } icon={el.verified ? 'check-circle' : 'info-circle'} message={el.verified ? 'Verified' : 'Not Verified'} /> <br /></>
      </div>
    ))}
    </div>
  )

  const columns = [
    {
      Header: "Action",
      accessor: "action",
      sortingOff: true,
      className: "action-l",
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='edit' iconClass='default' message='Edit Member' handleEvent={() => openModal(cell.row.original)}  />
          {!cell.row.original.active ? <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Member' handleEvent={() => openConfirmModal(cell.row.original.user_id)} /> : ''}
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "user__first_name",
      sortType: "basic",
      filtering:true,
      Cell: (row) => (<div>{row.row.original.first_name + ' ' + row.row.original.last_name}</div>)
    },
    {
      Header: "Username",
      accessor: "user__username",
      sortType: "basic",
      filtering:true,
      Cell: (row) => (row.row.original.username)
    },
    {
      Header: "Email",
      accessor: "user__email",
      sortType: "basic",
      filtering: true,
      Cell: (cell) => (<div>
        {cell.row.original.email}&nbsp;
        {cell.row.original.all_emails.length > 0 &&
          <OverlayTrigger
            trigger="click"
            overlay={
              <Popover id="popover-basic" className="p-3">
                {renderEmails(cell.row.original.all_emails)}
              </Popover>
            }
          >
            <FontAwesomeIcon className='default' icon="info-circle" />
          </OverlayTrigger>
        }
      </div>)
    },
    {
      Header: "Membership",
      accessor: "package_id",
      sortType: "basic",
      sortingOff: true,
      Cell: (row) => (<div>{row.row.original.package_id === 1 ? 'Silver' : row.row.original.package_id === 2 ? 'Gold' : row.row.original.package_id === 3 ? 'Platinum' : ''}</div>)
    },
    {
      Header: "Duration",
      accessor: "Duration",
      sortType: "basic",
      sortingOff: true,
      Cell: (row) => (<div>{row.row.original.duration ? Math.round(row.row.original.duration / 30) : 'No Duration'}</div>)
    },
    {
      Header: "Invited By",
      accessor: "user__invited_member_id__invitee__first_name",
      sortType: "basic",
      className: 'text-nowrap',
      Cell: (row) => (row.row.original.invited_by)
    },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      className: 'text-nowrap',
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
    },
  ];


  const deleteMember = useCallback((mId) => {
      memberService.deleteMembers(mId).then(([response , error ]) => {
          dispatch(alertActions.success('Member Deleted Successfully.'));
          // fetchData(defaultLimit,0)
          setCallFetch(true);
         if(error){
          dispatch(alertActions.error(error.response));
        }
      })
  },[dispatch])

  const openModal = useCallback((data) => {
    if(data !== {}) setMemberData(data)
    showModal();
  }, [showModal]);


   const closeModal = useCallback(() => {
     setMemberData({});
     hideModal();
   },[hideModal])

   const openConfirmModal = (userId) => {
    setId(userId);
    showConfirm();
  };

  const closeConfirmModal = () => {
    setId('');
    hideConfirm();
  };

  const acceptConfirmation = useCallback(() => {
    deleteMember(id);
    hideConfirm();
  },[deleteMember , id , hideConfirm])

  return (
    <>
    <MemberForm data={memberData} isVisible={isVisible} hide={closeModal} output={() => setCallFetch(true)} />
    <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={acceptConfirmation} title={'Do you want delete this member ? '} />
    <Container fluid className="px-0">
      <Row className="no-gutters">
        <Col md={4} className="mb-md-0 mb-3">
        <h4 >Members List </h4>
        </Col>
        <Col md={3} className="mb-md-0 mb-2">
          <ButtonGroup toggle>
            {Object.values(MemberType).map((type, idx) => {
              return type.id ? (
                
                <ToggleButton key={idx} type="radio" variant="light" name="memberType"
                  value={type.id} checked={userType.id === type.id}
                  onChange={(e) => setUserType(MemberType.getType(type.id))}>
                  {type.title}
                </ToggleButton>
              ) : ''
            }
            )}
          </ButtonGroup>
        </Col>
        <SearchField md={4}  xs={10} action={setSearch}/>
        <Col md={1} xs={2} className="text-right">
          {Storage.getSession().group.includes(UserRole.SUPERADMIN.id) || Storage.getSession().group.includes(UserRole.ADMIN.id) ?
          <TooltipHover type='button' btnSize='md' variant='primary' btnIcon='plus' iconClass='text-white' message='Add Member' handleEvent={() => openModal(null)}  />
           
           :''}
         
        </Col>
      </Row>
      <AsyncAppTable
        columns={columns}
        data={members}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        filtering={true}
        callFetch={callFetch} />
    </Container>
    </>
  );
}

export default MemberList;