export const REACT_SELECT_BASIC_CUSTOM_STYLE = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#12493d' : '#898989',
        padding: 10,
        background: state.isSelected ? '#FFF' : '#FFF',
        fontWeight:state.isSelected ? 'bold' : 'normal',
    }),
    input: () => ({
        paddingTop: 5,
        width: 220,
    }),
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? '#ced4da' : '#ced4da',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #ced4da',
        }
    }),
    groupHeading: (base, state) => ({
        ...base,
        paddingTop: 15,
        paddingBottom: 15,
        backgroundColor: '#EBECF0',
        FontWeight: 'bold',
        marginTop: -5,

    }),
    group: (base, state) => ({
        ...base,
        padding: 0,


    }),
    multiValue: (base, state) => ({
        ...base,
        background: '#12493d',
        color: 'white',
        height: 24,
        borderRadius: 8,
        fontSize: 12,
        paddingBottom: 0,
        paddingRight: 7,
        paddingLeft: 14,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    multiValueRemove: (base, state) => ({
        color: 'white',
        background: 'transparent',
    }),
    multiValueLabel: (base, state) => ({
        color: 'white',
        background: 'transparent',
    }),

}