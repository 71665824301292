import React, { useEffect, useState, useCallback } from "react";
import {  Container, Row, Col, ButtonGroup, ToggleButton } from 'react-bootstrap';
import AppTable from "../../shared/AppTable";
import * as faqService from '../../services/faq';
import { useBoolean } from "../../hooks/basic";
import FaqForm from "./faqForm";
import TooltipHover from "../../shared/TooltipHover";
import { useDispatch } from "react-redux";
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import ConfirmModal from "../../shared/ConfirmModal";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons"
import { dateOnlyFormat } from "../../helpers";
import SearchField from '../../shared/SearchField';

const Faqs = () => {

  const dispatch = useDispatch();
  const [faqAllData, setFaqAllData] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [faqData, setFaqData] = useState({});
  const [statusVal, setStatusVal] = useState(1);
  const [confirm, setConfirm] = useState({show:false, id:0});
  const [search, setSearch] = useState('')


  const getfaqList = useCallback((status) => {
    status =status||1;
    setStatusVal(status);
    dispatch(loaderAction.loader(true));
    faqService.fetchAll(status).then(([response, error]) => {
      if (response) {
        setFaqAllData(response.results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [dispatch])

  useEffect(() => {
    getfaqList();
  }, [getfaqList])


  const columns = [
    {
      Header: "Action",
      accessor: "action",
      filteringOff:true,
      className: "text-center",
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' iconClass='default' btnSize='sm' variant='link' btnIcon='edit' message='Edit FAQ' handleEvent={() => openModal(cell.row.original)} />
          {statusVal === 2 ? (
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete FAQ' handleEvent={() => setConfirm({show:true, id:cell.row.original.id})} />
          ) : '' }
          <TooltipHover type='button' placement='bottom' variant='link' iconClass={statusVal === 1 ? '' : 'text-secondary' } btnIcon={faToggleOn} message={statusVal === 1 ? 'Disable FAQ' : 'Enable FAQ' } handleEvent={() => handleChangeStatus(cell.row.original.id, cell.row.original.status)} />
        </div>
      ),
    },
    {
      Header: "Question",
      accessor: "question",
      sortType: "basic",

    },
    {
      Header: "Answer",
      accessor: "answer",
      className:'oneline-text-p',
      dangerouslySetInnerHTML:true
    },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
    },

  ];

  const openModal = useCallback((data) => {
    setFaqData(data);
    showModal();
  }, [showModal]);


  const handleDelete = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    faqService.deleteFaq(id).then(([response, error]) => {
      const list = faqAllData.filter(obj => obj.id !== id);
      setFaqAllData(list);
      if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
    setConfirm({show:false, id:0})
    
  }, [faqAllData, dispatch]);
  
  const handleChangeStatus = useCallback((id, status) => {
    dispatch(loaderAction.loader(true));
    status = (status === 1 ? 2 : 1)
    const data = {
      status:status,
    }
    faqService.updateFaq(id, data).then(([response, error]) => {
      const list = faqAllData.filter(obj => obj.id !== id);
      setFaqAllData(list);
      if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [faqAllData, dispatch]);

  const status = [
    {
      Text:'Enabled',
      id:1
    },
    {
      Text:'Disabled',
      id:2
    }
  ]

  return (
    <Container fluid>
      {isVisible ? (
      <FaqForm data={faqData} isVisible={isVisible} hide={hideModal} output={getfaqList} />
      ) : (
        <>
      <ConfirmModal  show={confirm.show} onHide={() => setConfirm({show:false, id:0})} acceptConfirm={(id) => handleDelete(confirm.id)} title='Are you sure you want to delete?' />

        <Row className="no-gutters" >
        <Col md={2} className="mb-md-0 mb-3" >
            <h4> FAQs </h4>
          </Col>
            <Col md={5} className="text-center mb-md-0 mb-2">
            <ButtonGroup toggle>
              {Object.values(status).map((type, idx) => {
                return type.id ? (
                  <ToggleButton key={type.id}type="radio" variant="light" name="faqStatus"
                    value={type.id}
                    onChange={(e) => getfaqList(type.id)}
                    checked = {statusVal === type.id}
                    >
                    {type.Text}
                  </ToggleButton>
                  
                ) : ''
              }
              )} 
            </ButtonGroup>
          </Col>
          <SearchField md={4} xs={10} action={setSearch} />
          <Col md={1} xs={2} className="text-right">
        <TooltipHover type='button' btnSize='md' variant='primary' message='Add FAQ' btnIcon='plus' iconClass='text-white' handleEvent={() => openModal(null)}  />
          </Col>
        </Row>
      <AppTable columns={columns} globalFilter={search}  data={faqAllData} /> 
      </>
      )
          }
    </Container>
  );
}

export default Faqs;