import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Storage } from '../../helpers/storage';

// restricted = false meaning public route accessible evene after login. Like About Us, Contact Us or other footer links
// restricted = true meaning restricted route can't be accessed after login. like foprgot password, reset etc

export const PublicRoute = ({component: Component, restricted, ...rest}) => (
        <Route {...rest} render={props => (
          (Storage.getSession() !== null && restricted && Storage.getSession().permissions.includes('qrata.back_office_access')) ? <Redirect to="/dashboard" /> :  <Component {...props} />
        )} />   
    );