import React , { useEffect, useState , useCallback , useRef} from "react";
import { Container } from 'react-bootstrap';
import * as buildoutService from '../../services/buildout';
import ModalForm from "../../shared/ModalForm";
import {  useDispatch } from "react-redux";
import * as alertActions from "../../actions/alert";

const BuildoutForm = ({data , isVisible , hide , output}) => {
 
  const dispatch = useDispatch();
  const [topicData , setTopicData ] = useState({
    topic: ''
  });
  const prevState = useRef(topicData);

  useEffect(() => {
    if(data){
      setTopicData(data);
    }else {
      setTopicData(prevState.current);
    }
  },[data])

  const handleResponse = useCallback(([response, error]) => {
    if(response){
      output();
      hide();
    } else if(error){
      dispatch(alertActions.error(error.response));
    }
  }, [hide, output , dispatch]);


  const closeModal = useCallback((data) => {
    hide();
    if(data !== ''){
      if(data.id){
        buildoutService.updateTopic(data.id , data).then(handleResponse);
      }else if(data.topic){
        buildoutService.addTopic(data).then(handleResponse);
      }
    }
  }, [hide, handleResponse]);

  

  const fileds = [
    { label: 'Topic Name* ', name: 'topic', type: 'text', required: true, placeholder: 'Topic', sm: 12, md: 12, lg: 12, xl: 12 }
  ]


  return (
    <>
    <Container fluid>
      {isVisible ? 
       <ModalForm
      show={isVisible}
      onHide={(e) => closeModal(e)}
      data={topicData}
      fields={fileds}
      title='Topic'
      /> 
      : null }
    </Container>
    </>
  );
}

export default BuildoutForm;