import React , { useCallback} from 'react';
import ModalForm from '../../shared/ModalForm';
import * as feedbackService from '../../services/feedback';
import { useDispatch } from 'react-redux';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { Storage } from '../../helpers';

const Feedback = ({isVisible , hide}) => {

    const dispatch = useDispatch();

    // Create Feedback
    const createFeedback = useCallback((feedback) => {
        if(feedback.title && feedback.description){
            dispatch(loaderActions.loader(true));
            const data = new FormData();
            data.append('title' , feedback.title);
            data.append('description' , feedback.description);
            data.append('created_by' , Storage.getSession().id);
            if(feedback.file) data.append('attachment' , feedback.file);
            feedbackService.createFeedback(data).then(([response , error]) => {
                if(response){
                    dispatch(alertActions.success('Feedback Submitted Successfully.'))                  
                }else if(error){
                    dispatch(alertActions.error(error.response))
                }
                dispatch(loaderActions.loader(false));
            })
        }
    },[dispatch])


     // CLOSE POPUP MODAL & CALL CREATE AND UPDATE FUNC
   const closeModal = useCallback((data) => {
       if(data){
        createFeedback(data);
       }
    hide();
  }, [hide , createFeedback]);

  // FORM FIELDS
  const fields = [
    [{ label: 'Title*', name: 'title', type: 'text', required: true, placeholder: 'Title', sm: 12, md: 12, lg: 12, xl: 12 }],
    [{ label: 'Description*', name: 'description', rows: '4', type: 'textarea', required: true, placeholder: 'Description', sm: 12, md: 12, lg: 12, xl: 12 }],
    [{ label: 'File', name: 'file', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', type: 'file', placeholder: 'Select File', sm: 12, md: 12, lg: 12, xl: 12 }],
  ];

    return (
        <>
        {isVisible ?
            <ModalForm
              modalClass={''}
              show={isVisible}
              onHide={(e) => closeModal(e)}
              data={[]}
              fields={fields}
              title={'Feedback Form'}
              button={'Submit'}
            />
            : null}
            </>
    )
}
export default Feedback;