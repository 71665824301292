import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { users } from '../reducers/users';
import { auth } from '../reducers/auth';
import { catalog } from '../reducers/catalog';
import { criteria} from '../reducers/criteria';
import { alert } from '../reducers/alert';
import { loader } from '../reducers/loader';
import { application } from '../reducers/application';
import { assets } from '../reducers/assets';

export const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    users,
    catalog,
    criteria,
    alert,
    loader,
    application,
    assets
});
