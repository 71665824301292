import React from "react";
import { Container, Button, Form } from "react-bootstrap";
import FormBlock from '../../shared/FormBlock';
import { Validators } from "../../utility/Validators";


const DBSearchComponent = () => {
    const [formData, setFormData] = React.useState({
        name: 'Akash',
        email: '',
        password: '',
        file: '',
        age: '',
        checkbox: true,
        gender: '',
        comment: '',
        date: '',
        trans: ''
    });

    const handleChange = (e) => {
        setFormData(e);
    }


    const fileds1 = [[{
        label: 'Name', name: 'name', type: 'text', md: 6, sm: 12, lg: 6, xl: 6, placeholder: 'Enter the Name', value: formData.name, readOnly: true, maxlength: 10, validators:
            [
                { check: Validators.required, message: 'This field is required' }
            ]
    }, {
        label: 'Email ID', name: 'email', type: 'email', md: 6, sm: 12, lg: 6, xl: 6, placeholder: 'Enter the Email', value: formData.email, validators:
            [
                { check: Validators.required, message: 'This field is required' }
            ]
    },
    {
        label: 'Password', name: 'password', type: 'password', md: 12, sm: 12, lg: 12, xl: 12, placeholder: 'Password', maxlength: 10, value: formData.password
    }],
    { id: 'Select File', name: 'file', type: 'file', label: 'Select File', value: formData.file.name, fileFormat: "image/*" },
    { label: 'Age', name: 'age', type: 'number', placeholder: 'Enter Your Age', value: formData.age, required: true, minValue: 0, maxValue: 5, },
    { name: 'Checkbox123', type: 'checkbox', required: true, checked: formData.checkbox, value: false, placeholder: 'Agree', label: 'ARE YOU SURE TO SIGIN ?' },
    { label: 'Male', name: 'gender', type: 'radio', checked: formData.gender, value: 'Male' },
    { label: 'Female', name: 'gender', type: 'radio', checked: formData.gender, value: 'Female' },
    { label: 'Comment', name: 'comment', type: 'textarea', value: formData.comment, placeholder: 'Enter comments', required: true },
    { label: 'Select Date', name: 'date', type: 'date', placeholder: '15/05/1999', value: formData.date },
    { id: 'Disable', name: 'disbaled', type: 'text', value: 'Disabled TextBox', disabled: true },
    { label: 'Trans', name: 'trans', type: 'select', value: formData.select, disabled: false, placeholder: 'Select Trans', options: ['Car', 'Bus', 'Train', 'Plain'] }]
    return (
        <div id="inner-wrapper">
            <Container fluid>
                <h1> DB Search of Personnel Results </h1>
                <Form >
                    <FormBlock key={'test'} fields={fileds1} getData={(e) => handleChange(e)} formData={formData} />
                    <Button type="submit">Submit</Button>
                </Form>
            </Container>
        </div>
    );

}

export default DBSearchComponent;
