import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'promotion/';

//send admin request
export async function sendEditorPromotionRequest(id) {
  return await handleApi(axiosClient.post(`${BASE}${id}/offer-admin-promotion/`));
}

export async function verifyPromotionOffer(data) {
  return await handleApi(axiosClient.post(`${BASE}verify-promotion-offer/` , data));
}

export async function fetchAllPromotionRequests(pageSize, pageNumber, search = '', ordering = '', filter, status) {
  return await handleApi(axiosClient.get(`${BASE}?limit=${pageSize}&offset=${pageNumber}&status=${status}&search=${search}&ordering=${ordering}&${filter}`))
}

export async function updatePromotionStatus(id, data) {
  return await handleApi(axiosClient.patch(`${BASE}${id}/`, data))
}

