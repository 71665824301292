import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'catalogs/';

export async function add(data) {
  return await handleApi(axiosClient.post(BASE, data));
}

export async function update(id, data) {
  return await handleApi(axiosClient.put(`${BASE}${id}/`, data));
}

export async function fetchAll(typeId, parentId , limit , offset, ordering="", filters, search='') {

  return await handleApi(axiosClient.get(`${BASE}?catalog_type=${typeId}&parent=${parentId}&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filters}`));
}

export async function fetchOne(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/`));
}

export async function remove(id) {
  return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}

export async function getMajorCatalog(user_catalog=false) {
  return await handleApi(axiosClient.get(`catalogdto/major_catalog/${user_catalog? '?user_catalog=true':''}`));
}

export async function getMinorCatalog(id, user_catalog = false) {
  return await handleApi(axiosClient.get(`catalogdto/${id}/minor_catalog/${user_catalog? '?user_catalog=true':''}`));
}

export async function getCategoryCatalog(id, user_catalog=false) {
  return await handleApi(axiosClient.get(`catalogdto/${id}/category/${user_catalog? '?user_catalog=true':''}`));
}
