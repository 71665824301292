import { handleApi, HEADERS } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'application/';

// APPLICATION COMMENT API 
export async function createComment(data) {
  return  handleApi(axiosClient.post(`${BASE}create_comment/` , data));
}

export async function getComments(id) {
  return  handleApi(axiosClient.get(`${BASE}${id}/get_comment/`));
}

// GET ALL APPLICATION LIST BY STATUS
export async function getAllApplication(status) {
  return  handleApi(axiosClient.get(`${BASE}list_all/${status ? '?status='+status : ''}`));
}

// GET LIST APPLICATION
export async function getListApplication() {
  return  handleApi(axiosClient.get(`${BASE}list/`));
}

// GET APPLICATION DATA BY ID
export async function getAppData(id) {
  return  handleApi(axiosClient.get(`${BASE}${id}/get_app_data/`));
}

// VERIFY APPLICATION
export async function applicationVerify(data) {
  return  handleApi(axiosClient.post(`${BASE}verify/` , data));
}

// CREATE APPLICATION 
export async function createApplication(data) {
  return  handleApi(axiosClient.post(`${BASE}`, data));
}

// UPDATE APPLICATION
export async function updateApplication(id , data) {
  return  handleApi(axiosClient.put(`${BASE}${id}/`, data));
}

// GET APPLICATION BY ID
export async function getApplicationById(id) {
  return  handleApi(axiosClient.get(`${BASE}${id}/`));
}

// APPLICATION SPECIALIATION API'S
export async function createSpecialiazation(data) {
  return  handleApi(axiosClient.post(`${BASE}create_specialization/`, data));
}

// UPDATE SPECIALIAZATION
export async function updateSpecialiazation(data) {
  return  handleApi(axiosClient.post(`${BASE}update_specialization/`, data));
}

export async function getSpecialiazationById(id) {
  return  handleApi(axiosClient.get(`${BASE}${id}/get_specialization/`));
}

// APPLICATION EDUCATION API'S
export async function createEducation(data) {
  return  handleApi(axiosClient.post(`${BASE}create_education/`, data , {headers: HEADERS.FILE()}));
}

export async function getExistingEducation(id, bio_educations=false) {
  return  handleApi(axiosClient.get(`educationdto/${id}/education/?${bio_educations?'bio_educations='+bio_educations:''}`));
}

export async function getAppEducation(id) {
  return  handleApi(axiosClient.get(`${BASE}${id}/get_education/`));
}

export async function addExitingEducation(data) {
  return  handleApi(axiosClient.post(`${BASE}add_existing_education/`, data , {headers: HEADERS.FILE()}));
}

export async function deleteEducation(data) {
  return  handleApi(axiosClient.post(`${BASE}delete_education/`, data));
}

// APPLICATION JOB API'S

export async function createJob(data) {
  return  handleApi(axiosClient.post(`${BASE}create_job/`, data , {headers: HEADERS.FILE()}));
}

export async function getExistingJob(id, bio_jobs=false) {
  return  handleApi(axiosClient.get(`jobdto/${id}/job/?${bio_jobs?'bio_jobs='+bio_jobs:''}`));
}

export async function addExistingJob(data) {
  return  handleApi(axiosClient.post(`${BASE}add_existing_job/` , data));
}

export async function getJobById(id) {
  return  handleApi(axiosClient.get(`${BASE}${id}/get_jobs/`));
}

export async function deleteJob(data) {
  return  handleApi(axiosClient.post(`${BASE}delete_job/` , data));
}

// APPLICATION ACCOMPLISHMENT API'S

export async function getAccomplishmentById(id) {
  return  handleApi(axiosClient.get(`${BASE}${id}/get_accomplishment/`));
}

export async function createAccomplishment(data) {
  return  handleApi(axiosClient.post(`${BASE}create_accomplishments/`, data , {headers: HEADERS.FILE()}));
}

export async function getExistingAccomplishment(id, bio_accomplishments=false) {
  return  handleApi(axiosClient.get(`/accomplishmentdto/${id}/accomplishment/?${bio_accomplishments?'bio_accomplishments='+bio_accomplishments:''}`));
}

export async function addExistingAccomplishment(data) {
  return  handleApi(axiosClient.post(`${BASE}add_existing_accomplishment/`, data));
}

export async function deleteAccomplishment(data) {
  return  handleApi(axiosClient.post(`${BASE}delete_accomplishment/`, data ));
}

export async function getSearchSuggestions(query) {
  return  handleApi(axiosClient.get(`catalogdto/catalog/?search=${query}`));
}
