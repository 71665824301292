import React, { useCallback, useEffect, useState } from 'react';
import FormBlock from '../../../shared/FormBlock';
import * as applicationService from '../../../services/application';
import * as bioService from '../../../services/bio';
import { Card, Col, Row } from 'react-bootstrap';
import { monthYearFormat } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipHover from '../../../shared/TooltipHover';
import { HasPermission } from '../../../shared/HasPermission';
import * as loaderAction from '../../../actions/loader';
import * as alertActions from '../../../actions/alert';
import { useDispatch } from 'react-redux';
import { Status } from '../../../helpers/constants';

const BioEducation = ({ bioId, applicationId, education, getData, disable }) => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    education_id: '',
  })
  const [existingEdu, setExistingEdu] = useState([]);
  const [educationStatus, setEducationStatus] = useState(false);
  const [educationId, setEducationId] = useState('');

  // GET ALL EXISTING EDUCATION
  const existingAllEdu = useCallback(() => {
    if (applicationId !== '') {
      dispatch(loaderAction.loader(true));
      applicationService.getExistingEducation(applicationId, true).then(([response, error]) => {
        if (response) {
          setExistingEdu(response);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [applicationId, dispatch])

  useEffect(() => {
    if (applicationId !== '') {
      existingAllEdu();
    }
  }, [existingAllEdu, applicationId])

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

// DELETE EDUCATION
  const deleteEducation = useCallback((id) => {
    if (id) {
      dispatch(loaderAction.loader(true));
      const data = { education_id: id };
      bioService.deleteEducation(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Deleted Successfully.'));
          getData();
          existingAllEdu();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [getData, existingAllEdu, dispatch])

  // ADD EDUCATION IN BIO 
  const addEducation = useCallback(() => {
    if (formData.education_id !== '') {
      dispatch(loaderAction.loader(true));
      const data = { education_id: formData.education_id, bio_id: bioId, status: 2 };
      bioService.addExistingEducation(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Added Successfully.'));
          setFormData({education_id: '',})
          getData();
          existingAllEdu();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [formData, dispatch, getData, existingAllEdu, bioId])

  // EDUCATION DROPDOWN LISTING
  const form1 = [
    [{ label: 'Type', required: true, name: 'education_id', group:true , type: 'multi-select', placeholder: 'Select Education', options: existingEdu.length > 0 ? existingEdu : [], sm: 12, md: 10, lg: 10, xl: 10 },
    { placeholder: 'Save', disabled: existingEdu.length > 0 ? false : true, handleEvent: () => addEducation(), type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 2, lg: 2, xl: 2 }]];

// EDUCATION STATUS 
  const toggleChange = (e) => {
    const { name, checked } = e.target;
    setEducationId(name);
    setEducationStatus(checked);
  }


  useEffect(() => {
    if (educationId !== '') {
      const data = { bio_degree_id: educationId, status: educationStatus ? 1 : 3 }
      bioService.verifyEducation(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success(`Education ${educationStatus ? 'Enable' : 'Disable'} Successfully.`));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }, [educationId, educationStatus, dispatch])


  return (
    <>
      <HasPermission permission='qrata.add_biodegree'>
        <FormBlock fields={form1} getData={(e) => handleChange(e)} formData={formData} />
      </HasPermission>
      {education.length > 0 ?
        <Card className="mt-1 border-0">
          {education.map((item, index) => (
            <Card.Body key={index} className="pb-0">
              <Row key={index}>
                <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item.degree[0].university}>
                  {item.degree[0].university}
                </Col>
                <Col md={3} sm={12} className="text-warning text-left font-weight-bold text-truncate overflow-hidden" title={item.degree[0].course}>
                  {item.degree[0].course}
                </Col>
                <Col md={5} sm={12} className="text-info font-weight-bold font-style-italic text-right">
                  {monthYearFormat(item.degree[0].degree_month_year)}
                  <a className=" text-danger text-decoration-none ml-2" target="_blank" rel="noopener noreferrer" href={item.degree[1] ? item.degree[1].file_url : '#'}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>
                </Col>
                <Col md={1} sm={12}>
                  <HasPermission permission='qrata.delete_biodegree'>
                    <TooltipHover type='link' btnSize='sm' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Education' handleEvent={() => deleteEducation(item.degree_id)} />
                  </HasPermission>
                  <HasPermission permission='qrata.change_biodegree'>
                    {item.degree_status === Status.APPROVED.value ? '' : <>
                      <label className="switch">
                        <input type="checkbox" name={item.degree_id} onChange={(e) => toggleChange(e)} disabled={disable} />
                        <span className="slider round"></span>
                      </label></>}
                  </HasPermission>
                </Col>
              </Row>
            </Card.Body>
          ))} </Card>
        : 'No data found.'}
    </>
  )
}
export default BioEducation;