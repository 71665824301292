import React, { useMemo, useEffect } from "react";
import { useTable, useSortBy, useExpanded, usePagination, useFilters, useGlobalFilter } from "react-table";
import { Table, Pagination, DropdownButton, Dropdown, Row, Col } from "react-bootstrap";


const defaultPropGetter = () => ({});

const AppTable = ({ columns, data, subComponent, getHeaderProps = defaultPropGetter, globalFilter,
  getColumnProps = defaultPropGetter, getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter, }) => {

  // Define a default UI for filtering
  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    // const count = preFilteredRows.length

    return (
      <input className="form-control bg-transparent text-white mb-2 border-secondary"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    )
  }

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      initialState: { pageIndex: 0 },
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(globalFilter || undefined)
  }, [globalFilter, setGlobalFilter]);
  
  return (
    <>
      <div className="table-scroll t table-sm-responsive mb-0">
        <Table className="mb-0" striped bordered hover {...getTableProps()}>
          <thead className="border-0 bg-light text-info">
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={'coloumnHeaders'}>
                <tr {...headerGroup.getHeaderGroupProps()}  className="bg-dark text-white">
                  {headerGroup.headers.map((column) => (
                    <th  {...column.getHeaderProps([
                      {
                        className: `bg-dark text-white py-1 ${column.className}`,
                        style: column.style,
                      },
                      getColumnProps(column),
                      getHeaderProps(column),
                      column.getSortByToggleProps()
                    ])}
                    >
                      {column.render("Header")}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <span className='d-block sorting-up'></span>
                          : <span className='d-block sorting-down'></span>
                        : ""}
                    </th>
                  ))}
                </tr>
                <tr  {...headerGroup.getHeaderGroupProps()} key='two' className="bg-dark text-white">
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps([
                      {
                        className: `bg-dark text-white py-1 ${column.className}`,
                        style: column.style,
                      },
                      getColumnProps(column),
                      getHeaderProps(column),
                    ])}
                    >
                      {!column.filteringOff ? column.render('Filter') : null}
                    </th>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>

            {page.length === 0 ? <><tr ><td colSpan={columns.length} className="text-center py-1">No data found.</td></tr></> : null}
            {page.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (

                <React.Fragment key={'coloumn' + i}>
                  <tr key={'row' + i} {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell, i) => {

                      return (
                        cell.column.dangerouslySetInnerHTML ? (
                          <td className="py-1"  {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                              dangerouslySetInnerHTML: { __html: cell.value }
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),

                          ])}
                          ></td>
                        ) : (
                            <td  className="py-1"   {...cell.getCellProps([
                              {
                                className: cell.column.className,
                                style: cell.column.style,
                              },
                              getColumnProps(cell.column),
                              getCellProps(cell),

                            ])}
                            >{cell.render('Cell')}</td>
                          )

                      )
                    })}
                  </tr>

                  {
                    row.isExpanded ? (
                      <tr>
                        <td colSpan={visibleColumns.length} className="px-4 inner-table">
                          {subComponent({ row, rowProps, visibleColumns })}
                        </td>
                      </tr>
                    ) : null
                  }

                </React.Fragment>
              )
            })}
          </tbody>
    
        </Table>
      </div>
      <Row className="bg-white no-gutters px-2 py-2">
        <Col md={6} className="d-flex align-items-center">

                <span>
                  Showing {(pageSize * (pageIndex)) + page.length} of ~{data.length} results
                </span>
     
        </Col>
        <Col md={6}>
          
        <Pagination  className="mb-0 float-right">
                  <Pagination.First active={pageIndex === 0} onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />

                  {pageIndex > 1 ? (
                    <>
                      <Pagination.Item key={`page-${0}`} onClick={() => gotoPage(0)}>
                        {1}
                      </Pagination.Item>
                    </>
                  ) : null}

                  {pageIndex > 2 ? <Pagination.Ellipsis /> : null}

                  {pageIndex > 0 ? (
                    <Pagination.Item
                      key={`page-${pageIndex - 1}`}
                      onClick={() => previousPage()}
                    >
                      {pageIndex}
                    </Pagination.Item>
                  ) : null}

                  <Pagination.Item key={`page-${pageIndex}`} active={true}>
                    {pageIndex + 1}
                  </Pagination.Item>
                  {pageCount - 1 > pageIndex ? (
                    <Pagination.Item
                      key={`page-${pageIndex + 1}`}
                      onClick={() => nextPage()}
                    >
                      {pageIndex + 2}
                    </Pagination.Item>
                  ) : null}

                  {pageCount - pageIndex > 3 ? <Pagination.Ellipsis /> : null}

                  {pageCount - pageIndex > 2 ? (
                    <Pagination.Item
                      key={`page-${pageCount - 1}`}
                      onClick={() => gotoPage(pageCount - 1)}
                    >
                      {pageCount}
                    </Pagination.Item>
                  ) : null}
                  <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                  <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                  <DropdownButton variant="light" title={`Show ${pageSize}`}>
                    {[5, 10, 15, 20, 30, 50].map((size) => (
                      <Dropdown.Item key={`pgsize-${size}`} onClick={() => setPageSize(size)}>{size}</Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Pagination>
        </Col>
      </Row>
    </>
  );
};

export default AppTable;
