import React , { useState , useEffect , useCallback }  from 'react';
import AssetsNav from './AssetsNav';
import StepWizard from 'react-step-wizard';
import AssetsForm1 from './AssetsForm1';
import AssetsForm2 from './AssetsForm2';
import AssetsForm3 from './AssetsForm3';
import { useParams } from 'react-router-dom';

const AssetsRate = () => {
  const [isGrey, setIsGrey] = useState();
  const { id } = useParams();
  const [assetId , SetAssetId] = useState('');
  const [note , setNote] = useState('');
  const [enableNextStep , SetEnableNextStep] = useState(false);
  const onStepChange = (stats) => { }

  useEffect(() => {
   if(id !== ''){
    SetAssetId(Number(id));
   }
   return () => {
    SetAssetId('');
  }
  },[id])


  const getAssetId = useCallback((id) => {
    if(id !== '' && id !== undefined && !isNaN(id)){
        SetAssetId(id);
      }
  },[])

  const getNotes = useCallback((value) => {
    if(value !== ''){
      setNote(value)
    }
  },[])

  const getNextStep = useCallback((value) => {
      if(value){
        SetEnableNextStep(value);
      }
  },[])

  let custom = {   enterRight: 'fadein',   enterLeft : 'fadein',   exitRight : 'fadeout',   exitLeft  : 'fadeout' }
  return (
    <div className="bg-white">
      <StepWizard  transitions={custom} initialStep={1} nav={<AssetsNav assetId={assetId} enableNextStep={enableNextStep} />} onStepChange={onStepChange} >
        <AssetsForm1 getId={(e) => getAssetId(e)} getNotes={(e) => getNotes(e)} assetId={assetId} setIsGrey={setIsGrey}/>
        <AssetsForm2 assetId={assetId} getNextStep={(e) => getNextStep(e)} />
        <AssetsForm3 assetId={assetId} note={note} isGrey={isGrey}/>
      </StepWizard>
    </div>
  )
}
export default AssetsRate;
