import React, { useState, useEffect, useCallback } from 'react';
import user from '../../assets/images/default.jpg';
import { Col, Container, Row, Image, Button, Card } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import * as bioService from '../../services/bio';
import { useParams } from 'react-router-dom';
import AccordionForm from '../../shared/AccordionForm';
import BioJob from './bioListing/BioJob';
import BioAccomplishment from './bioListing/BioAccomplishment';
import BioEducation from './bioListing/BioEducation';
import { history } from '../../helpers';
import { useDispatch } from 'react-redux';
import * as loaderAction from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import CommentList from '../../shared/CommentList';

const BioView = () => {
   const dispatch = useDispatch();
   const applicationId = useParams();
   const [bioId, setBioId] = useState('');
   const [bioText, setBioText] = useState('');
   const [bioPic, setBioPic] = useState('');
   const [bioPicId, setBioPicId] = useState('');
   const [bioDisplayName, setBioDisplayName] = useState('');
   const [accomplishment, setAccomplishment] = useState([]);
   const [job, setJob] = useState([]);
   const [education, setEducation] = useState([]);
   const [catalog, setCatalog] = useState('');
   const [commentList, setCommentList] = useState([]);

   // GET BIO ALL DATA
   const getBioData = useCallback(() => {
      if (bioId !== '') {
         dispatch(loaderAction.loader(true));
         bioService.getBioById(bioId).then(([response, error]) => {
            if (response) {
               setCatalog(response.specialization);
               setBioDisplayName(response.bio.display_name ? response.bio.display_name : '');
               setBioId(response.bio.id ? response.bio.id : '');
               setBioText(response.bio.bio_text ? response.bio.bio_text : '');
               setBioPicId(response.bio.bio_photo ? response.bio.bio_photo : '');
               setAccomplishment(response.accomplishment);
               setEducation(response.education);
               setJob(response.job);
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [bioId, dispatch]);

   // GET BIO BY APPLICATION ID 
   const getBioByApplicationId = useCallback(() => {
      if (applicationId.applicationId !== '') {
         dispatch(loaderAction.loader(true));
         bioService.getBio(applicationId.applicationId).then(([response, error]) => {
            if (response && response.results.length > 0) {
                  setBioId(response.results[0].id);
                  getBioData();
            } if(response && response.length === 0){
              dispatch(alertActions.error('Invalid Bio.'));
                history.push('dashboard');
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [applicationId.applicationId, dispatch, getBioData])


   useEffect(() => {
      if (applicationId.applicationId) {
         getBioByApplicationId();
      }
   }, [applicationId.applicationId, getBioByApplicationId])

   const commentsCount  = commentList && commentList.length > 0 ? commentList.length : 0;
   // ACCORDION DATA
   const data = [
      { name: 'Accomplishment', open: true, type: 'component', component: <BioAccomplishment disable={true}  accomplishment={accomplishment && accomplishment.length > 0 ? accomplishment : []}  /> },
      { name: 'Education', open: true, type: 'component', component: <BioEducation disable={true} bioId={bioId} getData={() => getBioData()} education={education} applicationId={applicationId.applicationId} /> },
      { name: 'Jobs', open: true, type: 'component', component: <BioJob disable={true} bioId={bioId} getData={() => getBioData()} job={job} applicationId={applicationId.applicationId} /> },
      { name: `Comments (${commentsCount})`, open: false, type: 'component', component: <CommentList comments={commentList ? commentList : []} /> },
   ]
  
   useEffect(() => {
      if (bioPicId !== '') {
         dispatch(loaderAction.loader(true));
         bioService.getBioPhotoById(bioPicId).then(([response, error]) => {
            if (response) {
               setBioPic(response.file_url ? response.file_url : '');
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [bioPicId, dispatch])


   // GET ALL BIO COMMENTS
   const getBioComments = useCallback(() => {
      if (bioId !== '') {
         dispatch(loaderAction.loader(true));
         bioService.getCommentsBio(bioId).then(([response, error]) => {
            if (response) {
               setCommentList(response.bio_comment);
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderAction.loader(false));
         })
      }
   }, [bioId, dispatch])

   useEffect(() => {
      if (bioId !== '') {
         getBioComments();
      }
   }, [getBioComments, bioId])

   return (
      <Container fluid>
         <Row className="bg-white">
            <Col md={4} className="p-0 bg-dark">
               <Card className="bg-dark p-3 border-0">
                  <Card.Body className="text-center">
                     <Row className="no-gutters">
                        <Col md={12} className="justify-content-center">
                           <span className="user-img m-auto"><Image src={bioPic !== '' ? bioPic : user} roundedCircle /> </span>
                        </Col>
                        <Col md={12} className="py-2">
                           <span className='d-inline-block'>
                                 <span className="h3 text-white">{bioDisplayName ? bioDisplayName : 'Enter Name'} </span>          
                           </span>
                        </Col>
                     </Row>
                     <Card.Text className='text-warning'>
                        {catalog !== '' ? catalog : ''}
                     </Card.Text>
                  </Card.Body>
               </Card>
            </Col>
            <Col md={8} className=' bg-white p-3 positionm-relative'>
                <div className="overflow-auto" style={{ 'height': '300px' }} dangerouslySetInnerHTML={{ __html: bioText }}></div>
            </Col>
         </Row>
         <Row>
            <Col md={12} className='mt-5'>
               <AccordionForm data={data} />
            </Col>
            <Col className='text-right mt-2'>
                  <Button variant="outline-secondary" onClick={() => history.goBack()}>CLOSE</Button>
            </Col>
         </Row>
      </Container>
   )
}
export default BioView;