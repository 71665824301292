import React, { useCallback, useEffect, useState } from 'react';
import FormBlock from '../../../shared/FormBlock';
import * as applicationService from '../../../services/application';
import * as bioService from '../../../services/bio';
import { Card, Col, Row } from 'react-bootstrap';
import TooltipHover from '../../../shared/TooltipHover';
import { monthYearFormat } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HasPermission } from '../../../shared/HasPermission';
import * as loaderAction from '../../../actions/loader';
import * as alertActions from '../../../actions/alert';
import { useDispatch } from 'react-redux';
import { Status } from '../../../helpers/constants';

const BioJob = ({ bioId, applicationId, job, getData, disable }) => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    bio_id: bioId,
    job_id: '',
    status: 4
  })
  const [existingJob, setExistingJob] = useState([]);
  const [jobStatus, setJobStatus] = useState(false);
  const [jobId, setJobId] = useState('');

  // GET ALL EXISTING JOB 
  const existingAllJob = useCallback(() => {
    if (applicationId !== '') {
      dispatch(loaderAction.loader(true));
      applicationService.getExistingJob(applicationId, true).then(([response, error]) => {
        if (response) {
          setExistingJob(response);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [applicationId, dispatch])

  useEffect(() => {
    existingAllJob()
  }, [existingAllJob])

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

// DELETE JOB
  const deleteJob = useCallback((id) => {
    if (id) {
      dispatch(loaderAction.loader(true));
      const data = { job_id: id };
      bioService.deleteJob(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Deleted Successfully.'));
          getData();
          existingAllJob();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [existingAllJob, getData, dispatch])

  // ADD NEW JOB IN BIO
  const addJob = useCallback(() => {
    if (formData.job_id !== '') {
      dispatch(loaderAction.loader(true));
      const data = { job_id: formData.job_id, bio_id: bioId, status: 2 }
      bioService.addExistingJob(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Added Successfully.'));
          setFormData({
            bio_id: bioId,
            job_id: '',
            status: 4
          });
          getData();
          existingAllJob();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [formData, existingAllJob, getData, dispatch, bioId])

  // JOB SLECT DROPDOWN JSON
  const form1 = [
    [{ label: 'Type', required: true, name: 'job_id', group:true , type: 'multi-select', placeholder: 'Select Job', options: existingJob.length > 0 ? existingJob : [], sm: 12, md: 10, lg: 10, xl: 10 },
    { placeholder: 'Save', disabled: existingJob.length > 0 ? false : true, handleEvent: () => addJob(), type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 2, lg: 2, xl: 2 }]];

    // TOGGLE BUTTON CHANGE STATUS
  const toggleChange = (e) => {
    const { name, checked } = e.target;
    setJobId(name);
    setJobStatus(checked);
  }

  useEffect(() => {
    if (jobId !== '') {
      const data = { bio_job_id: jobId, status: jobStatus ? 1 : 3 };
      bioService.verifyJob(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success(`Job ${jobStatus ? 'Enable' : 'Disable'} Successfully.`));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }, [jobId, jobStatus, dispatch])


  return (
    <>
      <HasPermission permission='qrata.add_biojobs'>
        <FormBlock fields={form1} getData={(e) => handleChange(e)} formData={formData} />
      </HasPermission>
      {job.length > 0 ?
        <Card className="mt-1 border-0">
          {job.map((item, index) => (

            <Card.Body key={index} className="pb-0">
              <Row key={index}>
                <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item.job[0].company}>
                  {item.job[0].company}
                </Col>
                <Col md={3} sm={12} className="text-warning text-left font-weight-bold text-truncate overflow-hidden" title={item.job[0].job_title}>
                  {item.job[0].job_title}
                </Col>
                <Col md={2} sm={12}>
                  {item.job[0].is_conflict ? <>Conflict <TooltipHover type='icon' iconClass='ml-1' variant='link' icon='info-circle' message={item.job[0].conflict_reason} handleEvent={() => null} /> </> : 'No Conflict'}

                </Col>
                <Col md={3} sm={12} className="text-info font-weight-bold font-style-italic text-right">
                   {monthYearFormat(item.job[0].experience_to) + ' - ' + (item.job[0].experience_from !== null? monthYearFormat(item.job[0].experience_from):"To Present")}
                  <a className=" text-danger text-decoration-none ml-2" target="_blank" rel="noopener noreferrer" href={item.job[1] ? item.job[1].file_url : '#'}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>
                </Col>
                <Col md={1} sm={12}>
                  <HasPermission permission='qrata.delete_biojobs'>
                    <TooltipHover type='link' btnSize='sm' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Job' handleEvent={() => deleteJob(item.job_id)} />
                  </HasPermission>
                  <HasPermission permission='qrata.change_biojobs'>
                    {item.job_status === Status.APPROVED.value ? '' :
                      <><label className="switch">
                        <input type="checkbox" name={item.job_id} onChange={(e) => toggleChange(e)} disabled={disable} />
                        <span className="slider round"></span>
                      </label></>}
                  </HasPermission>
                </Col>
              </Row>
            </Card.Body>
          ))} </Card>
        : 'No data found.'}
    </>
  )
}
export default BioJob;