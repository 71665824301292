import React from 'react';
import Select from 'react-select';
import { REACT_SELECT_BASIC_CUSTOM_STYLE } from './SelectStyle';
import PropTypes from 'prop-types';
import CreateSelect from './CreateSelect';

const SelectOption = ({ data, multi , value ,creatable, handleEvent , group , placeholder , style , disabled, inputRef,onCreate}) => {
    
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      };
      const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
      };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
          <span>{data.label}</span>
          <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
      );

    const createTag = () => {

    }
    
    return (
        <>{
          !creatable ? 
            <Select
                ref={inputRef} 
                value={value}
                placeholder={placeholder}
                onChange={(value) => handleEvent(value)}
                options={data}
                styles={style}
                isMulti={multi}
                isDisabled={disabled}
                formatGroupLabel={group ? formatGroupLabel : ''}
            /> :
             <CreateSelect 
             ref={inputRef} 
             value={value}
             placeholder={placeholder}
             onChange={(value) => handleEvent(value)}
             options={data}
             styles={style}
             onCreate={onCreate}
             isMulti={multi}
             isDisabled={disabled}
             formatGroupLabel={group ? formatGroupLabel : ''}
              />
        }
        </>
    );
}

SelectOption.propTypes = {
    data: PropTypes.array,
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    disabled: PropTypes.bool,
    value : PropTypes.any , 
    placeholder : PropTypes.string,
    style : PropTypes.any,
    group : PropTypes.bool,
    handleEvent: PropTypes.func
};

SelectOption.defaultProps = {
    data: [],
    multi: false,
    creatable: false,
    disabled:false,
    value : '', 
    group: false ,
    placeholder : 'Select',
    style : REACT_SELECT_BASIC_CUSTOM_STYLE,
    handleEvent: PropTypes.func
};
export default SelectOption;