import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Container, Form, Row, Col, Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as assetsDiscussionService from '../../services/assetsDiscussion';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { dateWithTimeFormat, Storage } from '../../helpers';
import FileField from '../../shared/formComponent/FileField'

const AssetDiscussion = ({ data, isVisible, title, hide }) => {

  const pageSize = 10;
  const pageIndex = 0;
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [editData, setEditData] = useState([]);
  const [formData, setFormData] = useState({
    comment: '',
    user: Storage.getSession().id,
    attachment: undefined
  });
  const prevState = useRef(formData);
  const [predefinedResponseData, setPredefinedResponseData] = useState([]);
  const [suggestion, setSuggestion] = useState('');
  
  useEffect(() => {
    if(suggestion){
      const data = predefinedResponseData.find((v) => v.value === Number(suggestion));
      setFormData({
        ...formData,
        comment: data ? data.label : ''
      });
    }
  }, [suggestion])

  const getPredefinedResponseData = useCallback(() => {
    dispatch(loaderActions.loader(true));
    assetsDiscussionService.getPredefinedResponse( pageSize, pageIndex).then(([response, error]) => {
      if (response) {
        const results = response.results ? response.results.map((result) => {
          return {value: result.id, label: result.suggestion};
        }) : [];
        setPredefinedResponseData(results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch])

  useEffect(() => {
    getPredefinedResponseData();
  }, [dispatch, getPredefinedResponseData]);

  const replyComments = useCallback(() => {
    dispatch(loaderActions.loader(true));
    assetsDiscussionService.getComment(data.id, pageSize, pageIndex).then(([response, error]) => {
      if (response) {
        setEditData(response.results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch, data.id])

  useEffect(() => {
    if (isVisible) {
      replyComments()
    }
  }, [dispatch, replyComments, isVisible])

  const closeModal = useCallback(() => {
    setEditData({});
    hide();
  }, [hide]);

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

  const saveCreateData = useCallback(() => {
    dispatch(loaderActions.loader(true));
    const form = new FormData();
    form.append('comment', formData.comment);
    if (formData.attachment) {
      form.append('attachment', formData.attachment)
   };
    assetsDiscussionService.createComment(Number(data.id), form).then(([response, error]) => {
      if (response) {
        setFormData(prevState.current);
        setValidated(false);
        dispatch(alertActions.success('Comment Added Successfully'));
        setSuggestion('');
        replyComments();
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch, data.id, formData.comment, replyComments, formData.attachment])

  const saveData = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      saveCreateData();
    }
  }, [saveCreateData])

  const openlink = (link) => {
    window.open(link, '_blank');
  }

  return (
    <Container fluid>
      {isVisible ?
        <Modal show={isVisible} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Form noValidate validated={validated} onSubmit={saveData}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card className="border-0" >
                <Card.Body className='pb-0'>
                  <Row>
                    <Col md={4} className="mb-3">
                      <Card.Subtitle className="mb-2 ">
                        <span><small className="text-muted">Asset</small></span>
                        <h4>{editData ? data.id : ''}</h4>
                      </Card.Subtitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Card.Subtitle className="mb-2 ">
                        <span><small className="text-muted">Specific Topic</small></span>
                        <h4>{editData ? data.specific_topic : ''}</h4>
                      </Card.Subtitle>
                    </Col>
                  </Row>

                </Card.Body>
              </Card>

              <Col md={12}>
                <Card.Text className="mb-2">
                  <span><small className="text-muted" >Comments:</small></span>

                </Card.Text>
              </Col>
              <Card className="border-0" style={{ maxHeight: 'calc(60vh - 210px)', overflow: 'auto' }} >
                <Card.Body className='pt-0'>
                  <Row>
                    {editData.length > 0 ? editData.map((item, index) => (
                      <Col md={12} key={'comment'+index} className={editData.length - 1 === index ? 'py-2 position-relative ' : 'py-2 position-relative border border-top-0 border-right-0 border-left-0 border-bottom-primary'}>
                        <span className="guest-user font-weight-bold"><small className="font-weight-bold">{item.first_name + " " + item.last_name}</small></span>
                        <p className="mb-0" key={'reply' + index}>
                        {item.attachments.length > 0 ? 
                          <FontAwesomeIcon className='cursor-pointer btn-link' onClick={() => openlink(item.attachments[0].file_url)} icon='download' /> : ""
                          } 
                        {" " + item.comment}
                          <span className="position-absolute date-time text-light" ><small>{dateWithTimeFormat(item.created_on)}</small></span></p>
                      </Col>
                    )) : ''}
                  </Row>
                </Card.Body>
                  </Card>

            </Modal.Body>
            <Modal.Footer>
            <Row className="w-100 no-gutters">
                  <Form.Control as="select" name='suggestion' value={suggestion} onChange={(e) => setSuggestion(e.target.value)} >
                            <option value=''>Select from suggestions</option>
                            {predefinedResponseData.map((response) => { return (<option value={response.value}>{response.label}</option>); })}
                        </Form.Control>
                  </Row>
              <Row className="w-100 no-gutters">
                <Col md={10} xs={9}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control type='textarea'  className="rounded-0" required placeholder='Reply type here...' name='comment' value={formData.comment} onChange={(e) => handleChange(e.target)} rows={1} />
                    <span className="attachment-icon text-right">
                  <FileField key="attachment" icon="download" value={formData.attachment} id="attachment" className="p-0" type="file"   fileFormat=".doc,.docx,.pdf,.jpg,.png,.jpeg" handleEvent={(e) => handleChange(e)} placeholder={"Select File"} name='attachment' />
              </span>
                  </Form.Group>
                </Col>
             
                <Col md={2} xs={3}>
                  <Button variant="primary" className="w-100 rounded-0" type="submit" ><FontAwesomeIcon size="lg" className="icon-green mr-2" icon="paper-plane" />Send</Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
        : null}
    </Container>
  )
}
export default AssetDiscussion;