import React, { useEffect, useState, useCallback, useRef } from "react";
import { Container } from 'react-bootstrap';
import ModalForm from "../../shared/ModalForm";
import { useDispatch } from "react-redux";
import * as loaderAction from '../../actions/loader';
import * as alertAction from '../../actions/alert';
import { Storage } from "../../helpers";
import * as memberPackageService from '../../services/member';

const MemberForm = ({ data, isVisible, hide, output }) => {

  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    name: '',
    username: '',
    email: '' , 
    user: Storage.getSession().id , 
    package_id: '',
    duration: ''
  });

  const prevState = useRef(userData);

  // const calculateDuration = useCallback((duration) => {
  //   if(duration){
  //     const months = duration / 30;
  //     if(months === 1) return 1;
  //     if(months === 3) return 3;
  //     if(months === 6) return 6;
  //     if(months === 12) return 12;
  //   }
  // },[])

  useEffect(() => {
    if(data !== null){
      setUserData({
        name: data.first_name || data.last_name ? data.first_name + ' '+data.last_name : '',
        email: data.email ? data.email : '',
        package_id : data.package_id ?  data.package_id : '',
        // duration : data.duration ? calculateDuration(data.duration) : '',
        duration : data.duration ? data.duration : '',
        id : data.user_id ?  data.user_id : '' ,
        user: Storage.getSession().id,
        username: data.username ? data.username : '',
        referral_code : data.referral_code
      })
    }else {
      setUserData(prevState.current)
    }
  },[data ])
  // },[data , calculateDuration])


  const closeModal = useCallback((data) => {
    if (data) {
      dispatch(loaderAction.loader(true));
        if (data.id) {
          const name = data.name.split(' ');
          data.first_name = name[0];
          data.last_name = name[name.length - 1];
          data.package = Number(data.package_id);
          data.duration = Number(data.duration);
          data.user = Number(data.id);
          // data.duration = Number(data.duration) * 30;
          data.duration = Number(data.duration);
          memberPackageService.updateMember(data).then(([response , error]) => {
            if(response){
              dispatch(alertAction.success('Member Updated Successfully.'));
              output();
              hide();
            }else if(error){
              dispatch(alertAction.error(error.response));
            }
          })
        } else {
        //  data.duration = Number(data.duration) * 30 ;
         data.duration = Number(data.duration);
         memberPackageService.createMember(data).then(([response , error]) => {
           if(response){
            dispatch(alertAction.success('Member Created Successfully.'))
            output();
            hide();
           }else if(error){
            dispatch(alertAction.error(error.response));
           }
         })
        }
        dispatch(loaderAction.loader(false));
    } else {
      hide();
    }
  }, [ hide , dispatch , output ]);


  const fileds = [
    [{ label: 'Name*', name: 'name', type: 'text', required: true, placeholder: 'Name', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Email Address*', name: 'email', type: 'email', disabled : userData.email !== '' ? true : false , required: true, placeholder: 'Email Address', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Membership*', name: 'package_id', type: 'select', disabled : userData.package_id !== '' ? true : false  , required: true,  placeholder: '---Select Membership---', options : [{name : 'Silver' , value: 1} , {name : 'Gold' , value: 2} , {name : 'Platinum' , value: 3}] ,  sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Duration*', name: 'duration', type: 'select',  disabled : userData.duration !== '' ? true : false , required: true, placeholder: '---Select Duration---', options: [{name: '1 months', value: 30} ,{name: '3 months', value: 90} , {name: '6 months', value: 180} , {name: '12 months', value: 360}  ], sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Referral Code', name: 'referral_code', type: 'text', disabled : true,  placeholder: 'Referral Code', sm: 12, md: 12, lg: 12, xl: 12 }]
  ]
  
  return (
    <>
      <Container fluid>
        {isVisible ?
          <ModalForm
            show={isVisible}
            onHide={(e) => closeModal(e)}
            data={userData ? userData : {}}
            fields={fileds}
            title={data !== {} && data !== null ? 'Edit Member' : 'Add Member'}
            button={data !== {} && data !== null ? 'Update' : 'Save'}
          />
          : null}
      </Container>
    </>
  );
}

export default MemberForm;