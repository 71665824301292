import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Stats from './Stats';
import FormBlock from '../../../shared/FormBlock';
import * as cataloServices from '../../../services/catalog';
import * as applicationServices from '../../../services/application';
import { useDispatch } from 'react-redux';
import * as alertActions from "../../../actions/alert";
import * as loaderAction from "../../../actions/loader";
import { Status } from '../../../helpers/constants';
import SearchModal from './SearchModal';


const First = ({applicationId, saveDraft, ...props}) => {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        major: '',
        minor: '',
        category: ''
    })
    const [majorCatalog, setMajorCatalog] = useState([]);
    const [minorCatalog, setMinorCatalog] = useState([]);
    const [category, setCategory] = useState([]);
    const [specializationId, setSpecializationId] = useState('');
    const [oldData, setOldData] = useState([]);
    const [status, setStatus] = useState('');
    const [showSearchModal, setShowSearchModal] = useState(false);
    
    const userId = props.user.id;

    useEffect(() => {
        if (props.status) {
            setStatus(props.status);
        }
    }, [props.status])


    // GET SPECIALIZATION BY ID 
    useEffect(() => {
        if (applicationId && props.status) {
            dispatch(loaderAction.loader(true));
            applicationServices.getSpecialiazationById(applicationId).then(([response, error]) => {
                if (response) {
                    setOldData(response.user_specialization);
                    setFormData({
                        major: getFilterData(response.user_specialization, 'MAJOR')[0].catalog_id,
                        minor: getFilterData(response.user_specialization, 'MINOR')[0].catalog_id,
                        category: getFilterData(response.user_specialization, 'CATEGORY').length > 0 ? getFilterData(response.user_specialization, 'CATEGORY')[0].catalog_id : null
                    })
                    setSpecializationId(response.specialization_id);
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }
                dispatch(loaderAction.loader(false));
            })
        }
        return () => {
            setSpecializationId('');
            setOldData([]);
        }
    }, [applicationId, props.status, dispatch])


    const getFilterData = (data, type) => {
        return data.filter(obj => obj.catalog_type === type);
    }

    // GET MAJOR CATALOG 
    useEffect(() => {
        cataloServices.getMajorCatalog().then(([response, error]) => {
            if (response) {
                setMajorCatalog(JSON.parse(response));
            } else if (error) {
                dispatch(alertActions.error(error.response));
            }
        });

        return () => {
            setMajorCatalog([]);
        }
    }, [dispatch])

    // GET MINOR CATALOG BY MAJOR CATALOG ID  
    useEffect(() => {
        if (formData.major) {

            cataloServices.getMinorCatalog(formData.major).then(([response, error]) => {
                if (response) {
                    setMinorCatalog(JSON.parse(response));
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }
            });
        }
        return () => {
            setMinorCatalog([]);
        }
    }, [formData.major, dispatch]);

    // GET CATEGORY CATALOG BY MINOR CATALOG ID
    useEffect(() => {
        if (formData.minor) {
            cataloServices.getCategoryCatalog(formData.minor).then(([response, error]) => {
                if (response) {
                    setCategory(JSON.parse(response));
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }

            });
        }
    }, [formData.minor, dispatch]);

    // MULTI SELECT FORM JSON
    const form1 = [
        { label: 'Major Catalog', id: 'major', required: true, name: 'major', type: 'multi-select', placeholder: '---Select---', options: majorCatalog, sm: 12, md: 12, lg: 12, xl: 12 },
        { label: 'Minor Catalog', id: 'minor', required: true, name: 'minor', type: 'multi-select', placeholder: '---Select---', options: minorCatalog, sm: 12, md: 12, lg: 12, xl: 12 },
        { label: 'Category', id: 'category', required: true, name: 'category', type: 'multi-select', placeholder: '---Select---', options: category, sm: 12, md: 12, lg: 12, xl: 12 }
    ];

    const handleChange = useCallback((e) => {
        if(e.name === 'major'){
            setFormData({
                major: e.value
            });
        }
        else if (e.name === 'minor'){
            setFormData({
                ...formData,
                minor: e.value,
                category: ''
            });
        }
        else{
            setFormData({
                ...formData,
                [e.name]: e.value
            });
        }
    }, [formData]);

    // useEffect(() => {
    //     if (props.applicationId !== '' && formData.major !== '' && formData.minor !== '' && specializationId === '') {
    //         // Temporary Code Start 
    //         let catalog = [];
    //         catalog.push(formData.major);
    //         catalog.push(formData.minor);
    //         if (formData.category) {
    //             catalog.push(formData.category);
    //         }
    //         // Temporary Code End 
    //         if (props.status === '') {
    //             dispatch(loaderAction.loader(true));
    //             const data = { application_id: props.applicationId, user_id: props.user.id, catalog_ids: catalog, status: 4 }
    //             applicationServices.createSpecialiazation(data).then(([response, error]) => {
    //                 if (response && response.user_specialization) {
    //                     setOldData(response.user_specialization);
    //                     dispatch(alertActions.success("Specialization saved successfully."))
    //                     setSpecializationId(response.specialization_id);
    //                     setStatus(4);
    //                 } else if (error) {
    //                     dispatch(alertActions.error(error.response));
    //                 }
    //                 dispatch(loaderAction.loader(false));
    //             })
    //         }
    //     }
    // }, [formData.major, status, formData.minor, props.status, props.applicationId, formData.category, props.user.id, specializationId, dispatch])

    // useEffect(() => {
    //     if (oldData.length > 0) {
    //         console.log(' scenario for new -- old Data', oldData);
    //         const oldCatId = getFilterData(oldData, 'CATEGORY').length > 0 ? getFilterData(oldData, 'CATEGORY')[0].catalog_id : null;
    //         if (specializationId !== '' && status === Status.DRAFT.value 
    //         && formData.major !=='' && formData.minor !== '' 
    //         && (getFilterData(oldData, 'MAJOR')[0].catalog_id !== formData.major  
    //             || getFilterData(oldData, 'MINOR')[0].catalog_id !== formData.minor 
    //             || (oldCatId !== formData.category))) {
    //             dispatch(loaderAction.loader(true));
    //             // Temporary Code Start ;
    //             let catalog = [];
    //             catalog.push(formData.major);
    //             catalog.push(formData.minor);
    //             if (formData.category) {
    //                 catalog.push(formData.category);
    //             }
    //             // Temporary Code End 

    //             const data = { specialization_id: specializationId, application_id: props.applicationId, user_id: props.user.id, catalog_ids: catalog, status: 4 }
    //             applicationServices.updateSpecialiazation(data).then(([response, error]) => {
    //                 if (response) {
    //                     dispatch(alertActions.success("Update Specialization Successfully."))
    //                 } else if (error) {
    //                     dispatch(alertActions.error(error.response));
    //                 }
    //                 dispatch(loaderAction.loader(false));
    //             })
    //         }
    //     }
    // }, [specializationId, oldData, status, props.applicationId, props.user.id, formData.major, formData.minor, formData.category, dispatch])


    const validation = () => { if (formData.major !== '' && formData.minor !== '') return true; else return false; }


    const handleResponse = useCallback((response,error) => {
        if (response) {
            dispatch(alertActions.success("Update Specialization Successfully."))
        } else if (error) {
            dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
    },[dispatch]);

    const handleSave = useCallback(() => {

        let catalog = [];
            catalog.push(formData.major);
            catalog.push(formData.minor);
            if (formData.category) {
                catalog.push(formData.category);
            }

        // chek of specilaization already exists. do nothing and show error 
        dispatch(loaderAction.loader(true));
        //  create application and specialization in case of new
        const data = { application_id: applicationId, user_id: userId, catalog_ids: catalog, status: 4 };
        if(applicationId && specializationId) {
            // updating existing specialization
            data.specialization_id = specializationId;
            applicationServices.updateSpecialiazation(data).then(([response, error]) => {
                handleResponse(response,error);      
                
            });
        } else if(applicationId && !specializationId) {
            // creating a new specialization
            applicationServices.createSpecialiazation(data).then(([response, error]) => {
                if (response && response.user_specialization) {
                    setSpecializationId(response.specialization_id);
                    setStatus(4);
                }
                handleResponse(response,error);
            });
        } else if(!applicationId) {
            // calling save application in ApplicationExperts and then create specialization on success after callback
            const intialData = {
                user: userId,
                catalog_ids: catalog
              };
            saveDraft(intialData, (res)=> {
                data.application_id = res.id;
                applicationServices.createSpecialiazation(data).then(([response, error]) => {
                    if (response && response.user_specialization) {
                        setSpecializationId(response.specialization_id);
                        setStatus(4);
                    }
                    handleResponse(response,error);
                });
            });
        }
        // update specilization in case of existing
    },[formData, applicationId, specializationId, userId, handleResponse, saveDraft, dispatch]);

    const handleFetchData = (val) => {
         setFormData({
             major: val.major_catalog?.value,
             minor: val.minor_catalog?.value,
             category: val.category?.value
         })
    }

    return (
        <Row className="justify-content-md-center pt-5 mx-0">
            <Col md={12}>
                <Row className='mb-4'>
                    <Col md={10} sm={10} lg={10} xl={10}>
                        <h4 className="mb-4">Specialization applied for</h4>
                    </Col>
                    <Col md={2} sm={2} lg={2} xl={2}>
                        <Button onClick={() => setShowSearchModal(true)}>
                            Search for Suggestion
                        </Button>
                    </Col>
                </Row>
                <FormBlock fields={form1} size={12} getData={(e) => handleChange(e)} formData={formData} />
                <div style={{
                    backgroundColor: '#c2f9f2',
                    padding: '10px',
                    borderLeft: '6px solid',
                    borderRadius: '5px'}}>
                    <strong>Note:</strong> You only get to apply for one Expert topic with your initial application. So pick your strongest topic and once we have worked together for a while you may become eligible to apply as an Expert in additional topics.
                </div>
                <Stats {...props} applicationId={applicationId} saveApplication = {handleSave} validation={() => validation()} />
            </Col>
            {
                showSearchModal ?
                <SearchModal
                    show={showSearchModal}
                    closeModal={() => setShowSearchModal(false)}
                    title={"Search for suggestion for your speciality"}
                    fetchData={handleFetchData}
                />
                : ''
            }
        </Row>

    );
}
export default First;