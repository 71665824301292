import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Row, Col, Form, Card } from 'react-bootstrap';
import Stats from './Stats';
import FormBlock from '../../../shared/FormBlock';
import { useDispatch } from 'react-redux';
import * as alertActions from "../../../actions/alert";
import * as loaderAction from "../../../actions/loader";
import * as applicationService from '../../../services/application';
import TooltipHover from '../../../shared/TooltipHover';
import { monthYearFormat } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Fourth = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    accomplishment_type: '',
    title: '',
    accomplishment_month_year: null,
    description: '',
    accomplishment_file: ''
  })
  const prevState = useRef(formData);
  const [formData1, setFormData1] = useState({ new: true, });
  const [formData2, setFormData2] = useState({ accomplishment_id: '' })
  const [validated, setValidated] = useState(false);
  const [existingAcc, setExsitingAcc] = useState([]);
  const [accomplishmentList, setAccomplishmentList] = useState([]);
  const [changeState, setChangeState] = useState(true);

  //GET ALL ACCOMPLISHMENT
  const getAllAccomplishment = useCallback(() => {
    if (props.applicationId !== '') {
      dispatch(loaderAction.loader(true));
      applicationService.getAccomplishmentById(props.applicationId).then(([response, error]) => {
        if (response?.user_accomplishments) {
          setAccomplishmentList(response.user_accomplishments);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [props.applicationId, dispatch])
  
  useEffect(() => {
    if (props.applicationId) {
      getAllAccomplishment()
    }
  }, [props.applicationId, dispatch , getAllAccomplishment])

  useEffect(() => {
    if(accomplishmentList){
          props.getStatus("Accomplishment", accomplishmentList.length);
    }
  },[accomplishmentList, props, props.getStatus]);

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

  const onChange = useCallback((e) => {
    setFormData2({
      ...formData2,
      [e.name]: e.value
    });
  }, [formData2])

  // ADD ACCOMPLISHMENT FIELDS
  const getFields = useCallback(() => {
    let form1 = [
      [
        { label: 'Type', required: true, name: 'accomplishment_type', type: 'select', placeholder: '---Select---', options: [{ name: 'Award ' }, { name: ' License' }, { name: ' Publication ' }, { name: ' Other' }], sm: 12, md: 5, lg: 5, xl: 5 },
        { label: 'Title', required: true, name: 'title', type: 'text', placeholder: 'Title', sm: 12, md: 5, lg: 5, xl: 5 },
        { label: 'Month-Year', required: true, name: 'accomplishment_month_year', type: 'date', placeholder: 'MM/yyyy', sm: 12, md: 2, lg: 2, xl: 2 }],
      [{ label: 'Description', required: true, name: 'description', type: 'text', placeholder: 'Description', sm: 12, md: 5, lg: 5, xl: 5 },
      { label: 'Attachment', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', required: false, name: 'accomplishment_file', type: 'file', placeholder: 'Select File', sm: 12, md: 5, lg: 5, xl: 5 },
      { placeholder: 'ADD', type: 'button', variant: 'primary', className: 'float-right', sm: 12, md: 2, lg: 2, xl: 2 }
      ]];

      const conditionalFields = [
        { label: 'Type of License', name: 'license_type', type: 'text', required: true, placeholder: 'For eg: Real Estate Brokers License, CPA=Certified Public Accountant, Attorney etc', sm: 12, md: 12, lg: 12, xl: 12 },
        { label: 'State or Geographic Area of License', name: 'geographic_area', type: 'text', required: true, placeholder: '', sm: 12, md: 6, lg: 6, xl: 6 },
        { label: 'License #', name: 'license_id', type: 'text', required: true, placeholder: '', sm: 12, md: 6, lg: 6, xl: 6 }
      ]

      if(formData.accomplishment_type === 'License'){
        form1.splice(1, 0, conditionalFields);
      }

      return form1;
  }, [formData])
  

    // RADIO BUTTON 
  const form2 = [
    [{ label: 'Add new ', id: 'add-fourth', type: 'radio', checked: true, required: true, placeholder: 'Add New ', value: 'add', name: 'new', sm: 6, md: 2, lg: 2, xl: 2 },
    { label: 'Existing', id: 'existing-fourth', type: 'radio', checked: false, required: true, placeholder: 'Existing ', value: 'existing', name: 'new', sm: 6, md: 1, lg: 1, xl: 1 },
    { icon: '', type: 'button', variant: 'info', className: 'float-right', sm: 12, md: 9, lg: 9, xl: 9 }],
  ]

  // SHOW EXISTING EDUCATION LISTING IN MULTI-SELECT
  const form4 = [
    [{ label: 'Type', required: true, name: 'accomplishment_id', group: true ,  type: 'multi-select', placeholder: '---Select---', options: existingAcc, value : formData2.accomplishment_id ? formData2.accomplishment_id : '' , sm: 12, md: 10, lg: 10, xl: 10 },
    { placeholder: 'Save', disabled : existingAcc.length > 0 ? false : true ,  handleEvent: () => addExistingAccomplishment(), type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 2, lg: 2, xl: 2 }]];


  const handleChange2 = useCallback((e) => {
    setFormData1({
      ...formData1,
      [e.name]: e.value
    });
  }, [formData1])


  const Submit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      saveAccomplishment();
    }
  }

  // SAVE ACCOMPLISHMENT
  const saveAccomplishment = useCallback(() => {
    if (props.applicationId && formData.accomplishment_type !== '' && formData.title !== '' && formData.accomplishment_month_year !== '') {
      let data = new FormData();
      data.append("application_id", props.applicationId);
      data.append("user_id", props.user.id);
      data.append("accomplishment_type", formData.accomplishment_type);
      data.append("title", formData.title);
      data.append("accomplishment_month_year", formData.accomplishment_month_year);
      data.append("description", formData.description);
      if (formData.accomplishment_file.name) {
        data.append("accomplishment_file", formData.accomplishment_file, formData.accomplishment_file.name);
      }
      if(formData.accomplishment_type === 'License'){
        data.append("license_type", formData.license_type);
        data.append("geographic_area", formData.geographic_area);
        data.append("license_id", formData.license_id);
      }
      dispatch(loaderAction.loader(true));
      applicationService.createAccomplishment(data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Add Accomplishment Successfully.'));
          setFormData(prevState.current);
          setValidated(false);
          getAllAccomplishment();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    } else {
      dispatch(alertActions.error('Please fill all fields.'));
    }
  }, [dispatch, props.applicationId, formData.accomplishment_type, formData.title, formData.accomplishment_month_year,
    formData.description, formData.accomplishment_file, props.user.id, getAllAccomplishment])

    // GET ALL EXISTING ACCOMPLISHMENT LISTING
    const getAllExistingAccomplishment = useCallback((id) => {
      dispatch(loaderAction.loader(true));
      applicationService.getExistingAccomplishment(id).then(([response, error]) => {
        if (response) {
          setExsitingAcc(response);
          setChangeState(false);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    },[dispatch])

  useEffect(() => {
    if (formData1.new === 'existing' && changeState) {
      getAllExistingAccomplishment(props.applicationId)
    }
  }, [formData1.new, props.applicationId, dispatch, changeState , getAllExistingAccomplishment])

  // ADD EXISTING ACCOMPLISHMENT IN APPLICATION
  const addExistingAccomplishment = useCallback(() => {
    if (formData2.accomplishment_id !== '') {
      let data = new FormData();
      data.append('application_id', props.applicationId);
      data.append('accomplishment_id', formData2.accomplishment_id);
      dispatch(loaderAction.loader(true));
      applicationService.addExistingAccomplishment(data).then(([response, error]) => {
        if (response) {
          setFormData2({
            accomplishment_id: ''
          })
          dispatch(alertActions.success('Add Accomplishment Successfully.'));
          setChangeState(true);
          getAllAccomplishment();
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    } else {
      dispatch(alertActions.error('Please select the accomplishment.'));
    }
  }, [props.applicationId, formData2.accomplishment_id, dispatch, getAllAccomplishment])


  // REMOVE ACCOMPLISHMENT
  const removeAccomplishment = useCallback((id) => {
    if (id) {
      let data = new FormData();
      data.append('application_id', props.applicationId);
      data.append('accomplishment_id', id)
      dispatch(loaderAction.loader(true));
      applicationService.deleteAccomplishment(data).then(([response, error]) => {
        if (response) {
          const list = accomplishmentList.filter((item) => item[0].id !== id);
          setAccomplishmentList(list);
          dispatch(alertActions.success('Delete Successfully.'));
          getAllExistingAccomplishment(props.applicationId);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [props.applicationId, dispatch, accomplishmentList, getAllExistingAccomplishment])


  return (
    <Row className="justify-content-md-center pt-5 mx-0">
      <Col md={12}>
        <Row className='mb-4'>
          <Col md={3}>
            <h4 >Accomplishments</h4>
          </Col>
          <Col md={9}>
            <Form>
              <FormBlock fields={form2} size={12} getData={(e) => handleChange2(e)} formData={formData1} />
            </Form>
          </Col>
        </Row>
        {formData1.new === true || formData1.new === 'add' ?
          <Form noValidate validated={validated} onSubmit={Submit}>
            <FormBlock fields={getFields()} size={12} getData={(e) => handleChange(e)} formData={formData} />
          </Form> :
            <FormBlock fields={form4} size={12} getData={(e) => onChange(e)} formData={formData2} />
        }
        {accomplishmentList.length > 0 ?
          <Card className="py-3">
            {accomplishmentList.map((item, index) => (

              <Card.Body key={index} className="py-1">
                <Row key={index}>
                  <Col md={2} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item[0].accomplishment_type}>
                  <label className="d-block text-left mb-0"><small>Type</small></label>
                  <h6> {item[0].accomplishment_type}</h6>
                  </Col>
                  <Col md={3} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={item[0].title}>
                  <label className="d-block text-left mb-0"><small>Title</small></label>
                    <h6 className="text-warning">{item[0].title}</h6>
                  </Col>
                  <Col md={2} className='text-truncate overflow-hidden' sm={12} title={item[0].description}>
                  <label className="d-block text-left mb-0"><small>Description</small></label>
                    <h6>{item[0].description}</h6>
                  </Col>
                  <Col md={2}>
                  <label className="d-block text-left mb-0"><small>Year </small></label>
                  <h6>{monthYearFormat(item[0].accomplishment_month_year)}</h6>
                  </Col>
                  <Col md={2} sm={12} className="font-style-italic ">
                        <label className="d-block text-left mb-0"><small>Status</small></label>
                        <h6 className=" text-left"> {item[0].approved_on === null ? 'Not Approved' : 'Approved'}</h6>
                  </Col>
                  <Col md={1} sm={12} className="text-nowrap text-info font-weight-bold font-style-italic text-right">
                    <TooltipHover type='button' btnSize='sm' iconClass="text-danger ml-2" variant='link' btnIcon='trash' message='Delete' handleEvent={() => removeAccomplishment(item[0].id)} />
                    {item[1] ? <a className=" text-danger  ml-2 text-decoration-none" target="_blank" rel="noopener noreferrer" href={item[1].file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>: null } 
                    
                   
                  </Col>
                </Row>
                { accomplishmentList.length - 1 === index ? null : <><hr className="mt-1 mb-2" /></>}
              </Card.Body>

            ))}  </Card>
          : null}
        <Stats {...props}  type={"Accomplishment"} listLength={accomplishmentList.length}  />
      </Col>
    </Row>

  );
}
export default Fourth;