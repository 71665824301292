import React, { useEffect, useState, useCallback } from 'react';
import { Form,  Container, Row, Col } from 'react-bootstrap';
import AppTable from "../../shared/AppTable";
import SearchField from '../../shared/SearchField';
import TooltipHover from "../../shared/TooltipHover";
import { useDispatch } from "react-redux";
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import * as feedbackService from '../../services/feedback';
import FeedbackForm from './FeedbackForm';
import { useBoolean } from '../../hooks/basic';
import { QueriesStatus, dateOnlyFormat } from '../../helpers';

const FeedbackListing = () => {

    const dispatch = useDispatch();
    const [feedbackListing, setFeedbackListing] = useState([]);
    const [isVisible, showModal, hideModal] = useBoolean(false);
    const [search, setSearch] = useState('');
    const [feedbackData , setFeedbackData] = useState({});
    const [status , setStatus] = useState(1);

    const getAllFeedback = useCallback(() => {
        dispatch(loaderActions.loader(true));
        feedbackService.getAllFeedback(status).then(([response, error]) => {
            if (response) {
                setFeedbackListing(response.results)
            } else if (error) {
                dispatch(alertActions.error(error.response));
            }
            dispatch(loaderActions.loader(false));
        })
    }, [dispatch, status])

    useEffect(() => {
        getAllFeedback();
    }, [getAllFeedback]);

    const handleDelete = useCallback((id) => {
        if (id) {
            dispatch(loaderActions.loader(true));
            feedbackService.deleteFeedback(id).then(([response, error]) => {
                dispatch(alertActions.success('Feedback Deleted Successfully.'));
                getAllFeedback();
                if (error) {
                    dispatch(alertActions.error(error.response));
                }
                dispatch(loaderActions.loader(false));
            })
        }
    }, [dispatch, getAllFeedback])

    const columns = [
        {
            Header: "Action",
            accessor: "action",
            className: "text-center py-1",
            filteringOff: true,
            Cell: (cell) => (
                <div>
                    <TooltipHover type='button' iconClass='default' btnSize='sm' variant='link' btnIcon='edit' message='Reply' handleEvent={() => openModal(cell.row.original)} />
                    <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete' handleEvent={() => handleDelete(cell.row.original.id)} />
                </div>
            ),
        },
        {
            Header: "Title",
            accessor: "title",
            sortType: "basic",
        },
        {
            Header: "Description",
            accessor: "description",
            sortType: "basic",
            filteringOff: false
        },
        {
            Header: "Created By",
            accessor: "full_name",
            sortType: "basic"
        },
        {
            Header: "Created On",
            accessor: "created_on",
            sortType: "basic",
            Cell: (row) => (<div>{row.row.original.created_on !== "" ? (dateOnlyFormat(row.row.original.created_on)): null}</div>)
        },
 
    ];

    const openModal = useCallback((data) => {
        setFeedbackData(data)
        showModal();
      }, [showModal]);

    const closeModal = useCallback(() => {
        setFeedbackData({})
        getAllFeedback();
        hideModal();
    }, [hideModal, getAllFeedback])


    return (
        <>
            <FeedbackForm data={feedbackData} title='Feedback Reply' isVisible={isVisible} hide={closeModal} refreshData={() => getAllFeedback()} />
            <Container fluid>
                <Row >
                    <Col md={5}>
                        <h4> Feedback List </h4>
                    </Col>
                    <Col md={3}>
                            <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
                            {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
                            )}
                        </Form.Control>
                    </Col>
                    <SearchField md={4} action={setSearch} />
                </Row>
                <AppTable columns={columns} globalFilter={search} data={feedbackListing} />
            </Container>
        </>
    )
}
export default FeedbackListing;