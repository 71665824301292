import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HasPermission } from '../HasPermission';
import InputMask from 'react-input-mask';

const InputField = ({ label, permission, id, md, sm, lg, xs, xl, type, mask, col, placeholder, name, opts, value, handleEvent, maxValue }) => {

    const errorMessage = 'Please fill the ' + label;
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const data = { name, value };
        handleEvent(data);
    }

    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}>
                        {label ? <Form.Label >{label}</Form.Label> : ''}
                        <InputMask mask={mask} type={type} size={col} onChange={(e) => handleChange(e)} name={name} value={value}  {...opts}  className='form-control form-control-md' placeholder={placeholder} />
                        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

InputField.propTypes = {
    value: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.any,
    label: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    maxlength: PropTypes.any,
    permission: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired
};

InputField.defaultProps = {
    id: '',
    value: '',
    name: '',
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: '',
    permission: '',
    type: 'text',
    label: '',
    maxlength: '',
    opts: []
};
export default InputField;