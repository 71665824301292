import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'query/';

export async function createRequest(data) {
  return await handleApi(axiosClient.post(`${BASE}create/` , data));
}

export async function getContactEnums() {
  return await handleApi(axiosClient.get(`${BASE}query_enums/`));
}

export async function getDataById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get/`));
}

export async function getSupportEnums() {
  return await handleApi(axiosClient.get(`${BASE}problem_enums/`));
}

export async function getListing(queryType , limit , offset , enums='' , search='' , user = '', ordering="" , filter, memberType = '', status=1, excludedQuery) {
  return await handleApi(axiosClient.get(`${BASE}${queryType}/${memberType === 1 ? 'list/?' : memberType === 2 ? 'global_query/?' : ''}status=${status}&${enums !== ''?'qenums='+enums+'&':''}${user !== ''?'user='+user+'&':''}${search !== ''?'search='+search+'&':''}excludedQuery=${excludedQuery}&limit=${limit}&offset=${offset}&ordering=${ordering}&${filter}`));
}

// export async function createReply(data) {
//   return await handleApi(axiosClient.post(`${BASE}create_reply/` , data));
// }

// export async function ticketReply(ticket) {
//   return await handleApi(axiosClient.get(`${BASE}${ticket}/ticket_reply/`));
// }

export async function deleteTicket(id, user='') {
  if (user !== '') {
    return await handleApi(axiosClient.delete(`${BASE}${id}/`));
  }
  else {
    return await handleApi(axiosClient.delete(`${BASE}${id}/delete/`));
  }
}

export async function createComment(id, data) {
  return await handleApi(axiosClient.post(`${BASE}${id}/create_comment/` , data));
}

export async function getComments(id,is_member_query ) {
  return await handleApi(axiosClient.get(`${BASE}${id}/comments/?is_member_query=${is_member_query}`));
}

export async function updateQuery(id, is_member_query, data) {
  return await handleApi(axiosClient.patch(`${BASE}${id}/update/?is_member_query=${is_member_query}` , data));
}