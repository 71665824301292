import React, { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useQuery } from '../../helpers/utility';
import * as loaderService from '../../actions/loader';
import { useDispatch } from 'react-redux';
import AlertAndLoader from '../../shared/AlertAndLoader';
import * as promotionService from '../../services/promotion';
import * as alertActions from "../../actions/alert";

function UserPromotion({ history }) {

  const dispatch = useDispatch();
  const [message, setMessage] = useState(' Verifying')
  const { token } = useParams();
  const query = useQuery();


    const verifyRequest = useCallback(() => {
        dispatch(loaderService.loader(true));
        let interested = query.get('interested')
        let data = {promotion_token:token, interested}
        promotionService.verifyPromotionOffer(data).then(([response, error]) => {
            if (response) {
                if ( interested !== 'false') {
                    setMessage('Thanks for showing your interest in the offer. A request for verification is sent to super admin.')
                } else {
                    setMessage('Thanks for letting us know! You can continue your work at Qrata as Editor!')
                }
                dispatch(loaderService.loader(false));
            } else if (error) {
              dispatch(loaderService.loader(false));
                if (error.response.status === 403) {
                  dispatch(alertActions.error('Invalid Request'));
                  history.push('/')
                } else {
                  setMessage('Invalid Request!')
                  dispatch(loaderService.loader(false));
                }
                
            }
        });
    }, [dispatch, query, token, history])

    useEffect(() => {
        if(token){
        verifyRequest()
        }
    }, []);


  return (
    <>
    
    <AlertAndLoader />
      <Container>
        <Row>
          <Col md={12} sm={12} className="bg-light align-self-center p-5 rounded shadow  bg-white" >
            <Row className="text-center no-gutters my-4 justify-content-center">
              {message}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserPromotion;