import React, { useState, useCallback } from 'react';
import { Container, Row, Col, Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import AsyncAppTable from "../../shared/AsyncAppTable";
import { useEffect, Fragment } from 'react';
import * as catalogService from '../../services/catalog';
import { CatalogType } from '../../helpers/constants';
import { useParams } from 'react-router-dom';
import { useBoolean } from '../../hooks/basic';
import CatalogForm from './CatalogForm';
import { useDispatch } from "react-redux";
import * as alertActions from "../../actions/alert";
import * as loaderAction from "../../actions/loader";
import TooltipHover from '../../shared/TooltipHover';
import SearchField from '../../shared/SearchField';
import { dateOnlyFormat } from "../../helpers";

const CatalogPage = () => {

  // getting catalog type from url params
  const { type } = useParams();

  const dispatch = useDispatch();

  // Setting initial sates 
  const [catalogType, setCatalogType] = useState(CatalogType.getType(type));
  const [parent, setParent] = useState("");
  const [catalogs, setCatalogs] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [editData, setEditData] = useState(null);
  const [header, setHeader] = useState({});
  const [pageCount, setPageCount] = React.useState(0)
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = useState(false);
  const [resultCount, setResultCount] = useState(0)
  const [search, setSearch] = useState('')
  const [callFetch, setCallFetch] = useState(false);

  // useEffect(() => {
  //   dispatch(loaderAction.loader(true));
  //   catalogService.fetchAll(catalogType.id, parent, 10, 0).then(([response, error]) => {
  //     if (response) {
  //       setCatalogs(response.results);
  //       setResultCount(response.count)
  //       setPageCount(Math.ceil(response.count / 10))
  //     } else if (error) {
  //       dispatch(alertActions.error(error.response));
  //     }
  //     dispatch(loaderAction.loader(false));
  //   });
  // }, [catalogType.id, parent, dispatch]);

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField = '', filter, }) => {
    const fetchId = ++fetchIdRef.current
    dispatch(loaderAction.loader(true));
    setLoading(true);
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const limit = pageSize;
        let offset = pageSize * pageIndex;

        catalogService.fetchAll(catalogType.id, parent, limit, offset, sortedField, filter, search).then(([response, error]) => {
          setCatalogs(response.results);
          setResultCount(response.count)
          setPageCount(Math.ceil(response.count / pageSize))
        });

        dispatch(loaderAction.loader(false));
        setLoading(false);
        setCallFetch(false);
      }
    }, 100)
  }, [catalogType.id, parent, dispatch, search])


  const columns = [
    {
      Header: "Action",
      accessor: "id",
      sortingOff: true,
      className: "action-l py-1",
      Cell: (row) => (
        <Fragment>
          <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='edit' message='Edit' handleEvent={() => handleOpen(row.row.original)} />
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete' handleEvent={() => handleDelete(row.row.original.id)} />
        </Fragment>
      ),
    },
    {
      Header: "Key",
      accessor: "key",
      filtering: true,
      Cell: (row) => (
        <Button className="p-0" variant="link" onClick={() => handleNext(row.row.original)}>
          {row.value}
        </Button>)
    },
    {
      Header: "Name",
      accessor: "name",
      sortType: "basic",
      filtering: true,
    },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
    },

  ];


  // get All Catalog
  // const getAllCatalog = useCallback(() => {
  //   dispatch(loaderAction.loader(true));
  //   catalogService.fetchAll(catalogType.id, parent, 10, 0).then(([response, error]) => {
  //     if (response) {
  //       setCatalogs(response.results);
  //       setPageCount(Math.ceil(response.count / 10))
  //     } else if (error) {
  //       dispatch(loaderAction.loader(false));
  //     }
  //   }
  //   );
  //   dispatch(loaderAction.loader(false))
  // }, [catalogType.id, parent, dispatch])

  // Click on Catalog then Redirct to Child Catalog 
  const handleNext = useCallback((row) => {
    setHeader(row);
    const { catalog_type, id } = row;
    const catType = Number(catalog_type) + 1;
    if (catType <= 3) {
      setCatalogType(CatalogType.getType(catType));
      setParent(id);
    }
  }, [setCatalogType, setParent]);

  // Open Catalog Add New and Edit PopUp
  const handleOpen = useCallback((data) => {
    if (data !== null) {
      setEditData(data);
    }
    showModal();
  }, [showModal]);

  // Delete Catalog
  const handleDelete = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    catalogService.remove(id).then(([response, error]) => {
      if (response === '') {
        dispatch(alertActions.success('Item Deleted Successfully'));
        setCallFetch(true);
        // const list = catalogs.filter(obj => obj.id !== id);
        // setCatalogs(list);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [dispatch])

  // Change Tab
  const changeCatalog = useCallback((id) => {
    setCatalogType(id);
    setParent('');
    setHeader('');
  }, [])


  return (
    <Container fluid className="px-0">
      <CatalogForm data={editData} headerData={header} catalogType={catalogType} parent={parent} isVisible={isVisible} hide={hideModal} output={() => setCallFetch(true)} />
      <Row className="no-gutters">
        <Col md={3}  className="mb-md-0 mb-3" ><h4>{catalogType.title}</h4></Col>
        <Col md={5}  className="mb-md-0 mb-2">
          <ButtonGroup toggle>
            {Object.values(CatalogType).map((catType, idx) => {
              return catType.id ? (
                <ToggleButton size={'sm'} key={idx} type="radio" variant="light" name="catTypes"
                  value={catType.id} checked={catalogType.id === catType.id}
                  onChange={(e) => changeCatalog(CatalogType.getType(catType.id))}>
                  {catType.title}
                </ToggleButton>
              ) : ''
            }
            )}
          </ButtonGroup>
        </Col>
        <SearchField md={3}  xs={10} action={setSearch}/>
        <Col md={1} xs={2} className="text-right">
        <TooltipHover type='button' btnSize='md' variant='primary' message='Add Catalog' btnIcon='plus' iconClass='text-white' handleEvent={() => handleOpen(null)}  />
        </Col>
      </Row>
      <AsyncAppTable
        columns={columns}
        data={catalogs}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        filtering={true} 
        callFetch={callFetch}/>
    </Container>
  )
}

export default CatalogPage;