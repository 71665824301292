import React from 'react'
import { Button, Modal } from "react-bootstrap";

const DoNotRankDeleteForm = ({ isPending, data, show, onHide, acceptAll, rejectAll, acceptConfirm, title }) => {
    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body style={{ textAlign: 'center' }}>
                <h4>{title}</h4>
                <br />
                
                {data===2 && isPending>0  ?
                (
                <>
                    <Button variant="primary" onClick={acceptAll}>Approve all and delete</Button>
                    &nbsp;&nbsp;
                    <Button variant="danger" onClick={rejectAll}>Reject all and delete</Button>
                    &nbsp;&nbsp;
                    <Button variant="light" onClick={onHide}>Cancel</Button>
                </>
                )
                :
                <>
                <Button variant="danger" onClick={acceptConfirm}>Delete</Button>
                &nbsp;&nbsp;
                <Button variant="light" onClick={onHide}>Cancel</Button>

                </>
            }
            </Modal.Body>
        </Modal >
    )
}

export default DoNotRankDeleteForm