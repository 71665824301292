import { handleApi, HEADERS } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'job/';

// GET JOB LIST BY USER
export async function getJob(user , status) {
  return await handleApi(axiosClient.get(`${BASE}?${user ? 'user='+user : ''}${user && status ? '&' : ''}${status ? 'status='+status : ''}`));
}

// GET JOB BY ID
export async function getJobById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_jobs/`));
}

// CREATE JOB
export async function createJob(data) {
  return await handleApi(axiosClient.post(`${BASE}create_job/`, data , {headers: HEADERS.FILE()}));
}

// UPDATE JOB
export async function updateJob(data) {
  return await handleApi(axiosClient.post(`${BASE}update_job/`, data , {headers: HEADERS.FILE()}));
}

// DELETE JOB
export async function deleteJob(id) {
  return await handleApi(axiosClient.delete(`${BASE}`));
}

// VERIFY JOB
export async function jobVerify(data) {
  return await handleApi(axiosClient.post(`${BASE}job_verify/`, data , {headers: HEADERS.FILE()}));
}

// GET ALL COMMENTS IN JOB
export async function getJobComment(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_comment/`));
}

// CREATE COMMENT IN JOB
export async function createJobComment(data) {
  return await handleApi(axiosClient.post(`${BASE}create_comment/`, data));
}

// GET COMPLETE JOB INFORMATION OF A PARTICULAR USER
export async function getJobInfo(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/list/`));
}