import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userImg from "../../assets/images/default.jpg";
import { Navbar, Button, Nav, Image, NavDropdown} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../actions/auth";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { isExpired } from "../../helpers/auth.helper";
import {  Storage } from '../../helpers';
import AlertAndLoader from "../../shared/AlertAndLoader";
import * as userService from '../../services/user';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import TooltipHover from "../../shared/TooltipHover";
const TopHeader = (props) => {

  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.loginData);

  

  function handleLogout() {
    if(Storage.getLog()){
      const data = {
        active : false
      }
      Storage.removeToken();
      dispatch(loaderActions.loader(true));
      userService.updateLog(Storage.getLog() , data ).then(([response , error]) => {
          Storage.removeLog();
          dispatch(loaderActions.loader(false));
          dispatch(authActions.logout());
        if(error){
          dispatch(loaderActions.loader(false));
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }

  if (isExpired()) {
    handleLogout();
  }

    return (
      <>
        <AlertAndLoader />
        <Navbar sticky="top" bg="white" className="navbar navbar-default px-2 navbar-static-top shadow-sm rounded">
          <Button onClick={props.toggle} className="mr-2 text-light" size="sm" variant="link"><FontAwesomeIcon icon="bars" /></Button>
          <Navbar.Brand href="/dashboard">
            {" "}
            <Image src={logo} width="100px" height="40px" rounded />
            
          </Navbar.Brand>
         
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto " navbar >
            <Link to='/search' className='d-flex align-items-center a-search text-secondary mr-3 text-decoration-none'>
          

            <TooltipHover type='button'  btnSize='sm'  variant='link' className="a"  btnIcon='search' iconClass='text-secondary'  message='Advanced Search'  />
           <span className="a-search">Advanced Search</span> </Link>
              <Image width="40px" height="40px" src={loginData.profile_pic ? loginData.profile_pic : userImg} roundedCircle />
              <NavDropdown
                alignRight
                title={`${loginData.first_name} ${loginData.last_name}`}
                id="dropdown-menu-align-right"
              >
                

                <ul className='mb-0 p-0 pl-3'>
                <NavDropdown.Item eventKey="4.12" className="b-0" as={Link} to="/account/profile">
                    <FontAwesomeIcon className="text-green mr-2" icon="award" size="sm">
                    </FontAwesomeIcon>
                    Basic Information
                </NavDropdown.Item>

                <NavDropdown.Item eventKey="4.13" className="b-0" as={Link} to="/account/education">
                    <FontAwesomeIcon className="text-green mr-2" icon="book" size="sm">
                    </FontAwesomeIcon>
                Education
                </NavDropdown.Item>

                <NavDropdown.Item eventKey="4.14" className="b-0" as={Link} to="/account/job">
                    <FontAwesomeIcon className="text-green mr-2" icon="briefcase" size="sm">
                    </FontAwesomeIcon>
                Professional Experience
                </NavDropdown.Item>

                  <NavDropdown.Item eventKey="4.21" className="b-0" as={Link} to="/account/accomplishment">
                    <FontAwesomeIcon className="text-green mr-2" icon="award" size="sm">
                    </FontAwesomeIcon>
                Accomplishments
                </NavDropdown.Item>
                </ul>


                <NavDropdown.Item eventKey="4.2"  as={Link} to="/account/security">
                  <FontAwesomeIcon className="text-green mr-2" icon="lock" size="sm">
                  </FontAwesomeIcon> Security
              </NavDropdown.Item>
                <NavDropdown.Item eventKey="4.3"  as={Link} to="/account/setting">
                  <FontAwesomeIcon className="text-green mr-2" icon="cog" size="sm">
                  </FontAwesomeIcon>Settings
              </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item  onClick={handleLogout} as={Button}>
                  <FontAwesomeIcon className="text-green mr-2" icon="sign-out-alt" size="sm">
                  </FontAwesomeIcon>Log Out
            </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
}

export default TopHeader;
