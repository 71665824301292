import React, { useState,  useCallback } from "react";
import { Container, Row, Col, ButtonGroup, ToggleButton } from "react-bootstrap";
import { useBoolean } from "../../hooks/basic";
import InvitesForm from "./InvitesForm";
import * as userService from '../../services/user';
import ConfirmModal from "../../shared/ConfirmModal";
import { useDispatch } from "react-redux";
import * as alertActions from "../../actions/alert";
import * as loaderAction from "../../actions/loader";
import TooltipHover from "../../shared/TooltipHover";
import { UserRole, UserType, dateOnlyFormat } from "../../helpers";
import AsyncAppTable from "../../shared/AsyncAppTable";
import { defaultLimit } from "../../helpers";
import SearchField from '../../shared/SearchField';
import { HasPermission } from "../../shared/HasPermission";

const Invites = () => {

  const columns = [
    {
      Header: "Action",
      accessor: "action",
      sortingOff:true,
      className: 'py-1 text-nowrap',
      Cell: (row) => (<div>
        {(row.row.original.is_active) ?
          <div className="text-center">
           <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='edit' iconClass='default'  message='View the User Information' handleEvent={() => openModal(row.row.original)} />
           <HasPermission permission='auth.change_user'><TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='default' btnIcon={row.row.original.is_super_expert?'arrow-down' :'arrow-up'} message={row.row.original.is_super_expert? 'Make Expert':'Make Super Expert' } handleEvent={() => handleSuperExpert(row.row.original.id)}/></HasPermission>
           {/* <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete User' handleEvent={() => openDeleteConfirmModal(row.row.original.id)}/> */}
           <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='default' btnIcon='user-minus' message='Deactivate Expert' handleEvent={() => openInactiveModal(row.row.original.id)}/>
          </div>
          :
          <div className="text-center">
          {row.row.original.is_activated ? <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='default' btnIcon='user-plus' message='Activate Expert' handleEvent={() => openActiveModal(row.row.original.id)}/> : ''}
           <TooltipHover type='button' btnSize='sm'  variant='link' iconClass='default' btnIcon='paper-plane' message='Resend Invite' handleEvent={() => openReinviteConfirmModal(row.row.original.id)} />
           <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete User' handleEvent={() => openDeleteConfirmModal(row.row.original.id)}/>
           </div>
        }</div>
      ),
    },
    {
      Header: "First Name",
      accessor: "first_name",
      sortType: "basic",
      filtering:true
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      sortType: "basic",
      filtering:true
    },
    {
      Header: "Username",
      accessor: "username",
      sortType: "basic",
      filtering:true
    },
    {
      Header: "Email",
      accessor: "email",
      filtering:true
    },
    {
      Header: "Group",
      accessor: "groups",
      Cell: (cell) => (<div>{cell.row.original.groups[0] === UserRole.EXPERT.id ? 'EXPERT' : ''}</div>)
    },
    {
      Header: "Created On",
      accessor: "date_joined",
      sortType: "basic",
      className: 'text-nowrap',
      Cell: (cell) => <div>{dateOnlyFormat(cell.row.original.date_joined)}</div>
    },
    
  ];

  const dispatch = useDispatch();

  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [inviteId, setInviteId] = useState();
  const [userType, setUserType] = useState(UserType.getType(UserType.ACTIVE.id));
  const [invitesList, setInvitesList] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [confirmType, setConfirmType] = useState('');
  const [userData , setUserData] = useState(null);
  const fetchIdRef = React.useRef(0);
  const [loading , setLoading ] = useState(false);
  const [pageCount, setPageCount] = React.useState(0)
  const [resultCount, setResultCount] = useState(0)
  const [search, setSearch] = useState('')
  const [callFetch, setCallFetch] = useState(false);

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField="", filter }) => {
    const fetchId = ++fetchIdRef.current
    
    setLoading(true);
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const limit = pageSize;
        let offset = pageIndex * pageSize;
        dispatch(loaderAction.loader(true));
        userService.fetchAll(+userType.value , +UserRole.EXPERT.id , limit , offset, sortedField, filter, search ).then(([response, error]) => {
          if(response){
            setInvitesList(response.results);
            setPageCount(Math.ceil(response.count / pageSize))
            setResultCount(response.count)
          }else if(error){
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
        setLoading(false);
        setCallFetch(false)
      }
    }, 100)
  }, [dispatch , userType.value, search])

  const openInactiveModal = useCallback((id) =>{
    setInviteId(id);
    setConfirmType('deactivate');
    setModalTitle('Do you want deactivate Expert?');
    showConfirm();
  }, [showConfirm]);

  const openActiveModal = (id) =>{
    setInviteId(id);
    setConfirmType('activate');
    setModalTitle('Do you want activate Expert?');
    showConfirm();
  };

  const openReinviteConfirmModal = useCallback((id) => {
    setInviteId(id);
    setConfirmType('reinvite');
    setModalTitle('Do you want to re-invite this member?');
    showConfirm();
  }, [showConfirm]);

  const openDeleteConfirmModal = useCallback((id) => {
    setInviteId(id);
    setConfirmType('delete');
    setModalTitle('Confirm you wish to delete ?');
    showConfirm();
  }, [showConfirm]);

  const closeConfirmModal = useCallback(() => {
    setInviteId();
    setConfirmType('');
    setModalTitle('');
    hideConfirm();
  }, [hideConfirm]);

  const acceptConfirmation = () => {
    if (confirmType === 'reinvite') {
      resendInvite();
    } else if (confirmType === 'delete') {
      deleteInvite();
    } else if (confirmType === 'deactivate'){
      deactivateExpert();
    } else if (confirmType === 'activate') {
      activateExpert()
    }
  }

  const handleSuperExpert = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    userService.handleSuperExpert(id).then(([response, error]) => {
      if (response) {
        dispatch(alertActions.success('Permissions Updated Successfully'));
        // fetchData(defaultLimit ,0);
        setCallFetch(true);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [dispatch])

  const resendInvite = useCallback(() => {
    hideConfirm();
    const data = { "id": inviteId };
    dispatch(loaderAction.loader(true));
    userService.resendInvite(data).then(([response, error]) => {
      if (response) {
        dispatch(alertActions.success('Reinvite Email Sent to User'));
        setInviteId();
        // fetchData({defaultLimit ,0});
        setCallFetch(true);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
    setConfirmType('');
    setModalTitle('');
  }, [hideConfirm, inviteId, dispatch ])

  const deleteInvite = useCallback(() => {
    hideConfirm();
    dispatch(loaderAction.loader(true));
    userService.deleteById(inviteId).then(([response, error]) => {
      if (response === '') {
        dispatch(alertActions.success('Invited User Deleted Successfully'));
        setInviteId();
        setCallFetch(true)
        // fetchData({defaultLimit , 0});
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
    setConfirmType('');
    setModalTitle('');
  }, [hideConfirm, inviteId, dispatch]);

  const deactivateExpert = useCallback(() => {
    hideConfirm();
    const data = { "id": inviteId };
    dispatch(loaderAction.loader(true));
    userService.inactiveStatus(data).then(([response, error]) => {
      if (response.success) {
        dispatch(alertActions.success('Expert Account Deactivated Successfully'));
        setCallFetch(true);
        setInviteId();
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
    setConfirmType('');
    setModalTitle('');
  },[hideConfirm, inviteId, dispatch]);

  const activateExpert = useCallback(() => {
    hideConfirm();
    const data = { "id": inviteId };
    dispatch(loaderAction.loader(true));
    userService.activeStatus(data).then(([response, error]) => {
      if (response.success) {
        dispatch(alertActions.success('Expert Account Activated Successfully'));
        setCallFetch(true)
        setInviteId();
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
    setConfirmType('');
    setModalTitle('');
  },[hideConfirm, inviteId, dispatch]);

  const changeUserType = (obj) => {
    setUserType(obj);
  }

  const openModal = useCallback((data) => {
    setUserData(data);
    showModal();
  }, [showModal]);



  return (
    <Container fluid className="p-0">
      <InvitesForm isVisible={isVisible}  userData={userData} hide={hideModal} refreshList={() => setCallFetch(true)} />
      <ConfirmModal  show={confirm} onHide={closeConfirmModal} acceptConfirm={acceptConfirmation} title={modalTitle} />
      <Row className="no-gutters">
        <Col md={4} className="mb-md-0 mb-3"><h4>Pool of Experts</h4></Col>
        <Col md={3}  className="mb-md-0 mb-2 pr-md-3 ">
        <ButtonGroup toggle>
            {Object.values(UserType).map((type, idx) => {
              return type.id ? (
                <ToggleButton key={idx} type="radio" variant="light" name="userTypes"
                  value={type.id} checked={userType.id === type.id}
                  onChange={(e) => changeUserType(UserType.getType(type.id))}>
                  {type.title}
                </ToggleButton>
              ) : ''
            }
            )}
          </ButtonGroup>
        </Col>
        <SearchField md={4} xs={10} action={setSearch}/>
        <Col md={1}  xs={2} className="text-right p-0">
          <TooltipHover type='button' btnSize='md' variant='primary' btnIcon='plus' iconClass='text-white' message='Add Expert' handleEvent={() => openModal(null)}  />
        </Col>
      </Row>
      <AsyncAppTable
        columns={columns}
        data={invitesList}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount} 
        resultCount={resultCount}
        filtering={true}
        callFetch={callFetch}/>
    </Container>
  );
}

export default Invites;