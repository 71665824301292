import React, { useRef, useState, useCallback } from "react";
import { Container, Col, Row, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import TooltipHover from "../../shared/TooltipHover";
import * as challengesServices from '../../services/challenges';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import AsyncAppTable from "../../shared/AsyncAppTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoolean } from "../../hooks/basic";
import ConfirmModal from "../../shared/ConfirmModal";
import ChallengeForm from "./ChallengeForm";
import { defaultLimit } from "../../helpers";
import moment from "moment"
import { dateOnlyFormat, QueriesStatus } from '../../helpers';
import SearchField from '../../shared/SearchField';


const ChallengesList = () => {

  const dispatch = useDispatch();
  const fetchIdRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [id, setId] = useState('');
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [editData, setEditData] = useState('');
  const [resultCount, setResultCount] = useState(0)
  const [search , setSearch] = useState('');
  const [status , setStatus] = useState(1);
  const [callFetch, setCallFetch] = useState(false);

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField="", filter, }) => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const limit = pageSize;
        let offset = pageIndex * pageSize;
        dispatch(loaderAction.loader(true));
        challengesServices.RequestListing('' , limit, offset, sortedField, filter, search, status ).then(([response, error]) => {
          if (response) {
            setListingData(response.results);
            setResultCount(response.count)
            setPageCount(Math.ceil(response.count / pageSize))
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
        setLoading(false);
        setCallFetch(false);
      }
    }, 100)
  }, [dispatch, search, status])

  const openlink = (link) => {
    window.open(link, '_blank');
  }

  const deleteData = useCallback(() => {
    dispatch(loaderAction.loader(true));
    challengesServices.deleteTicket(id).then(([response, error]) => {
      hideConfirm();
      // fetchData(defaultLimit, 0);
      setCallFetch(true);
      if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch, id, hideConfirm])

  const openConfirmModal = (id) => {
    setId(id);
    showConfirm();
  };

  const closeConfirmModal = () => {
    setId('');
    hideConfirm();
  };

  const acceptConfirmation = useCallback(() => {
    deleteData();
  }, [deleteData])

  const openModal = useCallback((data) => {
    if (data) setEditData(data);
    showModal();
  }, [showModal]);


  const closeModal = useCallback(() => {
    setEditData('');
    // fetchData(defaultLimit, 0);
    setCallFetch(true)
    hideModal();
  }, [hideModal])


  const columns = [
    {
      Header: "Action",
      accessor: "action",
      className: 'text-center',
      sortingOff: true,
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default' message='View Message' handleEvent={() => openModal(cell.row.original)} />
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Message' handleEvent={() => openConfirmModal(cell.row.original.id)} />
        </div>
      ),
    },
    {
      Header: "Ticket Id",
      accessor: "ticket",
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Name",
      accessor: "user__first_name",
      sortType: "basic",
      className: 'text-capitalize',
      filtering:true,
      Cell: (cell) => (
        <div>
          {cell.row.original.first_name || cell.row.original.last_name ? cell.row.original.first_name + ' ' + cell.row.original.last_name : 'Guest user'}
        </div>
      )
    },
    {
      Header: "Email ID",
      accessor: "email",
      id: "user__email",
      sortType: "basic",
      filtering:true,
      className: 'text-capitalize'
    }, 
    {
      Header: "Asset",
      accessor: "specific_topic",
      id: 'asset__specific_topic',
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
    },
    {
      Header: "File",
      className: 'text-center',
      sortingOff: true,
      Cell: (cell) => (
        <div>
          {cell.row.original.file ? <FontAwesomeIcon className='cursor-pointer' onClick={() => openlink(cell.row.original.file[0].file_url)} icon='download' /> : 'No file'}
        </div>
      )
    },

  ];


  return (
    <>
      <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={acceptConfirmation} title={'Do you want delete this challenge  ? '} />
      <ChallengeForm data={editData} title='Challenges Reply' isVisible={isVisible} hide={closeModal} refreshData={() => setCallFetch(true)} />
      <Container fluid className="px-0">
      <Row >
        <Col md={5} sm={12} xs={12}  className="mb-md-0 mb-3">
          <h4>Challenges</h4> 
        </Col>
        <Col md={3}>
            <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
            {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
            )}
            </Form.Control>
          </Col>
        <SearchField md={4} action={setSearch}/>
      </Row>
        <AsyncAppTable
          columns={columns}
          data={listingData}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          resultCount={resultCount} 
          filtering={true} 
          callFetch={callFetch}/>
      </Container>
    </>  
  );
}

export default ChallengesList;