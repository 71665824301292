import React, { useEffect, useState, useCallback } from "react";
import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import FormBlock from "./FormBlock";
import { useBoolean } from "../hooks/basic";
import { dateWithTimeFormat } from "../helpers";
import AccordionForm from "./AccordionForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalForm = ({onCreate, value, header, title, show, onHide, data, fields, comment, button , modalClass, fetchUpdated}) => {

  const [formState, setFormState] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [validated, setValidated] = useBoolean(false);

  useEffect(() => {
    if (fields) setFormFields(fields);
    setFormState(data);

  }, [data, fields]);

  const handleChange = (e) => {
    if(fetchUpdated){
      fetchUpdated({...formState, [e.name]: e.value});
    }else{
      setFormState({
        ...formState,
        [e.name]: e.value
      });
    }
  }

  const changeConflict = useCallback((value) => {
    if (value && formFields.length === 3) {
      const reason = { label: 'Conflict Reason', required: formState.is_conflict ? true : false, name: 'conflict_reason', type: 'textarea', placeholder: 'Type here...', sm: 12, md: 12, lg: 12, xl: 12 }
      const data = formFields;
      data.push(reason);
      setFormFields(data);
    } else if (value === false && formFields.length > 3) {
      formFields.pop();
    }
  }, [formFields, formState.is_conflict])


  useEffect(() => {
    if (formState.is_conflict) changeConflict(formState.is_conflict);
    if (formState.is_conflict === false) changeConflict(formState.is_conflict);
  }, [formState.is_conflict, changeConflict]);


  const saveData = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      onHide(formState);
    }
  }

  const closeModal = () => {
    setFormState({});
    onHide(false);
  }

  const openlink = (link) => {
    window.open(link, '_blank');
  }

  const CommentModal = ({ commentList }) => {
    return (
      <>
        {commentList.map((item, index) => (
          <Row key={index} className="no-gutters border border-top-0 border-right-0 border-left-0 mb-2 py-3">
            <Col md={12}>
              <Row className="no-gutters px-2">
                <Col md={12} className="font-weight-bold">
                  {item.first_name + ' ' + item.last_name}
                </Col>
                <Col md={12}>
                    {item.attachments.length > 0 ? 
                    <FontAwesomeIcon className='cursor-pointer btn-link' onClick={() => openlink(item.attachments[0].file_url)} icon='download' /> : ""
                    } 
                  {" " + item.comment}
                </Col>
                <Col md={12}>
                  <small> {dateWithTimeFormat(item.created_on)}</small>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </>
    )
  }

  const commentsCount  = comment && comment.length > 0 ? comment.length : '';
  const commentJSON = [
    { name: `Comments (${commentsCount})`, open: false, type: 'component', component: <CommentModal commentList={comment ? comment : []} /> }
  ]

  return (
    <Modal className={modalClass} show={show} onHide={closeModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Form noValidate validated={validated} onSubmit={saveData}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {header ? <h5>{header.name}</h5> : null}
          <FormBlock value={value} fields={formFields} getData={(e) => handleChange(e)} formData={formState} onCreate={onCreate}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeModal}>Cancel</Button>
          <Button variant="primary" type="submit" >{button !== '' && button !== undefined ? button : 'Save'}</Button>
        </Modal.Footer>
        {comment && comment.length > 0 ? <AccordionForm data={commentJSON} /> : ''}
      </Form>
    </Modal>
  );
}

export default ModalForm;