import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'feedback/';

export async function createFeedback(data) {
    return await handleApi(axiosClient.post(`${BASE}`, data));
}

export async function updateFeedback(id, data) {
    return await handleApi(axiosClient.put(`${BASE}${id}/`, data));
}

export async function getAllFeedback(status=null) {
    return await handleApi(axiosClient.get(`${BASE}${status? '?status='+status:""}`));
}

export async function deleteFeedback(id) {
    return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}

export async function getFeedbackByID(id) {
    return await handleApi(axiosClient.get(`${BASE}${id}/`));
}

export async function forwardFeedback(id, data) {
    return await handleApi(axiosClient.post(`${BASE}${id}/forward/`, data));
}

export async function getFeedbackComment(id) {
    return await handleApi(axiosClient.get(`${BASE}${id}/get_comment/`));
}

export async function CreateFeedbackComment(data) {
    return await handleApi(axiosClient.post(`${BASE}create_comment/` , data));
}