import React, { useCallback, useState, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { NavForm } from './formSteps/NavForm';
import First from './formSteps/First';
import Second from './formSteps/Second';
import Third from './formSteps/Third';
import Fifth from './formSteps/Fifth';
import Fourth from './formSteps/Fourth';
import Sixth from './formSteps/Sixth';
import * as applicationServices from '../../services/application';
import { useSelector, useDispatch } from 'react-redux';
import * as alertActions from "../../actions/alert";
import * as loaderAction from "../../actions/loader";
import { useParams } from 'react-router-dom';
import { Status } from '../../helpers/constants';

const ApplicationExpert = () => {

  const { applicationId } = useParams();
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.loginData);
  const intialData = {
    user: sessionData.id
  };
  const [id, setId] = useState('');
  const [startProcess, setStartProcess] = useState(true);
  const [status, setStatus] = useState('');
  const [verifyReason, setVerifyReason] = useState(false);
  const [educationStatus , setEducationStatus] = useState(false);
  const [jobStatus , setJobStatus] = useState(false);
  const [accomplsihmentStatus , setAccomplsihmentStatus] = useState(false);
  const [commentList, setcommentList] = useState([]);

  useEffect(() => {
    if (applicationId === '') {
      setStartProcess(true);
      setStatus('');
      setId('');
    }
  }, [applicationId])

  useEffect(() => {
    if (applicationId !== '' && applicationId !== undefined) {
      dispatch(loaderAction.loader(true));
      applicationServices.getApplicationById(applicationId).then(([response, error]) => {
        if (response && ((response.status === Status.DRAFT.value) || (response.status === Status.REVISE.value))) {
          setId(applicationId);
          setStatus(response.status);
          setStartProcess(false);
          if (response.reason !== null) {
            setVerifyReason(true);
          }
        } else if (response && response.status === Status.UNDER_REVIEW.value) {
          dispatch(alertActions.info('Your application is already submitted that is in under Review .'));
        } else if (response && response.status === Status.APPROVED.value) {
          dispatch(alertActions.info('Your application is already approved .'));
        } else if (error) {
          dispatch(alertActions.error('Invalid Application.'));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [applicationId, dispatch]);


  const saveApplication = useCallback((data,afterSave) => {
    setStartProcess(true);
    if (id === '') {
      dispatch(loaderAction.loader(true));
      applicationServices.createApplication(data).then(([response, error]) => {
        if (response && response.id) {
          setId(response.id);
          setStartProcess(false);
          if(afterSave){
            afterSave(response);
          }
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    } else if (id !== '') {
      setStartProcess(false);
    }
  }, [id, dispatch])

  const onStepChange = (stats) => { }

  const verifyStatus = (value) => {
    if (value) {
      setVerifyReason(true);
    }
  }

  const getData = (e,i) => {
   if(e === "Education" && i > 0){
    setEducationStatus(true);
   }else if(e === "Job" && i > 0){
    setJobStatus(true);
   }else if(e === "Accomplishment" && i > 0){
    setAccomplsihmentStatus(true);
   }
  }

  const getComments = useCallback(() => {
    if (id !== '') {
      applicationServices.getComments(id).then(([response, error]) => {
        if (response) {
          setcommentList(response.app_comment);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
      })
    }
  }, [id, dispatch])
  let custom = {   enterRight: 'fadein',   enterLeft : 'fadein',   exitRight : 'fadeout',   exitLeft  : 'fadeout' }

  return (
    <div className="bg-white">
      <StepWizard  transitions={custom} initialStep={1} nav={<NavForm accomplishment={accomplsihmentStatus} education={educationStatus} job={jobStatus} start={startProcess} verifyReason={verifyReason} />} onStepChange={onStepChange} >
        <First status={status} start={startProcess} applicationId={id} user={sessionData} saveDraft={saveApplication} getComments={getComments} commentList={commentList}/>
        <Second applicationId={id} user={sessionData} getStatus={(e,i) => getData(e,i)} getComments={getComments} commentList={commentList}  />
        <Third applicationId={id} user={sessionData} getStatus={(e,i) => getData(e,i)} getComments={getComments} commentList={commentList} />
        <Fourth applicationId={id} user={sessionData} getStatus={(e,i) => getData(e,i)} getComments={getComments} commentList={commentList} />
        <Fifth applicationId={id} user={sessionData} verifyStatus={(e) => verifyStatus(e)} getComments={getComments} commentList={commentList} />
        <Sixth applicationId={id} user={sessionData} getComments={getComments} commentList={commentList}/>
      </StepWizard>
    </div>
  );

}
export default ApplicationExpert;
