import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { monthYearFormat, getStatus, Storage } from '../../../helpers';
import AppTable from '../../../shared/AppTable';
import TooltipHover from '../../../shared/TooltipHover';
import * as accomplishmentServices from '../../../services/accomplishment';
import { useBoolean } from '../../../hooks/basic';
import AccomplishmentForm from './AccomplishmentForm';
import { useDispatch } from 'react-redux';
import * as alertActions from '../../../actions/alert';
import * as loaderAction from '../../../actions/loader';
import SearchField from '../../../shared/SearchField';
import {dateOnlyFormat} from '../../../helpers';

const Accomplishment = () => {

  const dispatch = useDispatch();
  const [accomplishment, setAccomplishment] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [editData, setEditData] = useState('');
  const [search, setSearch] = useState('')

  // GET ALL ACCOMPLISHMENT LIST
  const getAllAccomplishment = useCallback(() => {
    dispatch(loaderAction.loader(true));
    const userId = Storage.getSession().id;
    accomplishmentServices.getAccomplishment(userId, '').then(([response, error]) => {
      if (response) {
        setAccomplishment(response.results)
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [dispatch])

  useEffect(() => {
    getAllAccomplishment();
  }, [getAllAccomplishment])

  // OPEN POPUP PANEL
  const openModal = useCallback((data) => {
    if (data) setEditData(data);
    showModal();
  }, [showModal]);

  // CLOSE POPUP PANEL
  const closeModal = useCallback(() => {
    setEditData('');
    hideModal();
  }, [hideModal])

  // REMOVE ACCOMPLISHMENT
  const removeAccomplishment = useCallback((id) => {
    if (id) {
      dispatch(loaderAction.loader(true));
      accomplishmentServices.deleteAccomplishment(id).then(([response, error]) => {
        const list = accomplishment.filter((item) => item.id !== id);
        setAccomplishment(list);
        dispatch(alertActions.success('Delete Successfully.'));
        if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [accomplishment, dispatch])

  // TABLES COLUMNS
  const columns = [
    {
      Header: "Action",
      accessor: "action",
      className: 'text-center',
      filteringOff:true,
      disableFilters: true,
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='edit' iconClass='default' message='Edit accomplishment' handleEvent={() => openModal(cell.row.original.id)} />
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete accomplishment' handleEvent={() => removeAccomplishment(cell.row.original.id)} />
        </div>
      ),
    },
    {
      Header: "Type",
      accessor: "accomplishment_type",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Title",
      accessor: "title",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Description",
      accessor: "description",
      sortType: "basic"
    },
    {
      Header: "Year",
      accessor: "accomplishment_month_year",
      sortType: "basic",
      Cell: (cell) => (<div>{monthYearFormat(cell.row.original.accomplishment_month_year)}</div>)
    },
    {
      Header: "Status",
      accessor: "status",
      filteringOff:true,
      Cell: (row) => (<div>{getStatus(row.row.original.status)}</div>)
    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on) : 'N/A'}</div>)
    },

  ];

  return (
    <Container fluid  className="px-0">
      <AccomplishmentForm id={editData} isVisible={isVisible} hide={closeModal} output={() => getAllAccomplishment()} />
      <Row className="no-gutters" >
        <Col md={7}  className="mb-md-0 mb-3"><h4> Accomplishments </h4></Col>
        <SearchField md={4} xs={10}  action={setSearch} />
        <Col md={1} xs={2} className="text-right">
        <TooltipHover type='button' btnSize='md' variant='primary' message='Add Accomplishment' btnIcon='plus' iconClass='text-white' handleEvent={() => openModal('')}  />
        </Col>
      </Row>
      <AppTable  globalFilter={search} columns={columns} data={accomplishment} />
    </Container>
  )
}
export default Accomplishment;