import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as loaderAction from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import * as jobServices from '../../services/job';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { monthYearFormat, getStatus } from '../../helpers';
import TooltipHover from '../../shared/TooltipHover';

const JobInfo = () => {

     // getting User Id from url params
  const { userId } = useParams();

  const [jobList, setJobList] = useState([]);

    const dispatch = useDispatch();

    const getAllJobs = useCallback(() => {
        dispatch(loaderAction.loader(true));
        jobServices.getJobInfo(userId).then(([response, error]) => {
          if (response) {
            setJobList(response.job_list);
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
      }, [dispatch, userId]);

      useEffect(() => {
        getAllJobs();
      }, [getAllJobs])

      return (
        <>
          {jobList.length > 0 ?
            <Card className="py-3">
              {jobList.map((item, index) => (
  
                <Card.Body key={index} className="py-1">
                  <Row key={index}>
                    <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item.job[0].company}>
                    <label className="d-block text-left mb-0"><small>Company</small></label>
                      <h6>{item.job[0].company}</h6>
                    </Col>
                    <Col md={3} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={item.job[0].job_title}>
                    <label className="d-block text-left mb-0"><small>Title</small></label>
                     <h6 className='text-warning'> {item.job[0].job_title}</h6>
                    </Col>
                    <Col md={2}>
                    <label className="d-block text-left mb-0"><small>Status</small></label>
                    <Badge pill variant="primary">
                      {getStatus(item.job[0].status) ? getStatus(item.job[0].status) : null}
                    </Badge>
                    </Col>
                    <Col md={3} sm={6}>
                    <label className="d-block text-left mb-0"><small>Experience</small></label>
                    <h6>{monthYearFormat(item.job[0].experience_to) + ' - ' + monthYearFormat(item.job[0].experience_from)}</h6>
                    </Col>
                    <Col md={1} sm={6} className="text-info font-weight-bold font-style-italic text-right">
                    <label className="d-block text-left mb-0"><small>&nbsp;</small></label>
                    {item.job[0].is_conflict ? <><TooltipHover type='icon' iconClass='ml-1' variant='link' icon='info-circle' message={item.job[0].conflict_reason} handleEvent={() => null} /> </> : ''}
                    {item.job[1].file_url ?   <a className=" text-danger text-decoration-none  ml-2" target="_blank" rel="noopener noreferrer" href={item.job[1].file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a> : '' }
                    </Col>
                  </Row>
                  {jobList.length - 1 === index ? '' : <hr className="mt-1 mb-2" />}
                </Card.Body>
  
              ))} </Card>
            : 'No data found.'}
        </>
      )
  }

  export default JobInfo;