import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'assets/';
export async function assetsListing(pageSize, offset, search, ordering) {
    return await handleApi(axiosClient.get(`${BASE}discussed/?${search !== ''?'search='+search+'&':''}limit=${pageSize}&offset=${offset}&ordering=${ordering}`));
  }

export async function createComment(id, data) {
    return await handleApi(axiosClient.post(`${BASE}${id}/create-discussion/` , data));
  }

export async function getComment(id, pageSize, pageNumber ) {
    return await handleApi(axiosClient.get(`${BASE}${id}/discussion/?limit=${pageSize}&offset=${pageNumber}`));
  }
  
export async function getPredefinedResponse( pageSize, pageNumber ) {
    return await handleApi(axiosClient.get(`response-suggestion/?limit=${pageSize}&offset=${pageNumber}`));
  }


