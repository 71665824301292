import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import AccordionForm from '../../shared/AccordionForm';
import * as cataloServices from '../../services/catalog';
import * as assetService from '../../services/asset';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dateWithTimeFormat, history  } from "../../helpers";
import { AssetStatus } from '../../helpers/constants';

const AssetView = () => {
 
  const dispatch = useDispatch();
  const { id , assetexpertId } = useParams();
  const [area, setArea] = useState([]);
  const [branch, setBranch] = useState([]);
  const [majorCatalog, setMajorCatalog] = useState([]);
  const [minorCatalog, setMinorCatalog] = useState([]);
  const [category, setCategory] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [editable, setEditable] = useState(false);
  const [comments, setComments] = useState([]);


  const [formData, setFormData] = useState({
    specific_topic: '',
    url: '',
    notes: '',
    created_by: '',
    branch_opinion: '',
    opinion_percent: '',
    status: '',
    area: '',
    major: '',
    minor: '',
    category: '',
    sub_cat: '',
    topic: '',
    sub_topic: ''
  });

  const [comment, setComment] = useState({ notes: '' })
  const [overviewFormData, SetOverviewFormData] = useState({
    strengths: '',
    weakness: '',
    evaluation: ''
  });



  // GET AREA & BRANCH
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    assetService.getArea().then(([response, error]) => {
      if (response) setArea(response)
      if (error) dispatch(alertActions.error(error.response));
    })
    assetService.getBranch().then(([response, error]) => {
      if (response) setBranch(response)
      if (error) dispatch(alertActions.error(error.response));
    })
    dispatch(loaderAction.loader(false));

    return () => {
      setArea([]);
      setBranch([]);
    }
  }, [dispatch])

  // GET MAJOR CATALOG 
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    cataloServices.getMajorCatalog().then(([response, error]) => {
      if (response) {
        setMajorCatalog(JSON.parse(response));
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });

    return () => {
      setMajorCatalog([]);
    }
  }, [dispatch])


  // GET MINOR CATALOG BY MAJOR CATALOG ID  
  useEffect(() => {
    if (formData.major !== '' && formData.major !== undefined) {
      dispatch(loaderAction.loader(true));
      cataloServices.getMinorCatalog(formData.major).then(([response, error]) => {
        if (response) {
          setMinorCatalog(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
    return () => {
      setMinorCatalog([]);
    }
  }, [formData.major, dispatch]);

  // GET CATEGORY CATALOG BY MINOR CATALOG ID
  useEffect(() => {
    if (formData.minor !== '' && formData.minor !== undefined) {
      dispatch(loaderAction.loader(true));
      cataloServices.getCategoryCatalog(formData.minor).then(([response, error]) => {
        if (response) {
          setCategory(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }

    return () => {
      setCategory([]);
    }
  }, [formData.minor, dispatch]);


  const setAssetData = useCallback((response) => {
    setFormData({
      id: response.asset.id,
      specific_topic: response.asset.specific_topic,
      url: response.asset.url,
      notes: response.asset.notes,
      created_by: response.asset.created_by,
      branch_opinion: response.asset.branch_opinion,
      opinion_percent: response.asset.opinion_percent,
      status: response.asset.status,
      area: response.asset.area,
      major: response.specialization[2].catalog_id,
      minor: response.specialization[1].catalog_id,
      category: response.specialization[0].catalog_id,
      sub_cat: response.asset.sub_cat,
      topic: response.asset.topic,
      sub_topic: response.asset.sub_topic
    });
    setComment({
      notes: response.asset.notes
    })
  }, [])

  const getExpertAssetData = useCallback(() => {
    if(assetexpertId !== '' && +assetexpertId !== 0){
      assetService.assetExpertById(assetexpertId).then(([response , error]) => {
        if(response){
          SetOverviewFormData({
            strengths: response.strengths !== null && response.strengths !== '' ?  response.strengths : '',
            weakness: response.weakness !== null && response.weakness !== '' ?  response.weakness : '',
            evaluation: response.evaluation !== null && response.evaluation !== '' ?  response.evaluation : '',
          })
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
      })
    }
  },[assetexpertId , dispatch])


  const Form1 = [
    [{ label: 'Specific Topic', type: 'text', readOnly: true, name: 'specific_topic', sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'https://', type: 'url', readOnly: true, name: 'url', sm: 12, md: 6, lg: 6, xl: 6, }],
  ];

  const Form2 = [
    [{ label: 'Branch Opinion', type: 'multi-select', disabled: true, name: 'branch_opinion', placeholder: 'Select Branch', options: branch, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Opinion Percent ', readOnly: true, type: 'number', disabled: true, name: 'opinion_percent', minValue: 0, maxValue: 100,  sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Major Classifications', type: 'multi-select', disabled: true, name: 'major', placeholder: 'Major Classifications', options: majorCatalog, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Minor Classifications', type: 'multi-select', disabled: true, name: 'minor', placeholder: 'Minor Classifications', options: minorCatalog, sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Category', type: 'multi-select', disabled: true, name: 'category', placeholder: 'Category ', options: category, sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Sub-Category ', type: 'text', readOnly: true, name: 'sub_cat', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Topic ', type: 'text', readOnly: true, name: 'topic', sm: 12, md: 6, lg: 6, xl: 6, },
    { label: 'Sub-Topic ', type: 'text', readOnly: true, name: 'sub_topic', placeholder: 'Sub-Topic', sm: 12, md: 6, lg: 6, xl: 6, }],
    [{ label: 'Geographical Area ', type: 'multi-select', disabled: true, name: 'area', placeholder: 'Select Area', options: area, sm: 12, md: 6, lg: 6, xl: 6, },
    { placeholder: '', id: 'Save Classification', type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 6, lg: 6, xl: 6 }]
  ];

  const Form4 = [
    {
      disabled: true, type: 'textarea', name: 'notes', placeholder: 'Permanent Comments / Notes',
    }
  ]

  const ratingOverview = [
    { label: 'Strengths', disabled:  true, type: 'textarea', name: 'strengths', placeholder: 'Strengths' },
    { label: 'Weakness', disabled:  true, type: 'textarea', name: 'weakness', placeholder: 'Weakness' },
    { label: 'Evaluation', disabled:  true, type: 'textarea', name: 'evaluation', placeholder: 'Evaluation' }
  ]


  const CommentBio = ({ assetId, commentList }) => {
    return (
      <>
        {commentList && commentList.length > 0 ? commentList.map((item, index) => (
          <Row key={index} className="no-gutters border border-top-0 border-right-0 border-left-0 mb-2 py-3">
            {/* <Col md={1} className="text-center">
           <span className=" m-auto"><Image width="50px" className="border"  src={user} roundedCircle /></span>
           </Col> */}
            <Col md={12}>
              <Row className="no-gutters px-2">
                <Col md={12} className="font-weight-bold">
                  {item.first_name + ' ' + item.last_name}
                </Col>
                <Col md={12}>
                  {item.comment}
                </Col>
                <Col md={12}>
                  <small> {dateWithTimeFormat(item.created_on)}</small>
                </Col>
              </Row>
            </Col>
          </Row>
        )) : 'No Comments.'}
      </>
    )
  }

  const commentsCount  = comments && comments.length > 0 ? comments.length : 0;
// ACCORDION JSON DATA
  const data = [
    { name: 'Specific Topic Name', open: true, type: 'form', label: formData.specific_topic, fields: Form1, func: (e) => null, formData: formData },
    { name: 'Category', open: true, type: 'form', fields: Form2, func: (e) => null, formData: formData },
    { name: 'Rate Asset',  label: 'When rating an Asset note that 1 is the lowest score and 10 is the best score ', open: true, type: 'custom',  isDragDisabled: true, rating: true, editable: editable, fields: criteria, func: (e) => null },
    { name: 'Permanent Comments / Notes', open: true, type: 'form', fields: Form4, func: (e) => null, formData: comment },
    { name: 'Rating Overview', open: true, type: 'form', label: '', fields: ratingOverview, func: (e) => null, formData: overviewFormData },
    { name: `Comments (${commentsCount})`, open: false, type: 'component', component: <CommentBio assetId={id} commentList={comments ? comments : []} /> }
  ]

  // GET ASSET BY ID
  const getAssetById = useCallback(() => {
    dispatch(loaderAction.loader(true));
    assetService.getAssetById(id).then(([response, error]) => {
      if (response) {
        if(response && response.asset){
          setAssetData(response);
          getExpertAssetData();
          if(response.asset.status === AssetStatus.DRAFT.value){
            dispatch(alertActions.info('Asset Draft.'))
          }else if(response.asset.status === AssetStatus.UNDER_REVIEW.value){
            dispatch(alertActions.info('Asset Under Review.'))
          }else if(response.asset.status === AssetStatus.PUBLISHED.value){
            dispatch(alertActions.info('Asset Published.'))
          }
        }else {
          dispatch(alertActions.error('No Asset Found.'));
          history.push('/dashboard');
        }
        dispatch(loaderAction.loader(false));
      }else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
  }, [id, dispatch , setAssetData  , getExpertAssetData ])

  // GET RATED CRITERIA 
  const getCriteriaRating = useCallback(() => {
    if (assetexpertId !== '' && assetexpertId !== null) {
      assetService.getCriteriaRating(assetexpertId).then(([response, error]) => {
        if (response && response.asset_criteria_rating.length > 0) {
          setEditable(true);
          setCriteria(response.asset_criteria_rating);
        }  else if (error) {
          dispatch(alertActions.error(error.data));
        }
      })
    }
    
  }, [assetexpertId, dispatch])


  const getComment = useCallback(() => {
    if (id !== '') {
      assetService.getComment(id).then(([response, error]) => {
        if (response) {
          setComments(response.bio_comment);
        } else if (error) {
          dispatch(alertActions.error(error.data));
        }
      })
    }

  }, [id, dispatch])

 
   useEffect(() => {
    if (id !== '') {
      getAssetById();
      getCriteriaRating();
      getComment();
    }

    return () => {
      setCriteria([]);
      setFormData([]);
      SetOverviewFormData([]);
      setComments([]);
    }
  }, [id, getAssetById , getCriteriaRating , getComment])


  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }} className="p-0">
          <h4 className="py-3 ">Asset View</h4>
          <AccordionForm data={data} />
          <Button className='float-right' variant='outline-secondary' onClick={() => history.push('/dashboard')}>CLOSE</Button>
        </Col>
      </Row>
    </Container>
  )
}
export default AssetView;