import React, { useState, useCallback } from 'react';
import { Container, Row, Col, ButtonGroup, ToggleButton } from "react-bootstrap";
import { InfoType } from '../../helpers/constants';
import EducationInfo from './EducationInfo';
import JobInfo from './JobInfo';
import AccomplishmentInfo from './AccomplishmentInfo';
import BasicInfo from './BasicInfo';


const UserInfoControl = () => {

  // Setting initial states 
  const [infoType, setInfoType] = useState(InfoType.BASIC);

  // Change Tab
  const changeTabs = useCallback((id) => {
    setInfoType(id);
  }, [])


  return (
    <Container fluid>
      <Row className="py-3">
        <Col md={3}><h4>{infoType.title}</h4></Col>
        <Col md={6}>
          <ButtonGroup toggle>
            {Object.values(InfoType).map((obj, idx) => {
              return obj.id ? (
                <ToggleButton key={idx} type="radio" variant="light" name="catTypes"
                  value={obj.id} checked={infoType.id === obj.id}
                  onChange={(e) => changeTabs(InfoType.getType(obj.id))}>
                  {obj.title}
                </ToggleButton>
              ) : ''
            }
            )}
          </ButtonGroup>
        </Col>
        <Col md={2} className="text-right"/>
      </Row>
      {infoType.id === InfoType.BASIC.id ? <BasicInfo/> : null }
      {infoType.id === InfoType.EDUCATION.id ? <EducationInfo/> : null }
      {infoType.id === InfoType.JOB.id ? <JobInfo/> : null }
      {infoType.id === InfoType.ACCOMPLISH.id ? <AccomplishmentInfo/> : null }
    </Container>
  )
}

export default UserInfoControl;