import { createActionType } from '../actions/actionBase';

export const ACCOUNT = {
    CHANGE_PASSWORD: createActionType('ACCOUNT__CHANGE_PASSWORD'),
    LOGIN: createActionType('ACCOUNT__LOGIN'),
    LOGOUT: createActionType('ACCOUNT__LOGOUT'),
    REFRESH__TOKEN: createActionType('REFRESH__TOKEN'),
    PASSWORD_FORGOT: createActionType('ACCOUNT__PASSWORD_FORGOT'),
    UPDATE_SESSION: createActionType('UPDATE__USER_SESSION')
}

export const APPLICATION = {
    GET_ALL_APPLICATION: createActionType('GET__ALL__APPLICATION'),
    GET_LIST_APPLICATION: createActionType('GET__LIST__APPLICATION'),
    GET_APP_DATA: createActionType('GET__APP__DATA'),
    APPLICATION_VERIFY: createActionType('APPLICATION__VERIFY'),
    GET_APPLICATION_BY_ID: createActionType('GET__APPLICATION__BY__ID'),
    CREATE_APPLICATION: createActionType('CREATE__APPLICATION'),
    UPDATE_APPLICATION: createActionType('UPDATE__APPLICATION'),
    CREATE_SPECIALIZATION: createActionType('CREATE__SPECIALIZATION'),
    UPDATE_SPECIALIZATION: createActionType('UPDATE__SPECIALIZATION'),
    GET_SPECIALIZATION_BY_ID: createActionType('GET__SPECIALIZATION__BY__ID'),
}
export const ASSETS = {
    GET_LIST_ASSETS: createActionType('GET_LIST_ASSETS'),
}
export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    INFO: 'ALERT_INFO',
    WARNING: 'ALERT_WARNING',
    CLEAR: 'ALERT_CLEAR'
};

export const Loader = {
    LOADER : 'LOADER_APP'
}


export const USER = {
    FETCH_ALL: createActionType('USER__FETCH_ALL'),
    ADD: createActionType('USER__ADD'),
    UPDATE: createActionType('USER__UPDATE'),
    DELETE: createActionType('USER__DELETE'),
    USER_GROUP: createActionType('USER__USER_GROUP'),
    RESEND_INVITE: createActionType('RESEND__INVITE')
}

export const CATALOG = {
    CATALOG__FETCH_ALL: createActionType('CATALOG__FETCH_ALL'),
    CATALOG__FETCH_ONE: createActionType('CATALOG__FETCH_ONE'),
    CATALOG__CREATE_ONE: createActionType('CATALOG__CREATE_ONE'),
    CATALOG__UPDATE_ONE: createActionType('CATALOG__UPDATE_ONE'),
    CATALOG__DELETE: createActionType('CATALOG__DELETE'),
    CATALOG__MAJOR: createActionType('CATALOG__MAJOR'),
    CATALOG__MINOR: createActionType('CATALOG__MINOR'),
    CATALOG__CATEGORY: createActionType('CATALOG__CATEGORY')

}

export const CRITERIA = {
    FETCH_ALL: createActionType('CRITERIA__FETCH_ALL'),
    CRITERIA__POST: createActionType('CRITERIA__POST')
}

export const BUILDOUT = {
    FETCH_ALL: createActionType('BUILDOUT__FETCH_ALL'),
    BUILDOUT__ADD: createActionType('USER__ADD'),
    BUILDOUT__UPDATE: createActionType('USER__UPDATE'),
    BUILDOUT__DELETE: createActionType('USER__DELETE')

}