import { CRITERIA } from "../helpers/actionTypes";

const initState = {
  isFetching: false,
  isDeleting: false,
  allCriteria: [],
};

export function criteria(state = initState, { payload, type, error }) {
  switch (type) {
    case CRITERIA.FETCH_ALL.REQUEST:
      return {
        ...initState,
        isFetching: true,
      };
    case CRITERIA.FETCH_ALL.SUCCESS:
      return {
        ...state,
        allCriteria: payload.data.results,
        isFetching: false,
      };
    case CRITERIA.FETCH_ALL.FAILURE:
      return {
        ...state,
        isFetching: false,
        allCriteria: [],
        error: error,
      };
    case CRITERIA.CRITERIA__POST.REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case CRITERIA.CRITERIA__POST.SUCCESS:
      return {
        ...state,
        isUpdating: false,
        allCriteria: payload.data.results,
      };
    case CRITERIA.CRITERIA__POST.FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: error,
      };
    default:
      return state;
  }
}
