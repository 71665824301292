import React, { useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HasPermission } from '../HasPermission';
import TooltipHover from '../TooltipHover';

const DateField = ({ label, id, permission, md, sm, lg, xs, xl, opts, disabled, value, placeholder, name, handleEvent, isClearable, info }) => {
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        if (value){
            setStartDate(new Date(value));
        }else{
            setStartDate(null);
        }
    }, [value])
    
    const handleChange = (date) => {
        const data = { name: name, value: new Date(date).toISOString() };
        handleEvent(data);
    }
    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}>
                        <div className='d-inline'>
                            {label ? <Form.Label className={info?"":"d-block"}>{label}</Form.Label> : ''}
                            {info ? <TooltipHover type='icon' iconClass='ml-0' variant='link' icon='info-circle' message={info} handleEvent={() => null} />: ""}
                        </div>
                        <DatePicker
                            className="form-control"
                            selected={startDate}
                            onChange={(date) => handleChange(date)}
                            dateFormat={placeholder}
                            placeholderText={placeholder}
                            showMonthYearPicker
                            disabledKeyboardNavigation
                            isClearable={isClearable}
                            disabled={disabled}
                            {...opts}
                        />
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

DateField.propTypes = {
    id: PropTypes.string,
    isClearable: PropTypes.any,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    permission: PropTypes.any,
    opts: PropTypes.any,
    md: PropTypes.any,
    sm: PropTypes.any,
    lg: PropTypes.any,
    xs: PropTypes.any,
    xl: PropTypes.any,
    handleEvent: PropTypes.func.isRequired
};

DateField.defaultProps = {
    id: '',
    name: '',
    value: '',
    isClearable: true,
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: 'dd/MM/yyyy',
    permission: '',
    label: '',
    opts: []
};
export default DateField;