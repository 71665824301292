import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'donotRank/';

export async function fetchAll(list_type, limit , offset,  ordering="", search='', filters) {
    list_type = list_type||2
    return await handleApi(axiosClient.get(`${BASE}?list_type=${list_type}&limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&${filters}`));
}

export async function addDomain(data) {
    return await handleApi(axiosClient.post(`${BASE}`, data));
}

export async function updateDomain(id, data) {
    return await handleApi(axiosClient.put(`${BASE}${id}/`, data));
}

export async function deleteDomain(id) {
    return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}

export async function getListType() {
    return await handleApi(axiosClient.get(`${BASE}list_type_enums/`));
} 

export async function acceptAllGreyPendingList(id) {
    return await handleApi(axiosClient.get(`${BASE}${id}/accept_all_grey_pending_list/`))
}

export async function rejectAllGreyPendingList(id) {
    return await handleApi(axiosClient.get(`${BASE}${id}/reject_all_grey_pending_list/`))
}