import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'criteria/';

export async function fetchAll() {
  return await handleApi(axiosClient.get(`${BASE}`));
}

export async function saveCriteria(data) {
  return await handleApi(axiosClient.post(`${BASE}list/` , data));
}

// export async function saveCriteria(data) {
//   return await handleApi(axiosClient.put(`${BASE}`, data));
// }