import React, { useCallback, useEffect , useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import PageNotFound from '../../shared/PageNotFound';
import Accomplishment from '../account/profile/Accomplishment';
import Education from '../account/profile/Education';
import Job from '../account/profile/Job';
import ProfileComponent from '../account/ProfileComponent';
import SecurityComponent from '../account/SecurityComponent';
import SettingComponent from '../account/SettingComponent';
import ApplicationBio from '../asset/ApplicationBio';
import ApplicationExpert from '../asset/ApplicationExpert';
import ApplicationReview from '../asset/ApplicationReview';
import Applications from '../asset/Applications';
import AssetExpertList from '../asset/AssetExpertList';
import AssetList from '../asset/AssetList';
import BioList from '../asset/BioList';
import Qualifications from '../asset/Qualifications';
import AssetsRate from '../asset/RateAssets/AssetsRate';
import RatedFormTable from '../asset/RateFormTable';
import RateFormView from '../asset/RateFormView';
import Specialization from '../asset/Specialization';
import CatalogPage from '../catalog/CatalogPage';
import ApplicationView from '../dashboard/ApplicationView';
import AssetView from '../dashboard/AssetView';
import BioView from '../dashboard/BioView';
import DashboardComponent from '../dashboard/DashboardComponent';
import Sidebar from '../layout/Sidebar';
import TopHeader from '../layout/TopHeader';
import ChallengesList from '../members/ChallengesList';
import ConstructiveCriticism from '../members/ConstructiveCriticism';
import SupportAndContactMain from '../members/SupportAndContactMain';
import MemberList from '../members/MemberList';
import AssetDiscussionListing from '../members/AssetDiscussionListing';
import TopicRequestList from '../members/TopicRequestList';
import PredefinedResponseComponent from '../members/PredefinedResponseComponent';
import Advertising from '../system/Advertising';
import BuildoutList from '../system/BuildoutList';
import MembershipInvitationList from '../system/MembershipInvitationList';
import Faqs from '../system/Faqs';
import RatingCriteria from '../system/RatingCriteria';
import TrustScore from '../system/TrustScore';
import UserInfoControl from '../userDetails/UserInfoControl';
import DBSearchComponent from '../users/DBsearchComponent';
import Invites from '../users/Invites';
import SuggestExpertForm from '../users/SuggestExpertForm';
import UserList from '../users/UserList';
import { PrivateRoute } from './PrivateRoute';
import SearchPopup from '../layout/SearchPopup';
import TooltipHover from '../../shared/TooltipHover';
import { useBoolean } from '../../hooks/basic';
import Feedback from './Feedback';
import FeedbackListing from '../system/FeedbackListing';
import PromotionRequestList from '../promotion/PromotionRequestList'
import UserPromotion from '../promotion/UserPromotion'
import DoNotRankList from '../system/DoNotRankList';
import PendingGreyList from '../system/PendingGreyList';

const MainPage = ({ component: Component, permission, ...rest }) => {
  const [toggleOpened, setToggleOpened] = useState(true);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const handleWindowResize = useCallback(() => {
    if (window.innerWidth <= 620) {
      setToggleOpened(false);
    } else {
      setToggleOpened(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize]);


  const setToggle = useCallback(() => {
    setToggleOpened(!toggleOpened);
  },[toggleOpened])


  const openModal = useCallback(() => {
    showModal();
  }, [showModal]);

  

  return (
    <div>
      <TopHeader toggle={() => setToggle()} />
      <div className="App wrapper">
        <Sidebar toggle={() => setToggle()} isOpen={toggleOpened} />
        <Container fluid className={toggleOpened ? 'is-open content' : 'content'} >
          <Row>
            <Col className="mx-md-4">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                
                <PrivateRoute path={`/dashboard`} component={DashboardComponent} />
                <PrivateRoute path={'/search'} component={SearchPopup} />
                <PrivateRoute component={UserPromotion} path="/promotion-offer/:token" />
                {/* ACCOUNT ROUTE */}
                <PrivateRoute path="/account/profile" component={ProfileComponent} />
                <PrivateRoute path="/account/education" component={Education} />
                <PrivateRoute path="/account/job" component={Job} />
                <PrivateRoute path="/account/accomplishment" component={Accomplishment} />
                <PrivateRoute path="/account/security" component={SecurityComponent} />
                <PrivateRoute path="/account/setting" component={SettingComponent} />
                <PrivateRoute path="/userDetails/:userId" component={UserInfoControl} />

                {/* ASSET ROUTE */}
                <PrivateRoute path="/assetForm" permission='qrata.add_asset' exact component={AssetsRate} />
                <PrivateRoute path="/assetFormEdit/:id" permission='qrata.view_asset' exact component={AssetsRate} />
                <PrivateRoute path="/assets" permission="qrata.asset_list" component={AssetList} />
                <PrivateRoute path="/assetList" permission='qrata.expert_list' component={AssetExpertList} />
                <PrivateRoute path="/expertAssetView/:id/:assetexpertId/:statusExpert" exact component={RateFormView} />
                <PrivateRoute path="/expertAssetScoring/:id/:assetexpertId/:statusExpert" exact component={RateFormView} />
                <PrivateRoute path="/rateFormTableView/:id" component={RatedFormTable} />
                <PrivateRoute path="/assetView/:id/:assetexpertId" exact component={AssetView} />


                {/* APPLICATION ROUTE */}
                <PrivateRoute path="/specialization" permission='qrata.specialization_list' exact component={Specialization} />
                <PrivateRoute path="/applicationExpert" permission='qrata.specialization_list' exact component={ApplicationExpert} />
                <PrivateRoute path="/applicationExpertEdit/:applicationId" permission='qrata.specialization_list' exact component={ApplicationExpert} />
                <PrivateRoute path="/applicationView/:applicationId" exact component={ApplicationView} />
                <PrivateRoute path="/bio/:applicationId" component={ApplicationBio} />
                <PrivateRoute path="/bioView/:applicationId" component={BioView} />
                <PrivateRoute path="/bioList" permission='qrata.bio_list' exact component={BioList} />
                <PrivateRoute path="/qualifications" permission='qrata.qualifications_list' component={Qualifications} />


                <PrivateRoute path="/applicationReview/:applicationId" permission='qrata.approve_application' component={ApplicationReview} />
                <PrivateRoute path="/applications" permission='qrata.approve_application' component={Applications} />

                {/* USER ROUTE */}
                <PrivateRoute path="/user/suggestExpertForm" component={SuggestExpertForm} />
                <PrivateRoute path="/user/dbSearch" component={DBSearchComponent} />
                <PrivateRoute path="/user/invites" permission='qrata.users_access' component={Invites} />
                <PrivateRoute path="/user/list" permission='qrata.users_access' component={UserList} />

                {/* MEMBER ROUTE */}
                <PrivateRoute path="/members/list" permission='qrata.members_access' component={MemberList} />
                <PrivateRoute path="/members/support" permission='qrata.members_access' component={SupportAndContactMain} />
                <PrivateRoute path="/members/topicRequest" permission='qrata.members_access' component={TopicRequestList} />
                <PrivateRoute path="/members/challenges" permission='qrata.members_access' component={ChallengesList} />
                <PrivateRoute path="/members/constructiveCriticism" permission='qrata.members_access' component={ConstructiveCriticism} />
                <PrivateRoute path="/members/assetslistWithComments" permission='qrata.members_access' component={AssetDiscussionListing} />

                {/* SYSTEM ROUTE */}
                <PrivateRoute path={`/system/trustScore`} permission='qrata.system_access' component={TrustScore} />
                <PrivateRoute path={`/system/buildoutList`} permission='qrata.system_access' component={BuildoutList} />
                <PrivateRoute path={`/system/doNotRankList`} permission='qrata.system_access' component={DoNotRankList} />
                <PrivateRoute path={`/system/pendingGreyList/:id`} permission='qrata.system_access' component={PendingGreyList} />
                <PrivateRoute path={`/system/catalog/:type`} permission='qrata.system_access' component={CatalogPage} />
                <PrivateRoute path={`/system/membershipInvitation`} permission='qrata.system_access' component={MembershipInvitationList} />
                <PrivateRoute path={`/system/advertising`} permission='qrata.system_access' component={Advertising} />
                <PrivateRoute path={`/system/ratingCriteria`} permission='qrata.system_access' component={RatingCriteria} />
                <PrivateRoute path={`/system/faq`} permission='qrata.change_faq' component={Faqs} />
                <PrivateRoute path={`/system/feedback`} permission='qrata.change_faq' component={FeedbackListing} />
                <PrivateRoute path={`/system/promotion-requests`} permission='auth.change_user' component={PromotionRequestList} />
                <PrivateRoute path="/system/predefined-responses" permission='qrata.system_access' component={PredefinedResponseComponent}/>

                
                <Route path='*' component={PageNotFound} />
              </Switch>
              <Feedback isVisible={isVisible} hide={hideModal} />
             <Row>
               <Col className="feedback-icon text-right position-fixed" md={2}>
              <TooltipHover  type='button' btnClass={'float-btn'} btnSize='lg' handleEvent={() => openModal()} variant='link' iconClass='text-white' btnIcon='comment-dots' message='Feedback' />
              </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div >
  );
}
export default MainPage;