import React, { useEffect, useState } from 'react'
import SearchField from '../../shared/SearchField';
import * as doNotRankService from '../../services/doNotRank';
import { useBoolean } from "../../hooks/basic";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as loaderAction from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import TooltipHover from "../../shared/TooltipHover";
import { dateOnlyFormat, defaultLimit, DonotRankListType, history } from '../../helpers';
import { Container, Row, Col, Badge, Form } from 'react-bootstrap';
import AppTable from "../../shared/AppTable";
import DoNotRankForm from './DoNotRankForm';
import ConfirmModal from '../../shared/ConfirmModal';
import DoNotRankDeleteForm from './DoNotRankDeleteForm';
import * as assetGreyService from '../../services/donotRankAsset';
import AsyncAppTable from '../../shared/AsyncAppTable';

const DoNotRankList = () => {

    const dispatch = useDispatch();
    const [doNotRankData, setDoNotRankData] = useState([]);
    const [isVisible, showModal, hideModal] = useBoolean(false);
    const [domainData, setDomainData] = useState({});
    const [search, setSearch] = useState('');
    const [listType, setListType] = useState(2)
    const [confirm, setConfirm] = useState({ show: false, id: 0 });
    const [count, setCount] = useState()
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0)
    const fetchIdRef = React.useRef(0);
    const [callFetch, setCallFetch] = useState(false);
    const [resultCount, setResultCount] = useState(0)
    const [pageLimit, setPageLimit] = useState(defaultLimit);

    const fetchData = React.useCallback((
        {pageSize, pageIndex, sortedField = '', filter,}
         ) => {
        const fetchId = ++fetchIdRef.current
        
        setLoading(true)
        dispatch(loaderAction.loader(true));
        setTimeout(() => {
            if (fetchId === fetchIdRef.current) {
                const limit = pageSize;
                setPageLimit(limit)
                let offset = pageSize * pageIndex
                doNotRankService.fetchAll(listType,
                     limit, offset, sortedField, search, filter
                     ).then(([response, error]) => {
                    if (response) {
                        setPageCount(Math.ceil(response.count / pageSize))
                        setResultCount(response.count)
                        setDoNotRankData(response.results);
                    } else if (error) {
                        dispatch(alertActions.error(error.response))
                    }
                    dispatch(loaderAction.loader(false));
                    setLoading(false);
                    setCallFetch(false);
                })
            }
        }, 100)
    }, [dispatch, search, listType])


    const columns = [
        {
            Header: "Action",
            accessor: "action",
            className: "text-center py-1",
            filteringOff: true,
            Cell: (cell) => (
                <div>
                    <TooltipHover type='button' iconClass='default' btnSize='sm' variant='link' btnIcon='edit' message='Edit' handleEvent={() => openModal(cell.row.original)} />
                    <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete' handleEvent={() => handleCount(cell.row.original.asset_count, cell)} />
                    {listType == 2 ?
                        <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='default' btnIcon='eye' message='View Assets' handleEvent={() => history.push('/system/pendingGreyList/' + cell.row.original.id)} />
                     : ''
                    }
                </div>
            ),
        },
        {
            Header: "Domain",
            accessor: "domain",
            sortType: "basic",
            filtering: true,

            Cell: (cell) =>
                <div>
                    <TooltipHover type='text' text={cell.row.original.domain} message={cell.row.original.domain} textClass='text-ellipsis d-block' handleEvent={() => null} />
                </div>
        },
        {
            Header: "Reason",
            accessor: "reason",
            sortType: "basic",
            filtering: true,

        },
        {
            Header: "Tags",
            accessor: 'tags',
            filtering: true,
            id: "tags__tag",
            sortType: "basic",
            Cell: (row) => (<div>{row.row.original.tags.map((v) => {
                return (
                    <>
                        <Badge className='bg-primary' style={{ 'margin': '2px' }} key={v.id} pill bg="primary">{v.tag}</Badge>
                    </>
                )
            })}</div>)
        },
        {
            Header: "Created On",
            accessor: "created_on",
            sortType: "basic",

            Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
        },

    ];

    const openModal = useCallback((data) => {
        setDomainData(data);
        showModal();
    }, [showModal]);

    const handleDelete = useCallback((id) => {
        dispatch(loaderAction.loader(true));
        doNotRankService.deleteDomain(id).then(([response, error]) => {
            const list = doNotRankData.filter(obj => obj.id !== id);
            setDoNotRankData(list);
            if (error) {
                console.log(error);
                dispatch(alertActions.error(error.response))
            }
            dispatch(alertActions.success('Deleted successfully'))
            dispatch(loaderAction.loader(false))
        });
        setCallFetch(true);
        setConfirm({ show: false, id: 0 })
    }, [doNotRankData, dispatch])

    const handleRejectAll = useCallback((id) => {
        dispatch(loaderAction.loader(true))
        doNotRankService.rejectAllGreyPendingList(id).then(([response, error]) => {
            if (response) {
                const list = doNotRankData.filter(obj => obj.id !== id);
                setDoNotRankData(list);
                dispatch(alertActions.success(response.success))
            } else if (error) {
                dispatch(alertActions.error(error.response))
            }
            dispatch(loaderAction.loader(false))
        })
        setConfirm({ show: false })
        setCallFetch(true);
    }, [doNotRankData,dispatch])

    // useEffect(() => {
    //     fetchData()
    // }, [fetchData])

    const handleCount = ((value, cell) => {
        setCount(value)
        setConfirm({ show: true, id: cell.row.original.id })

    })

    const handleAcceptAll = useCallback((id) => {
        dispatch(loaderAction.loader(true))
        doNotRankService.acceptAllGreyPendingList(id).then(([response, error]) => {
            if (response) {
                const list = doNotRankData.filter(obj => obj.id !== id);
                setDoNotRankData(list);
                dispatch(alertActions.success(response.success))
            } else if (error) {
                console.log('error', error);
                dispatch(alertActions.error(error.response))
            }
            dispatch(loaderAction.loader(false))
            setLoading(false)
        })
        setConfirm({ show: false, id: 0 })
        setCallFetch(true);
    }, [doNotRankData,dispatch])

    return (
        <Container fluid className="px-0">
            <DoNotRankForm data={domainData} isVisible={isVisible} hide={hideModal} output={() => setCallFetch(true)} />

            <DoNotRankDeleteForm isPending={count} data={listType} show={confirm.show} onHide={() => setConfirm({ show: false, id: 0 })} acceptConfirm={(id) => handleDelete(confirm.id)}
                acceptAll={(id) => handleAcceptAll(confirm.id)} rejectAll={(id) => handleRejectAll(confirm.id)} title={count > 0 ? `You have ${count} assets pending for approval. Please, select one of the options.` : 'Are you sure you want to delete?'} />

            <Row className="no-gutters" >
                <Col md={6} className="mb-md-0 mb-3">
                    <h4> DoNot Rank List </h4>
                </Col>
                <Col md={0} sm={1} className="mb-md-0 mb-2 pr-md-3 ">
                    <Form.Control as="select" size={'md'} value={listType}
                        onChange={(e) => setListType(Number(e.target.value))}>
                        {Object.values(DonotRankListType).map((item, i) => { return item.value ? <option value={item.value} key={i}>{item.label}</option> : null }
                        )}
                    </Form.Control>
                </Col>
                <SearchField md={4} xs={10} action={setSearch} />
                <Col md={1} xs={2} className="text-right">
                    <TooltipHover type='button' btnSize='md' variant='primary' message='Add Link' btnIcon='plus' iconClass='text-white' handleEvent={() => openModal(null)} />
                </Col>
            </Row>
            {/* <AppTable columns={columns} globalFilter={search} data={doNotRankData} /> */}
            <AsyncAppTable
                columns={columns}
                data={doNotRankData}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                resultCount={resultCount}
                filtering={true}
                callFetch={callFetch} />
        </Container>
    )
}

export default DoNotRankList