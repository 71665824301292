import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'topic_suggest/';

export async function createTopic(data) {
  return  handleApi(axiosClient.post(`${BASE}create/` , data));
}

export async function createComment(id, data) {
  return  handleApi(axiosClient.post(`${BASE}${id}/create_comment/` , data));
}

export async function topicRequestListing(user = '',  limit , offset, ordering='', filter, search, status=1) {
  return  handleApi(axiosClient.get(`${BASE}list/?status=${status}&${user !== ''?'user='+user+'&':''}${search !== ''?'search='+search+'&':''}limit=${limit}&offset=${offset}&ordering=${ordering}&${filter}`));
}

export async function deleteTicket(id) {
  return  handleApi(axiosClient.delete(`${BASE}${id}/`));
}

export async function getComment(id) {
  return  handleApi(axiosClient.get(`${BASE}${id}/comments/`));
}

export async function updateQuery(id, data) {
  return  handleApi(axiosClient.patch(`${BASE}${id}/` , data));
}

export async function setParent(currentParent) {
  return  handleApi(axiosClient.post(`${BASE}${currentParent}/set-parent/`));
}

export async function changeParent(data, id) {
  return  handleApi(axiosClient.post(`${BASE}${id}/change-parent/`, data));
}

export async function removeParent(id) {
  return  handleApi(axiosClient.post(`${BASE}${id}/remove-parent/`));
}

export async function topicRequestTitle(search) {
  return  handleApi(axiosClient.get(`${BASE}get-topics/?search=${search}`));
}