import axiosClient from '../helpers/axiosClient';

import { APPLICATION } from '../helpers/actionTypes'
import { createAction } from '../actions/actionBase';

const BASE = 'application/';
export function fetchApplicationList() {
    return createAction({
        type: APPLICATION.GET_LIST_APPLICATION,
        action: () => axiosClient.get(`${BASE}list/`)
    });
}

