import React , { useState, useCallback } from 'react';
import * as predefinedResponseService from '../../services/predefinedResponse';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import TooltipHover from '../../shared/TooltipHover';
import { useDispatch } from 'react-redux';
import AsyncAppTable from '../../shared/AsyncAppTable';
import { useBoolean } from '../../hooks/basic';
import PredefinedResponseModal from './PredefinedResponseModal';
import { defaultLimit, dateOnlyFormat } from '../../helpers';
import { Col, Container, Row } from 'react-bootstrap';
import SearchField from '../../shared/SearchField';

const PredefinedResponseComponent = () => {

const dispatch = useDispatch();
const [predefinedResponseData , setPredefinedResponseData] = useState([]);
const [loading , setLoading ] = useState(false);
const [pageCount, setPageCount] = useState(0);
const [isVisible, showModal, hideModal] = useBoolean(false);
const [editData , setEditData] = useState('');
const [resultCount, setResultCount] = useState(0);
const [search , setSearch] = useState('');
const [callFetch, setCallFetch] = useState(false);

 const fetchData = React.useCallback(({ pageSize, pageIndex, filter, sortedField=""}) => {
  setLoading(true);
  setTimeout(() => {
      let offset = pageSize * pageIndex;
      dispatch(loaderAction.loader(true));
      predefinedResponseService.predefinedResponseListing(pageSize, offset, search, filter, sortedField).then(([response , error]) => {
        if(response){
          setPredefinedResponseData(response.results);
          setResultCount(response.count)
          setPageCount(Math.ceil(response.count / pageSize))
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
      setLoading(false);
      setCallFetch(false);
  }, 100)
}, [dispatch, search])

const openModal = useCallback((data) => {
  if(data) setEditData(data);
  showModal();
}, [showModal]);


const closeModal = useCallback(() => {
  setEditData('');
  // fetchData(defaultLimit ,0);
  hideModal();
  setCallFetch(true);
},[hideModal]);

const deleteSuggestion = useCallback((id) => {
    setLoading(true);
    setTimeout(() => {
        dispatch(loaderAction.loader(true));
        predefinedResponseService.deletePredefinedResponse(id).then(([response , error]) => {
            // const list = predefinedResponseData.filter((item) => item.id !== id);
            // setPredefinedResponseData(list);
            dispatch(alertActions.success('Suggestion Deleted Successfully'));
            setCallFetch(true)
          if(error){
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
        setLoading(false);
    }, 100)
  }, [dispatch]);

const predefinedResponseColumns = [
  // {
  //   Header: "Suggestion Id",
  //   accessor: "id",
  //   sortType: "basic",
  //   //filtering:true,
  // },
  {
    Header: "Suggestion",
    accessor: "suggestion",
    sortType: "basic",
    filtering:true,
  },
  {
    Header: "Created On",
    accessor: "created_on",
    sortType: "basic",
    //filtering:true,
    className:"text-nowrap",
    Cell: (row) => (<div>{row.row.original.created_on !== "" ? (dateOnlyFormat(row.row.original.created_on)): null}</div>)
  },
  {
    Header: "Action",
    accessor: "action",
    sortingOff: true,
    className: 'text-center',
    Cell: (cell) => (
      <div>
        <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='edit' iconClass='default'  message='Edit' handleEvent={() => openModal(cell.row.original)} />
        <TooltipHover type='button' btnSize='sm' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete' handleEvent={() => deleteSuggestion(cell.row.original.id)}/>
      </div>
    ),
  },
];

  return (
    <Container fluid className="px-0">
        <Row>
            <Col md={8} sm={12} xs={12} className="mb-md-0 mb-3"><h4>Predefined Response Suggestions</h4></Col>
            <SearchField md={3} action={setSearch}/>
            <Col md={1} sm={1}  xs={2} className='text-right mb-md-0 mb-2'>
            <TooltipHover type='button' btnSize='md' variant='primary' btnIcon='plus' iconClass='text-white' message='Add Suggestion' handleEvent={() => openModal({id: 0})}/>
            </Col>
        </Row>
        <PredefinedResponseModal data={editData} title='Add a suggestion' isVisible={isVisible} hide={closeModal} refreshData={() => setCallFetch(true)} predefinedResponseData={predefinedResponseData}/>
        <AsyncAppTable
            columns={predefinedResponseColumns}
            data={predefinedResponseData}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            resultCount={resultCount} 
            filtering={true} 
            callFetch={callFetch}
            />
    </Container>
  )
}
export default PredefinedResponseComponent;