import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'topic_challenge/';

export async function createTopic(data) {
  return await handleApi(axiosClient.post(`${BASE}create/` , data));
}

export async function createComment(id, data) {
  return await handleApi(axiosClient.post(`${BASE}${id}/create_comment/` , data));
}

export async function RequestListing(user = '',   limit , offset, ordering, filters, search, status=1) {
  return await handleApi(axiosClient.get(`${BASE}list/?status=${status}&${user !== ''?'user='+user+'&':''}${search !== ''?'search='+search+'&':''}limit=${limit}&offset=${offset}&ordering=${ordering}&${filters}`));
}

export async function deleteTicket(id) {
  return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}

export async function getComment(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/comments/`));
}

export async function getAsset(search) {
  return await handleApi(axiosClient.get(`${BASE}${search}/asset_list/`));
}

export async function updateQuery(id, data) {
  return await handleApi(axiosClient.patch(`${BASE}${id}/` , data));
}
