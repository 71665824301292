import React, { useState, useEffect } from "react";
import { Nav, Collapse, Col, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";
import { CatalogType } from "../../helpers/constants";
import { HasPermission } from "../../shared/HasPermission";
import { history } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { Storage } from '../../helpers/storage';
import {fetchApplicationList} from '../../actions/application';
import {fetchAssetsList} from '../../actions/assets';
import * as loaderAction from "../../actions/loader";
import { NavLink } from "react-router-dom";

const Sidebar = (props) => {
  const { location } = props;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(location.pathname.startsWith("/members/"));
  const [open3, setOpen3] = useState(location.pathname.startsWith("/system/"));
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.loginData);
  const group = Storage.getSession().group;
  let id = '';
    if(loginData.permissions.includes('qrata.view_asset_editor')){
        id = loginData.id;
    }else {
      id = ''
    }
    useEffect(() => {
      dispatch(loaderAction.loader(true));
      if(group[0] === 1){ 
        dispatch(fetchApplicationList());
      }
      else if(group[0] === 4){
         dispatch(fetchAssetsList(id , 2, 5, 0, '', '', ''));
      }
      dispatch(loaderAction.loader(false));
    }, [dispatch])
    const applications = useSelector((state) => state.application.appData.applications);
    const assets = useSelector((state) => state.assets.assetsData.results);
    const loadingAssets = useSelector((state) => state.assets.isLoading);
    const loadingApplication = useSelector((state) => state.application.isLoading);

    const [disable, setDisable] = useState(false);

    useEffect(() => {
      if(group[0] === 1 && !applications){
        setDisable(true);
      }else if(group[0] === 4 && !assets){
        setDisable(true);
      }else{
        setDisable(false);
      }
    }, [group, applications, assets, disable])

    useEffect(() => {
          if(loadingApplication === false && (group[0] === 1 && !applications)){
            history.push("/specialization"); 
          }else if(loadingAssets === false && (group[0] === 4 && !assets)){
             history.push("/assets");
          }
       }, [loadingApplication, loadingAssets])
    
  const handleLink = (link) => {
    if (window.innerWidth <= 620) props.toggle()
     history.push(link); 
  }

  return (
    <div className={props.isOpen ? ' is-open sidebar shadow-sm' : 'sidebar shadow-sm'}>

      <Nav defaultActiveKey="/dashboard" className="flex-column pt-4 pb-5 mb-5">
        <Nav.Link eventKey="dashboard" onClick={() => handleLink('/dashboard')} active={"/dashboard" === location.pathname} disabled={disable}>
          <FontAwesomeIcon className=" text-info nav-icons " size="sm" icon="tachometer-alt" />
            Dashboard
        </Nav.Link>


      <HasPermission permission="qrata.asset_list">
          <Nav.Link eventKey="assetRated"  onClick={() => handleLink('/assets')}>
             <FontAwesomeIcon className="text-info nav-icons " size="sm" icon="database"/>
               Assets
          </Nav.Link>
        </HasPermission>

        
        <HasPermission permission="qrata.expert_list">
          <Nav.Link eventKey="assetRated" onClick={() => handleLink('/assetList')} disabled={disable}>
            <FontAwesomeIcon className="text-info nav-icons " size="sm" icon="database"/>
              {loginData.is_super_expert ? 'Rate Assets': 'Assets'}
          </Nav.Link>
        </HasPermission>
       

        <HasPermission permission='qrata.specialization_list'>
          <Nav.Link eventKey="specialization"  onClick={() => handleLink('/specialization')}>
          <FontAwesomeIcon className="text-info nav-icons " size="sm" icon="file-invoice" />
            My Specializations</Nav.Link>
        </HasPermission>

      

      <HasPermission permission='qrata.approve_application'>
      <Nav.Link eventKey="link-74" onClick={() => setOpen(!open)} aria-controls="nav2" aria-expanded={open} disabled={disable}>
        <FontAwesomeIcon className="text-info nav-icons" icon="user-graduate" size="sm" />
            Experts
        <FontAwesomeIcon className="text-info position-absolute sidebar-icon" size="sm" icon={open ? 'angle-down' : 'angle-right'} />
      </Nav.Link>
      <Navbar.Collapse in={open}>
        <Collapse in={open}>
          <Col className="sub-menu p-0 py-2" id="nav1">
            {/* <HasPermission permission="qrata.asset_list">
              <Nav.Link eventKey="assetRated" as={NavLink} to="/assets">Assets</Nav.Link>
            </HasPermission>
            <HasPermission permission="qrata.expert_list">
              <Nav.Link eventKey="assetRated" as={NavLink} to="/assetList">Assets</Nav.Link>
            </HasPermission> */}
            <HasPermission permission='qrata.users_access'>
            <Nav.Link eventKey="invites" onClick={() => handleLink('/user/invites')} >Pool</Nav.Link>
            </HasPermission>
            <HasPermission permission='qrata.approve_application'>
              <Nav.Link eventKey="applications" onClick={() => handleLink('/applications')} >Applications</Nav.Link>
            </HasPermission>
            <HasPermission permission='qrata.qualifications_list'>
              <Nav.Link eventKey="bio" onClick={() => handleLink('/qualifications')}>Qualifications</Nav.Link>
            </HasPermission>
            <HasPermission permission='qrata.bio_list'>
              <Nav.Link eventKey="bio" onClick={() => handleLink('/bioList')}>Public Bios</Nav.Link>
            </HasPermission>
          </Col>
        </Collapse>
      </Navbar.Collapse>
      </HasPermission>
      

      <HasPermission permission='qrata.users_access'>
        <Nav.Link eventKey="link-5" onClick={() => handleLink('/user/list')}>
          <FontAwesomeIcon className="text-info nav-icons" icon="user-friends" size="sm" />
            Managers
        </Nav.Link>
      </HasPermission>
      <HasPermission permission='qrata.members_access'>
        <Nav.Link className="d-md-inline-flex d-block" eventKey="link-15" onClick={() => setOpen2(!open2)} aria-controls="nav3" aria-expanded={open2} disabled={disable}>
          <FontAwesomeIcon className="text-info nav-icons" icon="users" size="sm" />
            Members
            <FontAwesomeIcon className="text-info position-absolute sidebar-icon" size="sm" icon={open2 ? 'angle-down' : 'angle-right'} />
        </Nav.Link>
        <Navbar.Collapse in={open2}>
          <Collapse in={open2}>
            <Col className="sub-menu p-0 py-2">
              <Nav.Link eventKey="link-11" onClick={() => handleLink('/members/list')}>List</Nav.Link>
              <Nav.Link eventKey="link-11" onClick={() => handleLink('/members/support')}>Support & Contact</Nav.Link>
              <Nav.Link eventKey="link-12" onClick={() => handleLink('/members/challenges')}>Challenges</Nav.Link>
              <Nav.Link eventKey="link-13" onClick={() => handleLink('/members/constructiveCriticism')}> Constructive Criticism</Nav.Link>
              <Nav.Link eventKey="link-14" onClick={() => handleLink('/members/topicRequest')}>Topic Requests</Nav.Link>
              {group[0] === 2 || group[0] === 3 ? <Nav.Link eventKey="link-15" onClick={() => handleLink('/members/assetslistWithComments')}>Asset Discussion</Nav.Link> : ''}
            </Col>
          </Collapse>
        </Navbar.Collapse>
      </HasPermission>
      <HasPermission permission='qrata.system_access'>
        <Nav.Link active={location.pathname.startsWith("/system")} eventKey="link-16" onClick={() => setOpen3(!open3)} aria-controls="nav4" aria-expanded={open3}>
          <FontAwesomeIcon className="text-info nav-icons" icon="server" size="sm" />
            System
            <FontAwesomeIcon className="text-info position-absolute sidebar-icon" size="sm" icon={open3 ? 'angle-down' : 'angle-right'} />
        </Nav.Link>
        <Navbar.Collapse in={open3}>
          <Collapse in={open3}>
            <Col className="sub-menu p-0 py-2 ">
              <Nav.Link eventKey="link-17" onClick={() => handleLink('/system/buildoutList')}>Buildout List</Nav.Link>
              <Nav.Link eventKey="link-26" onClick={() => handleLink('/system/doNotRankList')}>DoNot Rank List</Nav.Link>
              {/* <Nav.Link eventKey="link-28" onClick={() => handleLink('/system/pendingGreyList')}>Pending Grey List</Nav.Link> */}
              <Nav.Link eventKey="link-24" onClick={() => handleLink('/system/feedback')}>Feedback</Nav.Link>
              <Nav.Link eventKey="link-18" onClick={() => handleLink(`/system/catalog/${CatalogType.MAJOR.id}`)} active={location.pathname.startsWith("/system/catalog")} >Catalog</Nav.Link>
              {/* <Nav.Link eventKey="link-19" as={NavLink} to='/system/membershipInvitation'>Membership Invitation</Nav.Link> */}
              <Nav.Link eventKey="link-19" as={NavLink} to='/system/promotion-requests'>Promotion Requests</Nav.Link>
              <Nav.Link eventKey="link-25" onClick={() => handleLink('/system/predefined-responses')}>Predefined Responses</Nav.Link>
              <Nav.Link eventKey="link-20" onClick={() => handleLink('/system/advertising')}>Advertising</Nav.Link>
              <Nav.Link eventKey="link-21" onClick={() => handleLink('/system/ratingCriteria')} active={"/system/ratingCriteria" === location.pathname} >Rating Criteria</Nav.Link>
              <Nav.Link eventKey="link-23" onClick={() => handleLink('/system/trustScore')}>Trust Score</Nav.Link>
              <Nav.Link eventKey="link-24" onClick={() => handleLink('/system/faq')}>FAQs</Nav.Link>
            </Col>
          </Collapse>
        </Navbar.Collapse>
      </HasPermission> 
            
            </Nav>
            
    </div >
  );
};

export default withRouter(Sidebar);
