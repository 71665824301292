export const FORM_REQUEST_HEADERS = {
    'Content-Type': ''
};

export const CatalogType = {
    MAJOR: { id: 1, title: 'Major Classification' },
    MINOR: { id: 2, title: 'Minor Classification' },
    CATEGORY: { id: 3, title: 'Category' },
    getType: id => Object.values(CatalogType).find(a => (a.id === Number(id)))
};

export const InfoType = {
    BASIC: { id: 1, title: 'Basic Information' },
    EDUCATION: { id: 2, title: 'Education' },
    JOB: { id: 3, title: 'Professional Experience' },
    ACCOMPLISH: { id: 4, title: 'Accomplishment' },
    getType: id => Object.values(InfoType).find(a => (a.id === Number(id)))
};

export const UserRole = {
    ALL: { id: -1, title: 'All Groups' },
    SUPERADMIN: { id: 2, title: 'Super Admin' },
    ADMIN: { id: 3, title: 'Admin' },
    EDITOR: { id: 4, title: 'Editor' },
    MEMBER : { id : 5 , title : 'Member'},
    EXPERT: { id: 1, title: 'Expert' },
    getRole: id => Object.values(UserRole).find(a => (a.id === Number(id)))
}

export const REGEXES = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{6,14}$/
};

export const UserType = {
    ACTIVE: { id: 1, title: 'Active ', value: true },
    INACTIVE: { id: -1, title: 'Inactive ', value: false },
    getType: id => Object.values(UserType).find(a => (a.id === Number(id)))
};

export const MemberType = {
    ACTIVE: { id: 1, title: 'Active', value: true, membership_plan:true },
    INACTIVE: { id: -1, title: 'Inactive', value: false, membership_plan:true },
    EXPIRED: { id: 2, title: 'Expired', value: true , membership_plan:false },
    getType: id => Object.values(MemberType).find(a => (a.id === Number(id)))
};

export const SupportType = {
    TECHNICAL: { id: 1, title: 'Technical', value: 1 },
    PAYMENT: { id: 2, title: 'Payment', value: 2 },
    MEMBERSHIP: { id: 3, title: 'Membership', value: 3 },
    getType: id => Object.values(SupportType).find(a => (a.id === Number(id)))
};

export const QueryType = {
    SUPPORT: { id: 1, title: 'Support', value: 2 },
    CONTACT: { id: 2, title: 'Contact', value: 1 },
    getQueryType: id => Object.values(QueryType).find(a => (a.id === Number(id)))
};

// Status used for Application. Need to make sure its not used for other places
export const Status = { 
    APPROVED : { name: 'APPROVED' , value : 1  , label : 'Approved' },
    UNDER_REVIEW  : { name: 'UNDER REVIEW' , value : 2 , label : 'Under Review'},
    REJECTED : { name: 'REJECTED' , value : 3  , label : 'Rejected' },
    DRAFT : { name: 'DRAFT' , value : 4 , label: 'Draft'},
    REVISE : { name: 'REVISE' , value : 5 , label: 'Revise'},
    getStatus: id => Object.values(Status).find(a => (a.value === Number(id)))
};

export const PromotionStatus = { 
    DEFAULT : { name: 'DEFAULT' , value : undefined , label: 'Promote to Admin', icon: 'arrow-up'},
    OFFERED : { name: 'OFFERED' , value : 1 , label: 'Admin Promotion Offer Sent', icon: 'clock'},
    INTERESTED  : { name: 'INTERESTED' , value : 2 , label : 'Interested in Admin Promotion', icon: 'clock'},
    NOT_INTERESTED : { name: 'NOT_INTERESTED' , value : 3  , label : 'Not Interested in Admin Promotion',icon: 'redo' },
    APPROVED : { name: 'APPROVED' , value : 4 , label: 'Promotion Appproved by Super Admin', icon: 'arrow-up' },
    REJECTED : { name: 'REJECTED' , value : 5 , label: 'Promotin Rejected by Super Admin', icon: 'redo' },
    getPromotionStatus: id => Object.values(PromotionStatus).find(a => (a.value === Number(id)))
};


export const AssetStatus = { 
    DRAFT : { name: 'DRAFT' , value : 1 , label: 'Draft'},
    UNDER_REVIEW  : { name: 'ASSIGNED' , value : 2 , label : 'Assigned'},
    SUBMITTED : { name: 'SUBMITTED' , value : 3  , label : 'Submitted' },
    REWORK : { name: 'REWORK' , value : 4 , label: 'Rework'},
    PUBLISHED : { name: 'PUBLISHED' , value : 5 , label: 'Published'},
    INACTIVE : { name: 'INACTIVE' , value : 6 , label: 'Inactive'},
    DEAD : { name: 'DEAD' , value : 7 , label: 'Dead'},
    RELIVE : { name: 'RELIVE' , value : 8 , label: 'Re-live'},
    ARCHIVE : { name: 'ARCHIVE' , value : 9 , label: 'Archived'},
    PENDING : { name: 'PENDING' , value : 10 , label: 'Pending'},
    REJECTED : { name: 'REJECTED' , value : 11 , label: 'Rejected'},
    getAssetStatus: id => Object.values(AssetStatus).find(a => (a.value === Number(id)))
};


export const DonotRankListType = {
    BLACK : { name: 'BLACK', value : 1, label: 'Black' },
    GREY : { name: 'GREY', value : 2, label: 'Grey' },
    getDonotRankListType: id => Object.values(DonotRankListType).find(a => (a.value === Number(id)))
}

export const DonotRankAssetListType = {
    APPROVED : { name: 'APPROVED', value: 1, label: 'Approved'},
    PENDING : { name: 'PENDING', value : 2, label: 'Pending'},
    REJECTED : { name: 'REJECTED',value: 3, label: 'Rejected'},
    getDonotRankAssetListType : id => Object.values(DonotRankAssetListType).find(a => (a.value === Number(id)))
}

export const QueriesStatus = {
    NEW : { name: 'NEW' , value : 1 , label: 'New'},
    ACTIVE  : { name: 'ACTIVE' , value : 2 , label : 'Active'},
    CLOSED : { name: 'CLOSED' , value : 3  , label : 'Closed' },
    getAssetStatus: id => Object.values(QueriesStatus).find(a => (a.value === Number(id)))
}

export const AssetExpertStatus = { 
    DRAFT : { name: 'APPROVED' , value : 1 , label: 'Approved'},
    UNDER_REVIEW  : { name: 'UNDER_REVIEW' , value : 2 , label : 'Under Review'},
    SUBMITTED : { name: 'REJECTED' , value : 3  , label : 'Rejected' },
    REWORK : { name: 'DRAFT' , value : 4 , label: 'Draft'},
    getAssetStatus: id => Object.values(AssetStatus).find(a => (a.value === Number(id)))
};

export const DateConsts = {
    DATE_FORMAT: 'M/D/YY',
    TIME_FORMAT: 'h:mm A',
    DATETIME_FORMAT: 'MM/DD/YY h:mm A',
    DB_DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    DB_DATE_FORMAT: 'YYYY-MM-DD',
    DB_TIME_FORMAT: 'HH:mm:ss',
    UI_DATETIME_FORMAT: 'MM/DD/YYYY hh:mm A',
    UI_DATE_FORMAT: 'MM/DD/YYYY',
    UI_DATE_FORMAT_CALENDER: 'YYYY-MM-DD',
    UI_TIME_FORMAT: 'hh:mm A',
    DATE_ONLY_FORMAT: 'DD MMM YYYY',
    DATE_AND_TIME_FORMAT: 'DD MMM YYYY HH:mm',
    MONTH_YEAR_FORMAT: 'MMM YYYY'
  };

  export const MembersType = {
    MEMBERS: { name: "MEMBERS", value: 1, label: "Members" },
    NONMEMBERS: { name: "NONMEMBERS", value: 2, label: "Non-Members" },
    getMemberType: (id) =>
    Object.values(MembersType).find((a) => a.value === Number(id)),
  };