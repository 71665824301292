import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Form, Card } from 'react-bootstrap';
import Stats from './Stats';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormBlock from '../../../shared/FormBlock';
import * as applicationService from '../../../services/application';
import { useDispatch } from 'react-redux';
import * as alertActions from "../../../actions/alert";
import * as loaderAction from "../../../actions/loader";
import TooltipHover from '../../../shared/TooltipHover';
import { monthYearFormat } from '../../../helpers';

const Second = (props) => {
    
    const dispatch = useDispatch();
    const [formData1, setFormData1] = useState({ new: true });
    const [formData, setFormData] = useState({
        university: '',
        course: '',
        degree_month_year: null,
        education_file: ''
    });
    const [formData2, setFormData2] = useState({ education_id: '' });
    const [validated, setValidated] = useState(false);
    const [existingData, setExistingData] = useState([]);
    const [educationList, setEducationList] = useState([]);
    const [changeState, setChangeState] = useState(true);
    const prevState = useRef(formData);


    // GET USER ALL EDUCATION LIST
    const getUserData = useCallback(() => {
        if (props.applicationId) {
            dispatch(loaderAction.loader(true));
            applicationService.getAppEducation(props.applicationId).then(([response, error]) => {
                if (response && response.user_education) {
                    setEducationList(response.user_education)
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }
                dispatch(loaderAction.loader(false));
            })
        }
    }, [props.applicationId, dispatch])


    useEffect(() => {
      if(educationList){
            props.getStatus("Education", educationList.length);
      }
    },[educationList, props, props.getStatus]);

    
    // GET ALL EXISTING EDUCTION
    const getAllExistingEducation = useCallback((id) => {
        dispatch(loaderAction.loader(true));
            applicationService.getExistingEducation(id).then(([response, error]) => {
                if (response) {
                    setExistingData(response);
                    setChangeState(false);
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }
                dispatch(loaderAction.loader(false));
            })
    },[dispatch])

    useEffect(() => {
        if (props.applicationId) {
            getUserData();
        }
    }, [props.applicationId, dispatch, getUserData])

    // RADIO BUTTON CHANGE ON GET EXISTING EDUCATION DATA AND SHOW IN MULTISELECT
    useEffect(() => {
        if (formData1.new === 'existing' && props.applicationId !== '' && changeState) {
            getAllExistingEducation(props.applicationId)
            
        }
    }, [formData1.new, props.applicationId, dispatch, changeState , getAllExistingEducation])


    // HANDLE CHANGE FOR FORMDATA
    const handleChange = useCallback((e) => {
        setFormData({
            ...formData,
            [e.name]: e.value
        });
    }, [formData])

    // HANDLE CHNAGE 2 FOR RADIO BUTTONS
    const handleChange2 = useCallback((e) => {
        setFormData1({
            ...formData1,
            [e.name]: e.value
        });
    }, [formData1])

    // HANDLE CHANGE FOR FORMDATA2
    const onChange = useCallback((e) => {
        setFormData2({
            ...formData2,
            [e.name]: e.value
        });
    }, [formData2])


    const Submit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            saveEducation();
        }
    }

    // CREATE NEW EDUCATION FOR APPLICATION
    const saveEducation = useCallback(() => {
        if (props.applicationId && formData.course !== '' && formData.university !== '' ) {
            let data = new FormData();
            data.append("application_id", props.applicationId);
            data.append("user_id", props.user.id);
            data.append("course", formData.course);
            data.append("degree_month_year", formData.degree_month_year);
            data.append("university", formData.university);
            if (formData.education_file.name) {
                data.append("education_file", formData.education_file, formData.education_file.name);
            }else{
                data.append("education_file", '');
            }
            dispatch(loaderAction.loader(true));

            applicationService.createEducation(data).then(([response, error]) => {
                if (response.user_education) {
                    dispatch(alertActions.success('Add Education Successfully.'));
                    setFormData(prevState.current);
                    setValidated(false);
                    getUserData();
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }
                dispatch(loaderAction.loader(false));
            })
        } else {
            dispatch(alertActions.error('Please fill all fields.'));
        }
    }, [props.applicationId, getUserData, props.user.id, formData.course, formData.university, formData.education_file, formData.degree_month_year, dispatch]);

    // Save Exiting Education Show in Dropdown List
    const saveExistingEducation = useCallback(() => {
        if (formData2.education_id !== '') {
            let data = new FormData();
            data.append('application_id', props.applicationId);
            data.append('education_id', formData2.education_id);
            dispatch(loaderAction.loader(true));
            applicationService.addExitingEducation(data).then(([response, error]) => {
                if (response) {
                    setFormData2({
                        education_id: ''
                    })
                    dispatch(alertActions.success('Add Education Successfully.'));
                    setChangeState(true);
                    getUserData();
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }
                dispatch(loaderAction.loader(false));
            })
        } else {
            dispatch(alertActions.error('Click Add to save the Education before proceeding.'));
        }
    }, [dispatch, props.applicationId, formData2.education_id, getUserData]);

    // REMOVE EDUCATION IN LISTING
    const removeEducation = useCallback((id) => {
        if (id) {
            let data = new FormData();
            data.append('application_id', props.applicationId);
            data.append('education_id', id)
            dispatch(loaderAction.loader(true));
            applicationService.deleteEducation(data).then(([response, error]) => {
                if (response) {
                    const list = educationList.filter((item) => item[0].id !== id);
                    setEducationList(list);
                    dispatch(alertActions.success('Delete Successfully.'));
                    getAllExistingEducation(props.applicationId)
                } else if (error) {
                    dispatch(alertActions.error(error.response));
                }
                dispatch(loaderAction.loader(false));
            })
        }
    }, [props, dispatch, educationList, getAllExistingEducation])

    // RADIO BUTTON JSON
    const form1 = [
        [{ label: 'Add new ', id: 'add-second', type: 'radio', checked: true, placeholder: 'Add New ', value: 'add', name: 'new', sm: 3, md: 2, lg: 2, xl: 2 },
        { label: 'Existing', id: 'existing-second', type: 'radio', checked: false, placeholder: 'Existing ', value: 'existing', name: 'new', sm: 3, md: 1, lg: 1, xl: 1 },
        { icon: '', type: 'free-space1', variant: 'info', className: 'float-right', sm: 6, md: 9, lg: 9, xl: 9 }],
    ]

    // EDUCATION FORM JSON
    const form2 = [
        [{ label: 'University', id: 'university', name: 'university', type: 'text', required: true, placeholder: 'University Name', sm: 12, md: 5, lg: 5, xl: 5 },
        { label: 'Degree / Major / Minor / Courses / Certifications ', id: 'course', name: 'course', type: 'text', required: true, placeholder: 'Course', sm: 12, md: 5, lg: 5, xl: 5 },
        { label: 'Completion Date', id: 'Year', name: 'degree_month_year', type: 'date', required: true, placeholder: 'MM/yyyy', sm: 12, md: 2, lg: 2, xl: 2 }],
        [{ label: 'Attachment', id: 'educationFile', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', name: 'education_file', type: 'file', placeholder: 'Select File', sm: 12, md: 5, lg: 5, xl: 5 },
        { placeholder: 'Add', id: 'Add-Button', type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 7, lg: 7, xl: 7 }],
    ];

    // EXISTING EDUCATION MULTI SELECT JSON
    const form3 = [
        [{ label: 'Select Education', required: true, name: 'education_id', type: 'multi-select', group: true , options: existingData, placeholder: '---Select---', sm: 12, md: 10, lg: 10, xl: 10 , value: formData2.education_id ? formData2.education_id : '' },
        { placeholder: 'Save', disabled: existingData.length > 0 ? false : true, handleEvent: () => saveExistingEducation(), id: 'saveEducation', type: 'button', variant: 'primary', className: 'align-self-center float-right  mt-2', sm: 12, md: 2, lg: 2, xl: 2 }]];


    return (
        <>
            <Row className="justify-content-md-center pt-5 mx-0">
                <Col md={12}>
                    <Row className='mb-4'>
                        <Col md={2}>
                            <h4 >Education</h4>
                        </Col>
                        <Col md={10}>
                            <Form>
                                <FormBlock fields={form1} size={12} getData={(e) => handleChange2(e)} formData={formData1} />
                            </Form>
                        </Col>
                    </Row>
                    {formData1.new === true || formData1.new === 'add' ?
                        <Form noValidate validated={validated} onSubmit={Submit}>
                            <FormBlock fields={form2} size={12} getData={(e) => handleChange(e)} formData={formData} />
                        </Form> :
                        <FormBlock fields={form3} size={12} getData={(e) => onChange(e)} formData={formData2} />
                    }

                    {educationList.length > 0 ?
                        <Card className="py-3">
                            {educationList.map((item, index) => (
                                <Card.Body key={index} className="py-1">
                                    <Row key={index}>
                                        <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item[0].university}>

                                            <label className="d-block text-left mb-0"><small>University</small></label>
                                            <h6> {item[0].university}</h6>
                                        </Col>
                                        <Col md={3} sm={12} className="text-truncate overflow-hidden" title={item[0].course}>

                                            <label className="d-block text-left mb-0"><small>Degree</small></label>
                                            <h6 className="text-warning"> {item[0].course}</h6>
                                        </Col>
                                        <Col md={3} sm={12} className="font-style-italic">

                                            <label className="d-block text-left mb-0"><small>Completion Date</small></label>
                                            <h6 className=" text-left"> {monthYearFormat(item[0].degree_month_year)}</h6>


                                        </Col>
                                        <Col md={2} sm={12} className="font-style-italic text-nowrap">
                                        <label className="d-block text-left mb-0"><small>Status</small></label>
                                            <h6 className=" text-left"> {item[0].approved_on === null ? 'Not Approved' : 'Approved'}</h6>
                                        </Col>
                                        <Col md={1} sm={12} className="text-nowrap font-style-italic d-flex align-items-center justify-content-end">

                                            <a className=" text-danger  ml-2 text-decoration-none" target="_blank" rel="noopener noreferrer" href={item[1].file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>
                                            <TooltipHover type='button' btnSize='sm' iconClass="text-danger ml-2" variant='link' btnIcon='trash' message='Delete' handleEvent={() => removeEducation(item[0].id)} />
                                        </Col>
                                    </Row>
                                    { educationList.length - 1 === index ? null : <><hr className="mt-1 mb-2" /></>}
                                </Card.Body>

                            ))}
                        </Card>
                        : null}
                    <Stats {...props} type={"Education"} listLength={educationList.length} />
                </Col>
            </Row>

        </>
    );
}
export default Second;