import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Col, Card, Row, Form , Badge} from 'react-bootstrap';
import TooltipHover from "./TooltipHover";
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * Moves an item from one list to another list.
 */
// const move = (source, destination, droppableSource, droppableDestination) => {
//     const sourceClone = Array.from(source);
//     const destClone = Array.from(destination);
//     const [removed] = sourceClone.splice(droppableSource.index, 1);

//     destClone.splice(droppableDestination.index, 0, removed);

//     const result = {};
//     result[droppableSource.droppableId] = sourceClone;
//     result[droppableDestination.droppableId] = destClone;

//     return result;
// };

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "10px",
    // change background colour if dragging
    background: isDragging ? "#7d6f70" : "#dee2e6",

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#E0F2F1" : "#009688",
});

const options = () => {
    const arr = [];
    for (let i = 1; i <= 10; i++) {
        arr.push(<option key={i} value={i}>{i}</option>)
    }
    return arr;
}

const DragDropCustom = ({ data, onDataChange, rating, isDragDisabled, editable, infoHide, useInfo }) => {

    const [state, setState] = useState(data);
    const [hideNote , setHideNote] = useState('');

    useEffect(() => {
        setState(data);
    }, [data]);

    function onDragEnd(result) {
        const { source, destination } = result;
        // console.log(source.droppableId, ' = src , dest = ', destination.droppableId);
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state, source.index, destination.index);
            const modified = items.map((item, index) => { item.weight = index + 1; return item });
            // const newState = [...state];
            // newState[sInd] = items;
            // console.log(items, 'modified');
            // console.log(newState, 'newState');
            setState(modified);
            onDataChange(modified);
        } else {
            // const result = move(state[sInd], state[dInd], source, destination);
            // const newState = [...state];
            // newState[sInd] = result[sInd];
            // newState[dInd] = result[dInd];
            // const filtered = newState.filter(group => group.length);
            // setState(filtered);
            // console.log(filtered, 'filtered');
            // onDataChange(filtered);
        }
    }

    // console.log(state, 'state data');

    const handleChange = (e, data , index) => {
        const { value } = e.target;
        let list = [...state];
        list[index] = { ...list[index], notes: value }
        setState([...list]);
        onDataChange(state);
    }

    const handleChangeHtml = (value, data , index) => {
        let list = [...state];
        list[index] = { ...list[index], info: value }
        setState([...list]);
    }

    useEffect(()=>{
        onDataChange(state)
    },[state])

    const toggleNote = (index) => {
        if(index === hideNote){
            setHideNote('')
        }else {
            setHideNote(index);
        }
    }

    const ratingChange = (e, data , index) => {
        const { value } = e.target;
        let list1 = [...state];
        list1[index] = { ...list1[index], rating: Number(value) }
        setState([...list1]);
        const info = list1;
        onDataChange(info);

    }

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        [{ 'link': [] }]
     ];
  
     const modules = {
        toolbar: {
           container: toolbarOptions
        }
     }

    return (
        <DragDropContext onDragEnd={onDragEnd} >

            <Droppable droppableId={'0'} key={'0'} >
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps} className="row">
                        {state.map((item, index) => (
                            <Draggable isDragDisabled={isDragDisabled} key={`dragkey-${item.id}`} draggableId={`drag-${item.id}`} index={index}>
                                {(provided, snapshot) => (
                                    <div className="col-md-12 col-sm-12" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                        <div>
                                            <Col md={12} className="px-2 mb-2 mt-2">
                                                <Card className="py-3 px-3  border-0 ">
                                                    {editable ?
                                                        <><Row>
                                                            <Col md={10} sm={10} xs={10}>
                                                                <Form.Label><Badge variant="light mr-2">{index + 1}</Badge>{item.name}</Form.Label>
                                                            </Col>
                                                            <Col md={2} sm={2} xs={2}>
                                                                {item.rating}
                                                            </Col>
                                                        </Row></>
                                                        : <>
                                                            <Row className="no-gutters">
                                                                <Col md={8}>
                                                                    <Form.Label><Badge variant="light mr-2">{index + 1}</Badge>{item.name}</Form.Label>
                                                                </Col>
                                                                <Col md={2}>
                                                                    {rating ? <Form.Control className='p-0' value={item.rating} name='rating' onChange={(e) => ratingChange(e, item , index)} as="select" size="sm">
                                                                        {options()}
                                                                    </Form.Control> : ''}
                                                                </Col>
                                                                <Col md={2} className=" d-flex justify-content-end align-items-center">
                                                                
                                                                    {infoHide && item.info ?
                                                                        <TooltipHover type='button' btnSize='sm' btnClass="text-info float-right" variant='link' btnIcon='info-circle' iconSize='lg' message={<div dangerouslySetInnerHTML={{ __html: item.info }}></div>} handleEvent={() => null} />
                                                                        : ''}
                                                                        <TooltipHover type='button' btnSize='sm' btnClass={item.notes ?  'text-primary float-right' : 'text-info float-right'} variant='link' btnIcon='sticky-note' iconSize='lg' message={'Add Explanation'} handleEvent={() => toggleNote(index)} />
                                                                </Col>
                                                            </Row>
                                                            {index === hideNote ?
                                                            <Row className="no-gutters" >
                                                                <Col xs={12} md={12}>
                                                                    
                                                                    <Form.Group className="mb-0" controlId="exampleForm.ControlTextarea1">
                                                                    {useInfo ?
                                                                    <ReactQuill
                                                                        name='info'
                                                                        defaultValue={item.info}
                                                                        onChange={(e) => handleChangeHtml(e, item , index)}
                                                                        className='border border-top-0 '
                                                                        style={{ 'height': '150px' }}
                                                                        modules={modules}
                                                                    /> 
                                                                        :
                                                                    <Form.Control as="textarea" name='notes' placeholder="Explanations.." autoFocus={true} rows="2" defaultValue={item.notes} onChange={(e) => handleChange(e, item , index)} />
                                                                    }
                                                                 </Form.Group>
                                                                   
                                                                    
                                                                </Col>
                                                            </Row>: null }
                                                        </>}
                                                </Card>
                                            </Col>

                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

        </DragDropContext>

    );
}
DragDropCustom.propTypes = {
    data: PropTypes.any,
    onDataChange: PropTypes.func,
    rating: PropTypes.bool,
    isDragDisabled: PropTypes.bool,
    editable: PropTypes.bool,
    infoHide: PropTypes.bool
};

DragDropCustom.defaultProps = {
    rating: false,
    isDragDisabled: false,
    editable: false,
    infoHide: true
};
export default DragDropCustom;