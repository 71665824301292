import React, { useCallback, useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { useBoolean } from "../../hooks/basic";
import AsyncAppTable from "../../shared/AsyncAppTable";
import TooltipHover from "../../shared/TooltipHover";
import { useDispatch } from "react-redux";
import * as loaderAction from '../../actions/loader';
import * as trustscoreService from '../../services/trustscore';
import * as alertActions from '../../actions/alert';
import TrustscoreForm from "./TrustscoreForm";
import SearchField from '../../shared/SearchField';

const TrustScore = () => {
  const dispatch = useDispatch();
  const [expertsData, setExpertsData] = useState([]);
  const [userData , setUserData] = useState(null);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [pageCount, setPageCount] = useState(0)
  const [resultCount, setResultCount] = useState(0)
  const [search , setSearch] = useState('');
  const [callFetch, setCallFetch] = useState(false);

  const getExpertsList = useCallback(({ pageSize, pageIndex, sortedField='' , filter}) => {
    dispatch(loaderAction.loader(true));
    const limit = pageSize;
    let offset = pageIndex * pageSize;
    trustscoreService.fetchAllExperts(limit, offset, sortedField, filter, search).then(([response, error]) => {
      if (response) {
        setPageCount(Math.ceil(response.count / pageSize))
        setResultCount(response.count)
        setExpertsData(response.results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
      setCallFetch(false);
    });
  }, [dispatch, search])

  // useEffect(() => {
  //   getExpertsList(10 ,0);
  // }, [getExpertsList])

  const columns = [
    {
      Header: "Action",
      accessor: "action",
      sortingOff: true,
      className: "action-l py-1",
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' iconClass='default' btnSize='sm' variant='link' btnIcon='edit' message='Edit Trust Score' handleEvent={() => openModal(cell.row.original)} />
        </div>
      ),
    },
    {
      Header: "Expert’s Name",
      accessor: "first_name",
      sortType: "basic",
      filtering: true,
      Cell :(cell) => (cell.row.original.get_full_name)
    },
    {
      Header: "Trust Score",
      accessor: "profile__trustworthiness",
      sortType: "basic",
      filtering: true,
      Cell :(cell) => (cell.row.original.trustscore)
    },
    {
      Header: "Assets Rated",
      accessor: "asset_count",
      sortType: "basic",
      Cell :(cell) => (cell.row.original.asset_count)
    },

  ];

  const openModal = useCallback((data) => {
    setUserData(data);
    showModal();
  }, [showModal]);

  return (
    <Container fluid>
      <TrustscoreForm userData={userData} isVisible={isVisible} hide={hideModal} refreshList={() => setCallFetch(true)}/>
      <Row >
          <Col md={8}>
          <h4 >Expert’s Internal Qrata “Trust Score”</h4>
          </Col>
          <SearchField md={4} action={setSearch}/>
      </Row>
      
      <AsyncAppTable
        columns={columns}
        data={expertsData}
        fetchData={getExpertsList}
        pageCount={pageCount}
        resultCount={resultCount}
        filtering={true}
        callFetch={callFetch}
       />
    </Container>
  );
}

export default TrustScore;