import { dateFormat, handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'search/';

export async function getAllUser(user) {
  return await handleApi(axiosClient.get(`${BASE}user/?user=${user}`));
}

export async function getAllClassification(cat) {
  return await handleApi(axiosClient.get(`${BASE}catalog/?cat=${cat}`));
}

export async function getAssets(
  user = '',
  cat = '',
  ratingFilter = '',
  rating = '',
  specific_topic = '',
  url = '',
  area = '',
  opinionFilter = '',
  opinion = '',
  dateFilter = '',
  s_date = '',
  e_date = '',
  status='',
  limit = '' , 
  offset= '') {
  return await handleApi(axiosClient.get(`${BASE}assets/?${user!==''?'user='+user+'&':''}${cat!==''?'cat='+cat+'&':''}${ratingFilter!==''&&rating!==''?ratingFilter+'='+rating+'&':''}${specific_topic!==''?'topic='+specific_topic+'&':''}${url!==''?'url='+url+'&':''}${opinionFilter!==''&&opinionFilter!=='op_np=1'?opinionFilter+'='+opinion+'&':''}${opinionFilter==='op_np=1'?opinionFilter+'&':''}${dateFilter==='date_lt'?'date_lt='+dateFormat(e_date , 'YYYY-MM-DDT23:59')+'&':''}${dateFilter==='date_gt'?'date_gt='+dateFormat(e_date , 'YYYY-MM-DDT01:01')+'&':''}${dateFilter==='date_eq'?'s_date='+dateFormat(e_date , 'YYYY-MM-DDT01:01')+'&e_date='+dateFormat(e_date , 'YYYY-MM-DDT23:59')+'&':''}${dateFilter !== 'date_eq' && dateFilter !=='date_lt'&&dateFilter!=='date_gt'&&dateFilter!==''?'s_date='+s_date+'&e_date='+e_date+'&':''}${area!==''?'area='+area+'&':''}${status!==''?'status='+status+'&':''}${limit !== '' ? 'limit='+limit+'&':''}${offset !== '' ? 'offset='+offset:''}`));
}
