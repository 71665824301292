import React , { useEffect  , useState} from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import AppTable from "../../shared/AppTable";
import TooltipHover from "../../shared/TooltipHover";
import * as applicationServices from '../../services/application';
import { history  , Status} from "../../helpers";
import { useDispatch } from 'react-redux';
import * as alertActions from "../../actions/alert";
import Loader from "../../shared/Loader";
import moment from "moment"
import { dateOnlyFormat } from "../../helpers";
import SearchField from '../../shared/SearchField';

const Applications = () => {
  const dispatch = useDispatch();
  const [applications , setApplications] = useState([]);
  const [loading , setLoading ] = useState(false);
  const [ status , setStatus ] = useState(2);
  const [search, setSearch] = useState('')

  useEffect(() => {
    setLoading(true);
    applicationServices.getAllApplication(status).then(([response , error ]) => {
      if(response){
        setApplications(response.application_all);
        setLoading(false);
      }else if(error){
        setLoading(false);
        dispatch(alertActions.error(error.response));
      }
      
    })
  }, [dispatch , status])


  const columns = [
    {
      Header: "Action",
      accessor: "action",
      className: 'text-center',
      filteringOff:true,
      Cell: (cell) => (
        <div>
          {cell.row.original.status === Status.UNDER_REVIEW.value  ? 
          <TooltipHover type='button' btnSize='sm'  variant='link' iconClass='default' btnIcon='eye' message='View Application' handleEvent={() => history.push('/applicationReview/'+cell.row.original.application_id)} />
           :  (cell.row.original.status === Status.APPROVED.value) || (cell.row.original.status === Status.REVISE.value) ? 
           <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='eye' message='Application Preview' handleEvent={() => history.push('/applicationView/' + cell.row.original.application_id)} />
          : ''}
          </div>
      ),
    },
    {
      Header: "Name",
      accessor: "full_name",
      sortType: "basic",
      className : 'text-capitalize text-truncate overflow-hidden'
    },
    {
      Header: "Catalog",
      accessor: "catalog_name",
      sortType: "basic",
    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on) : 'N/A'}</div>)
    },

  ];

  return (
    <Container fluid className="p-0">
      <Loader loading={loading}/>
      <Row>
        <Col md={6} sm={12} className="mb-md-0 mb-3"><h4>Applications</h4></Col>
        
        <Col md={2} sm={12} className="mb-md-0 mb-2">
          <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
            {Object.values(Status).map((item, i) => { return item.value === 1 || item.value === 2 || item.value  === 3 || item.value === 5 ? <option value={item.value} key={i}>{item.label}</option> : null }
            )}
          </Form.Control>
        </Col>
        <SearchField md={4} action={setSearch} />
      </Row>
      <AppTable columns={columns} globalFilter={search} data={applications ? applications : []} />
    </Container>
  );
}

export default Applications;