import React, { useState, useEffect , useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover, Container, Row, Col, Modal , Form} from 'react-bootstrap';
import AsyncAppTable from "../../shared/AsyncAppTable";
import SubTable from "../../shared/SubTable";
import { ReactTinyLink } from "react-tiny-link";
import TooltipHover from "../../shared/TooltipHover";
import {  } from "../../helpers";
import * as assetService from '../../services/asset';
import { HasPermission } from "../../shared/HasPermission";
import ModalForm from "../../shared/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import * as loaderAction from '../../actions/loader';
import * as alertAction from '../../actions/alert';
import { Status , AssetStatus } from "../../helpers/constants";
import SelectOption from "../../shared/select/SelectOption";
import {getStatus, history, defaultLimit, dateOnlyFormat, UserRole } from "../../helpers";
import SearchField from '../../shared/SearchField';
import {faUndo, faSkull, faClone} from '@fortawesome/free-solid-svg-icons';

import {fetchAssetsList} from '../../actions/assets';
import { Storage } from '../../helpers/storage';

const SubComponent = ({row}) => {
  const dispatch = useDispatch();
  const [assetList , setAssetList] = useState([]);
 
  
  
  const getExpertAssetList = useCallback((id) => {
    dispatch(loaderAction.loader(true));
    assetService.getAssetExpertList(row.original.id).then(([response , error]) => {
      if(response){
        setAssetList(response.asset_expert_list)
      }else if(error){
        dispatch(alertAction.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  },[dispatch , row.original.id]) 

  useEffect(() => {
    
    if(row.original.id){
     getExpertAssetList()
    }
  }, [row.original.id , dispatch , getExpertAssetList])

    // SUB COMPONENT COLUMNS
const subColumns = [
  {
    Header: "Expert Name",
    accessor: "specific_topic",
    sortType: "basic",
    Cell: (cell) => (<div>{cell.row.original.first_name + ' ' + cell.row.original.last_name}</div>)
  },
  {
    Header: "Raw Score",
    accessor: "raw_score",
    sortType: "basic",
    className:'text-center'
  },
  {
    Header: "Final Score",
    accessor: "final_score",
    sortType: "basic",
    className:'text-center'
  },
  {
    Header: "Status",
    accessor: "status",
    sortType: "basic",
    Cell: (cell) => (<div>{getStatus(cell.row.original.status)}</div>)
  },
  {
    Header: "Approved On",
    accessor: "approved_on",
    sortType: "basic",
    Cell: (cell) => (<div>{cell.row.original.approved_on !== null ? dateOnlyFormat(cell.row.original.approved_on) : 'N/A'}</div>)
  },
  {
    Header: "Action",
    accessor: "action",
    className: "action-l text-center",
    Cell: (cell) => (
      <div>
        {cell.row.original.status === Status.UNDER_REVIEW.value ? 
          <TooltipHover type='button' btnSize='sm' handleEvent={() =>  history.push('/expertAssetView/'+cell.row.original.asset+'/'+cell.row.original.id+'/'+cell.row.original.status)} variant='link' iconClass='default' btnIcon='edit' message='Rating Asset' /> : '' }
          {cell.row.original.status === Status.APPROVED.value ? 
          <TooltipHover type='button' btnSize='sm' handleEvent={() =>  history.push('/expertAssetView/'+cell.row.original.asset+'/'+cell.row.original.id+'/'+cell.row.original.status)} variant='link' iconClass='default' btnIcon='eye' message='Rating Preview' /> : 
           <HasPermission permission='qrata.delete_asset_expert'> <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Expert' handleEvent={() => deleteAssetExpert(cell.row.original.asset , cell.row.original.id)}/></HasPermission>}
      </div>
    )
  }
];


const deleteAssetExpert = useCallback((assetId , expertId ) => {
  if(assetId !== '' && expertId !== ''){
    const data = {"id":expertId, "asset_id":assetId}
    assetService.deleteExpert(data).then(([response , error]) => {
      if(response){
        getExpertAssetList();
      }else if(error){
        dispatch(alertAction.error(error.response))
      }
    })
  }
},[getExpertAssetList , dispatch])

  return <><SubTable columns={subColumns} data={assetList} /></>;
}

const AssetList = () => {

  const dispatch = useDispatch();
  const [assetList, setAssetList] = useState([]);
  const loginData = useSelector((state) => state.auth.loginData);
  const [show, setShow] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [expertList , setExpertList] = useState([]);
  const [selectedExpert , setSelectedExpert] = useState([]);
  const [assetId , setAssetId] = useState('');
  const [initialValue , setInitialValue] = useState([]);
  const [status , setStatus] = useState(2);
  const fetchIdRef = React.useRef(0);
  const [loading , setLoading ] = useState(false);
  const [resultCount, setResultCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [search, setSearch] = useState('');
  const [showPopUp, setShowPopUp] = useState(true);
  const group = Storage.getSession()?.group;
  const [pageLimit, setPageLimit] = useState(defaultLimit);
  const [callFetch, setCallFetch] = useState(false);

  const getAssetList = useCallback(({ pageSize, pageIndex, sortedField="", filter }) => {
    const fetchId = ++fetchIdRef.current
    dispatch(loaderAction.loader(true));
    setLoading(true);
    let id = '';
    if(loginData.permissions.includes('qrata.view_asset_editor')){
        id = loginData.id;
    }else {
      id = ''
    }
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const limit = pageSize;
        setPageLimit(pageSize)
        let offset = pageSize * pageIndex;
        // console.log('dispatch',offset,pageIndex,limit,loginData.id , loginData.permissions , status, search);
        dispatch(fetchAssetsList(id , status, limit, offset, sortedField, filter, search));
      //   .then(([response, error]) => {
      //   if (response) {
      //     if (response.results)
      //     {setAssetList(response.results);
      //     setResultCount(response.count)
      //     setPageCount(Math.ceil(response.count / pageSize))}
      //     else {
      //       setAssetList([]);
      //     setResultCount(0)
      //     setPageCount(0)
      //     }
      //   } else if (error) {
      //     dispatch(alertAction.error(error.response))
      //   }
      // });
      dispatch(loaderAction.loader(false));
        setLoading(false);
        setCallFetch(false)
      }
    }, 10)
  },[dispatch , loginData.id , loginData.permissions , status, search])

  const assetsData = useSelector((state) => state.assets.assetsData);
  const isLoading = useSelector((state) => state.assets.isLoading);

useEffect(() => {
  // console.log(assetsData,'isloading')
  if (assetsData  && !isLoading ) {
        // console.log(assetsData,'assetsData',pageLimit,)
        if (assetsData.results)
        {
          setAssetList(assetsData.results);
          setResultCount(assetsData.count);
          setPageCount(Math.ceil(assetsData.count / pageLimit));
        }
        else {
          // console.log('elsepart')
          setAssetList([]);
          setResultCount(0);
          setPageCount(0);
        }
      }
}, [assetsData,pageLimit,isLoading])


 const getExpertList = (id) => {
  dispatch(loaderAction.loader(true));
   assetService.getUnsignedExpert(+id).then(([response , error]) => {
     if(response){
      setExpertList(response.expert_list)
     }else if(error){
      dispatch(alertAction.error(error.response))
     }
     dispatch(loaderAction.loader(false));
   })
 }

  const handleClose = () => {
    setShow(false);
    setAssetId('');
  } 

  const hideReRateForm = (data) => {
    setVisible(false);
    if(data !== ''){
      if(data.url){
        assetService.reRateAsset(assetId, data).then(([response, error]) => {
          if (response) {
            // console.log("rerate-response", response)
            dispatch(alertAction.success('Asset Cloned For Rerating.'));
            setStatus(2)
          } else if (error) {
            dispatch(alertAction.error(error.response));
          }
        })
      }
    }
  }

  const handleShow = (value) => {
    setAssetId(value)
    getExpertList(value);
    setShow(true);
    setInitialValue([]);
    setSelectedExpert([]);
  } 

  const showReRateForm = (value) => {
    setAssetId(value)
    setVisible(true);
  }

  const restoreAsset = useCallback((id) => {
    if (id !== '') {
      assetService.publishAsset(id).then(([response, error]) => {
        if (response) {
          dispatch(alertAction.success('Asset Restored Succesfully.'));
          setStatus(6)
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
      })
    }
  }, [dispatch])

  const markAsDead = useCallback((id) => {
    if (id !== '') {
      let data = {status:7}
      assetService.partialUpdate(id, data).then(([response, error]) => {
        if (response) {
          dispatch(alertAction.success('Asset Marked as Dead successfully.'));
          setStatus(6)
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
      })
    }
  }, [dispatch])
  
  const assetData = {
    url:''
  }

  const fileds = [
    { label: 'New URL* ', name: 'url', type: 'text', required: true, placeholder: 'Enter new url', sm: 12, md: 12, lg: 12, xl: 12 }
  ]

  const all_columns = [
    {
      Header: "#",
      accessor: "id",
      id: 'expander', 
      sortingOff: true,
      Cell: ({ row, rows, toggleRowExpanded }) => (
        
        <span {...row.getToggleRowExpandedProps} onClick={() => {
          const expandedRow = rows.find(row => row.isExpanded);

          if (expandedRow) {
            const isSubItemOfRow = Boolean(
              expandedRow && row.id.split(".")[0] === expandedRow.id
            );

            if (isSubItemOfRow) {
              const expandedSubItem = expandedRow.subRows.find(
                subRow => subRow.isExpanded
              );

              if (expandedSubItem) {
                const isClickedOnExpandedSubItem =
                  expandedSubItem.id === row.id;
                if (!isClickedOnExpandedSubItem) {
                  toggleRowExpanded(expandedSubItem.id, false);
                }
              }
            } else {
              toggleRowExpanded(expandedRow.id, false);
            }
          }
          row.toggleRowExpanded();
        }
      }>
          {row.isExpanded ? <span aria-label='img' role='img' > <FontAwesomeIcon className="text-mute  nav-icons" icon="caret-down" size="lg" /></span> :
          <span aria-label='img' role='img' ><FontAwesomeIcon className="text-mute  nav-icons" icon="caret-right" size="lg" /></span>}
        </span>
      )
    },
    {
      Header: "Action",
      accessor: "action",
      sortingOff: true,
      className: "action-l text-left w-80px text-nowrap",
      Cell: (cell) => (
        <div>
          {cell.row.original.status === AssetStatus.DRAFT.value ?
            <TooltipHover type='button'  btnSize='sm' handleEvent={() => history.push('assetFormEdit/' + cell.row.original.id)} variant='link' iconClass='default' btnIcon='edit' message='Edit Asset' />
            : cell.row.original.status === AssetStatus.UNDER_REVIEW.value ? 
           <><HasPermission permission='qrata.asset_expert_tableview'> <TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/rateFormTableView/'+cell.row.original.id)} variant='link' iconClass='default' btnIcon='edit' message='View Asset detail' /></HasPermission>
           <HasPermission permission='qrata.unassigned_asset_expert'><TooltipHover type='button' btnSize='sm' handleEvent={() => handleShow(cell.row.original.id)} variant='link' iconClass='default' btnIcon='user-check' message='Assign Asset' /></HasPermission></>
            : cell.row.original.status === AssetStatus.INACTIVE.value ?
            <><TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/rateFormTableView/' + cell.row.original.id)} variant='link' iconClass='default' btnIcon='eye' message='Asset Preview' />
            <HasPermission permission='qrata.add_asset'><TooltipHover type='button' btnSize='sm' handleEvent={() => restoreAsset(cell.row.original.id)} variant='link' iconClass='default' btnIcon={faUndo} message='Publish Asset' /></HasPermission>
            <HasPermission permission='qrata.add_asset'><TooltipHover type='button' btnSize='sm' handleEvent={() => markAsDead(cell.row.original.id)} variant='link' iconClass='default' btnIcon={faSkull} message='Mark as Dead' /></HasPermission>
            <HasPermission permission='qrata.add_asset'><TooltipHover type='button' btnSize='sm' handleEvent={() => showReRateForm(cell.row.original.id)} variant='link' iconClass='default' btnIcon={faClone} message='Re-rate Asset' /></HasPermission>
            </>
            :cell.row.original.status === AssetStatus.RELIVE.value || cell.row.original.status === AssetStatus.DEAD.value ? 
            <>
            <TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/rateFormTableView/' + cell.row.original.id)} variant='link' iconClass='default' btnIcon='eye' message='Asset Preview' />
            <HasPermission permission='qrata.add_asset'><TooltipHover type='button' btnSize='sm' handleEvent={() => restoreAsset(cell.row.original.id)} variant='link' iconClass='default' btnIcon={faUndo} message='Publish Asset' /></HasPermission>
            <HasPermission permission='qrata.add_asset'><TooltipHover type='button' btnSize='sm' handleEvent={() => showReRateForm(cell.row.original.id)} variant='link' iconClass='default' btnIcon={faClone} message='Re-rate Asset' /></HasPermission>
            </>
            :<TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/rateFormTableView/' + cell.row.original.id)} variant='link' iconClass='default' btnIcon='eye' message='Asset Preview' />}
        </div>
      ),
    },
    {
      Header: "Specific Topic",
      accessor: "specific_topic",
      sortType: "basic",
      filtering:true,
      Cell: (cell) => 
      <div>
          <OverlayTrigger
            trigger="click"
            placement='top'
            overlay={
              <Popover id="popover-basic">
                <ReactTinyLink
                  cardSize="small"
                  showGraphic={true}
                  maxLine={2}
                  minLine={1}
                  url={cell.row.original.url}
                />
              </Popover>
            }
          >
            <div>{cell.row.original.specific_topic}</div>
          </OverlayTrigger>
        </div> 
    },

    {
      Header: "Specialization",
      accessor: "specialization",
      sortType: "basic",
      filtering:true,
      Cell: (cell) => 
      <div>
        <TooltipHover type='text' text={cell.row.original.specialization} message={cell.row.original.specialization} textClass='text-ellipsis d-block' handleEvent={() => null} />
      </div>
    },
    {
      Header: "Created by",
      accessor: "created_by",
      sortType: "basic",
      filtering:true,
      id: "created_by__first_name",
      className: 'text-truncate overflow-hidden',
      Cell: (cell) => (<div>{cell.row.original.first_name + ' ' + cell.row.original.last_name}</div>)
    },
    {
      Header: "Published On",
      accessor: "published_on",
      sortType: "basic",
      className:"text-nowrap",
      Cell: (row) => (<div>{row.row.original.published_on !== "" ? (dateOnlyFormat(row.row.original.published_on)): null}</div>)
    },
    {
      Header: "Dead On",
      accessor: "dead_on",
      sortType: "basic",
      className:"text-nowrap",
      Cell: (row) => (<div>{row.row.original.dead_on ? (dateOnlyFormat(row.row.original.dead_on)): null}</div>)
    },
    {
      Header: "Dead Count",
      accessor: "dead_count",
      sortType: "basic",
      filtering: true,
      className: 'text-truncate overflow-hidden text-center',
    },
    {
      Header: "Raw Score",
      accessor: "raw_score",
      sortType: "basic",
      filtering: true,
      className: 'text-truncate overflow-hidden text-center',
    },
    {
      Header: "Final Score",
      accessor: "final_score",
      sortType: "basic",
      filtering: true,
      className: 'text-truncate overflow-hidden text-center',
    },

  ];

  const [columns , setColumns] = useState(all_columns);

  var filterColumns = () => {
    let new_columns = all_columns.filter((item) => {
      if ((item.accessor === "dead_on" || item.accessor === "dead_count") && (status in [1,2,3,4,5,6]) ) {
        return false
      } else {
        return true
      }
    });
    setColumns(new_columns)
  }
  
  useEffect(() => {  
    filterColumns()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])
  
  const renderRowSubComponent = useCallback(({ row  }) => (
      <>
        <SubComponent
        row={row} />
      </>
    ), [])

    const getValues = useCallback((value) => {
      let data = []
      value.filter(obj =>  data.push(obj.value));
      return data;
    },[])

    const addExpertAsset = useCallback(() => {
      setShow(false);
      if(assetId !== ''){
        const data = { asset_id : assetId , expert_id : getValues(selectedExpert)}
        assetService.addExpert(data).then(([response , error]) => {
          if(response){
            setAssetId('');
            // getAssetList({defaultLimit});
            setCallFetch(true);
            dispatch(alertAction.success('Asset assigned to Expert successfully.'));
          }else if(error){
            dispatch(alertAction.error(error.response))
          }
        })
      }
    },[selectedExpert , assetId , getValues , dispatch])

    const handleChange = (e) => {
    if(e !== null){
      setInitialValue(e);
      setSelectedExpert(e);
        }else {
      setInitialValue([]);
    }
  }
   const guideMsg = "Click here to create an asset";
   return (
    <Container fluid className="px-0">
      
      <Modal aria-labelledby="contained-modal-title-vcenter"
      centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Experts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <SelectOption  data={expertList} multi={true}  handleEvent={(e) => handleChange(e)} placeholder='Select Expert' value={initialValue}  />
         </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={() => addExpertAsset()}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
{/* clone assets */}

      <ModalForm
      show={isVisible}
      onHide={(e) => hideReRateForm(e)}
      fields={fileds}
      data={assetData}
      title='Re-rate Asset'
      /> 
    {/* clone asset end */}
    
      <Row className="no-gutters">
        <Col md={5} sm={12} className="mb-md-0 mb-3">
          <h4>Assets List</h4>
        </Col>
        <Col md={2} sm={6} className="mb-md-0 mb-2 pr-md-3 ">
        <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
            {Object.values(AssetStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
            )}
          </Form.Control>
        </Col>
        <SearchField md={4} xs={10} action={setSearch}  />
        <Col md={1} sm={1}  xs={2} className='text-right mb-md-0 mb-2'>
          <HasPermission permission='qrata.add_asset'>
            <TooltipHover type='button' btnSize='md' variant='primary' btnIcon='plus' iconClass='text-white' message='Add Asset' handleEvent={() => history.push('/assetForm')} showGuide={ group && group[0] === UserRole.ED && assetList.length === 0} pageMsg={guideMsg}/>
          </HasPermission>
        </Col>
      </Row>
      {/* {console.log(pageCount,resultCount,assetData,'AsyncAppData')} */}
      <AsyncAppTable 
      columns={columns}
      data={assetList}
      fetchData={getAssetList}
      loading={loading}
      pageCount={pageCount}
      resultCount={resultCount}
      subComponent={renderRowSubComponent}
      filtering={true} 
      callFetch={callFetch}/>

    </Container>
  );
}
export default AssetList;