import React from 'react';
import { Row, Col, Card, Accordion, Button, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

const CountCard = ({ data }) => {

  return (
    <>
          <Col md={12} className="py-2">
            <Card className={'shadow-sm dash-cards border-5  h-100 text-dark ' + data.style} >
              <Card.Body className="pb-0 top-body">
                <Row>
                <Col md={12} className='py-2 px-1 display-5 card-d-title' >  <h3 className="cards-c-title">{data.title}</h3></Col>
                 </Row>
                <Row className="count-sec">
                <Col md={6} xs={6} className="d-flex  align-items-center justify-content-center">
                    <h4 className="count ">{data.count}</h4>
                  
                  </Col>
                  <Col md={6} xs={6} className="text-right p-0 pr-2 d-flex justify-content-center align-items-center">
                  <img src={data.icon} className="app-icon" alt="icon"/>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className={"text-center border-0 p-0 " + data.style}>
                <Accordion defaultActiveKey="">
                  <Card className={"p-0 m-0 border-0  cursor-pointer " + data.style}>
                    <Accordion.Toggle as={Button} className="text-dark" variant="link" eventKey="0" onClick={() => data.handleEvent()}>
                      <FontAwesomeIcon className="text-mute  nav-icons" icon="chevron-down" size="sm" />
                    </Accordion.Toggle>
                    <Accordion.Collapse className="text-left" eventKey="0">
                      <Card.Body className={"p-0 " + data.style}>
                        {data.list && data.list.length > 0 ?
                        <>
                        <ListGroup variant="flush">
                        {data.list.map((item, i) =>
                          <ListGroup.Item key={i} className=" bg-dark text-white">
                            <Link className="text-white" to='RateForm'>
                              {item}
                            </Link>
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                      </>: ''}
                        {data.component ? data.component : ''}
                        
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Card.Footer>
            </Card>
          </Col>
    </>
  );
}

export default CountCard;