import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'tags/';

export async function fetchAll() {
    return await handleApi(axiosClient.get(`${BASE}`));
}

export async function addTag(data) {
    return await handleApi(axiosClient.post(`${BASE}`, data));
}

export async function updateTag(id, data) {
    return await handleApi(axiosClient.put(`${BASE}${id}/`, data));
}

export async function deleteTag(id) {
    return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}
