import React, { useState } from 'react';
import { Form, ButtonGroup, Col, Container, Row, ToggleButton } from 'react-bootstrap';
import { MembersType, QueryType, QueriesStatus } from '../../helpers';
import SearchField from '../../shared/SearchField';
import SupportListing from './SupportListing';
import ContactListing from './ContactListing';


const SupportAndContactMain = () => {
    const [queryType, setQueryType] = useState(QueryType.getQueryType(QueryType.SUPPORT.id));
    const [memberType , setMemberType] = useState(1)
    const [search , setSearch] = useState('');
    const [status , setStatus] = useState(1);


    return (
        <>
        <Container fluid className="px-0">
            <Row>
                <Col md={3} sm={12} xs={12} className="mb-md-0 mb-3"><h4>Support & Contact</h4></Col>
                <Col md={2} sm={12} xs={12} className="mb-md-0 mb-2">
                    <ButtonGroup toggle>
                        {Object.values(QueryType).map((type, idx) => {
                            return type.id ? (
                                <ToggleButton key={idx} type="radio" variant="light" name="memberType"
                                    value={type.id} checked={queryType.id === type.id}
                                    onChange={(e) => setQueryType(QueryType.getQueryType(type.id))}>
                                    {type.title}
                                </ToggleButton>
                            ) : ''
                        }
                        )}
                    </ButtonGroup>
                </Col>
                <Col md={2} sm={12} xs={12} className="mb-md-0 mb-2">
                    <Form.Control as="select" size={'md'} value={memberType} onChange={(e) => setMemberType(Number(e.target.value))} >
                        {Object.values(MembersType).map((item, i) => { return item.value ? <option value={item.value} key={i}>{item.label}</option> : null }
                        )}
                    </Form.Control>
                </Col>
                <Col md={2} sm={12} xs={12} className="mb-md-0 mb-2">
                    <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
                    {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
                    )}
                    </Form.Control>
                </Col>
                <SearchField md={3} action={setSearch} />
            </Row>
            {queryType.id === 1 ? 
            <SupportListing search={search} memberType={memberType} status={status} queryType={queryType} /> : 
            queryType.id === 2 ? 
            <ContactListing search={search} memberType={memberType} status={status} queryType={queryType} /> : ''}
        </Container>
        </>
    )
}
export default SupportAndContactMain;