import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'invite';

export async function inviteVerify(data) {
  return await handleApi(axiosClient.post(`${BASE}/verify/` , data));
}

export async function inviteDelete(id) {
  return await handleApi(axiosClient.delete(`${BASE}/${id}/`));
}
