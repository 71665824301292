import React, { useEffect, useCallback, useState } from "react";
import { Container, ListGroup, Row, Spinner, Modal, Col } from "react-bootstrap";
import CountCard from "./CountCard";
import appApproveExper from "../../assets/images/icons/approved-expert-application.png";
import appApproveBio from "../../assets/images/icons/approved-expert-bio.png";
import assignAsset from "../../assets/images/icons/assignedassets.png";
import expertApps from "../../assets/images/icons/expert-application.png";
import expertAssets from "../../assets/images/icons/expert-assets.png";
import expertBio from "../../assets/images/icons/expert-bio.png";
import adminAsset from "../../assets/images/icons/manager-assets.png";
import PublishedAssets from "../../assets/images/icons/Published-assets.png";
import reviewAssets from "../../assets/images/icons/review-assets.png";
import { HasPermission } from "../../shared/HasPermission";
import * as dashboardService from "../../services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Status, UserRole } from "../../helpers/constants";
import { Link } from "react-router-dom";
import { history } from "../../helpers";
import TooltipHover from "../../shared/TooltipHover";
import * as loader from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import * as loaderAction from "../../actions/loader";
import { Storage } from '../../helpers/storage';
import {fetchApplicationList} from '../../actions/application';
import {fetchAssetsList} from '../../actions/assets';

const DashboardComponent = () => {

   const dispatch = useDispatch();
   const loginData = useSelector((state) => state.auth.loginData);
   const [userId , setUserId] = useState([]);
   const [assetRated, setAssetRated] = useState([]);
   const [assetReview , setReviewAsset] = useState([]);
   const [appPending , setAppPending] = useState([]);
   const [appApproved , setAppApproved] = useState([]);
   const [bioPending , setBioPending] = useState([]);
   const [bioApproved , setBioApproved] = useState([]);
   const [assetWorked , setAssetWorked] = useState([]);
   const [assetPublish , setAssetPublish] = useState([]);
   const [assignExpertList , setAssignExpertList] = useState([]);
   const [reviewApp , setReviewApp] = useState([]);
   const [ approveExpert , setApproveExpert] = useState([]);
   const [bioReview , setBioReview] = useState([]);
   const [publishedAsset , setPublishedAsset] = useState([]);
   const [prevType , setPrevType] = useState('');
   const [bioPendingList , setBioPendingList] = useState([]);
   const [bioApproveList , setBioApproveList] = useState([]);
   const [expertCount, setExpertCount] = useState({
      approved_application: 0,
      pending_application: 0,
      approved_bio: 0,
      pending_bio: 0,
      asset_to_be_rated: 0,
      asset_waiting_review: 0
   });
   const [count , setCount] = useState({
      approved_application_for_expert: 0,
      asset_i_have_worked_on: 0 ,
      assign_asset_to_expert: 0,
      bio_review_for_publish: 0,
      published_asset: 0,
      review_expert_applicant: 0,
      review_rated_asset_for_publish: 0,
      topic_from_buildout: 0,
      approved_bio: 0 ,
   })
   const limit = 5;
  let id = '';
    if(loginData.permissions.includes('qrata.view_asset_editor')){
        id = loginData.id;
    }else {
      id = ''
    }
   
   const getExpertCount = useCallback(() => {
      dispatch(loader.loader(true));
      dashboardService.getExpertCount(loginData.id).then(([response, error]) => {
         if (response) {
            setExpertCount({
               approved_application: response.approved_app,
               pending_application: response.pending_app,
               approved_bio: response.approved_bio,
               pending_bio: response.pending_bio,
               asset_to_be_rated: response.asset_to_be_rated,
               asset_waiting_review: response.asset_waiting_review
            })
         } else if (error) {
            dispatch(alertActions.error(error.response));
         }
         dispatch(loader.loader(false));
      })
   }, [loginData.id , dispatch])

   const getAppCount = useCallback((id , groupId) => {
      dispatch(loader.loader(true));
      dashboardService.getAppCounts(id , groupId ).then(([response , error]) => {
         if(response){
            setCount({
               approved_application_for_expert: response.approved_application_for_expert,
               asset_i_have_worked_on: response.asset_i_have_worked_on,
               assign_asset_to_expert: response.assign_asset_to_expert,
               published_asset: response.published_asset,
               review_rated_asset_for_publish: response.review_rated_asset_for_publish,
               bio_review_for_publish: response.bio_review_for_publish,
               review_expert_applicant: response.review_expert_applicant,
               topic_from_buildout: response.topic_from_buildout,
               approved_bio: response.approved_bio,
               pending_bio: response.pending_bio,
            })
         }else if(error){
            dispatch(alertActions.error(error.response));
         }
         dispatch(loader.loader(false));
      })
   },[dispatch])

   const getAssetList = (type , status) => {
      if(prevType !== type){
      dashboardService.getAssetList(userId, status, limit).then(([response, error]) => {
         if (response) {
            setPrevType(type);
            if(type === 'rated') setAssetRated(response.asset_list);
            if(type === 'review') setReviewAsset(response.asset_list);
         } else if (error) {
            dispatch(alertActions.error(error.response));
         }
      })
    }
   }

   const getAssetWorked = (type , status) => {
      if(prevType !== type){
         let id = '';
         if(type === 'assetWorked') {
            id = userId;
            status = ''
         } 
          let expStatus = '';
          if(type === 'assetPublish') expStatus = 2;
          if(loginData.group.includes(UserRole.EDITOR.id)) id = userId;
         dashboardService.getAssetsLists(id , status, limit , expStatus).then(([response, error]) => {
            if (response) {
               setPrevType(type);
               if(type === 'assetWorked') setAssetWorked(response.asset_list);
               if(type === 'assetPublish') setAssetPublish(response.asset_list)
               if(type === 'assignExpert') setAssignExpertList(response.asset_list);
               if(type === 'publishedAsset') setPublishedAsset(response.asset_list)
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
         })
      }
   }
   

   const getApplicationList = (type , status) => {
      if(prevType !== type){
      let id = userId ;
      if(type === 'reviewExpert' || type === 'approveExpert' ) id = ''
      dashboardService.getApplicationList(id, status, limit).then(([response, error]) => {
         if (response) {
            setPrevType(type);
            if(type === 'appPending') setAppPending(response.specialization_list);
            if(type === 'appApproved') setAppApproved(response.specialization_list);
            if(type === 'reviewExpert') setReviewApp(response.specialization_list)
            if(type === 'approveExpert') setApproveExpert(response.specialization_list)
         } else if (error) {
            dispatch(alertActions.error(error.response));
         }
      })
    }
   }  

   const getBioList = (type , status) => {
      if(prevType !== type){
      let id = '' ;
      if(type === 'bioReview' || type === 'approveBios') id = ''
      dashboardService.getBios(id, status, limit).then(([response, error]) => {
         if (response) {
            setPrevType(type);
            if(type === 'bioPending') setBioPending(response.specialization_list);
            if(type === 'bioApproved') setBioApproved(response.specialization_list);
            if(type === 'bioReview') setBioReview(response.specialization_list);
            if(type === 'approveBios') setBioApproveList(response.specialization_list);
            if(type === 'approveBios') setBioPendingList(response.specialization_list);
         } else if (error) {
            dispatch(alertActions.error(error.response));
         }
      })
   }
   }  

   useEffect(() => {
      if(loginData.id !== ''){
         setUserId(loginData.id);
         if(loginData.group.includes(UserRole.EXPERT.id)){
            getExpertCount();
         }else if(loginData.group.includes(UserRole.EDITOR.id)){
            getAppCount(loginData.id , UserRole.EDITOR.id);
         }else if(loginData.group.includes(UserRole.ADMIN.id)){
            getAppCount(loginData.id , UserRole.ADMIN.id)
         }else if(loginData.group.includes(UserRole.SUPERADMIN.id)){
            getAppCount(loginData.id , UserRole.SUPERADMIN.id)
         }
      }
   }, [getExpertCount , getAppCount, loginData.group , loginData.id])


   const AssetRatedList = ({list , redirect , status}) => {
      if(status === 0){
         return <><p className='text-center'>No Assets Found.</p></>;
      }else{
      return (
         <ListGroup variant="flush">
            {list.length === 0 ? <div className='text-center'><Spinner  size="sm"  animation="border" /> </div> : ''}
            {list.map((item, i) =>
               <ListGroup.Item key={i} className="bg-dark">
                  {redirect === 'assetView' ? 
                  <Link className='text-decoration-none text-white' to={'/'+redirect+'/'+item.asset_id+'/'+item.asset_expert_id}>{item.specific_topic}</Link>
                  :
                  <Link className='text-decoration-none text-white' to={'/'+redirect+'/'+item.asset_id+'/'+item.asset_expert_id+'/'+Status.DRAFT.value}>{item.specific_topic}</Link>
                  }
                      
               </ListGroup.Item>
            )}
         </ListGroup>
      )
    }
   }

   const AppListing = ({list , redirect , status}) => {
      if(status === 0){
         return <><p className='text-center'>No Application Found.</p></>;
      }else{
      return (
         <ListGroup variant="flush">
           {list.length === 0 ? <div className='text-center'><Spinner  size="sm"  animation="border" /> </div> : ''}
            {list.map((item, i) =>
               <ListGroup.Item key={i} className=" bg-dark">
                  <TooltipHover type='text' handleEvent={() => history.push('/'+redirect+'/'+item.id)} textClass='text-decoration-none text-white' text={item.specialization}  message={item.title} />
               </ListGroup.Item>
            )}
         </ListGroup>
      )
            }
   }

   const BioListing = ({list , redirect , status}) => {
      if(status === 0){
         return <><p className='text-center'>No Bio Found.</p></>;
      }else{
      return (
         <ListGroup variant="flush">
           {list.length === 0 ? <div className='text-center'><Spinner  size="sm"  animation="border" /> </div> : ''}
            {list.map((item, i) =>
               <ListGroup.Item key={i} className=" bg-dark">
                     <TooltipHover type='text' handleEvent={() => history.push('/'+redirect+'/'+item.id)} textClass='text-decoration-none text-white' text={item.specialization}  message={item.title} />
               </ListGroup.Item>
            )}
         </ListGroup>
      )
    }
   }

   const AssetListing = ({list , redirect , status}) => {
      if(status === 0){
         return <><p className='text-center'>No Assets Found.</p></>;
      }else{
      return (
         <ListGroup variant="flush">
            {list.length === 0 ? <div className='text-center'><Spinner  size="sm"  animation="border" /> </div> : ''}
            {list.map((item, i) =>
               <ListGroup.Item key={i} className="bg-dark">
                      <Link className='text-decoration-none text-white' to={'/'+redirect+'/'+item.id}>{item.specific_topic}</Link>
               </ListGroup.Item>
            )}
         </ListGroup>
      )
    }
   }

   const dbeActions = [
      { title: 'Assigned Assets ', handleEvent: () => count.assign_asset_to_expert === 0 ? null : getAssetWorked('assignExpert' , Status.UNDER_REVIEW.value ), permission: 'qrata.assign_asset', count: count.assign_asset_to_expert, style: 'border-secondary', icon: assignAsset, component : <AssetListing list={assignExpertList ? assignExpertList : []} redirect={'rateFormTableView'} status={count.assign_asset_to_expert} /> },
      { title: 'Pending Assets', handleEvent: () => count.review_rated_asset_for_publish === 0 ? null : getAssetWorked('assetPublish' , Status.UNDER_REVIEW.value ), permission: 'qrata.review_asset', count: count.review_rated_asset_for_publish, style: 'border-secondary', icon: reviewAssets, component : <AssetListing list={assetPublish ? assetPublish : []} redirect={'rateFormTableView'} status={count.review_rated_asset_for_publish} /> },
      { title: 'My Assets', handleEvent: () => count.asset_i_have_worked_on === 0 ?  null : getAssetWorked('assetWorked' , Status.UNDER_REVIEW.value ), permission: 'qrata.assets_worked', count: count.asset_i_have_worked_on, style: 'border-secondary', icon: adminAsset, component : <AssetListing list={assetWorked ? assetWorked : []} redirect={'rateFormTableView'} status={count.asset_i_have_worked_on} /> },
      { title: 'Published Assets', handleEvent:() => count.published_asset === 0 ? null : getAssetWorked('publishedAsset' , 5 ), permission: 'qrata.published_asset', count: count.published_asset || 0, style: 'border-secondary', icon: PublishedAssets, component : <AssetListing list={publishedAsset ? publishedAsset : []} redirect={'rateFormTableView'} status={count.published_asset} /> },
      { title: 'Pending Applications', handleEvent: () => count.review_expert_applicant === 0 ? null : getApplicationList('reviewExpert' , Status.UNDER_REVIEW.value), permission: 'qrata.review_application', count: count.review_expert_applicant || 0, style: 'border-secondary', icon: expertApps, component : <AppListing list={reviewApp ? reviewApp : []} redirect={'applicationReview'} status={count.review_expert_applicant} /> },
      { title: 'Approved  Applications', handleEvent: () => count.approved_application_for_expert === 0 ? null : getApplicationList('approveExpert' , Status.APPROVED.value), permission: 'qrata.approve_application', count: count.approved_application_for_expert, style: 'border-secondary', icon: appApproveExper, component : <AppListing list={approveExpert ? approveExpert : []} redirect={'applicationView'} status={count.approved_application_for_expert} /> },
      { title: 'Pending Bios', handleEvent: () => count.bio_review_for_publish === 0 ? null :  getBioList('bioReview' , Status.UNDER_REVIEW.value ), permission: 'qrata.review_bio', count: count.bio_review_for_publish || 0, style: 'border-secondary', icon: expertBio, component : <BioListing list={bioReview ? bioReview : []} redirect={'bio'} status={count.bio_review_for_publish} /> },
      { title: 'Approved  Bios ', handleEvent: () => count.approved_bio === 0 ? null : getBioList('approveBios' , Status.APPROVED.value), permission: 'qrata.approve_application', count: count.approved_bio, style: 'border-secondary', icon: appApproveBio, component : <AppListing list={bioApproveList ? bioApproveList : []} redirect={'bioView'} status={count.approved_bio} /> }
   ]

   const dbeActions1 = [
      { title: 'Assets to be rated', handleEvent: () => expertCount.asset_to_be_rated === 0 ? null : getAssetList('rated' , Status.DRAFT.value ), permission: 'qrata.asset_rated', count: expertCount.asset_to_be_rated, style: 'border-secondary', icon: assignAsset,  component : <AssetRatedList list={assetRated ? assetRated : []}  redirect={'expertAssetScoring'} status={expertCount.asset_to_be_rated} />},
   // { title: 'Topic from Buildout ', handleEvent: () => null, permission: 'qrata.topic_buildout', count: count.topic_from_buildout, style: 'border-secondary', icon: topicbuildout, list: ['Benefits of Yoga', 'The Science In Everyday Life And Its Importance', 'History of science', 'Digital marketing'] },
      { title: 'Waiting for review ', handleEvent: () => expertCount.asset_waiting_review === 0 ? null : getAssetList('review' , Status.UNDER_REVIEW.value ), permission: 'qrata.waiting_review', count: expertCount.asset_waiting_review, style: 'border-secondary', icon: reviewAssets, component : <AssetRatedList list={assetReview ? assetReview : [] } redirect={'assetView'} status={expertCount.asset_waiting_review} /> },
      { title: 'My Pending Applications', handleEvent: () => expertCount.pending_application === 0 ? null : getApplicationList('appPending' , Status.UNDER_REVIEW.value), permission: 'qrata.pending_application', count: expertCount.pending_application, style: 'border-secondary', icon:expertApps , component : <AppListing list={appPending ? appPending : []} redirect={'applicationView'} status={expertCount.pending_application} /> },
      { title: 'My Approved Applications', handleEvent: () => expertCount.approved_application === 0 ? null : getApplicationList('appApproved' , Status.APPROVED.value), permission: 'qrata.pending_application', count: expertCount.approved_application, style: 'border-secondary', icon: appApproveExper, component : <AppListing list={appApproved ? appApproved : []}  redirect={'applicationView'} status={expertCount.approved_application} /> },
      { title: 'My Pending Bios ', handleEvent: () => expertCount.pending_bio === 0 ? null : getBioList('bioPending' , Status.UNDER_REVIEW.value ), permission: 'qrata.pending_bio', count: expertCount.pending_bio, style: 'border-secondary', icon: expertBio, component : <BioListing list={bioPending ? bioPending : []} redirect={'bioView'} status={expertCount.pending_bio} />  },
      { title: 'My Approved Bios ', handleEvent: () => expertCount.approved_bio === 0 ? null : getBioList('bioApproved' , Status.APPROVED.value ), permission: 'qrata.pending_bio', count: expertCount.approved_bio, style: 'border-secondary', icon: appApproveBio, component : <BioListing list={bioApproved ? bioApproved : []} redirect={'bioView'} status={expertCount.approved_bio} /> }
   ]

   return (
      <Container fluid className="p-0">
         <h4>Assets Summary</h4>
         <Row className="justify-content-end">
            {(loginData.group[0] === UserRole.EXPERT.id ? dbeActions1 : dbeActions).map((action, i) =>
               <Col lg={4} md={6} sm={6} xs={12} className="text-center">
                  <Row className="no-gutters">
                     <HasPermission key={'CountedCard' + i} permission={action.permission}>
                        <CountCard data={action}/>
                     </HasPermission>
                  </Row>
               </Col>
            )}
         </Row>
         
      </Container>
      
   );
}
export default DashboardComponent;