import React, { useCallback, useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBoolean } from '../../../hooks/basic';
// import TooltipHover from '../../../shared/TooltipHover';
import CommentModal from '../../../shared/CommentModal';
import { useDispatch, useSelector } from 'react-redux';
import * as alertActions from '../../../actions/alert';
import * as assetService from '../../../services/asset';
import TooltipHover from '../../../shared/TooltipHover';

const AssetsStats = ({
  currentStep,
  isActive,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  assetId,
  isGrey,
  saveAsset,
  updateAsset,
  nextStepEnable,
  checkValidity
}) => {

  const dispatch = useDispatch();
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [id, setId] = useState('');
  const loginData = useSelector((state) => state.auth.loginData);

  useEffect(() => {
    if (assetId !== '' && assetId !== undefined) {
      setId(assetId);
    }
  }, [assetId])


  // CLOSE COMMENT MODAL
  const closeModal = useCallback((data) => {
    hideModal();
    if (data.comment) {
      if (assetId !== '') {
        const newData = new FormData();
        newData.append('asset_id', assetId);
        newData.append('ask_by', loginData.id);
        newData.append('comment', data.comment);
        if (data.attachment) {
          newData.append('attachment', data.attachment)
        };
        assetService.createComment(newData).then(([response, error]) => {
          if (response) {

          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
        })
      } else {
        dispatch(alertActions.error('Please save the asset first.'));
      }
    }
  }, [hideModal, assetId, loginData, dispatch]);

  // OPEN COMMENT MODAL
  const openModal = useCallback(() => {
    showModal();
  }, [showModal]);

  const moveToTop = () => {
    window.scrollTo({
      top: 0,
  });
  }
  const nextStepforLast = () => {
    nextStep();
    moveToTop()
  }

  const previousStepforLast = () => {
    previousStep();
    moveToTop();
  }

  return (
    <div>
      <CommentModal title='Comments' show={isVisible} onHide={(e) => closeModal(e)} />
      <hr />
      <Row>
        <Col md={6} className='text-left'>
          {/* <Button disabled={assetId === '' ? true : false} className="mr-2 mb-2 float-left" variant="info" onClick={() => openModal()}>
            <FontAwesomeIcon className="text-white mr-1" icon="comment" size="sm" />
                Comment
              </Button> */}
          <TooltipHover type='button' btnSize='md' isDisabled={assetId === '' ? true : false} btnClass="mr-2" variant='info' iconClass="ml-1" btnIcon={'comment'} label='Comment' message='This is for internal Team Qrata questions because things are unclear, for suggestions an upgrades, problems but is NOT a live chat and cannot be seen by Members using the Database from the front end UI' handleEvent={() => openModal()} placement='bottom'/> 


          {currentStep === 1 ?
          <TooltipHover btnClass="ml-2" type='button' variant='outline-secondary' label='Save' message='Saves Asset' handleEvent={(e) => checkValidity(e)}
              placement='right' showGuide={currentStep === 1 && assetId === '' && isActive} pageMsg={'Save the asset first to enable the Next Button'}/> : null}
            {/*  <Button className="ml-2" variant="outline-secondary" onClick={(e) => checkValidity(e)}>Save</Button> : null} */}
          {currentStep === 3 ? <Button id='next' variant="outline-secondary" className=' mr-2 ml-2' onClick={(e) => updateAsset(e)}  >Save</Button> : null}
        </Col>
        <Col md={6} className='text-right'>
          {currentStep > 1 &&
            <Button variant="light" className="mr-2" onClick={previousStepforLast}><FontAwesomeIcon icon='angle-left'></FontAwesomeIcon> Back</Button>
          }
          {currentStep === 1 ?
            <Button id='next' variant="light" className=' mr-2 ml-2' disabled={id !== '' ? false : true} onClick={nextStepforLast}>Next <FontAwesomeIcon icon='angle-right'></FontAwesomeIcon></Button>
            :
            null
          }
          {currentStep === 2 && nextStepEnable ?
            <Button id='next' variant="light" disabled={!nextStepEnable} className=' mr-2 ml-2' onClick={nextStepforLast}>Next <FontAwesomeIcon icon='angle-right'></FontAwesomeIcon></Button>
            :
            null
          }
          {currentStep === totalSteps  ?
              <Button id='next' variant="secondary" className=' mr-2 ml-2' onClick={() => saveAsset(id)}  >{isGrey===1 ?  'Submit' : 'Assign to Expert' }</Button>
              : null
          }
        </Col>
      </Row>
      <hr />
    </div>
  )
}
export default AssetsStats;