import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Card,
  Badge,
} from "react-bootstrap";
import user from "../../assets/images/default.jpg";
import FormBlock from "../../shared/FormBlock";
import * as userService from "../../services/user";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../actions/auth";
import * as alertActions from "../../actions/alert";
import * as loaderAction from "../../actions/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserRole } from "../../helpers";

const ProfileComponent = ({ history }) => {
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.loginData);
  const [profilePic, setProfilePic] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [phoneCode, setPhoneCode] = useState(99);
  const [uploadFile, setUploadFile] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    former_first_name: "",
    former_last_name: "",
    nickname: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    social_security: "",
    state: "",
    wallet_address: "",
    zipcode: "",
    phone: "",
    reason_to_join: ""
  });

  const [validated, setValidated] = useState(false);

  // GET CURRENT USER DATA
  const fetchData = useCallback(() => {
    userService.fetchUserProfile(sessionData.id).then(([response, error]) => {
      if (response) {
        setProfilePic(response.image);
        setFormData({
          id: response.user_info.id,
          email: response.user_info.email,
          former_first_name: response.profile ? response.profile.former_first_name : "",
          former_last_name: response.profile ? response.profile.former_last_name : "",
          first_name: response.user_info ? response.user_info.first_name : "",
          last_name: response.user_info ? response.user_info.last_name : "",
          nickname: response.profile ? response.profile.nickname : "",
          address1: response.profile ? response.profile.address1 : "",
          address2: response.profile ? response.profile.address1 : "",
          city: response.profile ? response.profile.city : "",
          state: response.profile ? response.profile.state : "",
          country: response.profile ? response.profile.country : "",
          social_security: response.profile
            ? response.profile.social_security
            : "",
          wallet_address: response.profile
            ? response.profile.wallet_address
            : "",
          zipcode: response.profile ? response.profile.zipcode : "",
          phone: response.user_phone ? response.user_phone.phone : "",
          reason_to_join: response.profile && response.profile.reason_to_join ? response.profile.reason_to_join : ""
        });
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
    });
  }, [dispatch, sessionData]);

  const cleanContactNo = (str) => {
    return str.replace(/[^0-9+]/g, "");
  };
  //GET COUNTRY LIST
  useEffect(() => {
    dispatch(loaderAction.loader(true));
    userService.getCountries().then(([response, error]) => {
      if (response) {
        console.log(response);

        setCountries(JSON.parse(response));
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
    });
    dispatch(loaderAction.loader(false));
    return () => {
      setCountries([]);
    };
  }, [dispatch]);


  useEffect(() => {
    if (formData.country !== "" && formData.country !== null) {
      dispatch(loaderAction.loader(true));
      userService.getStates(formData.country).then(([response, error]) => {
        if (response) {
          setStates(JSON.parse(response));
          setPhoneCode(countries[formData.country - 1].phoneCode);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
    return () => {
      setStates([]);
    };
  }, [formData.country, dispatch, countries]);

  useEffect(() => {
    if (formData.state !== "" && formData.state !== null) {
      dispatch(loaderAction.loader(true));
      userService.getCities(formData.state).then(([response, error]) => {
        if (response) {
          setCities(JSON.parse(response));
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
    return () => {
      setCities([]);
    };
  }, [formData.state, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (e) => {
    
    if (e.name === "phone") {
      setFormData({
        ...formData,
        [e.name]: cleanContactNo(e.value),
      });
    }
    else {
      setFormData({
        ...formData,
        [e.name]: e.value,
      });
    }
  };

  // UPDATE USER DATA
  const updateUser = useCallback(() => {
    userService.updateUserProfile(formData).then(([response, error]) => {
      if (response) {
        const obj = {
          ...sessionData,
          email: formData.email,
          last_name: formData.last_name,
          first_name: formData.first_name,
          former_first_name: formData.former_first_name ? formData.former_first_name : '',
          former_last_name: formData.former_last_name ? formData.former_last_name : ''
        };
        dispatch(authActions.updateSession(obj));
        fetchData();
        dispatch(alertActions.success("Profile Updated Successfully"));
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
    });
  }, [formData, sessionData, fetchData, dispatch]);

  // HANDLE SUBMIT
  const handleSubmit = useCallback(
    (event) => {
      setValidated(true);
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === true) {
        updateUser();
      } else {
        event.stopPropagation();
      }
    },
    [updateUser]
  );

  // FORM FIELDS
  const fields = [
    [
      {
        label: "First Name",
        name: "first_name",
        type: "text",
        required: true,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
      {
        label: "Last Name",
        name: "last_name",
        type: "text",
        required: true,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
    ],
    [
      {
        label: "Former First Name",
        name: "former_first_name",
        type: "text",
        info: "Former/Maiden/Alias",
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
      {
        label: "Former Last Name",
        name: "former_last_name",
        type: "text",
        info: "Former/Maiden/Alias",
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
    ],
    [
      {
        label: "Email ID",
        disabled: true,
        name: "email",
        type: "email",
        required: true,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
      {
        label: "Address 1#",
        name: "address1",
        type: "text",
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
    ],
    [
      {
        label: "Address 2#",
        name: "address2",
        type: "text",
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
      {
        label: "Country",
        type: "multi-select",
        name: "country",
        placeholder: "",
        options: countries,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
    ],
    [
      {
        label: "State / Province",
        type: "multi-select",
        name: "state",
        placeholder: "",
        options: states,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
      {
        label: "City",
        name: "city",
        type: "multi-select",
        placeholder: "",
        options: cities,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
    ],
    [
      {
        label: "Zipcode",
        name: "zipcode",
        type: "tel",
        minValue: 6,
        maxValue: 6,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
      {
        label: "Contact No",
        name: "phone",
        type: "ssn",
        mask: `+\\${phoneCode} (999) 999-9999`,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
    ],
    [
      {
        label: "Wallet Address (#)",
        name: "wallet_address",
        type: "text",
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
      {
        label: "Social Security #",
        name: "social_security",
        placeholder: "XXX-XX-XXXX",
        type: "ssn",
        mask: "***-**-****",
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
      {
        label: "Why I am involved with Qrata?",
        name: "reason_to_join",
        type: "textarea",
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    ],
  ];

  // SELECT PHOTO FOR UPLOAD AND PREVIEW
  const selectPhoto = useCallback(
    (e) => {
      const fileFormat = ["jpg", "png", "jpeg"];
      let reader = new FileReader();
      const file = e.target.files[0];
      if (fileFormat.includes(file.type.split("/")[1])) {
        reader.onload = function (e) {
          setProfilePic(reader.result);
          setUploadFile(file);
        };
        reader.readAsDataURL(file);
      } else {
        dispatch(alertActions.error("Please select valid file format ."));
      }
    },
    [dispatch]
  );

  //  // UPLOAD PROFILE IMAGE
  const uploadPhoto = useCallback(() => {
    if (uploadFile !== "") {
      dispatch(loaderAction.loader(true));
      let data = new FormData();
      data.append("id", formData.id);
      data.append("image", uploadFile, uploadFile.name);
      userService.uploadProfilePic(data).then(([response, error]) => {
        if (response) {
          const obj = {
            ...sessionData,
            profile_pic: response.image,
          };
          dispatch(authActions.updateSession(obj));
          if (response.image) setProfilePic(response.image);
          dispatch(alertActions.success("Profile photo updated."));
          setUploadFile("");
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      });
    }
  }, [dispatch, formData, uploadFile, sessionData]);

  return (
    <div id="inner-wrapper">
      <Container fluid>
        <Row className="bg-white">
          <Col md={4}>
            <Row className="no-gutters">
              <Col md={12} className="bg-dark">
                <Card className="bg-dark border-0 py-3">
                  <Card.Body className="text-center">
                    <span className="user-img m-auto">
                      <Image
                        src={profilePic ? profilePic : user}
                        roundedCircle
                      />
                      {uploadFile === "" ? (
                        <>
                          <input
                            type="file"
                            accept=".jpg,.png,.jpeg"
                            onChange={(e) => selectPhoto(e)}
                            name="bioPicture"
                            id="bioPicture"
                            className="d-none"
                          />
                          <label
                            className="btn-sm btn-primary rounded-pill upload-btn"
                            htmlFor="bioPicture"
                          >
                            <FontAwesomeIcon icon="camera" />
                          </label>
                        </>
                      ) : (
                        <Button
                          variant="warning"
                          onClick={() => uploadPhoto()}
                          className="rounded-pill upload-btn"
                          size="sm"
                        >
                          <FontAwesomeIcon icon="upload" />{" "}
                        </Button>
                      )}
                    </span>
                    <Card.Title className="text-white mt-2">
                      {sessionData.first_name + " " + sessionData.last_name}
                    </Card.Title>
                    <Card.Text>
                      <Badge
                        className="bg-yellow p-2"
                        pill
                        variant="primary"
                        size="md"
                      >
                        {sessionData.group
                          ? UserRole.getRole(sessionData.group).title
                          : ""}
                      </Badge>
                    </Card.Text>
                    {/* <Card.Text className="text-white">Freelance editor and content writer . I help make your manuscript, essay, and outline shine!Freelance editor and content writer . I help make your manuscript, essay, and outline shine!</Card.Text> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col md={8}>
            <Card className="border-0">
              <h4 className="pl-3 pt-2">My Profile</h4>
              <Card.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <FormBlock
                    fields={fields}
                    getData={(e) => handleChange(e)}
                    formData={formData}
                  />
                  <Button
                    className="btn-green float-right"
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileComponent;