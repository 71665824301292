import React , { useEffect, useCallback , useRef} from 'react';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import ModalForm from '../../../shared/ModalForm';
import * as educationServices from '../../../services/education';
import { useDispatch, useSelector } from 'react-redux';
import * as loaderAction from '../../../actions/loader';
import * as alertActions from '../../../actions/alert';

const EducationForm = ({ id ,  isVisible, hide , getAllEducation}) => {
  
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.loginData);
  const [ comments , setComments] = useState([]);
  const [educationData , setEducationData] = useState({
    education_id : '',
    university: '',
    course: '',
    degree_month_year : null,
    education_file: '',
    file_id : ''
  });

  const prevState = useRef(educationData);

  // GET ALL COMMENTS  
  const getComment = useCallback(()=> {
    if(id !== ''){
      educationServices.getCommentEducation(id).then(([response , error]) => {
        if(response){
          setComments(response.comment);
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
      })
    }
  },[id , dispatch])


  useEffect(() => {
    if (id !== '') {
      dispatch(loaderAction.loader(true));
      educationServices.getEducationById(id).then(([response , error]) => {
        if(response){
          setEducationData({
            education_id : response[0].id,
            university: response[0].university,
            course: response[0].course,
            degree_month_year : response[0].degree_month_year,
            education_file: response[1].file_url,
            file_id: response[1].id
          });
          getComment();
        }else if(error){
          dispatch(alertActions.error(error.response))
        }
        dispatch(loaderAction.loader(false));
      })
    } else {
      setEducationData(prevState.current);
      setComments([]);
    }
  }, [id , dispatch , getComment])

  // CREATE NEW EDUCATION
  const createEducation = useCallback((data) => {
     if(data){
       let newData = new FormData();
       newData.append("user_id" , sessionData.id);
       newData.append('university' , data.university);
       newData.append('course' , data.course);
       newData.append('degree_month_year' , data.degree_month_year);
      if(data.education_file.name){
        newData.append('education_file' , data.education_file , data.education_file.name);
      }
              
       educationServices.createEducation(newData).then(([response , error]) => {
          if(response){
            dispatch(alertActions.success('Added Successfully.'));
            getAllEducation();
          }else if(error){
              dispatch(alertActions.error(error.response));
          }
        })
     }
  },[dispatch , sessionData , getAllEducation])

  // UPADTE EDUCATION 
  const updateEducation = useCallback((data) => {
    if(data){
      let newData = new FormData();
      newData.append("education_id" , data.education_id);
      newData.append("user_id" , sessionData.id);
      newData.append('university' , data.university);
      newData.append('course' , data.course);
      newData.append('degree_month_year' , data.degree_month_year);
     if(data.education_file.name && data.education_file.type){
       newData.append('education_file' , data.education_file , data.education_file.name);
       newData.append('file_id' , data.file_id);
     }
       educationServices.updateEducation(newData).then(([response , error]) => {
         if(response){
          dispatch(alertActions.success('Update Successfully.'));
          getAllEducation();
         }else if(error){
             dispatch(alertActions.error(error.response));
         }
       })
    }
 },[dispatch , sessionData , getAllEducation])

 // CLOSE POPUP PANEL
  const closeModal = useCallback((data) => {
      hide();
      if(data.education_id && data !== false){
        updateEducation(data);
      }else if(data !== false){
         createEducation(data);
      }
  }, [hide , createEducation , updateEducation]);

  // FORM FIELDS
  const fields =  [
    [{ label: 'University', name: 'university', type: 'text', required: true, placeholder: 'University', sm: 12, md: 12, lg: 12, xl: 12 }],
    [{ label: 'Degree / Major / Minor / Courses / Certifications ', name: 'course', type: 'text', required: true, placeholder: 'Course', sm: 12, md: 6, lg: 6, xl: 6 },
    { label: 'Completion Date', name: 'degree_month_year', type: 'date', required: true, placeholder: 'MM/yyyy', sm: 12, md: 6, lg: 6, xl: 6 }],
    [{ label: 'Attachment', required: id !== '' ? false : true ,  name: 'education_file', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', type: 'file', placeholder: 'Select File',  sm: 12, md: 12, lg: 12, xl: 12 }],
  ];

  return (
    <Container fluid>
      {isVisible ?
        <ModalForm
          show={isVisible}
          onHide={(e) => closeModal(e)}
          data={educationData}
          fields={fields}
          title={id !== '' ? 'Update an Education' : 'Add an Education'}
          comment={comments ? comments : []}
          button={id !== '' ? 'Update' : 'Save'}
        />
        : null}
    </Container>
  )
}

export default EducationForm;