import React , { useEffect , useState , useRef , useCallback } from 'react';
import * as contactService from '../../services/contact';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipHover from '../../shared/TooltipHover';
import { useDispatch } from 'react-redux';
import AsyncAppTable from '../../shared/AsyncAppTable';
import { QueryType , dateOnlyFormat } from '../../helpers';
import { useBoolean } from '../../hooks/basic';
import ContactForm from './ContactForm';
import ConfirmModal from '../../shared/ConfirmModal';
import { defaultLimit } from '../../helpers';
import moment from "moment"


const ContactListing = ({search , memberType , queryType, status}) => {

const dispatch = useDispatch();
const [contactData , setContactData] = useState([]);
const [contactEnums , setContactEnums ] = useState([]);
const fetchIdRef = useRef(0);
const [loading , setLoading ] = useState(false);
const [pageCount, setPageCount] = useState(0);
const [isVisible, showModal, hideModal] = useBoolean(false);
const [editData , setEditData] = useState('');
const [id , setId] = useState('');
const [name, setName] = useState("");
const [confirm, showConfirm, hideConfirm] = useBoolean(false);
const [resultCount, setResultCount] = useState(0);
const [value , setValue ] = useState('');
const [callFetch, setCallFetch] = useState(false);

useEffect(() => {
  contactService.getContactEnums().then(([response , error]) => {
    if(response){
      setContactEnums(response);
    }else if(error){
      dispatch(alertActions.error(error.response));
    }
  })
},[dispatch])

const displayContactQuery = (value) => {
  if(contactEnums.length > 0) {
    return contactEnums.filter((obj) => obj.value === value );
  }
}

 const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField="" , filter}) => {
   const fetchId = ++fetchIdRef.current;
  setLoading(true);
  setTimeout(() => {
    if (fetchId === fetchIdRef.current) {
      const excludedQuery = [1,5];
      const limit = pageSize;
      let offset = pageIndex * pageSize;
      dispatch(loaderAction.loader(true));
      contactService.getListing(queryType.value , limit , offset , '' , search, "", sortedField, filter, memberType, status, excludedQuery).then(([response , error]) => {
        if(response){
          // let result = response.results.filter((obj) => obj.query !== 1 && obj.query !== 5);
          setContactData(response.results);
          setResultCount(response.count)
          setPageCount(Math.ceil(response.count / pageSize))
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
      setLoading(false);
      setCallFetch(false);
    }
  }, 100)
}, [dispatch , queryType.value , search , memberType, status])

const openlink = (link) => {
  window.open(link, '_blank');
}

const deleteData  = useCallback(() => {
  dispatch(loaderAction.loader(true));
  contactService.deleteTicket(id, name).then(([response , error]) => {
    hideConfirm();
    // fetchData(defaultLimit ,0);
    setCallFetch(true);
    if(error){
      dispatch(alertActions.error(error.response));
    }
    dispatch(loaderAction.loader(false));
  })
},[dispatch, id , hideConfirm, name ])

const openModal = useCallback((data) => {
  if(data) setEditData(data);
  showModal();
}, [showModal]);


const closeModal = useCallback(() => {
  setEditData('');
  // fetchData(defaultLimit ,0);
  setCallFetch(true);
  hideModal();
},[hideModal])

const selectColumnFilterContact = (column) => {

  const filterHandler = (e) => {
    setValue(e.target.value);
    column.setFilterDate({...column.filterData, [column.column.id]:Number(e.target.value)})
  }

  return <select 
  className="form-control bg-dark text-white mb-2 border-secondary"
     name={column.column.id} onChange={(e) => filterHandler(e)} selected={value} value={Number(value)}>
      <option value="">All</option>
      {contactEnums.map((item, index) => item.value ? (
        <option value={Number(item.value)} key={'contact' + index} >{item.label}</option>
      ): ' ')}
  </select> 
}

const contact = [
  {
    Header: "Action",
    accessor: "action",
    sortingOff: true,
    className: 'text-center',
    Cell: (cell) => (
      <div>
        <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default'  message='View Ticket ' handleEvent={() => openModal(cell.row.original)} />
        <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Ticket' handleEvent={() => openConfirmModal(cell.row.original.id, cell.row.original.first_name ? cell.row.original.first_name : '')}/>
      </div>
    ),
  },
  {
    Header: "Ticket Id",
    accessor: "query_ticket",
    sortType: "basic",
    filtering:true,
  },
  {
    Header: "Name",
    accessor: "user__first_name",
    sortType: "basic",
    className: 'text-capitalize',
    filtering:memberType === 2 ? false: true,
    Cell: (cell) => ( 
      <div>
        {cell.row.original.first_name || cell.row.original.last_name ?  cell.row.original.first_name+' '+cell.row.original.last_name  : 'Guest user'}
      </div>
    )
  },
  {
    Header: "Email ID",
    accessor: "email",
    sortType: "basic",
    id:memberType === 2 ? 'email': "user__email",
    className: 'text-capitalize',
    filtering:true,
  },
  {
    Header: "Type",
    sortType: "basic",
    accessor: "query",
    filtering:true,
    Filter: selectColumnFilterContact,
    Cell: (cell) => {
      const results = displayContactQuery(cell.row.original.query);
      return ( 
        <div>
          {results && results.length > 0 ? results[0].label : ''}
        </div>
      )
    }
  },
  {
    Header: "Created On",
    accessor: "created_on",
    sortType: "basic",
    Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
  },
  {
    Header: "File",
    className: 'text-center',
    sortingOff: true,
    Cell: (cell) => ( 
      <div>
        {cell.row.original.file.length > 0 ? <FontAwesomeIcon className='cursor-pointer' onClick={() => openlink(cell.row.original.file[0].file_url)} icon='download' /> : 'No file'}
      </div>
    )
  },

];


const openConfirmModal = (id, user) => {
  setId(id);
  setName(user);
  showConfirm();
};

const closeConfirmModal = () => {
  setId('');
  hideConfirm();
};

const acceptConfirmation = useCallback(() => {
 deleteData();
},[deleteData])


  return (
    <>
    <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={acceptConfirmation} title={'Do you want delete this ticket ? '} />
    <ContactForm data={editData} title={editData.query_type === QueryType.CONTACT.id ? 'Contact Reply' : 'Support Reply'} isVisible={isVisible} hide={closeModal} refreshData={() => setCallFetch(true)} />
    <AsyncAppTable
        columns={contact}
        data={contactData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount} 
        filtering={true} 
        callFetch={callFetch}/>
    </>
  )
}
export default ContactListing;