import React , { useState , useEffect , useCallback } from 'react';
import {  Col, Container, Row } from 'react-bootstrap';
import { monthYearFormat, getStatus, Storage } from '../../../helpers';
import { useBoolean } from '../../../hooks/basic';
import * as educationServices from '../../../services/education';
import AppTable from '../../../shared/AppTable';
import TooltipHover from '../../../shared/TooltipHover';
import EducationForm from './EducationForm';
import { useDispatch } from 'react-redux';
import * as loaderAction from '../../../actions/loader';
import * as alertActions from '../../../actions/alert';
import { dateOnlyFormat } from "../../../helpers";
import SearchField from '../../../shared/SearchField';

const Education = () => {

  const dispatch = useDispatch();
  const [education , setEducation] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [editData , setEditData] = useState('');
  const [search, setSearch] = useState('')

  // GET ALL EDUCATION LIST
  const getAllEducation = useCallback(() => {
    dispatch(loaderAction.loader(true));
    const userId = Storage.getSession().id;
    educationServices.getEducation(userId , '').then(([response , error]) => {
      if(response){
        setEducation(response.results);
      }else if(error){
        dispatch(alertActions.error(error.response))
      }
      dispatch(loaderAction.loader(false));
    })
  },[dispatch])
  

  useEffect(() => {
    getAllEducation()
  }, [getAllEducation])

  // OPEN POPUP  MODAL
  const openModal = useCallback((data) => {
    if(data) setEditData(data);
    showModal();
  }, [showModal]);

  // CLOSE POPUP MODAL
  const closeModal = useCallback(() => {
    setEditData('');
    hideModal();
  },[hideModal])

  // REMOVE EDUCATION BY ID 
  const removeEducation = useCallback((id) => {
    if (id) {
      dispatch(loaderAction.loader(true));
      educationServices.deleteEducation(id).then(([response, error]) => {
          const list = education.filter((item) => item.id !== id);
          setEducation(list);
          dispatch(alertActions.success('Delete Successfully.'));
        if(error){
          dispatch(alertActions.error(error.response));
        }
         dispatch(loaderAction.loader(false));
      })
    }
  }, [education , dispatch])

  // TABLE COLUMNS FOR SHOW LISTING DATA
  const columns = [
    {
      Header: "Action",
      accessor: "action",
      filteringOff:true,
      className:'text-center',
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='edit' iconClass='default'  message='Edit education' handleEvent={() => openModal(cell.row.original.id)} />
          <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete education' handleEvent={() => removeEducation(cell.row.original.id)}/>
        </div>
      ),
    },
    {
      Header: "University",
      accessor: "university",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Course",
      accessor: "course",
      sortType: "basic",
      className: 'text-capitalize'
    },
    {
      Header: "Completion Date",
      accessor: "degree_month_year",
      sortType: "basic",
      Cell: (cell) => (<div>{monthYearFormat(cell.row.original.degree_month_year)}</div>)
      
    },
    {
      Header: "Status",
      accessor: "status",
      filteringOff:true,
      Cell: (row) => (<div>{getStatus(row.row.original.status)}</div>)
    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on) : 'N/A'}</div>)
    },

  ];

  return (
    <Container fluid className="px-0">
      <EducationForm id={editData} isVisible={isVisible} hide={closeModal} getAllEducation={() => getAllEducation()} />
      <Row className="no-gutters">
        <Col md={7}  className="mb-md-0 mb-3"><h4> Education </h4></Col>
        <SearchField md={4} xs={10} action={setSearch} />
        <Col md={1} xs={2} className="text-right">
        <TooltipHover type='button' btnSize='md' variant='primary' message='Add Education' btnIcon='plus' iconClass='text-white' handleEvent={() => openModal('')}  />
        </Col>
      </Row>
      <AppTable globalFilter={search} columns={columns} data={education} />
    </Container>
  )
}
export default Education;