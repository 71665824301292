import { handleApi, HEADERS } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'education/';

// CREATE EDUCATION IN PROFILE
export async function createEducation(data) {
  return await handleApi(axiosClient.post(`${BASE}create_education/`, data , {headers: HEADERS.FILE()}));
}

// GET EDUCATION LIST IN PROFILE
export async function getEducation(user , status) {
  return await handleApi(axiosClient.get(`${BASE}?${user ? 'user='+user : ''}${user && status ? '&' : ''}${status ? 'status='+status : ''}`));
}

//  UPDATE EDUCATION IN PROFILE
export async function updateEducation(data) {
  return await handleApi(axiosClient.post(`${BASE}update_education/`, data , {headers: HEADERS.FILE()}));
}

//  GET EDUCATION IN PROFILE BY ID
export async function getEducationById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_education/`));
}

// DELETE EDUCATION BY ID
export async function deleteEducation(id) {
  return await handleApi(axiosClient.delete(`${BASE}`));
}

// VERIFY EDUCATION
export async function educationVerify(data) {
  return await handleApi(axiosClient.post(`${BASE}education_verify/`, data , {headers: HEADERS.FILE()}));
}

// GET ALL COMMENTS IN EDUCATION
export async function getCommentEducation(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_comment/`));
}

// CREATE COMMENT IN EDUCATION
export async function createCommentEducation(data) {
  return await handleApi(axiosClient.post(`${BASE}create_comment/`, data ));
}

// GET COMPLETE EDUCATION INFORMATION OF A PARTICULAR USER
export async function getEducationInfo(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/list/`));
}



