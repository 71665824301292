import React , { useState } from 'react';
import ModalForm from './ModalForm';
import PropTypes from 'prop-types';

const CommentModal = ({ title, show, onHide, data , fields }) => {

  const formData = useState({
    comment: ''
  });

  const form1 = [
    { label: 'Comment', type: 'textarea', required: true, rows: 5, name: 'comment', placeholder: 'Type here.....' },
    { label: 'Attachment', fileFormat: '.doc,.docx,.pdf,.jpg,.png,.jpeg', name: 'attachment', type: 'file', placeholder: 'Select File'},
  ]

  return (
    <>
    {show ?
      <ModalForm
        show={show}
        onHide={(e) => onHide(e)}
        data={data ? data : formData}
        fields={fields ? fields : form1}
        title={title}
        button={'Submit'}
      />
      : null}
      </>
  )
}
CommentModal.propTypes = {
  button: PropTypes.string,
  data: PropTypes.any,
  show: PropTypes.any,
  onHide: PropTypes.func.isRequired
};

CommentModal.defaultProps = {
  button: 'Submit',
  data: [],
};
export default CommentModal