import React, { useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SelectOption from '../select/SelectOption';
import { HasPermission } from '../HasPermission';
import CreateSelect from '../select/CreateSelect';

const MultiSelect = ({ onCreate,creatable ,label, id, permission, inputRef, group ,  md, sm, lg, xs, xl, col, multi, opts, placeholder, name, value, options, handleEvent, validators }) => {

    const [initialValue, setInitialValue] = React.useState('');

    useEffect(() => {
        if (options && value) {
            options.filter((obj) => obj.value === value ? setInitialValue(obj) : null)
        }else {
            setInitialValue('');
        }
    }, [options, value])

    const handleSelect = (dt) => {
        const data = { name, value:dt };
        handleEvent(data)
    }
    
    const handleChange = (e) => {
        setInitialValue(e);
        const { value } = e;
        const data = { name, value };
        handleEvent(data);
    }

    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}>
                        {label ? <Form.Label >{label}</Form.Label> : ''}
                        {!creatable ?
                        <SelectOption id={id} data={options} group={group} multi={multi} inputRef={inputRef} {...opts} handleEvent={(e) => handleChange(e)} placeholder={placeholder} value={initialValue} /> 
                        : 
                        <CreateSelect onCreate={onCreate} value={value} creatable={creatable} id={id} data={options} group={group} multi={multi} inputRef={inputRef} {...opts} handleEvent={(e) => handleSelect(e)} placeholder={placeholder} />
                        }

                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

MultiSelect.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    validators: PropTypes.array,
    label: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    options: PropTypes.any,
    permission: PropTypes.any,
    multi: PropTypes.bool,
    group: PropTypes.bool,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired
};

MultiSelect.defaultProps = {
    id: '',
    value: '',
    name: '',
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    multi: false,
    placeholder: 'Select',
    permission: '',
    type: 'select',
    group : false,
    label: '',
    opts: {},
    options: [],
};
export default MultiSelect;