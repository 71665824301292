import { handleApi , HEADERS } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'bio/';

// GET BIO COMMNET
export async function getCommentsBio(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_comment/`));
}

// CREATE COMMENT IN BIO
export async function createCommentBio(data) {
  return await handleApi(axiosClient.post(`${BASE}create_comment/` , data));
}

// GET BIO LIST BY STATUS
export async function getBioList(id) {
  return await handleApi(axiosClient.get(`${BASE}list_bio/${id ? '?status='+id : ''}`));
}


export async function getBio(id) {
  return await handleApi(axiosClient.get(`${BASE}?catalog=${id}`));
}

// VERIFY BIO
export async function verifyBio(data) {
  return await handleApi(axiosClient.post(`${BASE}verify/`, data));
}

// CREATE BIO
export async function createBio(data) {
  return await handleApi(axiosClient.post(`${BASE}create_bio/`, data));
}

// UPDATE BIO
export async function updateBio(id , data) {
  return await handleApi(axiosClient.put(`${BASE}${id}/`, data));
}

// GET BIO BY ID
export async function getBioById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_bio_data/`));
}

// BIO Photo BY ID
export async function getBioPhotoById(id) {
  return await handleApi(axiosClient.get(`/bio_photo/${id}/`));
}

// UPLOAD BIO PHOTO
export async function uploadBioPhoto(data) {
  return await handleApi(axiosClient.post(`/bio_photo/upload_bio_photo/` , data , {headers: HEADERS.FILE()}));
}

// BIO ACCOMPLISHMENT
export async function addExistingAccomplishment(data) {
  return await handleApi(axiosClient.post(`${BASE}create_accomplishment/`, data));
}

export async function deleteAccomplishment(data) {
  return await handleApi(axiosClient.post(`${BASE}delete_accomplishment/`, data));
}

export async function verifyAccomplishment(data) {
  return await handleApi(axiosClient.post(`${BASE}accomplishment_verify/`, data));
}

// BIO EDUCATION
export async function addExistingEducation(data) {
  return await handleApi(axiosClient.post(`${BASE}create_education/`, data));
}

export async function deleteEducation(data) {
  return await handleApi(axiosClient.post(`${BASE}delete_education/`, data));
}

export async function verifyEducation(data) {
  return await handleApi(axiosClient.post(`${BASE}degree_verify/`, data));
}

// BIO JOB
export async function addExistingJob(data) {
  return await handleApi(axiosClient.post(`${BASE}create_job/`, data));
}

export async function deleteJob(data) {
  return await handleApi(axiosClient.post(`${BASE}delete_job/`, data));
}

export async function verifyJob(data) {
  return await handleApi(axiosClient.post(`${BASE}job_verify/`, data));
}

export async function getInitialBioText(id) {
  return await handleApi(axiosClient.get(`application/${id}/`));
}