import React, { useState, useCallback} from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Stats from './Stats';
import { useDispatch } from 'react-redux';
import * as alertActions from "../../../actions/alert";
import * as loaderAction from "../../../actions/loader";
import * as applicationServices from "../../../services/application";
import { history } from '../../../helpers';
import { Status } from '../../../helpers/constants';
import { useBoolean } from '../../../hooks/basic';
import ConfirmModal from '../../../shared/ConfirmModal';


const Sixth = (props) => {
  const dispatch = useDispatch();
  const [submission, setSubmission] = useState(true);
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);

  const submit = useCallback((e) => {
    if (e.target.checked) {
      setSubmission(false);
    } else {
      setSubmission(true);
      dispatch(alertActions.error('Please select the Terms & Conditions.'));
    }
  }, [dispatch])

  const submitApplication = useCallback(() => {
    if (submission === false && props.applicationId !== '') {
      
      const data = {
        user: props.user.id,
        status: Status.UNDER_REVIEW.value
      }
      dispatch(loaderAction.loader(true));
      applicationServices.updateApplication(props.applicationId, data).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Your application submitted successfully.'));
          setTimeout(() => {
            history.push('/specialization');
          }, 10)
        } else {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [props , dispatch, submission])

  const closeConfirmModal = () => {
    hideConfirm();
  };

  const handleConform = () => {
    hideConfirm();
    submitApplication();
  }

  const openConfirmModal = () => {
    showConfirm();
  };

  return (
    <Row className="justify-content-md-center pt-5 mx-0">
      <ConfirmModal show={confirm} onHide={closeConfirmModal} acceptConfirm={handleConform} title='Do you want submit this application ?' />
      <Col md={12}>
        <h4 className="mb-4">Final Submission</h4>
        <h5>Terms and Conditions for Qrata.com</h5>
        <h6>Introduction</h6>
        <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at qrata.com. </p>

        <p>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.</p>


        <h6>Intellectual Property Rights</h6>
        <p>Other than the content you own, under these Terms, Qrata.com and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>

        <p>You are granted limited license only for purposes of viewing the material contained on this Website.</p>


        <h6>Your Content</h6>
        <p>In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant Qrata.com a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>

        <p>Your Content must be your own and must not be invading any third-party's rights. Qrata.com reserves the right to remove any of Your Content from this Website at any time without notice.</p>
        <p>All the information that you provide that is authenticated. If you information is not valid .Please go back and make it correct.  </p>

        <strong> <Form.Check type='checkbox' label='I agree all the Terms and condition of this website.' id='term' onChange={(e) => submit(e)} name='term' placeholder='test' /></strong>
        <Stats {...props} start={submission} submitData={() => openConfirmModal()} />
      </Col>
    </Row>

  );
}
export default Sixth;