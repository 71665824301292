import { CATALOG } from '../helpers/actionTypes';

const initState = {
  isFetchedOne: false,
  isFetchingOne: false,
  isFetchingAll: false,
  isDeleting: false,
  isDeleted: false,
  isCreating: false,
  isCreated: false,
  oneCatalog: {},
  allCatalogs: []
}

export function catalog(state, { payload, type, error }) {
  if (!state) {
    state = initState
  }
  switch (type) {
    case CATALOG.CATALOG__CREATE_ONE.REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false
      };
    case CATALOG.CATALOG__CREATE_ONE.SUCCESS:
      return {
        ...state,
        isCreating: false,
        isCreated: true
      };
    case CATALOG.CATALOG__CREATE_ONE.FAILURE:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error: error
      };
      case CATALOG.CATALOG__UPDATE_ONE.REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false
      };
    case CATALOG.CATALOG__UPDATE_ONE.SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true
      };
    case CATALOG.CATALOG__UPDATE_ONE.FAILURE:
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
        error: error
      };
    case CATALOG.CATALOG__FETCH_ONE.REQUEST:
      return {
        ...state,
        isFetchingOne: true,
        isFetchedOne: false
      }
    case CATALOG.CATALOG__FETCH_ONE.SUCCESS:
      return {
        ...state,
        isFetchingOne: false,
        isFetchedOne: true,
        oneCatalog: payload.data
      };
    case CATALOG.CATALOG__FETCH_ONE.FAILURE:
      return {
        ...state,
        isFetchingOne: false,
        isFetchedOne: false,
        oneCatalog: {},
        error: error
      };
    case CATALOG.CATALOG__FETCH_ALL.REQUEST:
      return {
        ...state,
        isFetchingAll: true
      }
    case CATALOG.CATALOG__FETCH_ALL.SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        allCatalogs: payload.data.results
      };
    case CATALOG.CATALOG__FETCH_ALL.FAILURE:
      return {
        ...state,
        isFetchingAll: false,
        allCatalogs: [],
        error: error
      };
    case CATALOG.CATALOG__DELETE.REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false
      };
    case CATALOG.CATALOG__DELETE.SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: true
      };
    case CATALOG.CATALOG__DELETE.FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: error
      };
      case CATALOG.CATALOG__MAJOR.REQUEST:
      return {
        ...state,
        isFetchingAll: true
      }
    case CATALOG.CATALOG__MAJOR.SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        allCatalogs: payload.data.results
      };
    case CATALOG.CATALOG__MAJOR.FAILURE:
      return {
        ...state,
        isFetchingAll: false,
        allCatalogs: [],
        error: error
      };
      case CATALOG.CATALOG__MINOR.REQUEST:
      return {
        ...state,
        isFetchingAll: true
      }
    case CATALOG.CATALOG__MINOR.SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        allCatalogs: payload.data.results
      };
    case CATALOG.CATALOG__MINOR.FAILURE:
      return {
        ...state,
        isFetchingAll: false,
        allCatalogs: [],
        error: error
      };
      case CATALOG.CATALOG__CATEGORY.REQUEST:
      return {
        ...state,
        isFetchingAll: true
      }
    case CATALOG.CATALOG__CATEGORY.SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        allCatalogs: payload.data.results
      };
    case CATALOG.CATALOG__CATEGORY.FAILURE:
      return {
        ...state,
        isFetchingAll: false,
        allCatalogs: [],
        error: error
      };
    default:
      return state;
  }
}