import { APPLICATION } from '../helpers/actionTypes';

export const initState = {
    isLoading: true,
    error: {},
    appData: {
        applications: []
    }
}

export function application(state, { payload, type, error }) {
    if (!state) {
        state = initState
    }
    switch (type) {
       
        case APPLICATION.GET_LIST_APPLICATION.REQUEST:
            return {
                ...state,
                isLoading: true,
                error: {},
                appData: {}
            };
        case APPLICATION.GET_LIST_APPLICATION.SUCCESS:
            return {
                ...state,
                isLoading: false,
                appData: payload.data,
                error: {}
            };
        case APPLICATION.GET_LIST_APPLICATION.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: error,
                appData: {}
            };
        default:
            return state;
    }

}