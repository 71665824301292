const STORAGE = localStorage;
const SESSION = 'session';
const TOKEN = 'token';
const REFRESH_TOKEN = 'refreshToken';
const MEMBERSHIP = 'membership';
const LOG = 'log';
const LOGIN = 'validated';

export class Storage {


    static removeToken() {
        STORAGE.removeItem(TOKEN);
    }

    static removeRefreshToken() {
        STORAGE.removeItem(REFRESH_TOKEN);
    }

    static getSession() {
        try {
            return JSON.parse(STORAGE.getItem(SESSION));
        } catch (e) {
            return null;
        }
    }

    static getToken() {
        return STORAGE.getItem(TOKEN);
    }

    static getRefreshToken() {
        return STORAGE.getItem(REFRESH_TOKEN);
    }

    static removeAll() {
        STORAGE.removeItem(SESSION);
        this.removeToken();
        this.removeRefreshToken();
        this.removeMembership();
        this.removeLog();
      }

    static setToken(token) {
        STORAGE.setItem(TOKEN, token);
    }

    static setRefreshToken(refresh) {
        STORAGE.setItem(REFRESH_TOKEN, refresh);
    } 
    
    static setSession(user) {
        this.setToken(user.access);
        this.setRefreshToken(user.refresh);
        STORAGE.setItem(SESSION, JSON.stringify(user));
    }

    static updateSession(obj) {
        STORAGE.setItem(SESSION, JSON.stringify(obj));
    }

    static setMembership(id){
        STORAGE.setItem(MEMBERSHIP , id)
    }
    
    static getMembership(){
        return JSON.parse(STORAGE.getItem(MEMBERSHIP));
    }

    static removeMembership() {
        STORAGE.removeItem(MEMBERSHIP);
    }

    static setLog(id){
        STORAGE.setItem(LOG , id)
    }
    
    static getLog(){
        return JSON.parse(STORAGE.getItem(LOG));
    }

    static removeLog() {
        STORAGE.removeItem(LOG);
    }
    static setLoginState(data){
        STORAGE.setItem(LOGIN , data)
    }
    
    static getLoginState(){
        return JSON.parse(STORAGE.getItem(LOGIN));
    }

    static removeLoginState() {
        STORAGE.removeItem(LOGIN);
    }
}
