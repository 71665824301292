import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as loaderAction from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import * as accomplishmentServices from '../../services/accomplishment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { monthYearFormat, getStatus } from '../../helpers';

const AccomplishmentInfo = () => {

     // getting User Id from url params
  const { userId } = useParams();

  const [accomplishmentList, setAccomplishmentList] = useState([]);

    const dispatch = useDispatch();

    const getAllAccomplishment = useCallback(() => {
        dispatch(loaderAction.loader(true));
        accomplishmentServices.getAccomplishmentInfo(userId).then(([response, error]) => {
          if (response) {
            setAccomplishmentList(response.accomplishment_list)
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
      }, [dispatch, userId])

      useEffect(() => {
        getAllAccomplishment();
      }, [getAllAccomplishment])

      return (
        <>
          {accomplishmentList.length > 0 ?
            <Card className="py-3">
              {accomplishmentList.map((item, index) => (
  
                <Card.Body key={index} className="py-1">
                  <Row key={index}>
                    <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item.accomplishment[0].accomplishment_type}>
                    <label className="d-block text-left mb-0"><small>Type</small></label>
                     <h6>{item.accomplishment[0].accomplishment_type}</h6>
                    </Col>
                    <Col md={3} sm={12} className="text-left font-weight-bold text-truncate overflow-hidden" title={item.accomplishment[0].title}>
                    <label className="d-block text-left mb-0"><small>Title</small></label>
                     <h6 className='text-warning'> {item.accomplishment[0].title} </h6>
                    </Col>
                    <Col md={2} sm={12}>
                    <label className="d-block text-left mb-0"><small>Status</small></label>
                    <Badge pill variant="primary">
                    {getStatus(item.accomplishment[0].status) ? getStatus(item.accomplishment[0].status) : null}
                    </Badge>
                    </Col>
                    <Col md={3} sm={6}>
                    <label className="d-block text-left mb-0"><small>Year</small></label>
                    <h6>{monthYearFormat(item.accomplishment[0].accomplishment_month_year)}</h6>
                    </Col>
                    <Col md={1} sm={6} className="text-info font-weight-bold font-style-italic text-right">
                    <label className="d-block text-left mb-0"><small>&nbsp;</small></label>
                      {item.accomplishment[1]? <a className=" text-danger text-decoration-none  ml-2" target="_blank" rel="noopener noreferrer" href={item.accomplishment[1].file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a> : 'NA'}
                    </Col>
                    <Col md={12} sm={12} className='text-truncate overflow-hidden' title={item.accomplishment[0].description}>
                    <label className="d-block text-left mb-0"><small>Description</small></label>
                      <h6>{item.accomplishment[0].description}</h6>
                    </Col>
                  </Row>
                  {accomplishmentList.length - 1 === index ? '' : <hr className="mt-1 mb-2" />}
                </Card.Body>
  
              ))} </Card>
            : 'No data found.'}
        </>
      )
  }

  export default AccomplishmentInfo;