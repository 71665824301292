import React  from 'react';
import { Col, InputGroup, FormControl, Button  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchField = ({ md, xs, action, cssClass }) => {
    return (
        <Col md={md} xs={xs} className={cssClass}>
        <InputGroup className="mb-3">
          <FormControl
            type="search"
            placeholder="Search ..."
            aria-label="search"
            aria-describedby="search_contact"
            onBlur = {e => action(e.target.value)}
            onKeyDown={e => e.keyCode === 13 ? action(e.target.value) : null}
          />
          <InputGroup.Append>
            <Button variant="outline-secondary">
              <FontAwesomeIcon icon="search" />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Col>
    )
}

export default SearchField;