import { ASSETS } from '../helpers/actionTypes';

export const initState = {
    isLoading: true,
    error: {},
    assetsData: {}
}

export function assets(state, { payload, type, error }) {
    if (!state) {
        state = initState
    }
    switch (type) {
       
        case ASSETS.GET_LIST_ASSETS.REQUEST:
            return {
                ...state,
                isLoading: true,
                error: {},
                assetsData: {}
            };
        case ASSETS.GET_LIST_ASSETS.SUCCESS:
            return {
                ...state,
                isLoading: false,
                assetsData: payload.data,
                error: {}
            };
        case ASSETS.GET_LIST_ASSETS.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: error,
                assetsData: {}
            };
        default:
            return state;
    }

}