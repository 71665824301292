import React , { useState , useEffect} from "react";
import { OverlayTrigger, Popover, Container, Row, Col, Form } from 'react-bootstrap';
import AppTable from "../../shared/AppTable";
import { ReactTinyLink } from "react-tiny-link";
import TooltipHover from "../../shared/TooltipHover";
import { history } from "../../helpers";
import * as assetService from '../../services/asset';
import { HasPermission } from "../../shared/HasPermission";
import { Status } from "../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import SearchField from '../../shared/SearchField';
import { AssetExpertStatus, AssetStatus } from "../../helpers/constants";
import * as loaderActions from '../../actions/loader';
import { dateOnlyFormat } from '../../helpers';


const columns = [
  {
    Header: "Action",
    accessor: "action",
    filteringOff:true,
    className: "action-l text-center",
    Cell: (cell) => (
      <div>
        {cell.row.original.status === Status.DRAFT.value ? 
          <TooltipHover type='button' btnSize='sm' handleEvent={() =>  history.push('/expertAssetScoring/'+cell.row.original.id+'/'+cell.row.original.asset_expert_id+'/'+cell.row.original.status)} variant='link' iconClass='default' btnIcon='edit' message='Rating Asset' /> :
          <TooltipHover type='button' btnSize='sm' handleEvent={() =>  history.push('/assetView/'+cell.row.original.id+'/'+cell.row.original.asset_expert_id)} variant='link' iconClass='default' btnIcon='eye' message='Asset Preview' />}
         </div>
    ),
  },
  {
    Header: "Specific Topic",
    accessor: "specific_topic",
    sortType: "basic",
    Cell: (cell) => 
    <div>
        <OverlayTrigger
          trigger="click"
          placement='top'
          overlay={
            <Popover id="popover-basic">
              <ReactTinyLink
                cardSize="small"
                showGraphic={true}
                maxLine={2}
                minLine={1}
                url={cell.row.original.url}
              />
            </Popover>
          }
        >
          <div>{cell.row.original.specific_topic}</div>
        </OverlayTrigger>
      </div>
    
  },
  {
    Header: "Specialization",
    accessor: "specialization",
    sortType: "basic",
    filtering:true,
    Cell: (cell) => 
      <div>
        <TooltipHover type='text' text={cell.row.original.specialization} message={cell.row.original.specialization} textClass='text-ellipsis d-block' handleEvent={() => null} />
    </div>
  },

  {
    Header: "Asset Status",
    accessor: "asset_status",
    sortType: "basic",
    filtering:true,
    Cell: (cell) => 
      <div>
       <div>{AssetStatus.getAssetStatus(cell.row.original.asset_status).label}</div>
    </div>
  },
  
  {
    Header: "Created by",
    accessor: "first_name",
    sortType: "basic",
    className: 'text-truncate overflow-hidden',
    Cell: (cell) => (<div>{cell.row.original.first_name+' '+cell.row.original.last_name}</div>)
  },
  {
    Header: "Created On",
    accessor: "created_on",
    sortType: "basic",
    Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
  },

];

const AssetExpertList = () => {

  const dispatch = useDispatch();
  const [assetList , setAssetList] = useState([]);
  const loginData = useSelector((state) => state.auth.loginData);
  const [search, setSearch] = useState('')
  const [status , setStatus] = useState(4);

  useEffect(() => {
    dispatch(loaderActions.loader(true));
    assetService.getExpertAssets(loginData.id, status).then(([response , error]) => {
      if(response){
        setAssetList(response.asset_expert_list);
      }else if(error){
        console.log(error);
      }
      dispatch(loaderActions.loader(false));
    })
  },[loginData.id, status , dispatch])


  return (
    <Container fluid className="px-0">
      <Row className="no-gutters">
        <Col md={4} sm={4} className="mb-md-0 mb-3">
        <h4>Assets List</h4>
        </Col>
        <Col md={2} sm={6}  className="mb-md-0 mb-2">
        <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
            {Object.values(AssetExpertStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
            )}
          </Form.Control>
        </Col>
        <Col md={2} sm={4} className='text-right'>
        <HasPermission permission='qrata.add_asset'>
        <TooltipHover type='button' btnSize='md' variant='primary' btnIcon='plus' iconClass='text-white' message='Add Asset' handleEvent={() => history.push('/assetForm')}  />
        </HasPermission>
        </Col>
        <SearchField md={4} action={setSearch} cssClass='text-right' />
        

      </Row>
      <AppTable columns={columns} globalFilter={search}  data={assetList ? assetList : []} />
    </Container>
  );
}
export default AssetExpertList;


