
import React , { useEffect , useState }from  'react';
import { Col, Row } from 'react-bootstrap';
import { dateWithTimeFormat } from '../helpers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommentList = ({ comments }) => {
  const [commentsList, setCommentsList] = useState([]);

  useEffect(() => {
    if (comments) {
      setCommentsList(comments);
    }
  }, [comments])


  const openlink = (link) => {
    window.open(link, '_blank');
  }

  return (
    <>
      {commentsList && commentsList.length > 0 ? commentsList.map((item, index) => (
        <Row key={index} className={commentsList.length -1 === index ? "no-gutters  border-top-0 border-right-0 border-left-0 mb-2 py-3  " : ' border-bottom no-gutters  border-top-0 border-right-0 border-left-0 mb-2 py-3 '}>
          {/* <Col md={1} className="text-center">
         <span className=" m-auto"><Image width="50px" className="border"  src={user} roundedCircle /></span>
         </Col> */}
          <Col md={12}>
            <Row className="no-gutters px-2">
              <Col md={12} className="font-weight-bold">
                {item.first_name + ' ' + item.last_name}
              </Col>
              <Col md={12}>
              {item.attachments.length > 0 ? 
              <FontAwesomeIcon className='cursor-pointer btn-link' onClick={() => openlink(item.attachments[0].file_url)} icon='download' /> : ""
              }
                {" " + item.comment}
              </Col>
              <Col md={12}>
                <small> {dateWithTimeFormat(item.created_on)}</small>
              </Col>
            </Row>
          </Col>
        </Row>
      )) : 'No Comments.'}
    </>
  )
}
export default CommentList;