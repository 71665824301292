import React , { useEffect , useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import AppTable from '../../shared/AppTable';
import * as bioService from '../.././services/bio';
import * as loaderAction from '../../actions/loader';
import TooltipHover from '../../shared/TooltipHover';
import { history , Status } from '../../helpers';
import { useDispatch } from 'react-redux';
import { dateOnlyFormat } from "../../helpers";
import SearchField from '../../shared/SearchField';

const BioList = () => {

  const dispatch = useDispatch();
  const [bioList , setBioList ] = useState([]);
  const [status , setStatus] = useState(2);
  const [search, setSearch] = useState('')

  useEffect (() => {
    if(status !== ''){
      dispatch(loaderAction.loader(true));
      bioService.getBioList(status).then(([response, error]) => {
        if(response){
          setBioList(response.bio_list);
        }else if(error){
          console.log(error.response);
        }
        dispatch(loaderAction.loader(false));
      })
    }
  },[status , dispatch])

  const columns = [
    {
      Header: "Action",
      accessor: "action",
      filteringOff:true,
      className: "action-l text-center",
      Cell: (row) => (
       
        <div>
          {row.row.original.status === Status.UNDER_REVIEW.value  ?
          <TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/bio/'+row.row.original.application_id)} variant='link' iconClass='default' btnIcon='eye' message='View Bio' /> 
          : row.row.original.status === Status.APPROVED.value || row.row.original.status === Status.REJECTED.value ? 
          <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='eye' message='Bio Preview' handleEvent={() => history.push('/bioView/' + row.row.original.application_id)} /> 
         : '' }
           </div>
          
      ),
    },
    {
      Header: "Name",
      accessor: "full_name",
      sortType: "basic",
    },
    {
      Header: "Catlog",
      accessor: "specialization",
      sortType: "basic",
    },
    {
      Header: "Approved On",
      accessor: "approved_on",
      sortType: "basic",
      Cell: (row) => (<div>{row.row.original.approved_on !== null ? dateOnlyFormat(row.row.original.approved_on) : 'N/A'}</div>)
      },

  ];

  return (
    <Container fluid className="px-0">
    <Row>
      <Col md={6} sm={12} className="mb-md-0 mb-3"><h4>Bios List</h4></Col>
      
      <Col md={2} sm={12} className="text-right mb-md-0 mb-2">
      <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
            {Object.values(Status).map((item, i) => { return item.value === 1 || item.value === 2 || item.value  === 3  ? <option value={item.value} key={i}>{item.label}</option> : null }
            )}
          </Form.Control>
      </Col>
      <SearchField md={4} action={setSearch} />
    </Row>
    <AppTable columns={columns} globalFilter={search}  data={bioList ? bioList : [] } />
  </Container>
  )
}
export default BioList;
