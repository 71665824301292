import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { monthYearFormat } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Status } from '../../../helpers/constants';

const BioEducation = ({  education, disable }) => {

  return (
    <>
      {education.length > 0 ?
        <Card className="mt-1 border-0">
          {education.map((item, index) => (
            <Card.Body key={index} className="pb-0">
              <Row key={index}>
                <Col md={3} sm={12} className="text-info font-weight-bold text-truncate overflow-hidden" title={item.degree[0].university}>
                  {item.degree[0].university}
                </Col>
                <Col md={3} sm={12} className="text-warning text-left font-weight-bold text-truncate overflow-hidden" title={item.degree[0].course}>
                  {item.degree[0].course}
                </Col>
                <Col md={5} sm={12} className="text-info font-weight-bold font-style-italic text-right">
                  {monthYearFormat(item.degree[0].degree_month_year)}
                  <a className=" text-danger text-decoration-none ml-2" target="_blank" rel="noopener noreferrer" href={item.degree[1] ? item.degree[1].file_url : '#'}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>
                </Col>
                <Col md={1} sm={12}>
                 
                    {item.degree_status === Status.APPROVED.value ? '' : <>
                      <label className="switch">
                        <input type="checkbox" name={item.degree_id} onChange={(e) => null} disabled={disable} />
                        <span className="slider round"></span>
                      </label></>}
                  
                </Col>
              </Row>
            </Card.Body>
          ))} </Card>
        : 'No data found.'}
    </>
  )
}
export default BioEducation;