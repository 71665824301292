import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as loaderAction from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import * as educationServices from '../../services/education';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { monthYearFormat, getStatus } from '../../helpers';

const EducationInfo = () => {

     // getting User Id from url params
  const { userId } = useParams();

    const [educationList, setEducationList] = useState([]);

    const dispatch = useDispatch();

    const getAllEducation = useCallback(() => {
        dispatch(loaderAction.loader(true));
        educationServices.getEducationInfo(userId).then(([response , error]) => {
          if(response){
            setEducationList(response.education_list);
          }else if(error){
            dispatch(alertActions.error(error.response))
          }
          dispatch(loaderAction.loader(false));
        })
      },[dispatch, userId])

    useEffect(() => {
        getAllEducation()
      }, [getAllEducation])

      return (
        <>
          {educationList.length > 0 ?
            <Card className="py-3">
              {educationList.map((item, index) => (
  
                <Card.Body key={index} className="py-1">
                  <Row key={index}>
                    <Col md={3} sm={12} className="text-info font-weight-bold  text-truncate overflow-hidden" title={item.education[0].university}>
                    <label className="d-block text-left mb-0"><small>University</small></label>
                      <h6>{item.education[0].university}</h6>
                    </Col>
                    <Col md={3} sm={12} className=" text-left font-weight-bold text-truncate overflow-hidden" title={item.education[0].course}>
                    <label className=" d-block text-left mb-0"><small>Course</small></label>
                     <h6 className='text-warning'> {item.education[0].course}</h6>
                    </Col>
                    <Col md={2}>
                    <label className="d-block text-left mb-0"><small>Status</small></label>
                    <Badge pill variant="primary">
                    {getStatus(item.education[0].status) ? getStatus(item.education[0].status) : null}
                    </Badge>
                    </Col>
                    <Col md={3} sm={6}>
                    <label className="d-block text-left mb-0"><small>Passout Year</small></label>
                    <h6>{monthYearFormat(item.education[0].degree_month_year)}</h6>
                    </Col>
                    <Col md={1} sm={6} className="text-info font-weight-bold font-style-italic text-right">
                    <label className="d-block text-left mb-0"><small>&nbsp;</small></label>
                      <a className=" text-danger text-decoration-none  ml-2" target="_blank" rel="noopener noreferrer" href={item.education[1].file_url}>  <FontAwesomeIcon className="text-info nav-icons" icon="download" size="sm" /></a>
                    </Col>
                  </Row>
                  {educationList.length - 1 === index ? '' : <hr className="mt-1 mb-2" />}
                </Card.Body>
  
              ))} </Card>
            : 'No data found.'}
        </>
      )
  }

  export default EducationInfo;