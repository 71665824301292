import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as assetGreyService from '../../services/donotRankAsset';
import * as alertActions from '../../actions/alert';
import AppTable from "../../shared/AppTable";
import { useParams } from 'react-router-dom';
import TooltipHover from "../../shared/TooltipHover";
import { dateOnlyFormat, DonotRankAssetListType, history } from '../../helpers';
import { Container, Row, Col, Badge, Form } from 'react-bootstrap';
import SearchField from '../../shared/SearchField';
import * as alertAction from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import ConfirmModal from '../../shared/ConfirmModal';
import AsyncAppTable from '../../shared/AsyncAppTable';

const PendingGreyList = () => {

  const dispatch = useDispatch()
  const [pendingGreyList, setPendingGreyList] = useState([])
  const [search, setSearch] = useState('');
  const [listType, setListType] = useState(2)
  const [confirm, setConfirm] = useState({ show: false, id: 0 });
  const [confirm1, setConfirm1] = useState({ show: false, id: 0 });
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0);
  const [callFetch, setCallFetch] = useState(false);
  const [resultCount, setResultCount] = useState(0)


  let assetGreyId = useParams()
  console.log('id', assetGreyId.id);

  const getGreyListAssetsByType = useCallback((listVal,
    pageSize, pageIndex, sortedField = '', filter,
    ) => {
      const fetchId = ++fetchIdRef.current
    listVal = listVal || 2
    setLoading(true)
    dispatch(loaderAction.loader(true))
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const limit = listVal.pageSize;
        let offset = listVal.pageSize * listVal.pageIndex
        assetGreyService.getGreyListAssetsByType(assetGreyId.id, listType, 
          limit, offset, listVal.sortedField, search, listVal.filter
          ).then(([response, error]) => {
          if (response) {
            setResultCount(response.count)
            setPageCount(Math.ceil(response.count / listVal.pageSize))
            setPendingGreyList(response.results)
          } else if (error) {
            dispatch(alertActions.error(error.response))
          }
          dispatch(loaderAction.loader(false))
          setLoading(false);
          setCallFetch(false);          
        })
      }
    }, 100)
  }, [assetGreyId, dispatch, listType])

  const handleApprove = useCallback((id) => {
    if (id !== '') {
      // let data = { status: 2}
      dispatch(loaderAction.loader(true))
      assetGreyService.updateGreyListStatus(id).then(([response, error]) => {
        if (response) {
          getGreyListAssetsByType(1)
          dispatch(alertAction.success('Asset marked as Approved and will be assigned automatically'))
        } else if (error) {
          dispatch(alertAction.error(error.response))
        }
        dispatch(loaderAction.loader(false))
      })
    }
    setConfirm({ show: false, id: 0 })
  }, [getGreyListAssetsByType, dispatch, listType])

  const handleReject = useCallback((id) => {
    if (id !== '') {
      // let data = { status: 2}
      assetGreyService.rejectGreyListStatus(id).then(([response, error]) => {
        if (response) {
          getGreyListAssetsByType(3)
          dispatch(alertAction.success('Asset marked as Rejected'))
        } else if (error) {
          dispatch(alertAction.error(error.response))
        }
      })
    }
    setConfirm1({ show: false, id: 0 })
  }, [getGreyListAssetsByType, dispatch, listType])

  useEffect(() => {
    getGreyListAssetsByType()

  }, [
    getGreyListAssetsByType,
  ])

  const columns = [
    {
      Header: "Action",
      accessor: "action",
      sortingOff: true,
      className: "action-l text-left w-80px text-nowrap",
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' handleEvent={() => history.push('/rateFormTableView/' + cell.row.original.assets.id)} variant='link' iconClass='default' btnIcon='eye' message='View Asset' />
          {cell.row.original.status === 2 ?
            <>
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='user-check' message='Asset Approve' handleEvent={() => setConfirm({ show: true, id: cell.row.original.id })} />
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='danger' btnIcon='user-minus' message='Reject Asset' handleEvent={() => setConfirm1({ show: true, id: cell.row.original.id })} />
            </>
            : ''
          }
          {
            cell.row.original.status === 3 ?
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='default' btnIcon='user-check' message='Asset Approve' handleEvent={() => setConfirm({ show: true, id: cell.row.original.id })} />
              : ''
          }
          {
            cell.row.original.status === 1 ?
              <TooltipHover type='button' btnSize='sm' variant='link' iconClass='danger' btnIcon='user-minus' message='Reject Asset' handleEvent={() => setConfirm1({ show: true, id: cell.row.original.id })} />
              : ''
          }
        </div>
      ),
    },
    {
      Header: "Asset URL",
      accessor: "url",
      filtering: true,
      sortType: "basic",
      id: "assets__url",

      Cell: (cell) =>
        <div>
          <TooltipHover type='text' text={cell.row.original.assets.url} message={cell.row.original.assets.url} textClass='text-ellipsis d-block' handleEvent={() => null} />
        </div>
    },
    {
      Header: "Assets Topic",
      accessor: "assets",
      filtering: true,
      sortType: "basic",
      id: "assets__specific_topic",

      Cell: (cell) =>
        <div>
          <TooltipHover type='text' text={cell.row.original.assets.specific_topic} message={cell.row.original.assets.specific_topic} textClass='text-ellipsis d-block' handleEvent={() => null} />
        </div>
    },
    {
      Header: "Specific Topic",
      accessor: 'specific_topic',
      filtering: true,
      sortType: "basic",
      id: "assets__topic",

      Cell: (cell) =>
        <div>
          <TooltipHover type='text' text={cell.row.original.assets.topic} message={cell.row.original.assets.topic} textClass='text-ellipsis d-block' handleEvent={() => null} />
        </div>
    },
    {
      Header: "Asset Created On",
      accessor: "created_on",
      sortType: "basic",
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.assets.created_on)}</div>)
    },

  ];
  return (
    <Container fluid className="px-0">
      <ConfirmModal show={confirm.show} onHide={() => setConfirm({ show: false, id: 0 })} acceptConfirm={(id) => handleApprove(confirm.id)} title="Are you sure want to approve this asset ?" />
      <ConfirmModal show={confirm1.show} onHide={() => setConfirm1({ show: false, id: 0 })} acceptConfirm={(id) => handleReject(confirm1.id)} title="Are you sure want to reject this asset ?" />


      <Row className="no-gutters" >
        <Col md={6} className="mb-md-0 mb-3">
          <h4>Assets List </h4>
        </Col>
        <Col md={0} sm={2} className="mb-md-0 mb-2 pr-md-3 ">
          <Form.Control as="select" size={'md'} value={listType}
            onChange={(e) => setListType(e.target.value)}>
            {Object.values(DonotRankAssetListType).map((item, i) => { return item.value ? <option value={item.value} key={i}>{item.label}</option> : null }
            )}
          </Form.Control>
        </Col>
        <SearchField md={4} xs={10} action={setSearch} />

      </Row>
      {/* <AppTable columns={columns} globalFilter={search} data={pendingGreyList} /> */}
      <AsyncAppTable
        columns={columns}
        data={pendingGreyList}
        fetchData={getGreyListAssetsByType}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        filtering={true}
        callFetch={callFetch}
      />
    </Container>
  )
}

export default PendingGreyList