import React from 'react';
import { Tooltip, OverlayTrigger, Button, Popover, PopoverContent, PopoverTitle } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const TooltipHover = ({ type , btnSize , iconSize , icon , label , btnIcon, linkClass , text , textClass,  message , variant , btnClass , iconClass , placement , handleEvent, showGuide, pageMsg, isDisabled}) => {

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {message}
    </Tooltip>
  );
  const renderTooltipGuide  = (props) => (
      <Popover {...props}>
        <PopoverTitle as="h3"className="bg-highlight text-white">Let's get started</PopoverTitle>
        <PopoverContent className="py-3">
        <span className="display"> {pageMsg}</span>
        </PopoverContent>  
      </Popover>
  );

  const handleClickEvent = (e) => {
    if (handleEvent) {
      handleEvent(e)
    }
  }
  
  return (
    <>
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      defaultShow={showGuide}
      overlay={showGuide ? renderTooltipGuide : renderTooltip}
    >
      { type === 'button' ? 
       <span className="d-inline-block"><Button size={btnSize}  className={btnClass} onClick={(e) => handleClickEvent(e)} variant={variant} disabled={isDisabled}>{label ? label : null}
       { btnIcon ? <FontAwesomeIcon size={iconSize} className={iconClass} icon={btnIcon} /> : null}
       </Button></span>
       : type === 'icon' ? 
       <FontAwesomeIcon size={iconSize} className={iconClass} onClick={(e) => handleClickEvent(e)} icon={icon} > </FontAwesomeIcon> 
       : type === 'link' ? 
       <span className="d-inline-block" onClick={(e) => handleClickEvent(e)} className={'cursor-pointer  ' + linkClass} ><FontAwesomeIcon size={iconSize} className={iconClass} icon={btnIcon} /></span>
       : type === 'text' ?
       <span className="d-inline-block" onClick={(e) => handleClickEvent(e)} className={textClass}>{text}</span>
       : null
       }
      
    </OverlayTrigger>
    </>
  )
}
TooltipHover.propTypes = {
  type: PropTypes.any.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.any.isRequired,
  textClass: PropTypes.string,
  linkClass: PropTypes.string , 
  text : PropTypes.any,
  variant: PropTypes.string,
  btnClass: PropTypes.string,
  iconClass: PropTypes.string,
  placement: PropTypes.string,
  btnIcon: PropTypes.any,
  iconSize: PropTypes.string,
  btnSize : PropTypes.string,
  handleEvent: PropTypes.func
};

TooltipHover.defaultProps = {
  type: 'button',
  icon:  '',
  label: '',
  textClass: '' , 
  linkClass: '',
  text : '',
  message: 'This is a Tooltip message',
  variant:  'primary',
  btnClass: '',
  iconClass: '',
  placement: 'auto',
  btnIcon: '',
  iconSize: "1x",
  btnSize : '',
  handleEvent: () => undefined
};
export default TooltipHover
