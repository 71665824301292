import React, { useState , useCallback , useEffect} from 'react';
import Select from 'react-select';
import { REACT_SELECT_BASIC_CUSTOM_STYLE } from './SelectStyle';
import PropTypes from 'prop-types';

const AsyncSelect = ({multi , name ,  value, group , placeholder, style, disabled, getData , asyncMethod }) => {

  
   const [inputValue, setInputValue] = useState([]);
   const [selectedValue , setSelectedValue] = useState('');

  useEffect(() => {
    if(value === ''){
      setInputValue([]);
      setSelectedValue('');
    }
  },[value])

  const handleChangeAsset = useCallback((e) => {

    if(e !== null){
      const data1 = {
        name : name,
        value : e.value
      }
      setSelectedValue(e);  
      getData(data1)
    }else {
      const  dataEmpty = {
        name : name,
        value : ''
      }  
      setInputValue([]);
      getData(dataEmpty)
      setSelectedValue('');
    } 
  }, [getData , name ])


  const groupBadgeStyles = {
    backgroundColor: '#00b59d',
    borderRadius: '2em',
    color: '#fff',
    position:'absolute',
    right:15,
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <>
      {data.label}
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </>
  );

  const getDataAsync = useCallback((e) => {
    if(e.length > 2){
      asyncMethod(e).then(([response , error]) => {
        if(response){
          setInputValue(response);
        }
      })
    }
  },[asyncMethod])

  return (
    <>
      <Select
          isClearable
          options={inputValue}
          value={selectedValue}
          onChange={(value) => handleChangeAsset(value)}
          onInputChange={getDataAsync}
          styles={style}
          placeholder={placeholder}
          multi={multi}
          disabled={disabled}
          formatGroupLabel={group ? formatGroupLabel : ''}
        />
    </>
  );
}
AsyncSelect.propTypes = {
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  value : PropTypes.any , 
  placeholder : PropTypes.string,
  style : PropTypes.any,
  group : PropTypes.bool,
  handleEvent: PropTypes.func
};

AsyncSelect.defaultProps = {
  multi: false,
  disabled:false,
  value : '', 
  group: false ,
  placeholder : 'Select',
  style : REACT_SELECT_BASIC_CUSTOM_STYLE,
  handleEvent: PropTypes.func
};
export default AsyncSelect;