import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'buildout/';

export async function fetchAll() {
  return await handleApi(axiosClient.get(`${BASE}`));
}

export async function addTopic(data) {
  return await handleApi(axiosClient.post(`${BASE}`, data));
}

export async function updateTopic(id , data) {
  return await handleApi(axiosClient.put(`${BASE}${id}/`, data));
}

export async function deleteTopic(id) {
  return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}
