import React, { useEffect, useRef, useState, useCallback } from "react";
import TooltipHover from "../../shared/TooltipHover";
import {
  Container,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import * as criticismServices from "../../services/criticism";
import { useDispatch } from "react-redux";
import * as alertActions from "../../actions/alert";
import { useBoolean } from "../../hooks/basic";
import * as loaderAction from "../../actions/loader";
import ContactForm from "./ContactForm";
import ConfirmModal from "../../shared/ConfirmModal";
import AsyncAppTable from "../../shared/AsyncAppTable";
import { defaultLimit, QueriesStatus } from "../../helpers";
import moment from "moment"
import { dateOnlyFormat, MembersType } from '../../helpers';
import SearchField from '../../shared/SearchField';

const ConstructiveCriticism = () => {
  const dispatch = useDispatch();
  const [criticismData, setCriticismData] = useState([]);
  const fetchIdRef = useRef(0);
  const [editData, setEditData] = useState("");
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [confirm, showConfirm, hideConfirm] = useBoolean(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [resultCount, setResultCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [memberType , setMemberType] = useState(1)
  const [status , setStatus] = useState(1);
  const [callFetch, setCallFetch] = useState(false);

  const fetchData = useCallback(({pageSize, pageIndex, sortedField="", filter, }) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        dispatch(loaderAction.loader(true));
        const limit = pageSize;
        let offset = pageIndex * pageSize;
        criticismServices.getCriticismList(search, limit, offset, sortedField, filter, memberType, status).then(([response, error]) => {
          if (response) {
            let result = response.results;
            setPageCount(Math.ceil(response.count / pageSize))
            setResultCount(response.count)
            setCriticismData(result);
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        });
        setLoading(false);
        setCallFetch(false);
      }
    }, 100);
  }, [dispatch, search, memberType, status]);

  const deleteData = useCallback(() => {
    dispatch(loaderAction.loader(true));
    criticismServices.deleteTicket(id, name).then(([response, error]) => {
      // fetchData(defaultLimit);
      setCallFetch(true);
      hideConfirm();
      if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    });
  }, [dispatch, id, hideConfirm, name]);

  // useEffect(() => {
  //   fetchData(defaultLimit);
  // }, [fetchData]);

  const openModal = useCallback(
    (data) => {
      if (data) setEditData(data);
      showModal();
    },
    [showModal]
  );

  const closeModal = useCallback(() => {
    setEditData("");
    // fetchData(defaultLimit);
    setCallFetch(true);
    hideModal();
  }, [hideModal]);

  const openConfirmModal = (id, user) => {
    setId(id);
    setName(user);
    showConfirm();
  };

  const closeConfirmModal = () => {
    setId("");
    hideConfirm();
  };

  const acceptConfirmation = useCallback(() => {
    deleteData();
  }, [deleteData]);

  const columns = [
    {
      Header: "Action",
      accessor: "action",
      sortingOff: true,
      className: "action-l",
      Cell: (cell) => (
        <div>
          <TooltipHover
            type="button"
            btnSize="sm"
            variant="link"
            btnIcon="eye"
            iconClass="default"
            message="View Ticket "
            handleEvent={() => openModal(cell.row.original)}
          />
          <TooltipHover
            type="button"
            btnSize="sm"
            placement="bottom"
            variant="link"
            iconClass="text-danger"
            btnIcon="trash"
            message="Delete Ticket"
            handleEvent={() => openConfirmModal(cell.row.original.id, cell.row.original.first_name ? cell.row.original.first_name : '')}
          />
        </div>
      ),
    },
    {
      Header: "Ticket Id",
      accessor: "query_ticket",
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Name",
      accessor: "user__first_name",
      sortType: "basic",
      className: "text-capitalize",
      filtering:memberType === 2 ? false: true,
      sortingOff:memberType === 2 ? true: false,
      Cell: (cell) => (
        <div>
          {cell.row.original.first_name || cell.row.original.last_name
            ? cell.row.original.first_name + " " + cell.row.original.last_name
            : "Guest user"}
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      sortType: "basic",
      id:memberType === 2 ? 'email': "user__email",
      filtering:true,
    },
    {
      Header: "Subject",
      accessor: "subject",
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
    },

  ];


  return (
    <>
      <ConfirmModal
        show={confirm}
        onHide={closeConfirmModal}
        acceptConfirm={acceptConfirmation}
        title={"Do you want delete this ticket ? "}
      />
      <ContactForm
        data={editData}
        title="Contructive Criticism Reply"
        isVisible={isVisible}
        hide={closeModal}
      />
      <Container fluid className="px-0">
        <Row>
          <Col md={4} className="mb-md-0 mb-3">
            <h4> Constructive Criticism </h4>
          </Col>
          <Col md={2} className="mb-md-0 mb-2">
            <Form.Control
              as="select"
              size={"md"}
              value={memberType}
              onChange={(e) => setMemberType(Number(e.target.value))}
            >
              {Object.values(MembersType).map((item, i) => {
                return item.value ? (
                  <option value={item.value} key={i}>
                    {item.label}
                  </option>
                ) : null;
              })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12} xs={12} className="mb-md-0 mb-2">
                    <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
                    {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
                    )}
                    </Form.Control>
                </Col>
          <SearchField md={4} action={setSearch}/>
        </Row>
        <AsyncAppTable
          columns={columns}
          data={criticismData}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          resultCount={resultCount}
          filtering={true}
          callFetch={callFetch}
        />
      </Container>
    </>
  );
};

export default ConstructiveCriticism;
